import React from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons'
import CircularProgress from '@material-ui/core/CircularProgress'

moment.locale('de')

export const MatchInfo = ({
  match,
  openVideoPlayer,
  handleVideoPlayer,
  tagState,
  trelloTicketLink,
  cancelledReasonDescription
}) => {
  const time = moment(match.startTime).format('LT')
  const date = moment(match.startTime).format('L')
  let url = 'https://soccerwatch.tv'

  if (match.watermark === 'aisportswatch') {
    url = 'https://aisw.tv'
  }

  return (
    <div className='content-infobox' id='infotool-infobox'>
      <table className='infobox-table'>
        <tbody>
        <tr>
          <th>Spiel ID:</th>
          <td
            style={{ cursor: 'pointer', color: '#2888B7' }}
            onClick={() => { window.open(url + '/video/' + match.RowKey) }}
          >
            {match.RowKey}
          </td>
        </tr>
        <tr>
          <th>Startzeit:</th>
          <td>{date}, {time}Uhr</td>
        </tr>
        <tr>
          <th>Priorität:</th>
          <td>{match.priority || 'keine prio vorhanden'}</td>
        </tr>
        <tr>
          <th>Paywall:</th>
          <td>{match.paywall ? 'ja' : 'nein'}</td>
        </tr>
        <tr>
          <th style={{ paddingBottom: '30px' }}>Sportart:</th>
          <td style={{ paddingBottom: '30px' }}>{match.videoType || 'unbekannt'}</td>
        </tr>
        <tr>
          <th>Spielstatus:</th>
          <td>{match.active ? 'Aktiv' : 'Deaktiviert'}</td>
        </tr>

        {match.scoreA !== undefined && match.scoreB !== undefined ? (
          <tr>
            <th>Spielergebnis:</th>
            <td>{match.scoreA} : {match.scoreB}</td>
          </tr>
        ) : null}
        <tr>
          <th>Spielaufnahme:</th>
          {match.userStream ? (
            <td
              style={{ cursor: 'pointer', color: '#2888B7' }}
              onClick={() => { handleVideoPlayer() }}
              title={openVideoPlayer ? 'Schließe Video Player' : 'Öffne Video Player'}
            >
              <FontAwesomeIcon icon={openVideoPlayer ? faVideoSlash : faVideo} />
            </td>
          ) : (
            <td>
              Nicht vorhanden
            </td>
          )}
        </tr>
        {cancelledReasonDescription ? <tr>
          <th>
            Deaktivierungsgrund:
          </th>
          <td>
            {cancelledReasonDescription}
          </td>
        </tr> : null}
        <tr>
          <th>Tagging Status:</th>
          <td>{tagState || <CircularProgress style={{ width: '18px', height: '18px' }} />}</td>
        </tr>
        <tr>
          <th />
          <td>
            {trelloTicketLink ? <a style={{ color: '#2888B7' }} href={trelloTicketLink} target='_blank'>Link zum
              Trelloticket</a> : ''}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  )
}
