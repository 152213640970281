import React, { Component } from 'react'
import HeadText from '../../modules/static/tableHeading'
import Grid from '@material-ui/core/Grid'
import Table from '../../modules/static/table'
import discovery from '@soccerwatch/discovery'

import { header, getLazyLoading, getLazyLoadingWithoutDataObj } from '../../api/helper'
// import { getAllContract, createContract } from '../../api/api-contract'
import { postContract } from '../../api/api-user'
import { storageLayerTableInfo } from '../../modules/static/config/storageLayerTableConfig'
import { Snackbar } from '@material-ui/core'
import { eventModes, eventType } from '../static/dropdownValues'

const config = {
  tableName: 'contractTable',
  tableUrl: '/contracts/',
  tableDialogTitle: 'Vertrag',
  tableToolbar: true,
  tableAdd: true,
  columns: [
    { name: 'RowKey', title: 'RowKey', editable: false, width: 140 },
    { name: 'name', title: 'name', toCreate: true },
    { name: 'active', title: 'active', value: 'boolean', toCreate: true, default: true },
    { name: 'isDummy', title: 'isDummy', value: 'boolean', toCreate: true },
    // { name: 'cameraIds', title: 'cameraIds', value: 'array', toCreate: true, width: 140 },
    { name: 'cameraIds', title: 'cameraIds', value: 'tagInput', label: 'CameraIds', placeholder: 'CameraId', toCreate: true, width: 140 },
    // { name: 'clubIds', title: 'clubIds', value: 'array', toCreate: true, width: 140 },
    { name: 'clubIds', title: 'clubIds', value: 'tagInput', label: 'ClubIds', placeholder: 'ClubId', toCreate: true, width: 140 },
    { name: 'eventTypes', title: 'Erlaubte Events', value: 'tagInput', dropdownValue: [{ value: '', label: '' }, ...eventType], toCreate: true, width: 140, tooltip: 'Liste der angezeigten Events in Backstaige, ist kein Event angegeben, werden alle EventTypen angezeigt' },
    { name: 'eventModes', title: 'Erlaubte Modis', value: 'tagInput', dropdownValue: eventModes, toCreate: true, width: 140, tooltip: 'Liste der angezeigten Modis in Backstaige, ist kein Mode angegeben, werden alle Modi angezeigt, broadcast: Ist immer drin, auch wenn dieser mode hier nichts gesetzt wurde.' },
    { name: 'contingentAbos', title: 'contingentAbos', value: 'objectOverlay', toCreate: true, disabled: true },
    { name: 'contractContingents', title: 'contractContingents', value: 'contractContingents', toCreate: false },
    { name: 'contractMerge', title: 'contractMerge', value: 'contractMerge', toCreate: false, disabled: true },
    { name: 'adManagerSlots', title: 'adManagerSlots', value: 'number', toCreate: true, default: -1 },
    { name: 'cameraOwnerSlots', title: 'cameraOwnerSlots', value: 'number', toCreate: true, default: -1 },
    { name: 'commentatorSlots', title: 'commentatorSlots', value: 'number', toCreate: true, default: -1 },
    { name: 'contractOwnerSlots', title: 'contractOwnerSlots', value: 'number', toCreate: true, default: -1 },
    { name: 'taggerSlots', title: 'taggerSlots', value: 'number', toCreate: true, default: -1 },
    { name: 'streamerSlots', title: 'streamerSlots', value: 'number', toCreate: true, default: -1 },
    { name: 'trainerSlots', title: 'trainerSlots', value: 'number', toCreate: true, default: 0 },
    // { name: 'streamingMinutes', title: 'streamingMinutes', value: 'number', toCreate: false, editable: false },
    // { name: 'streamingMinutesUsed', title: 'streamingMinutesUsed', value: 'number', toCreate: false, editable: false },
    { name: 'contractUserManagement', title: 'contractUserManagement', value: 'contractUserManagement', toCreate: false, disabled: true },
    { name: 'privateActiveTeams', title: 'privateActiveTeams', value: 'tagInput', toCreate: true, width: 140 },
    { name: 'privateActiveCompetitions', title: 'privateActiveCompetitions', value: 'tagInput',toCreate: true, width: 140 },
    { name: 'disabledSetContractId', title: 'disabledSetContractId', value: 'boolean', tooltip: 'Für Backstaige, wenn dieser Wert aktiv ist, wird die ContractId bei Aufnahmen nicht gesetzt. "Für das Supportteam"',toCreate: true, default: false },
    { name: 'mayDeactivateAi', title: 'mayDeactivateAi', value: 'boolean', tooltip: 'Erlaubt Nutzern des Contracts, die Cameraführung für ein Spiel abzuschalten. Nur für die interne Verwendung', toCreate: true, default: false },
    ...storageLayerTableInfo
  ],
  defaultHiddenColumnNames: [{ label: 'Alles', value: [''] }]
}

class Contract extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: undefined,
      rows: [],
      tempId: 0,
      check: false,
      loadingRows: true,
      loadingCall: false
    }

    this.handleAddColum = this.handleAddColum.bind(this)
  }

  _isMounted = false;

  handleId = (id) => {
    if (id != null) {
      this.setState({ clubs: [], tempId: id })
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.handleGetCall()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleMounted = () => {
    return this._isMounted
  }

  handleGetContractList = (res, checkLast) => {
     // Sort and ADD Id by Rows
     res.sort((a, b) => {
      return null
    }).map((row, index) => {
      row.tableId = index
      return row
    })

    if (checkLast === true) {
      this.setState({
        rows: res,
        loadingRows: false
      })
    } else {
      this.setState({
        rows: res
      })
    }
  }

  handleGetCall = (check) => {
    const userId = this.props.match.params.UserId
    const getUrl = userId ?
      discovery.API_USER + `/Contracts/user/${userId}` : discovery.API_USER + '/Contracts'

    if (check) {
      this.state.rows.splice(0, this.state.rows.length)
      this.setState({ check: false, loadingRows: true })
    }

    const configArray = [
      {
        url: getUrl,
        minEntrys: undefined,
        auth: header,
        handle: this.handleGetContractList
      }
    ]

    if (userId) {
      getLazyLoadingWithoutDataObj(configArray, this.handleMounted)
    } else {
      getLazyLoading(configArray, this.handleMounted)
    }
  }

  handleEditColum = async (data) => {
    this.setState({ loadingCall: true })
    let checkPostStatus = false
    // SET 'RowKey' to Json, need this for backend
    data.json.RowKey = data.row.RowKey
    if (data.json.name && data.json.name === '') {
      this.setState({ error: 'Der Name darf nicht Leer sein!' })
      return
    }
    if (data.json.clubIds && !data.json.clubIds.length) {
      this.setState({ error: 'Es muss mindestens eine ClubId gesetzt sein!' })
      return
    } 
    if (data.json.camera &&  !data.json.cameraIds.length) {
      this.setState({ error: 'Es muss mindestens eine CameraIds gesetzt sein!' })
      return
    }
    await postContract(data.json).then((res) => {
      if (res.status === 200) {
        checkPostStatus = true
        this.setState({ loadingCall: false })
      }
    })

    return checkPostStatus
  }

  async handleAddColum(data) {
    let checkPostStatus = false
    if (!data.json.name || data.json.name === '') {
      this.setState({ error: 'Der Name darf nicht Leer sein!' })
      return
    }
    if (!data.json.clubIds || !data.json.clubIds.length) {
      this.setState({ error: 'Es muss mindestens eine ClubId gesetzt sein!' })
      return
    }
    if (!data.json.cameraIds || !data.json.cameraIds.length) {
      this.setState({ error: 'Es muss mindestens eine CameraIds gesetzt sein!' })
      return
    }
    data.json.clubIds = data.json.clubIds
    data.json.cameraIds = data.json.cameraIds
    data.json.contingentAbos = data.json.contingentAbos || []
    data.json.contractOwnerSlots = data.json.contractOwnerSlots || -1
    data.json.cameraOwnerSlots = data.json.cameraOwnerSlots || -1
    data.json.trainerSlots = data.json.trainerSlots || -1
    data.json.commentatorSlots = data.json.commentatorSlots || -1
    data.json.taggerSlots = data.json.taggerSlots || -1
    data.json.streamingMinutes = data.json.streamingMinutes || 0
    data.json.streamingMinutesUsed = data.json.streamingMinutesUsed || 0
    data.json.adManagerSlots = data.json.adManagerSlots || -1
    data.json.streamerSlots = data.json.streamerSlots || -1
    data.json.eventTypes = data.json.eventTypes || []
    data.json.eventModes = data.json.eventModes && data.json.eventModes.length > 0 ? data.json.eventModes : ['broadcast', 'training']

    await postContract(data.json).then((res) => {
      if (res.status === 200) {
        checkPostStatus = true
      }
    })

    return checkPostStatus
  }


  handleSnackbarClose = () => {
    this.setState({ error: undefined })
  }

  render() {
    return (
      <div>
        <HeadText text={this.props.headingText} />
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Table
              resetData={this.handleGetCall}
              onSelectId={this.handleId}
              tableConfig={config}
              tableRows={this.state.rows}
              param={this.props.match}
              loadingData={this.state.loadingRows}
              handleEditRow={this.handleEditColum}
              handleCreateRow={this.handleAddColum}
              loadingCall={this.state.loadingCall}
            />
          </Grid>
        </Grid>
        <Snackbar
          open={!!this.state.error}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          message={this.state.error}
          action={this.handleSnackbarClose}
        />
      </div>
    )
  }
}

export default Contract
