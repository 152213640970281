import React, { Component } from 'react'
import HeadText from '../../modules/static/tableHeading'
import Grid from '@material-ui/core/Grid'
import Table from '../../modules/static/table'
import discovery from '@soccerwatch/discovery'
import { addOrEditBlacklist, deleteBlacklist } from '../../api/api-blacklist'
import { header, getLazyLoading } from '../../api/helper'
import Paper from '@material-ui/core/Paper'
import { storageLayerTableInfo } from '../../modules/static/config/storageLayerTableConfig'

const config = {
  tableName: 'blacklist',
  tableUrl: '/blacklist/',
  tableDialogTitle: 'Blacklist',
  tableToolbar: true,
  tableAdd: true,
  columns: [
    { name: 'RowKey', title: 'RowKey', width: 85, toEditable: false, toCreate: true },
    { name: 'clubName', title: 'Club', width: 140, value: 'string', toEditable: true, toCreate: true, tooltip: 'Hat keine Auswirkung, ist nur zu Information da.' },
    { name: 'agesWhitelist', title: 'agesWhitelist', value: 'tagInput', width: 330 },
    { name: 'ages', title: 'ages', value: 'tagInput', width: 330 }, 
    { name: 'teamLeagues', title: 'teamLeagues ', value: 'objectGeneric', arrayConfig: [{ name: 'league' }, { name: 'teamId' }], width: 330, disabled: true, toCreate: true, },
    { name: 'leagues', title: 'leagues', value: 'tagInput', width: 330 },
    { name: 'lastModAt', title: 'lastModAt', width: 330, toEditable: false, toCreate: false },
    { name: 'lastModBy', title: 'lastModBy', width: 330, toEditable: false, toCreate: false },
    ...storageLayerTableInfo
  ],
  defaultHiddenColumnNames: [{ label: 'Alles', value: [''] }]
}

const defaultBlacklistAges = ['B-Junioren', 'B-Juniorinnen', 'C-Junioren', 'C-Juniorinnen', 'D-Junioren', 'D-Juniorinnen', 'E-Junioren', 'E-Juniorinnen', 'F-Junioren', 'F-Juniorinnen', 'G-Junioren', 'G-Juniorinnen', 'Bambini'].join(', ')

class Blacklist extends Component {
  constructor (props) {
    super(props)

    this.state = {
      rows: [],
      tempId: '0',
      check: false,
      loadingCall: false
    }
  }

  _isMounted = false;

  handleId = (id) => {
    if (id != null) {
      this.setState({ tempId: id })
    }
  };

  /**
   * Api-call get all GenericList
   */
  componentDidMount () {
    this._isMounted = true
    this.handleGetCall()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  handleMounted = () => {
    return this._isMounted
  }

  handleGetCall = (check) => {
    if (check) {
      this.state.rows.splice(0, this.state.rows.length)
      this.setState({ check: false, loadingRows: true })
    }

    const configArray = [
      {
        url: discovery.API_BLACKLIST + '/blacklist/',
        minEntrys: undefined,
        auth: header,
        handle: this.handleGetAdList
      }
    ]

    getLazyLoading(configArray, this.handleMounted)
  }

  handleGetAdList = (res, checkLast) => {
    // Sort and ADD Id by Rows
    res.sort((a, b) => {
      return Number(a.RowKey) - Number(b.RowKey)
    }).map((row, index) => {
      row.tableId = index
      return row
    })

    if (checkLast === true) {
      this.setState({
        rows: res,
        loadingRows: false
      })
    } else {
      this.setState({
        rows: res
      })
    }
  }

   handleEditColum = async (data) => {
     this.setState({ loadingCall: true })
     let checkPostStatus = false

     data.json.RowKey = data.row.RowKey

     await addOrEditBlacklist(data.json).then((res) => {
       if (res.status === 200) {
         checkPostStatus = true
       }
     })

     this.setState({ loadingCall: false })
     return checkPostStatus
   }

  handleDeleteColum = async (data) => {
    let checkPostStatus = false
    await deleteBlacklist(data.row.RowKey).then((res) => {
      if (res.status === 200) {
        checkPostStatus = true
      }
    })
    return checkPostStatus
  }

   handleAddColum = async (data) => {
     this.setState({ loadingCall: true })
     let checkPostStatus = false

     if (data.json.RowKey) {
       await addOrEditBlacklist(data.json).then((res) => {
         if (res.status === 200) {
           checkPostStatus = true
         }
       })
     }

     this.setState({ loadingCall: false })
     return checkPostStatus
   }

   render () {
     return (
       <div>
         <HeadText text={this.props.headingText} />
         <Grid container spacing={24}>
           <Grid item xs={12}>
             <Paper>
               <div className='layout-body'>
                 Folgende ages sind bei jedem Verein vorhanden:<br /><br />
                 {defaultBlacklistAges}<br /><br />
                 ages = Altersklassen, die zusätzlich auf der Blacklist sind<br />
                 agesWhitelist = Altersklassen, die von dieser Blacklist entfernt werden<br />
                 leagues = Mannschafts / Ligen Kombination, anhand der zusätzlich gefiltert wird<br /><br />
                 <div className='layout-body layout-body-warning'>ACHTUNG: Jeder Eintrag muss GENAU wie in der Fußball.de Liste geschrieben werden!</div>
               </div>
             </Paper>
           </Grid>
           <Grid item xs={12}>
             <Table
               resetData={this.handleGetCall}
               onSelectId={this.handleId}
               tableConfig={config}
               tableRows={this.state.rows}
               handleEditRow={this.handleEditColum}
               handleDeleteRow={this.handleDeleteColum}
               handleCreateRow={this.handleAddColum}
               loadingCall={this.state.loadingCall}
             />
           </Grid>
         </Grid>
       </div>
     )
   }
}

export default Blacklist
