import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from '@material-ui/core'
import { getByIdList } from '../../../../../api/api-city'
import AiswSpinner from '../../../../../common/AiswSpinner'
import { getCitySearch } from '../../../../../api/api-search'

// import './TagInput.scss'

class TagInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openOverlay: false,
      value: '',
      loadingList: true,
      citysNameIds: undefined,
      citis: undefined
    }
  }

  componentDidMount() {
    if(this.props.items.length === 0) {
      return this.setState({ loadingList: false })
    }
    getByIdList(this.props.items).then(res => {
      if(res.status === 200) {
        this.setState({
          citysNameIds: res.data,
          loadingList: false
        })
      } else {
        this.setState({
          loadingList: false
        })
      }
    })
  }

  handleOpen = () => {
    this.setState({ openOverlay: true })
  }

  handleClose = () => {
    this.setState({ openOverlay: false })
  }
  onChangeValue = (e) => {
    const value = e.currentTarget.value
    this.setState({ value })

    if(value === '' ) {
      this.setState({
        citis: undefined
      })
    } else {
      getCitySearch(value).then(res => {
        // console.log(res)
        if(res.data && res.data.hits) {
          const result = this.filterDuplicatesById(res.data.hits.hits)
          this.setState({
            citis: result
          })
        }
      })
    }
  }

  filterDuplicatesById(arr) {
    const uniqueIds = {};
    const result = [];
    for (const obj of arr) {
      const id = obj._id;
      if (!uniqueIds[id]) {
        result.push(obj);
        uniqueIds[id] = true;
      }
    }
    return result;
  }

  onAddItem = (e) => {
    if (!this.props.items.includes(e.RowKey)) {
      const added = this.props.onAddItem(e.RowKey)
      const citysNameIds = { ...this.state.citysNameIds,  [e.RowKey]: { name: e.name }}
      if (added) {
        this.setState({ 
          value: '',
          citis: undefined,
          citysNameIds: citysNameIds
        })
      }
    }
  }

  onRemoveItem = (index) => {
    const item = this.props.items[index]
    this.props.onRemoveItem(item, index)
  }

  render() {
    return (
      <Grid item xs={12} style={{ width: '100%' }}>
        <div className='textField-label'>
          {this.props.addLabel}
          {this.props.tooltip ? (
            <Tooltip disabled title={this.props.tooltip} placement="bottom-end">
              <div className="tooltip-icon">?</div>
            </Tooltip>
          ) : null}
        </div>

        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <TextField
            className='textField-table'
            id='name'
            label={this.props.placeholder}
            value={this.state.value}
            onChange={this.onChangeValue}
            margin='dense'
          />
        </div>

        {this.state.citis && this.state.citis.length > 0 && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 4, marginBottom: 8 }}>
            {this.state.citis && this.state.citis.map(res => { return (
              <Button onClick={() => this.onAddItem(res._source)} color='primary' variant='text' style={{ fontSize: 16, justifyContent: 'space-between', border: '1px solid #c7c7c7' }}>
                 {res._source.name} <FontAwesomeIcon icon={faPlusCircle} />
              </Button>
            ) }) }
          </div>
        )}

        <div style={{ display: 'flex', flexWrap: 'wrap', maxHeight: 400, overflow: 'auto' }}>
         
          {this.state.loadingList ? (
            <div style={{ marginTop: 10, width: '100%' }}>  
              <AiswSpinner />
            </div>
          ) : null}

          {this.props.items && !this.state.loadingList && this.props.items.length === 0 ? (
            <div style={{ marginTop: 10, width: '100%', color: '#00000036' }}>  
              noch keine stadt hinterlegt.
            </div>
          ) : null}

          {this.props.items && !this.state.loadingList && Array.isArray(this.props.items) ? this.props.items.sort((a, b) => this.state.citysNameIds[a].name.localeCompare(this.state.citysNameIds[b].name)).map((itm, index) => {
            return (
              <div key={itm} style={{ flexShrink: '0', padding: '0 10px 10px 0' }}>
                <div style={{ padding:' 6px 10px', background: '#FF35173b', borderRadius: '20px' }}>

                {this.state.citysNameIds && this.state.citysNameIds[itm] ? this.state.citysNameIds[itm].name : String(itm)}
              
                <Button onClick={() => { this.onRemoveItem(index) }} color='primary' variant='text' style={{ fontSize: 16, padding: 0, margin: 0, minWidth: 0, marginTop: '-2px', marginLeft: '6px' }}>
                  <FontAwesomeIcon icon={faMinusCircle} />
                </Button>
                </div>
              </div>
            )
          }) : null}
        </div>
      </Grid>

    )
  }
}

export default TagInput
