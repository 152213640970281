import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

class objectGeneric extends Component {
  constructor(props) {
    super(props)

    let obj
    this.props.row.arrayConfig.map((conf) => {
      obj = {...obj, [conf.name]: ''}
    })
    this.state = {
      openOverlay: false,
      value: obj
    }
  }

  handleOpen = () => {
    this.setState({ openOverlay: true })
  }

  handleClose = () => {
    this.setState({ openOverlay: false })
  }
  onChangeValue = (e, name) => {
    const value = { ...this.state.value, [name]: e.currentTarget.value }
    this.setState({ value })
  }

  onAddItem = (e) => {
    if (!this.props.value.includes(this.state.value) && this.state.value !== '') {
      const added = this.props.onAddItem(this.state.value)
      if (added) {
        let obj
        this.props.row.arrayConfig.map((conf) => {
          obj = {...obj, [conf.name]: ''}
        })
        this.setState({ value: obj })
      }
    }
  }

  onRemoveItem = (index) => {
    const item = this.props.value[index]
    this.props.onRemoveItem(item, index)
  }

  checkValid = () => {
    let check = false
    this.props.row.arrayConfig.map((conf) => {
      if (!this.state.value[conf.name]) {
        check = true
      }
    })
    return check
  }

  render() {
    return (
      <Grid item xs={12} style={{ margin: '0 0', width: '100%' }}>
        <div className='textField-label'>{this.props.addLabel}</div>
        <div style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '20px' }}>
          
          {this.props.row.arrayConfig.map((conf, index) => {
              return (
                <TextField
                  className='textField-table'
                  id={conf.name}
                  label={conf.name}
                  value={this.state.value[conf.name]}
                  onChange={(e) => this.onChangeValue(e, conf.name)}
                  margin='dense'
                  style={{ marginLeft: index > 0 ? '10px' : '' }}
                />
              )
          })}
          
          <Button disabled={this.checkValid()} onClick={this.onAddItem} color='primary' variant='text' style={{ fontSize: 24 }}>
            <FontAwesomeIcon icon={faPlusCircle} />
          </Button>
        </div>
        {/*<div>{this.props.listLabel}</div>*/}
        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '-14px', whiteSpace: 'nowrap' }}>
          {this.props.value ? this.props.value.map((itm, index) => {
            return (
              <div key={itm} style={{ flexShrink: '0', padding: '0 10px 10px 0' }}>
                <div style={{ padding:' 6px 10px', background: '#FF35173b', borderRadius: '20px', fontSize: '12px' }}>

                <Button onClick={() => { this.onRemoveItem(index) }} color='primary' variant='text' style={{ top: '-10px', right: '-16px', float: 'right', fontSize: 16, padding: 0, margin: 0, minWidth: 0, marginTop: '-2px', marginLeft: '6px' }}>
                  <FontAwesomeIcon icon={faMinusCircle} />
                </Button>

                {this.props.row.arrayConfig.map((conf) => {
                    return <div>{conf.name +': '+itm[conf.name]} </div>
                })}
                </div>
              </div>
            )
          }) : null}
        </div>
      </Grid>

    )
  }
}

export default objectGeneric
