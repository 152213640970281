import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { getAuthorization } from './helper'
rax.attach()

export const postTrainerToken = async (obj) => {
  const json = JSON.stringify(obj)
  return axios.post(discovery.API_USER + '/licenseToken/issue/', json, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}
