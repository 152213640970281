import React, { Component } from 'react'
import HeadText from '../../modules/static/tableHeading'
import Grid from '@material-ui/core/Grid'
import Table from '../../modules/static/table'
import discovery from '@soccerwatch/discovery'
import OverlayLanguage from './overlay/overlayImportMatch'
import Button from '@material-ui/core/Button'

import { setRegion } from '../../api/api-region'
import { header, getLazyLoading } from '../../api/helper'
import { storageLayerTableInfo } from '../../modules/static/config/storageLayerTableConfig'
import { getSignedUrlGeneric, uploadGeneric } from '../../api/api-mediaupload'


const noImg = 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_noimg.png'

const config = {
  tableName: 'region',
  tableUrl: '/region/',
  tableDialogTitle: 'Region',
  tableToolbar: true,
  tableAdd: true,
  columns: [
    { name: 'RowKey', title: 'RowKey', width: 140, toCreate: true, toEditable: false },
    { name: 'active', title: 'Aktiv', value: 'boolean', width: 100, toCreate: true },
    { name: 'name', title: 'Name', width: 200, toCreate: true },
    { name: 'link', title: 'Staige.tv Sprachdatei',  width: 160, disableDialog: true, toCreate: true },
    { name: 'isPromoter', title: 'isPromoter', width: 100, value: 'boolean', toCreate: true },
    { name: 'regionShort', title: 'Kurzbezeichnung für Region', width: 180, toCreate: true },
    { name: 'country', title: 'country', width: 140, toCreate: true },
    // { name: 'excludeCity', title: 'excludeCity', value: 'tagInput', width: 180, toCreate: true },
    // { name: 'includeCity', title: 'includeCity', value: 'tagInput', width: 180, toCreate: true },
    { name: 'cityIds', title: 'includeCity', value: 'tagInputCity', placeholder: 'stadt', width: 180, toCreate: true },

    { name: 'eventType', title: 'eventType', value: 'tagInput', width: 140, toCreate: true },
    { disabled: true, name: 'mediaImg', title: 'img', connectionRowName: 'thumbnail', imagesUpload: { width: 1800, height: 343, noImgUrl: noImg, methode: 'nojimp', fileFormat: ['png', 'jpeg', 'gif'] } },
    { name: 'clubIds', title: 'clubIds', value: 'tagInput', width: 140, toCreate: true },
    { name: 'thumbnail', title: 'Vorschaubild', width: 200, toCreate: true },
    { name: 'latitude', title: 'latitude', width: 160, toCreate: true },
    { name: 'longitude', title: 'longitude', width: 160, toCreate: true },
    { name: 'tenant', title: 'tenant', value: 'tagInput', label: 'tenant', toCreate: false, width: 180 },
    ...storageLayerTableInfo
  ],
  defaultHiddenColumnNames: [{ label: 'Alles', value: [''] }]
}

class Region extends Component {
  constructor (props) {
    super(props)

    this.state = {
      rows: [],
      tempId: '0',
      check: false,
      loadingCall: false,
      uploadProcess: undefined
    }

    this.langOverlay = React.createRef()
  }

  _isMounted = false;

  handleId = (id) => {
    if (id != null) {
      this.setState({ tempId: id })
    }
  };

  /**
   * Api-call get all GenericList
   */
  componentDidMount () {
    this._isMounted = true
    this.handleGetCall()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  handleMounted = () => {
    return this._isMounted
  }

  async uploadFile (data, fileName) {
    if (data.json.newThumbnail) {
      let file = data.json.newThumbnail
      const type = file.search('image/gif') >= 0 ? 'image/gif' : 'image/png'
      const imgType = file.search('image/gif') >= 0 ? '.gif' : '.png'
      
      // data.json.mediaType = 'image'
      file = file.replace('data:image/png;base64,', '')
      file = file.replace('data:image/gif;base64,', '')
      file = file.replace('data:image/jpeg;base64,', '')

      var imageContent = window.atob(file)
      // var imageContent = URL.createObjectURL(new Blob([file]));
      // create an ArrayBuffer and a view (as unsigned 8-bit)
      var buffer = new ArrayBuffer(imageContent.length)
      var view = new Uint8Array(buffer)
      // fill the view, using the decoded base64
      for (var n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n)
      }
      // convert ArrayBuffer to Blob
      var blob = new Blob([buffer], { type: type })
      file = blob
      // reset newThumbnail for post
      data.json.newThumbnail = undefined

      // search in fileName space and replace with - 
      fileName = fileName.replace(/\s+/g,"-")

      const dataSignedUrl = await getSignedUrlGeneric({ fileName: fileName, folderName: 'region', contentType: type })
      const signedUrl = dataSignedUrl.data.signedUrl
      if (signedUrl) {
        console.log(file)
        const check = await uploadGeneric(file, signedUrl, this.handleUploadBar, null, type)
        if (check) {
          const newUrl = 'https://storage.googleapis.com/sw-sc-de-assets/region/' + fileName + imgType
          data.json.thumbnail = newUrl
        }
      }
    }

    return data
  }

  handleGetCall = (check) => {
    if (check) {
      this.state.rows.splice(0, this.state.rows.length)
      this.setState({ check: false, loadingRows: true })
    }

    const configArray = [
      {
        url: discovery.API_REGION + '/regions/',
        minEntrys: undefined,
        auth: header,
        handle: this.handleGetAdList
      }
    ]

    getLazyLoading(configArray, this.handleMounted)
  }

  handleGetAdList = (res, checkLast) => {
    // Sort and ADD Id by Rows
    res.sort((a, b) => {
      return Number(a.RowKey) - Number(b.RowKey)
    }).map((row, index) => {
      row.tableId = index

      row.link = (
        <Button
          onClick={() => this.langOverlay.current.openOverlay(row.RowKey, row.name)}
          color='secondary'
          variant='contained'
          className='camera-btn'
          style={{ backgroundColor: '#fff !important', height: '24px', fontSize: '12px' }}
        >
          Sprachdatei
        </Button>
      )

      return row
    })

    if (checkLast === true) {
      this.setState({
        rows: res,
        loadingRows: false
      })
    } else {
      this.setState({
        rows: res
      })
    }
  }

   handleEditColum = async (data) => {
     this.setState({ loadingCall: true })
     let checkPostStatus = false

     const fileName = 'cl-' + data.row.RowKey
     data = await this.uploadFile(data, fileName)

     await setRegion(data.row.RowKey, data.json).then((res) => {
       if (res.status === 200) {
         checkPostStatus = true
       }
     })

     this.setState({ loadingCall: false })
     return checkPostStatus
   }

   handleAddColum = async (data) => {
     this.setState({ loadingCall: true })
     let checkPostStatus = false

     const fileName = 'cl-' + data.row.RowKey
     data = await this.uploadFile(data, fileName)

     if (data.json.RowKey) {
       await setRegion(data.json.RowKey, data.json).then((res) => {
         if (res.status === 200) {
           checkPostStatus = true
         }
       })
     }

     this.setState({ loadingCall: false })
     return checkPostStatus
   }

   render () {
     return (
       <div>
         <HeadText text={this.props.headingText} />

         <OverlayLanguage ref={this.langOverlay} /*rowinfo={this.state.rows[this.state.tempId]}*/ />

         <Grid container spacing={24}>
           <Grid item xs={12}>
             <Table
               resetData={this.handleGetCall}
               onSelectId={this.handleId}
               tableConfig={config}
               tableRows={this.state.rows}
               handleEditRow={this.handleEditColum}
               handleCreateRow={this.handleAddColum}
               loadingCall={this.state.loadingCall}
             />
           </Grid>
         </Grid>
       </div>
     )
   }
}

export default Region
