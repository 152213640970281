import React, { Component } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as _ from 'lodash'
import Button from '@material-ui/core/Button'

import { postCamUpdate } from '../../../api/api-camera'

class SystemUpdateOverlay extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeCams: [],
      consoleList: [],
      loading: false,
      err: undefined,
      successNumber: 0,
      errorNumber: 0,
      onlyErrorMsg: false
    }

    this.handleShowErrorMsg = this.handleShowErrorMsg.bind(this)
  }

  componentDidMount () {
    const activeCams = _.cloneDeep(this.props.allCameras).filter(itm => itm.systemState === 'ACTIVE' && itm.lifecircleState !== 'DEV')

    this.setState({
      activeCams: activeCams
    })
  }

  async handlePostRequest () {
    const { activeCams } = this.state

    // laoding true
    this.props.onLoading(true, true)
    this.setState({
      loading: true
    })

    for (let i = 0; i < activeCams.length; i++) {
      await postCamUpdate({}, activeCams[i].RowKey).then(res => {
        const consoleList = this.state.consoleList
        if (res.data.success) {
          const successNumber = this.state.successNumber + 1
          consoleList.push({ id: activeCams[i].RowKey, status: 'ok' })
          this.setState({
            consoleList: consoleList,
            successNumber: successNumber
          })
        } else {
          const errorNumber = this.state.errorNumber + 1
          consoleList.push({ id: activeCams[i].RowKey, status: 'error', msg: res.data.error || res.data.detail || 'unbekannt' })
          this.setState({
            consoleList: consoleList,
            errorNumber: errorNumber
          })
        }
      })
    }

    // laoding false
    this.props.onLoading(false)
    this.setState({
      loading: false
    })
  }

  handleShowErrorMsg () {
    this.setState({
      onlyErrorMsg: !this.state.onlyErrorMsg
    })
  }

  render () {
    return (
      <div>

        {this.state.consoleList.length <= 0 ? (
          <div>
            Möchtest du wirklich alle {this.state.activeCams.length} Aktiven Systeme Updaten?
          </div>
        ) : (
          <>
            <div className='camera-update-console'>

              {this.state.consoleList.map((itm, index) => {
                const color = itm.status === 'error' ? '#e07474' : '#74e07c'

                if (this.state.onlyErrorMsg && itm.status === 'ok') {
                  return null
                }

                return (
                  <div className='camera-update-console-row' key={index}>
                    <div style={{ color: color }}>[{itm.status}]:</div>
                    <div style={{ marginLeft: '10px' }}>id: {itm.id}</div>
                    <div style={{ marginLeft: '10px' }}>msg: {itm.msg}</div>
                  </div>
                )
              })}

              {this.state.loading ? (
                <div className='camera-update-console-row'>
                ...
                </div>
              ) : (
                <div className='camera-update-console-row'>
                Fertig
                </div>
              )}

            </div>
            <div className='camera-update-bottom-console'>
              <div style={{ display: 'flex' }}>
                <div>ok: {this.state.successNumber}</div>
                <div style={{ paddingLeft: '10px' }}>error: {this.state.errorNumber}</div>
              </div>
              <div>
                <Button
                  onClick={this.handleShowErrorMsg}
                  color='primary'
                  variant='contained'
                  className='camera-btn small-btn-console'
                  size='small'
                >
                  {this.state.onlyErrorMsg ? 'Zurück' : 'Nur Error Meldungen anzeigen'}
                </Button>
              </div>
            </div>
          </>
        )}

        {this.state.err ? (
          <div className='error-container'>
            Ein Fehler ist aufgetreten! Bitte versuche es erneut.
          </div>
        ) : null}

        {this.state.loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <CircularProgress />
          </div>
        ) : null}
      </div>
    )
  }
}

export default SystemUpdateOverlay
