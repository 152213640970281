import React, { Component } from 'react'
import { getLazyLoading, header } from '../../../api/helper'
import discovery from '@soccerwatch/discovery'

// import {dataX} from './data'
import { getClubsSystem } from '../../../api/api-club'
import { SubscriptionsOutlined } from '@material-ui/icons'

class SearchDoocket extends Component {
  constructor (props) {
    super(props)
    this.state = {
      rows: [],
      cams: [],
      clubInfo: [],
      loading: true
    }
  }

  async componentDidMount () {
    await getClubsSystem().then((res) => {
      this.setState({
        clubInfo: res
      })
    })

    this.handleGetCall()
  }

  _isMounted = true;

  handleMounted = () => {
    return this._isMounted
  }

  handleGetCall = () => {
    const configArray = [
      {
        url: discovery.API_METRIC + '/newestDockets/',
        minEntrys: undefined,
        auth: header,
        handle: this.handleGetClubList
      }
    ]

    getLazyLoading(configArray, this.handleMounted)
  }

  handleGetClubList = async (res, checkLast) => {
    if (checkLast === true) {
      this.setState({
        rows: res
      })
   

      // this.searchData(res, clubs)

      this.handleGetCallCams()
    } else {
      this.setState({
        rows: res
      })
    }
  }



  handleGetCallCams = () => {
    const configArray = [
      {
        url: discovery.API_CAMERA + '/info/list/',
        minEntrys: undefined,
        handle: this.handleGetCameraList
      }
    ]

    getLazyLoading(configArray, this.handleMounted)
  }

  handleGetCameraList = (res, checkLast) => {
    res.sort((a, b) => {
      return a.RowKey - b.RowKey
    }).map((row, index) => {
      row.tableId = index
      row.RowKey = parseInt(row.RowKey, 10)
      row.uid = parseInt(row.uid, 10)
      if (row.RowKey !== 'undefined') {
        let address = ''
        if (row.address) {
          address = row.address.replace(/(\r\n|\n|\r)/gm, '')
          if (address.startsWith(' ')) {
            address = address.substr(1)
          }
          if (address.slice(-1) === ' ') {
            address = address.slice(0, -1)
          }
        }
        row.hasSystem = 'Nein'
        row = this.state.clubInfo.map((clubId) => {
          if (Number(row.ownerClubId) === Number(clubId.RowKey)) {
            row.name = clubId.name
            if (clubId.hasSystem === true) {
              row.hasSystem = 'Ja'
            }
          }
          return row
        })
      }

      return row
    })

    if (checkLast === true) {
      this.setState({
        cams: res,
        loadingRows: false
      })

      this.searchData(res)
    } else {
      this.setState({
        cams: res
      })
    }
  }


  searchData = (cams) => {



    const result = []
    this.state.rows.map(res => {
      console.log(res)


      if(res.data.Cellular_SimId !== undefined) {
        let noClub = true
        cams.map(cam => {
          // console.log(club)
  
          if (res.PartitionKey === 'swcs-' + cam.RowKey && cam.name && !cam.name.includes('-deinstalled')) {  
            result.push({ simID: String(res.data.Cellular_SimId), KameraID: res.data.General_SystemId,  Club: cam.name, add: res.Timestamp })
            noClub = false
          }
  
        })
  
  
        if(noClub) {
          result.push({ simID: String(res.data.Cellular_SimId), KameraID: res.data.General_SystemId,  Club: 'kein club', add: res.Timestamp  })
        }
      }

     
    })

    // console.log(result)


    let sad = {}
    result.map(z => {
      if(sad[z.simID]) {
        sad[z.simID].push(z)
      } else {
        sad = {...sad, [z.simID]: [z]}
      }
    })

    console.log(JSON.stringify(sad))
  }

  render () {
    return (
        <div>
          {String(this.state.loading)}
        </div>
      )
  }
}

export default SearchDoocket
