import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Table from '../static/table'
import discovery from '@soccerwatch/discovery'

import { header, getLazyLoading } from '../../api/helper'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@material-ui/core'
import { getCloudlog } from '../../api/api-cloudlog'

import ReactJsonViewCompare from 'react-json-view-compare'

const config = {
  tableName: 'Logs',
  tableUrl: '/logs/',
  tableDialogTitle: 'Logs',
  tableToolbar: true,
  tableAdd: false,
  tableEdit: false,
  columns: [
    { name: 'RowKey', title: 'Log ID', operation: 'equal', editable: false, width: 90 },
    { name: '_date', title: 'date', value: 'string', toCreate: false, width: 140 },
    { name: 'primaryKey', title: 'primaryKey', operation: 'equal', editable: false, width: 90 },
    { name: 'ancestorKey', title: 'ancestorKey', operation: 'equal', editable: false, width: 90 },
    { name: 'exactOperation', title: 'Operation', value: 'string', editable: false },
    { name: '_entry', title: 'Entry', value: 'string', toCreate: false, width: 200 },
    { name: '_oldEntry', title: 'OldEntry', value: 'string', width: 200 },
    { name: 'timestamp', title: 'timestamp', editable: false, width: 90 },
  ],
  defaultHiddenColumnNames: [{ label: 'Alles', value: [''] }]
}

class BBULog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      logs: [],
      rows: [],
      tempId: 0,
      check: false,
      loadingRows: false,
      loadingCall: false,
      error: undefined,
      overlay: false,
      timer: undefined
    }

    this.handleGetLogList = this.handleGetLogList.bind(this)
    this.handleEditColum = this.handleEditColum.bind(this)
    this.handleAddColum = this.handleAddColum.bind(this)
    this.handleMounted = this.handleMounted.bind(this)
    this.handleGetCall = this.handleGetCall.bind(this)
    this.handleId = this.handleId.bind(this)
  }

  _isMounted = false;

  handleId(id) {
    if (id != null) {
      this.setState({ logs: [], tempId: id })
    }
  };

  /**
   * Api-call get ClubList
   */
  componentDidMount() {
    this._isMounted = true
    this.handleGetCall()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  debounce = () => {
    if(this.state.timer) {
      clearTimeout(this.state.timer)
      this.setState({
        timer: undefined
      })
      this.debounceTimer()
      return
    } else {
      this.debounceTimer()
    }
  }
  
  debounceTimer = () => {
    const counter = 1000
    const time = setTimeout(() => {
      this.handleGetCall(true)
      this.setState({
        timer: undefined
      })
    }, counter)
    this.setState({
      timer: time
    })
  }

  componentDidUpdate(lastProps) {
    if (lastProps.type !== this.props.type || lastProps.rowkey !== this.props.rowkey || lastProps.allLogs !== this.props.allLogs) {
      this.debounce()
    }
    if (lastProps.from !== this.props.from || lastProps.till !== this.props.till) {
      this.handleGetCall(true)
    }
  }

  handleMounted() {
    return this._isMounted
  }

  handleDelete = (Row) => async () => {
    console.error('Method not Implemented. THOU SHALT NOT DELETE LOGS!')
  }

  handleGetLogList(res, checkLast) {
    res = res.map((e, index) => {
      if (!e._entry) {
        e._entry = <Button onClick={() => this.handleOpenOverlay(e.entry, true, e.oldEntry) }>anzeigen</Button>
      }
      if (e.oldEntry && !e._oldEntry) {
        e._oldEntry = <Button onClick={() => this.handleOpenOverlay(e.oldEntry) }>anzeigen</Button>
      }
      if (!e._date) {
        const d = new Date(e.date)
        e._date = `${d.toLocaleDateString('de')} - ${d.toLocaleTimeString('de')}`
      }
      e.tableId = index
      return e
    })
    if (checkLast === true) {
      this.setState({
        rows: res,
        loadingRows: false
      })
    } else {
      this.setState({
        rows: res
      })
    }
  }

  handleGetCall(check) {
    const { type, rowkey, from, till, allLogs } = this.props

    if (!type || !rowkey ||  ((!from || !till) && !allLogs)) {
      console.error('Missing Parameter. Type:', type, 'from:', from, 'till', till)
      return
    }

    if(allLogs) {
      this.setState({ loadingRows: true })
      getCloudlog(type, rowkey).then(res => {
        if(res.status === 200) {
          this.handleGetLogList(res.data, true)
        }
      })
      return
    }

    if (check) {
      this.state.logs.splice(0, this.state.logs.length)
      this.state.rows.splice(0, this.state.rows.length)
      this.setState({ check: false, loadingRows: true })
    }
  
    let url = allLogs ? `${discovery.API_CLOUDLOG}/cloudlog/${type}/${rowkey}` : `${discovery.API_CLOUDLOG}/cloudlog/${type}/${rowkey}/${from}/${till}/`

    const configArray = [
      {
        url,
        minEntrys: undefined,
        auth: header,
        handle: this.handleGetLogList
      }
    ]

    getLazyLoading(configArray, this.handleMounted)
  }

  async handleEditColum(data) {
    // Verification Logic in CustomLicenseDialog
    return this.saveData(data, false)
  }

  async handleAddColum(data) {
    // Verification Logic in CustomLicenseDialog
    return this.saveData(data, true)
  }

  async saveData(data, isCreate) {
    console.error('Method not Implemented. This Logs should not be Changed anyway!')
  }

  handleSnackbarClose = () => {
    this.setState({ error: undefined })
  }

  handleCloseOverlay = () => {
    this.setState({
      overlay: false,
      overlayObj: undefined,
      compare: false
    })
  }

  handleOpenOverlay = (json, compare, oldEntry) => {
    this.setState({
      overlay: true,
      overlayObj: json,
      compare: compare,
      oldEntry: oldEntry
    })
  }

  render() {
    return (
      <div>
        <Dialog fullWidth open={this.state.overlay} onClose={this.handleCloseOverlay} aria-labelledby='alert-dialog-title'>
          <DialogTitle id='alert-dialog-title'>log</DialogTitle>
          <DialogContent style={{ whiteSpace: 'break-spaces' }}>

            {!this.state.compare ? (
              <ReactJsonViewCompare oldData={this.state.overlayObj} newData={this.state.overlayObj} />
            ) : (
              <ReactJsonViewCompare oldData={this.state.overlayObj} newData={this.state.oldEntry} />
            )}
            
            </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseOverlay} color='primary'>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Grid item xs={12}>
          <Table
            resetData={this.handleGetCall}
            onSelectId={this.handleId}
            tableConfig={config}
            tableRows={this.state.rows}
            param={this.props.match}
            loadingData={this.state.loadingRows}
            handleEditRow={this.handleEditColum}
            handleCreateRow={this.handleAddColum}
            loadingCall={this.state.loadingCall}
          />
        </Grid>
        <Snackbar
          open={this.state.error}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          message={this.state.error}
          action={this.handleSnackbarClose}
        />
      </div>
    )
  }
}

export default BBULog
