import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import NotificationSystem from 'react-notification-system'
import createReactClass from 'create-react-class'
import discovery from '@soccerwatch/discovery'
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from './style/style'
import './style/index.css'

var MyComponent = createReactClass({
  _notificationSystem: null,

  // (event) =>  this.props.notification(event, 'geht', 'test', 'success', 'tc' )
  _addNotification: function (event, title, message, level, position) {
    event.preventDefault()
    this._notificationSystem.addNotification({
      title: title,
      message: message,
      level: level,
      position: position
    })
  },

  componentDidMount: function () {
    this._notificationSystem = this.refs.notificationSystem
  },

  handleDiscovery (environment) {
    discovery.init(environment).then(mount)
    this.setState()
  },

  render: function () {
    return (
      <MuiThemeProvider theme={theme}>
        <CookiesProvider>
          <BrowserRouter>
            <div style={{ height: '100%' }}>
              <App notification={this._addNotification} onDiscovery={this.handleDiscovery} />
              <NotificationSystem ref='notificationSystem' />
            </div>
          </BrowserRouter>
        </CookiesProvider>
      </MuiThemeProvider>
    )
  }
})

// 'prod' or 'dev'
const environment = 'prod'
discovery.init(environment).then(mount)

function mount () {
  ReactDOM.render(React.createElement(MyComponent), document.getElementById('root'))
}
