import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import { patchUser, getAdditionalUserData, setAdditionalUserData, getUserLicenses, addUserLicense, deleteUserLicense, getUserLicensesSub } from '../../../api/api-user'
// import { setSubscription } from '../../../api/api-payment'

import '../user.css'
import { Snackbar } from '@material-ui/core'
import { Cookies } from 'react-cookie'

class EditColum extends Component {
  constructor (props) {
    super(props)
    const ValidUntil = new Date()
    ValidUntil.setMonth(ValidUntil.getMonth() + 1)
    this.state = {
      activeCheck: this.props.tableRow.disabled,
      ClubId: '',
      SubscriptionStatus: null,
      ValidUntil: ValidUntil.toISOString().split('T')[0],
      womenLeague: false,
      paywallAccess: [], // this.props.tableRow.paywallAccess,
      cameraAccess: [], // this.props.tableRow.cameraAccess,
      Username: this.props.tableRow.Username,
      coins: null, // this.props.tableRow.coins,
      RowKey: this.props.tableRow.uid,
      role: this.props.tableRow.role,
      subscriptions: [], // this.props.tableRow.subscriptions
      userData: null,
      adManagerCitys: [],
      adManagerClubs: []
    }

    this.handleRemoveCameraAccess = this.handleRemoveCameraAccess.bind(this)
    this.handleChangeValidUntil = this.handleChangeValidUntil.bind(this)
    this.handleRemovePaywall = this.handleRemovePaywall.bind(this)
    this.setUserSubscription = this.setUserSubscription.bind(this)
    this.handleChangeClubId = this.handleChangeClubId.bind(this)
    this.handleSnackClose = this.handleSnackClose.bind(this)
    this.handleAddPaywall = this.handleAddPaywall.bind(this)
    this.handleAddCamera = this.handleAddCamera.bind(this)

    this.handleAdManagerCitysInput = this.handleAdManagerCitysInput.bind(this)
    this.handleAdManagerClubsInput = this.handleAdManagerClubsInput.bind(this)
    this.handleAddAdManagerCitys = this.handleAddAdManagerCitys.bind(this)
    this.handleAddAdManagerClubs = this.handleAddAdManagerClubs.bind(this)
    this.handleRemoveAdManagerCitys = this.handleRemoveAdManagerCitys.bind(this)
  }

  componentDidMount () {
    const tempBoolean = this.props.tableRow.disabled === 'true' || this.props.tableRow.disabled === true
    this.setState({
      activeCheck: tempBoolean
    })
    getUserLicenses(this.props.tableRow.uid, 'TrainerSubscription').then((subscriptions) => {
      this.setState({ subscriptions })
    })
    getUserLicenses(this.props.tableRow.uid, 'CameraLicense').then((cameraAccess) => {
      cameraAccess.sort(function (a, b) {
        return a.data.swcsId - b.data.swcsId
      })

      this.setState({ cameraAccess })
    })
    getUserLicenses(this.props.tableRow.uid, 'PaywallAccess').then((paywallAccess) => {
      this.setState({ paywallAccess })
    })
    getAdditionalUserData(this.props.tableRow.uid).then((userData) => {
      this.setState({ coins: userData.coins })
    })

    getUserLicensesSub(this.props.tableRow.uid, 'AdManagerAccess', 'City').then((adManagerCitys) => {
      this.setState({ adManagerCitys })
    })

    getUserLicensesSub(this.props.tableRow.uid, 'AdManagerAccess', 'Club').then((adManagerClubs) => {
      this.setState({ adManagerClubs })
    })
  }

  handleChange (event) {
    const x = event.target.value
    this.setState({ [event.target.id]: x })
  }

  handleChangeCheck = (name) => (event) => {
    this.setState({ [name]: event.target.checked })
  };

  handleChangeClubId (evt) {
    const ClubId = evt.currentTarget.value
    this.setState({ ClubId })
  }

  handleChangeValidUntil (evt) {
    const ValidUntil = evt.currentTarget.value
    this.setState({ ValidUntil })
  }

  handleSnackClose () {
    this.setState({ SubscriptionStatus: null })
  }

  getTrainerLicense = (club, validUntil) => {
    return {
      type: 'TrainerSubscription',
      validUntil: validUntil,
      activeUntil: validUntil,
      paymentType: 'ADMIN_TOOL',
      paymentId: `AdminSet-${club}`,
      data: { club }
    }
  }

  async setUserSubscription () {
    const { RowKey, ClubId, ValidUntil } = this.state
    // const validTill = new Date(ValidUntil).getTime()
    if (!this.state.womenLeague && (!RowKey || !ClubId)) {
      console.error('Missing Property', RowKey, ClubId)
      this.setState({ SubscriptionStatus: 'Fehler: Bitte überprüfen sie die Eingabe' })
      return
    }

    if (this.state.womenLeague) {
      const arrayWomanLeagueIds = ['5648', '5523', '5540', '5421', '5097', '5485', '5566', '5691', '5700', '5401', '1928', '1501', '5732', '5702']

      for (var i = 0; i < arrayWomanLeagueIds.length; i++) {
        const existingLicense = this.state.subscriptions.find((s) => s.data.club === arrayWomanLeagueIds[i])
        if (existingLicense) {
          existingLicense.validUntil = ValidUntil
          existingLicense.activeUntil = ValidUntil
        }
        const licenseData = existingLicense || this.getTrainerLicense(arrayWomanLeagueIds[i], ValidUntil)
        await addUserLicense(RowKey, licenseData) // eslint-disable-next-line
          .then((res) => {
            this.setState({ SubscriptionStatus: 'Subscription id: ' + arrayWomanLeagueIds[i] + ' erfolgreich Angelegt' })
          }) // eslint-disable-next-line
          .catch((err) => {
            this.setState({ SubscriptionStatus: 'Es ist ein Fehler bei ' + arrayWomanLeagueIds[i] + ' aufgetreten' })
            console.error(err)
          })
      }
    } else {
      const existingLicense = this.state.subscriptions.find((s) => s.data.club === ClubId)
      if (existingLicense) {
        existingLicense.validUntil = ValidUntil
        existingLicense.activeUntil = ValidUntil
      }
      const licenseData = existingLicense || this.getTrainerLicense(ClubId, ValidUntil)
      addUserLicense(RowKey, licenseData)
        .then((res) => {
          this.setState({ SubscriptionStatus: 'Subscription erfolgreich Angelegt' })
        })
        .catch((err) => {
          this.setState({ SubscriptionStatus: 'Es ist ein Fehler aufgetreten' })
          console.error(err)
        })
    }
  }

  handlePaywallInput (event) {
    const x = event.target.value
    this.setState({ paywallInput: x })
  }

  handleCameraInput (event) {
    const x = event.target.value
    this.setState({ cameraInput: String(x) })
  }

  handleRemovePaywall (index) {
    const { paywallAccess } = this.state
    const paywallLicense = paywallAccess[index]

    deleteUserLicense(this.state.RowKey, paywallLicense.RowKey).then((res) => {
      paywallAccess.splice(index, 1)
      this.setState({
        paywallAccess: paywallAccess
      })
    })
  }

  handleRemoveAdManagerClubs (index) {
    const { adManagerClubs } = this.state
    const cameraLicense = adManagerClubs[index]

    deleteUserLicense(this.state.RowKey, cameraLicense.RowKey).then((res) => {
      adManagerClubs.splice(index, 1)
      this.setState({
        adManagerClubs: adManagerClubs
      })
    })
  }

  handleAdManagerCitysInput (event) {
    var x = event.target.value
    this.setState({ adManagerCitysInput: x })
  }

  handleAddAdManagerCitys () {
    const adManagerCitys = this.state.adManagerCitys || []
    const hasAdManagerCitys = adManagerCitys.find((c) => {
      return c.data.id === this.state.adManagerCitysInput
    })
    if (hasAdManagerCitys) {
      return
    }
    const cameraLicense = this.getAdManagerAccessLicense(this.state.adManagerCitysInput)
    addUserLicense(this.state.RowKey, cameraLicense).then((res) => {
      adManagerCitys.push(cameraLicense)
      this.setState({
        adManagerCitys: adManagerCitys,
        adManagerCitysInput: ''
      })
    })
  }

  handleRemoveAdManagerCitys (index) {
    const { adManagerCitys } = this.state
    const cameraLicense = adManagerCitys[index]

    deleteUserLicense(this.state.RowKey, cameraLicense.RowKey).then((res) => {
      adManagerCitys.splice(index, 1)
      this.setState({
        adManagerCitys: adManagerCitys
      })
    })
  }

  handleAdManagerClubsInput (event) {
    var x = event.target.value
    this.setState({ adManagerClubsInput: x })
  }

  handleAddAdManagerClubs () {
    const adManagerClubs = this.state.adManagerClubs || []
    const hasAdManagerClubs = adManagerClubs.find((c) => {
      return c.data.id === this.state.adManagerClubsInput
    })
    if (hasAdManagerClubs) {
      return
    }
    const cameraLicense = this.getAdManagerAccessLicenseClub(this.state.adManagerClubsInput)
    addUserLicense(this.state.RowKey, cameraLicense).then((res) => {
      adManagerClubs.push(cameraLicense)
      this.setState({
        adManagerClubs: adManagerClubs,
        adManagerClubsInput: ''
      })
    })
  }

  handleRemoveCameraAccess (index) {
    const { cameraAccess } = this.state
    const cameraLicense = cameraAccess[index]

    deleteUserLicense(this.state.RowKey, cameraLicense.RowKey).then((res) => {
      cameraAccess.splice(index, 1)
      this.setState({
        cameraAccess: cameraAccess
      })
    })
  }

  handleAddPaywall () {
    const paywallAccess = this.state.paywallAccess || []
    const accessExists = paywallAccess.find((a) => {
      return a.data.refId === this.state.paywallInput && a.data.subType === 'single-video-video'
    })
    if (accessExists) {
      return
    }
    const paywallLicense = this.getPaywallLicense(this.state.paywallInput)
    addUserLicense(this.state.RowKey, paywallLicense).then((res) => {
      paywallAccess.push(paywallLicense)
      this.setState({
        paywallAccess: paywallAccess,
        paywallInput: ''
      })
    })
  }

  getPaywallLicense = (matchId) => {
    return {
      type: 'PaywallAccess',
      validUntil: '*',
      activeUntil: '*',
      paymentType: 'ADMIN_TOOL',
      paymentId: `AdminSet-${matchId}`,
      subType: 'single-video-video',
      data: {
        packageName: 'single-video-video-' + matchId,
        refId: String(matchId),
        subType: 'single-video-video'
      }
    }
  }

  getCamLicense = (cameraId) => {
    return {
      type: 'CameraLicense',
      validUntil: '*',
      activeUntil: '*',
      paymentType: 'ADMIN_TOOL',
      paymentId: `AdminSet-${cameraId}`,
      data: { swcsId: cameraId }
    }
  }

  getAdManagerAccessLicense = (city) => {
    return {
      type: 'AdManagerAccess',
      subType: 'City',
      validUntil: '*',
      activeUntil: '*',
      paymentType: 'ADMIN_TOOL',
      paymentId: `AdminSet-${city}`,
      data: { id: city, city: city }
    }
  }

  getAdManagerAccessLicenseClub = (id) => {
    return {
      type: 'AdManagerAccess',
      subType: 'Club',
      validUntil: '*',
      activeUntil: '*',
      paymentType: 'ADMIN_TOOL',
      paymentId: `AdminSet-${id}`,
      data: { id: id, club: id }
    }
  }

  handleAddCamera () {
    const cameraAccess = this.state.cameraAccess || []
    const hasCameraAccess = cameraAccess.find((c) => {
      return c.data.swcsId === this.state.cameraInput
    })
    if (hasCameraAccess) {
      return
    }
    const cameraLicense = this.getCamLicense(this.state.cameraInput)
    addUserLicense(this.state.RowKey, cameraLicense).then((res) => {
      cameraAccess.push(cameraLicense)
      cameraAccess.sort(function (a, b) {
        return a.data.swcsId - b.data.swcsId
      })
      this.setState({
        cameraAccess: cameraAccess,
        cameraInput: ''
      })
    })
  }

  /*
   *  User Apicall Patch
   */
  async handleEditColum () {
    const editUser = {
      uid: this.state.RowKey,
      customClaims: {
        role: JSON.parse(this.state.role)
      },
      disabled: this.state.activeCheck
      // UserName: this.state.UserName,
      // Origin: 'Soccerwatch',
      // coins: Number(this.state.coins),
      // paywallAccess: this.state.paywallAccess,
      // cameraAccess: this.state.cameraAccess
    }

    const json = JSON.stringify(editUser) // Object to JSON
    patchUser(json) // APicall
    this.props.handleClose() // Close Edit-Dialog
    editUser.role = JSON.stringify(editUser.customClaims.role)
    editUser.tableId = this.props.tableRow.tableId
    this.props.changeRow({ json: editUser, row: this.props.tableRow }) // reset / rerender editing table row
  }

  createSubscriptionsOverview (subscriptions) {
    const subRows = subscriptions.map((sub) => {
      return (
        <TableRow key={sub.paymentId}>
          <TableCell>{sub.type}</TableCell>
          <TableCell>{JSON.stringify(sub.data)}</TableCell>
          <TableCell>{sub.validUntil}</TableCell>
          <TableCell>{sub.paymentId}</TableCell>
        </TableRow>
      )
    })
    return (
      <Paper style={{ overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>ValidUntil</TableCell>
              <TableCell>PaymentId</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{subRows}</TableBody>
        </Table>
      </Paper>
    )
  }

  handleUserRoles (role) {
    const parsedRoles = JSON.parse(this.state.role)
    const checkRole = parsedRoles.includes(role)
    if (checkRole) {
      const index = parsedRoles.indexOf(role)
      if (index > -1) {
        parsedRoles.splice(index, 1)
      }
    } else {
      parsedRoles.push(role)
    }
    this.setState({
      role: JSON.stringify(parsedRoles)
    })
  }

  createRoleOverview () {
    const cookies = new Cookies()
    cookies.get('soccerwatchusername')

    const valideAdminMail = ['patrick.heyer@aisportswatch.com', 'dwain.reinert@soccerwatch.tv', 'mb@soccerwatch.tv', 'dullens@soccerwatch.tv', 'steffen.sulzbacher@aisportswatch.com', 'steffen.sulzbacher@staige.tv','christian.bodenstein@soccerwatch.tv']

    // role existing list
    const rolesExisting = [
    //* Should not be set Manualy, but function was sometimes needed to clear bugs
    // 'cameraController', 'contractOwner',
      'videoeditor', 'useradmin', 'trainer', 'streamUser',
      'highlightadmin', 'curator', 'assembler', 'tagger', 'supporter',
      'testTrainer', 'officialCommentator', 'runnoteeditor', 'adFree',
      'paywallFree', 'clubSponsorAdmin', 'adManager', 'cameraPositionViewer',
      'videoConnectionEditor','clubStreamer', 'meshStreamTester', 'clubEditor', 'clubCreator', 'teamEditor', 'teamCreator', 'cityCreator', 'competitionCreator']

    if (valideAdminMail.find(ele => ele === cookies.cookies.soccerwatchusername)) {
      rolesExisting.unshift('admin')
      rolesExisting.unshift('machine')
    }

    let parsedRoles = []
    if (this.state.role) {
      parsedRoles = JSON.parse(this.state.role)
    }

    return (
      <Grid item xs={12} sm={12} className='user-edit'>
        <Grid>Zugriffsrecht</Grid>
        <Grid className='edit-role'>
          {rolesExisting.map((role, index) => {
            const checkRole = parsedRoles.includes(role)
            return (
              <Grid key={index} className='edit-role-container'>
                <Checkbox
                  checked={checkRole}
                  onChange={() => this.handleUserRoles(role)}
                  color='primary'
                />
                {role}
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    )
  }

  render () {
    const texfieldTempValue = []

    for (let i = 0; i < this.props.tableConfig.columns.length; i++) {
      texfieldTempValue.push(this.state[this.props.tableConfig.columns[i].name])
    }

    const subscriptionOverview = this.state.subscriptions ? (
      this.createSubscriptionsOverview(this.state.subscriptions)
    ) : (
      <span>Keine Subscriptions Vorhanden</span>
    )
    return (
      <>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <TextField
              className='textField-table'
              id='UserName'
              label='UserName'
              value={this.state.Username}
              // onChange={this.handleChange.bind(this)}
              disabled
              aria-readonly
              margin='normal'
            />
          </Grid>

          <Grid item xs={12}>
            Deaktiviert:
            <Checkbox
              checked={this.state.activeCheck}
              onChange={this.handleChangeCheck('activeCheck')}
              value='activeCheck'
              color='primary'
            />
          </Grid>

          {this.createRoleOverview()}

          <Grid item xs={12} style={{ padding: '20px', margin: '10px 0', backgroundColor: '#cecece', width: '100%' }}>
            Paywall Zugriff Hinzufügen:
            <div style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '20px' }}>
              <TextField
                className='textField-table'
                id='name'
                label='Match ID'
                value={this.state.paywallInput}
                onChange={this.handlePaywallInput.bind(this)}
                margin='dense'
              />
              <button onClick={this.state.paywallInput !== '' ? () => { this.handleAddPaywall() } : null} style={{ height: '36px', margin: '0 0 4px 4px' }}>HINZUFÜGEN</button>
            </div>
            Paywall Zugriffe:
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {this.state.paywallAccess ? this.state.paywallAccess.map((itm, index) => {
                if (itm.subType === 'single-video-video' || itm.subType === 'Generic') {
                  return (
                    <div key={index} style={{ flexShrink: '0', padding: '10px' }}>
                      {String(itm.data.refId)} <button onClick={() => { this.handleRemovePaywall(index) }}>x</button>
                    </div>
                  )
                }
                return null
              }) : null}
            </div>
          </Grid>

          {/* <Grid item xs={12} style={{ padding: '20px', margin: '10px 0 20px', backgroundColor: '#cecece' }}>
            Kamera Zugriff Hinzufügen:
            <div style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '20px' }}>
              <TextField
                className='textField-table'
                id='name'
                label='Kamera ID'
                value={this.state.cameraInput}
                onChange={this.handleCameraInput.bind(this)}
                margin='dense'
              />
              <button onClick={this.state.cameraInput !== '' ? () => { this.handleAddCamera() } : null} style={{ height: '36px', margin: '0 0 4px 4px' }}>HINZUFÜGEN</button>
            </div>
            Kamera Zugriffe:
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {this.state.cameraAccess ? this.state.cameraAccess.map((itm, index) => {
                return (
                  <div key={index} style={{ flexShrink: '0', padding: '10px' }}>
                    {itm.data.swcsId} <button onClick={() => { this.handleRemoveCameraAccess(index) }}>x</button>
                  </div>
                )
              }) : null}
            </div>
            </Grid> */}

          <Grid item xs={12} style={{ padding: '20px', margin: '10px 0', backgroundColor: '#cecece', width: '100%' }}>
            adManagerCitys Hinzufügen:
            <div style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '20px' }}>
              <TextField
                className='textField-table'
                id='name'
                label='City'
                value={this.state.adManagerCitysInput}
                onChange={this.handleAdManagerCitysInput.bind(this)}
                margin='dense'
              />
              <button onClick={this.state.adManagerCitysInput !== '' ? () => { this.handleAddAdManagerCitys() } : null} style={{ height: '36px', margin: '0 0 4px 4px' }}>HINZUFÜGEN</button>
            </div>
            adManagerCitys Zugriffe:
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {this.state.adManagerCitys ? this.state.adManagerCitys.map((itm, index) => {
                return (
                  <div key={index} style={{ flexShrink: '0', padding: '10px' }}>
                    {itm.data.id} <button onClick={() => { this.handleRemoveAdManagerCitys(index) }}>x</button>
                  </div>
                )
              }) : null}
            </div>
          </Grid>

          <Grid item xs={12} style={{ padding: '20px', margin: '10px 0', backgroundColor: '#cecece', width: '100%' }}>
            adManagerClubs Hinzufügen:
            <div style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '20px' }}>
              <TextField
                className='textField-table'
                id='name'
                label='Club id. * for all'
                value={this.state.handleAdManagerClubsInput}
                onChange={this.handleAdManagerClubsInput.bind(this)}
                margin='dense'
              />
              <button onClick={this.state.handleAdManagerClubsInput !== '' ? () => { this.handleAddAdManagerClubs() } : null} style={{ height: '36px', margin: '0 0 4px 4px' }}>HINZUFÜGEN</button>
            </div>
            adManagerClubs Zugriffe:
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {this.state.adManagerClubs ? this.state.adManagerClubs.map((itm, index) => {
                return (
                  <div key={index} style={{ flexShrink: '0', padding: '10px' }}>
                    {itm.data.id} <button onClick={() => { this.handleRemoveAdManagerClubs(index) }}>x</button>
                  </div>
                )
              }) : null}
            </div>
          </Grid>

        </Grid>
        <Grid container spacing={24}>
          <Grid className='dialog-btn'>
            <DialogActions>
              <Button onClick={this.props.handleClose} className='btn' color='primary' variant='contained'>
                Abbrechen
              </Button>
              <Button
                onClick={this.handleEditColum.bind(this)}
                className='btn'
                color='primary'
                variant='contained'
                autoFocus
              >
                Speichern
              </Button>
            </DialogActions>
          </Grid>
          <hr style={{ width: '100%' }} />
          <h4 id='alert-dialog-title'>Subscriptions</h4>
          <Grid className='dialog-btn sub-overview'>{subscriptionOverview}</Grid>
          <hr style={{ width: '100%' }} />
          <h4 id='alert-dialog-title'>Subscription Hinzufügen</h4>
          <Grid className='dialog-btn'>
            <Grid style={{ margin: '-10px 0 -24px 0' }}>
              <Checkbox
                checked={this.state.womenLeague}
                onChange={this.handleChangeCheck('womenLeague')}
                value='womenLeague'
                color='primary'
              />
              Alle der 2.Frauen Bundesliga
            </Grid>
            <TextField
              className='textField-table'
              id='ClubId'
              label='ClubId'
              value={this.state.ClubId}
              onChange={this.handleChangeClubId}
              margin='normal'
              disabled={this.state.womenLeague === true}
            />
            <TextField
              className='textField-table'
              id='ValidUntil'
              label='Valide Bis'
              type='date'
              value={this.state.ValidUntil}
              onChange={this.handleChangeValidUntil}
              margin='normal'
            />
            <DialogActions>
              <Button onClick={this.props.handleClose} className='btn' color='primary' variant='contained'>
                Abbrechen
              </Button>
              <Button onClick={this.setUserSubscription} className='btn' color='primary' variant='contained'>
                Subscription Hinzufügen
              </Button>
            </DialogActions>
            <Snackbar
              open={this.state.SubscriptionStatus}
              onClose={this.handleSnackClose}
              ContentProps={{
                'aria-describedby': 'message-id'
              }}
              message={<span id='message-id'>{this.state.SubscriptionStatus}</span>}
            />
          </Grid>
        </Grid>
      </>
    )
  }
}

export default EditColum
