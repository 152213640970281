import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { apiList, getAuthorization } from './helper'
rax.attach()

export const getCameraSystem = async (cameraId) => {
  return axios.get(discovery.API_CAMERA + `/info/single/${cameraId}`, { headers: { Authorization: await getAuthorization() } })
}

export const getCameraSystemList = async () => {
  return apiList(discovery.API_CAMERA + '/info/list', undefined, { headers: { Authorization: await getAuthorization() } })
}

export const postCameraSystem = async (json) => {
  return axios.post(discovery.API_CAMERA + '/manage', json, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const postNewCameraSystem = async (json) => {
  return axios.post(discovery.API_CAMERA + '/create', json, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const postCamStart = async (json, cameraId) => {
  return axios.post(discovery.API_CAMERA + '/control/' + cameraId + '/start', json, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const postCamStop = async (json, cameraId) => {
  return axios.post(discovery.API_CAMERA + '/control/' + cameraId + '/stop', json, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const getCamStatus = async (cameraId) => {
  return axios.post(discovery.API_CAMERA + '/control/' + cameraId + '/status', {}, { headers: { Authorization: await getAuthorization() } })
}

export const getCamSpeedTest = async (cameraId) => {
  return axios.post(discovery.API_CAMERA + '/control/' + cameraId + '/speedTest', {}, { headers: { Authorization: await getAuthorization() } })
}

export const getCamSystemCleanup = async (cameraId) => {
  return axios.post(discovery.API_CAMERA + '/control/' + cameraId + '/systemCleanup', {}, { headers: { Authorization: await getAuthorization() } })
}

export const postCamWebServerStart = async (cameraId) => {
  return axios.post(discovery.API_CAMERA + '/control/' + cameraId + '/startWebserver', {}, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const postCamWebServerStop = async (cameraId) => {
  return axios.post(discovery.API_CAMERA + '/control/' + cameraId + '/stopWebserver', {}, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const postCamTest = async (cameraId) => {
  return axios.post(discovery.API_CAMERA + '/control/' + cameraId + '/test', {}, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const postCamUpdate = async (body, cameraId) => {
  return axios.post(discovery.API_CAMERA + '/control/' + cameraId + '/update', JSON.stringify(body), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const postCamDeinstall = async (body, cameraId) => {
  const result = await axios.post(`${discovery.API_CAMERA}/deinstall/${cameraId}`, JSON.stringify(body), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
  return result
}

export const postCamMove = async (body, cameraIdOld, cameraIdNew) => {
  const result = await axios.post(
    `${discovery.API_CAMERA}/move/${cameraIdOld}/${cameraIdNew}`,
    JSON.stringify(body),
    { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } }
  )
  return result
}

export const getDockets = async (id) => {
  return axios.get(discovery.API_CAMERA + '/intern/rest/v1/camera/' + id + '/dockets/0', { headers: { Authorization: await getAuthorization() } })
}

export const getCameraComment = async (id) => {
  const listCmd = `${discovery.API_CAMERA}/commentsFor/${id}/`
  const comments = await apiList(listCmd, undefined, { headers: { Authorization: await getAuthorization() } })
  return comments
}

export const deleteCameraComment = async (commentId, cameraId) => {
  const deleteCmd = `${discovery.API_CAMERA}/deleteComment/${cameraId}/${commentId}`
  return axios.delete(deleteCmd, { headers: { Authorization: await getAuthorization() } })
}

export const postCameraComment = async (json) => {
  const postCmd = `${discovery.API_CAMERA}/comment/${json.cameraId}/`
  return axios.post(postCmd, json, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const getTaggingState = async (matchId) => {
  return axios.get(`${discovery.API_CAMERA}/trello/taggingStatus/${matchId}`, { headers: { Authorization: await getAuthorization() } })
}