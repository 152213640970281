import React from 'react'
import moment from 'moment'

export const ChatInfo = ({
  msg,
  match,
  heightInfoBox,
}) => {
  let url = 'https://soccerwatch.tv'
  let checkChatMsg = 0
  if (match.watermark === 'aisportswatch') {
    url = 'https://aisw.tv'
  }

  return (
    <div className='content-chat'>
      <div title='Zum Chat gelangen' style={{ cursor: 'pointer' }} onClick={() => { window.open(url + '/video/' + match.RowKey) }} className='chat-headbar'>
        Narichten im Chat
      </div>
      <div className='chat-content' style={{ height: heightInfoBox - 16 }}>
        {msg.map((massage, index) => {
          const time = moment(massage.timestamp).format('LT')
          const date = moment(massage.timestamp).format('L')
          if (massage.userId !== 'machine@soccerwatch.tv') {
            checkChatMsg++
            return (
              <div className='content-massage' key={index}>
                <div className='massage-user'>
                  {massage.userName}
                  <div className='user-date'>{date} - {time}</div>
                </div>
                <div className='massage-msg' onClick={() => { window.open(url + '/video/' + match.RowKey) }}>
                  {massage.msg}
                </div>
              </div>
            )
          } else {
            return null
          }
        })}
        {checkChatMsg > 0 ? null : (
          <div className='content-nochatmsg'>Keine Chat Narichten vorhanden.</div>
        )}
      </div>
    </div>
  )

}
