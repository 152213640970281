import axios from 'axios'
import * as rax from 'retry-axios'

import { getAuthorization } from './helper'

rax.attach()

export const api_admin_v2 = `https://europe-west1-aisw-ww-prod.cloudfunctions.net/api_admin_v2`

export const mergeTeam = async (from, to) => {
  return axios.get(`${api_admin_v2}/api_admin_v2/team/merge/${from}/${to}`, { headers: { Authorization: await getAuthorization() } })
}

export const mergeContract = async (from, to) => {
  return axios.get(`${api_admin_v2}/api_admin_v2/contract/merge/${from}/${to}`, { headers: { Authorization: await getAuthorization() } })
}

export const getCameraCornerPointsAll = async (cameraId) => {
  return axios.get(`${api_admin_v2}/camera/cornerpoints/${cameraId}`,  { headers: { Authorization: await getAuthorization() } })
}


/**
 * Same data as in api-cloudlog but also for contractOwner
 */
export const getCameraCloudlog = async (rowkey) => {
  return axios.get(api_admin_v2 + '/Camera/logs/' + rowkey, { headers: { Authorization: await getAuthorization() } })
}

/**
 * Same data as in api-cloudlog but also for contractOwner
 */
export const getCameraCloudlogFromTo = async (rowkey, from, to) => {
  return axios.get(api_admin_v2 + '/Camera/logs/' + rowkey + '/' + from + '/' + to, { headers: { Authorization: await getAuthorization() } })
}
