import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { apiList, getAuthorization } from './helper'
rax.attach()

export const getHeaders = async function getHeaders () {
  return apiList(`${discovery.API_REDACTIONAL}/Header/`, null, { headers: { Authorization: await getAuthorization() } })
}

export const postHeaders = async (data, headerName) => {
  return axios.post(discovery.API_REDACTIONAL + '/info/' + headerName, JSON.stringify(data), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}
