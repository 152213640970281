import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { getAuthorization, apiListFilter, apiList } from './helper'
rax.attach()

export const getHighlightsTill = async function getHighlights (since) {
  since = since || new Date().getTime() - 1000 * 60 * 60 * 24 * 30

  return apiListFilter(
    `${discovery.API_CLIP}/Highlight/simpleInfosTill/`,
    (entry) => {
      return new Date(entry.referenceDate).getTime() < since
    },
    { headers: { Authorization: await getAuthorization() } }
  )
}

export const postHighlight = async function postHighlights (data) {
  return axios.post(`${discovery.API_CLIP}/Highlight/info/${data.RowKey}`, JSON.stringify(data), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const getContentClips = async function getCitys () {
  return apiList(`${discovery.API_CLIP}/ContentClip/infos/`, null, { headers: { Authorization: await getAuthorization() } })
}

export const postContentClip = async function postContentClip (data, id) {
  return axios.post(`${discovery.API_CLIP}/ContentClip/info/${id}`, JSON.stringify(data), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const createContentClip = async function createContentClip (data) {
  return axios.post(`${discovery.API_CLIP}/ContentClip/create`, JSON.stringify(data), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}
