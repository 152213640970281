import React, { Component } from 'react'
import HeadText from '../static/tableHeading'
import Grid from '@material-ui/core/Grid'
import Table from '../static/table'
import discovery from '@soccerwatch/discovery'
import { header, getLazyLoading } from '../../api/helper'

import './competition.css'
import { createCompetition, updateCompetition } from '../../api/api-competition'
import { Snackbar, Alert } from '@material-ui/core'
import { storageLayerTableInfo } from '../../modules/static/config/storageLayerTableConfig'
import { priorityTenScale } from '../static/dropdownValues'

const config = {
  tableName: 'competitionTable',
  tableUrl: '/competition/',
  tableDialogTitle: 'Competition',
  tableToolbar: true,
  tableAdd: true,
  columns: [
    { name: 'RowKey', title: 'RowKey', operation: 'equal', editable: false, width: 90 },
    { name: 'active', title: 'Active', value: 'boolean', toCreate: true, width: 90 },
    { name: 'name', title: 'Name', toCreate: true, width: 140 },
    { name: 'priority', title: 'Priority', value: 'number', dropdown: true, dropdownValue: priorityTenScale, toCreate: false, width: 90, tooltip: 'Gewichtet die Anzeige dieser Competition und aller enthaltener Spiele auf der Platform' },
    { name: 'eventType', title: 'Event Type', value: 'string', toCreate: true, width: 90 },
    { name: 'Ref_CC_Priority_Score', title: 'Ref_CC_Priority_Score', value: 'string', toCreate: false, editable: false, width: 90, tooltip: 'Berechneter Score für diese Competition < 1 wird auf jeden Fall angezeigt, < 2 nur falls nicht genügend Content vorhanden ist > 2 nie.' },
    { name: 'eventCategory', title: 'Event Category', toCreate: true, width: 140 },
    { name: 'regionName', title: 'Region Name', toCreate: true, width: 140 },
    { name: 'leagueName', title: 'League Name', toCreate: true, width: 140 },
    { name: 'manualTagging', title: 'manualTagging',value: 'boolean', width: 50, editable: true  },
    { name: 'ageName', title: 'Age Name', toCreate: true, width: 140 },
    { name: 'areaName', title: 'Area Name', toCreate: true, width: 140 },
    { name: 'searchName', title: 'Search Name', toCreate: true, width: 140 },
    { name: 'season', title: 'Season', toCreate: true, width: 140 },
    { name: 'dId', title: 'dId', toCreate: true, width: 140 },
    { name: 'source', title: 'Source', value: 'string', toCreate: true, width: 90 },
    { name: 'link', title: 'Link', toCreate: true, width: 140 },
    { name: 'color', title: 'color', value: 'string', toCreate: false, width: 90 },
    { name: 'image', title: 'Image', value: 'string', toCreate: false, width: 140 },
    { name: 'teamIds', title: 'TeamIds', value: 'tagInput', toCreate: true, width: 140 },
    { name: 'videoIds', title: 'VideoIds',  value: 'tagInput', toCreate: true, width: 140 },
    { name: 'aliasList', title: 'Alias List', value: 'array', toCreate: true, width: 240 },
    { name: 'tenant', title: 'tenant', value: 'tagInput', label: 'tenant', toCreate: false, width: 180 },
    { name: 'SLNeedsVerification', title: 'SLNeedsVerification', value: 'boolean', width: 140, toCreate: false },
    { name: 'SLCreatedBy', title: 'SLCreatedBy', width: 140, toCreate: false },
    { name: 'SLLastUpdatedBy', title: 'SLLastUpdatedBy', width: 140, toCreate: false, editable: false },
    { name: 'Ref_CC_Count_Videos', title: 'Ref_CC_Count_Videos(dep)', toCreate: false, editable: false, width: 120 },
    ...storageLayerTableInfo
  ],
  defaultHiddenColumnNames: [{ label: 'Alles', value: [''] }]
}

class Competition extends Component {
  constructor (props) {
    super(props)

    this.state = {
      clubs: [],
      rows: [],
      tempId: 0,
      check: false,
      loadingRows: true,
      loadingCall: false,
      error: undefined
    }

    this.handleGetCompetitionList = this.handleGetCompetitionList.bind(this)
    this.handleEditColum = this.handleEditColum.bind(this)
    this.handleAddColum = this.handleAddColum.bind(this)
    this.handleMounted = this.handleMounted.bind(this)
    this.handleGetCall = this.handleGetCall.bind(this)
    this.handleId = this.handleId.bind(this)
  }

  _isMounted = false;

  handleId (id) {
    if (id != null) {
      this.setState({ clubs: [], tempId: id })
    }
  };

  /**
   * Api-call get ClubList
   */
  componentDidMount () {
    this._isMounted = true
    this.handleGetCall()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  handleMounted () {
    return this._isMounted
  }

  handleGetCompetitionList (res, checkLast) {
    // Sort and ADD Id by Rows
    res.sort((a, b) => {
      return null
      // return Number(a.RowKey) - Number(b.RowKey)
    }).map((row, index) => {
      index === 0 && console.log(row)
      // row.link = (
      //   <a
      //     target='_blank'
      //     rel='noopener noreferrer'
      //     style={{ color: 'blue' }}
      //     href={`https://www.fussball.de/verein/id/${row.dId}#!/`}
      //   >
      //     Fussball.de
      //   </a>
      // )
      row.tableId = index

      return row
    })

    if (checkLast === true) {
      this.setState({
        rows: res,
        loadingRows: false
      })
    } else {
      this.setState({
        rows: res
      })
    }
  }

  handleGetCall (check) {
    if (check) {
      this.state.clubs.splice(0, this.state.clubs.length)
      this.state.rows.splice(0, this.state.rows.length)
      this.setState({ check: false, loadingRows: true })
    }

    const configArray = [
      {
        url: discovery.API_COMPETITION + '/competitions/',
        minEntrys: undefined,
        auth: header,
        handle: this.handleGetCompetitionList
      }
    ]

    getLazyLoading(configArray, this.handleMounted)
  }

  async handleEditColum (data) {
    this.setState({ loadingCall: true })
    let checkPostStatus = false
    // SET 'RowKey' to Json, need this for backend
    data.json.RowKey = data.row.RowKey
    await updateCompetition(data.json).then((res) => {
      if (res.status === 200) {
        checkPostStatus = true
        this.setState({ loadingCall: false })
      }
    })

    return checkPostStatus
  }

  requiredFields = ['name', 'eventType', 'eventCategory', 'regionName', 'areaName', 'season', 'dId', 'link']

  async handleAddColum (data) {
    let checkPostStatus = false
    const missingFields = this.requiredFields.filter((key) => !data.json[key] && data.json[key] !== 0)
    if (missingFields.length) {
      this.setState({ error: `Benötigte Daten unvollständig. Bitte die Felder: ${missingFields.join(', ')} ausfüllen` })
      return
    } else {
      await createCompetition(data.json).then((res) => {
        if (res.status === 200) {
          checkPostStatus = true
        }
      })
    }
    return checkPostStatus
  }

  handleSnackbarClose = () => {
    this.setState({ error: undefined })
  }

  render () {
    return (
      <div>
        <HeadText text={this.props.headingText} />

        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Table
              resetData={this.handleGetCall}
              onSelectId={this.handleId}
              tableConfig={config}
              tableRows={this.state.rows}
              param={this.props.match}
              loadingData={this.state.loadingRows}
              handleEditRow={this.handleEditColum}
              handleCreateRow={this.handleAddColum}
              loadingCall={this.state.loadingCall}
              // addColum={AddColum}
            />
          </Grid>
        </Grid>
        <Snackbar
          open={this.state.error}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          message={this.state.error}
          action={this.handleSnackbarClose}
        />
      </div>
    )
  }
}

export default Competition
