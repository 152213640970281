import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'

class ErrorBoxVariable extends Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  handleClick (id) {
    window.open(window.location.origin + '/cameras/uid=' + id, '_blank')
  }

  render () {
    return (
      <div>
        <Paper>
          <div className='layout-head'>{this.props.headText}</div>
          <div className='errorBox'>
            {this.props.data.map((n, index) => {
              return (
                <div key={index} style={{ display: 'flex' }} onClick={this.handleClick.bind(this, n.swcsID)}>
                  <div className='errorBox-number'>{index + 1}</div>
                  <div className={n.camOK === 0 ? 'errorBox-list-fail errorBox-list' : 'errorBox-list'}>
                    <div className='list-sw'>
                      {' '}
                      swc
                      {n.swcsID}{' '}
                    </div>
                    <div className='list-ok'>
                      {' '}
                      {n.camOK}/{n.matchNumber} OK
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </Paper>
      </div>
    )
  }
}

export default ErrorBoxVariable
