import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

class NoRunnotes extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false,
      data: []
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    let check = false
    if (nextProps.data !== this.state.data) {
      this.setState({
        data: nextProps.data
      })
      check = true
    }

    if (nextProps.open !== this.state.open) {
      check = true
      this.setState({
        open: nextProps.open
      })
    }

    if (nextState.open !== this.state.open) {
      check = true
    }
    return check
  }

  handleOpen () {
    this.setState({
      open: !this.state.open
    })

    if (!this.state.open) {
      this.props.handleOpen(this.props.id)
    }
  }

  handleClick (id) {
    window.open(window.location.origin + '/cameras/uid=' + id + '&openNotes', '_blank')
  }

  render () {
    return (
      <div>
        <div className='dash-notes-heading' onClick={this.handleOpen.bind(this)}>
          <div className='dash-notes-headingtext'>{this.state.data.length} Fehlen</div>
          <div className='dash-notes-heading-btnX'>
            {this.state.open ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
          </div>
        </div>
        {this.state.open ? (
          <div className='dash-note-container'>
            {this.state.data.map((n, index) => {
              return (
                <div key={index} className='dash-note-container-body' onClick={this.handleClick.bind(this, n)}>
                  swcs-
                  {n}
                </div>
              )
            })}
          </div>
        ) : null}
      </div>
    )
  }
}

export default NoRunnotes
