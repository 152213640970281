import React from 'react'

import { Plugin, Getter } from '@devexpress/dx-react-core'

const handleRowsGetter = (onRowsUpdate) => ({ rows }) => {
  setTimeout(() => onRowsUpdate(rows))
  return rows
}

export const RowsProvider = ({ onRowsUpdate }) => {
  const handleRows = handleRowsGetter(onRowsUpdate)
  return (
    <Plugin>
      <Getter name='rows' computed={handleRows} />
    </Plugin>
  )
}
