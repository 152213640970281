import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'

class AlertCheck extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      loading: false,
      msg: undefined
    }
  }

  componentDidUpdate() {
    if (this.props.open !== this.state.open) {
      this.setState({
        open: this.props.open
      })

      if (!this.props.open) {
        setTimeout(() => {
          this.setState({
            loading: false,
            msg: undefined
          })
        }, 200)
      }
    }
  }

  async handleAccept() {
    this.setState({ loading: true })
    const check = await this.props.onAccept()
    this.setState({
      loading: false,
      msg: check.msg
    })
  }

  render() {
    const { open, loading, msg } = this.state
    return (
      <div>
        <Dialog
          open={open}
          onClose={this.props.onClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          disableBackdropClick={loading}
        >
          {loading && <CircularProgress size={24} className='buttonProgress' style={{ position: 'absolute', top: '16px', right: '16px' }} />}
          <DialogTitle id='alert-dialog-title'>{this.props.title || 'Titel fehlt!'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {this.props.disabled && <div style={{ background: '#ff7f7f', color: '#202020', padding: '10px', marginBottom: '24px', fontSize: '14px' }}>{this.props.disabledText}</div>}
              {!msg ? this.props.text || 'Text fehlt!' : msg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={this.props.onClose} color='primary'>
              Abbrechen
            </Button>
            {this.props.onAccept !== undefined && !this.props.disabled ? (
              <Button disabled={loading} onClick={msg ? this.props.onClose : this.handleAccept.bind(this)} color='primary' autoFocus>
                {this.props.Accept || 'Bestätigen'}
              </Button>
            ) : null}
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default AlertCheck
