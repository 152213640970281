import React, { Component } from 'react'
import HeadText from '../../modules/static/tableHeading'
import Grid from '@material-ui/core/Grid'
import Table from '../../modules/static/table'
import NoImg from '../../../style/img/noimg.png'
// import AddColum from './tableDialog/addColum'
import discovery from '@soccerwatch/discovery'

import { postGeneric, deleteGeneric } from '../../api/api-additional'
import { getSignedUrlGeneric, uploadGeneric } from '../../api/api-mediaupload'
import { getLazyLoading, getLazyLoadingNext, header } from '../../api/helper'
import { storageLayerTableInfo } from '../../modules/static/config/storageLayerTableConfig'
import { Snackbar } from '@material-ui/core'

const noSpecialCharsExp = /[^a-zA-Z\-_0-9\s]+/g

const noImg = 'https://storage.googleapis.com/sw-sc-de-assets/assets/asset_noimg_300x170.png'

const config = {
  tableName: 'genericTable',
  tableUrl: '/advertisement/',
  tableDialogTitle: 'Advertisement',
  tableToolbar: true,
  tableAdd: true,
  columns: [
    { name: 'AdId', title: 'AdId', width: 140, toCreate: false, toEditable: false },
    { name: 'link', title: 'Website URL', width: 140, toCreate: true },
    { name: 'name', title: 'Kundenname', width: 140, toCreate: true },
    { name: 'comment', title: 'Kommentar', width: 140, toCreate: true },
    { name: 'backgroundColor', title: 'Hintergrundfarbe', width: 140, toCreate: true },
    { name: 'enabledClubs', title: 'Club ID / IDs', value: 'tagInput', deactivateIfOtherFieldIsSet: ['enabledCityIds'], width: 140, toCreate: true },
    { name: 'enabledCityIds', title: 'City ID / IDs', value: 'tagInput', deactivateIfOtherFieldIsSet: ['enabledClubs'], width: 140, toCreate: true },
    { name: 'matchSponsor', title: 'Video ID / IDs', value: 'tagInput', width: 140, toCreate: true },
    { name: 'competitionSponsor', title: 'Competition Sponsor ID / IDs', value: 'tagInput', width: 140, toCreate: true },
    // { name: 'cupSponsor', title: 'Cup ID / IDs', value: 'array', width: 140, toCreate: true },
    { name: 'regionSponsor', title: 'Region ID / IDs', value: 'tagInput', width: 140, toCreate: true },
    // { name: 'digitalEnabler', title: 'Enabler', value: 'boolean', width: 140, toCreate: true },
    { name: 'hasGlobalPreroll', title: 'Globaler Preroll', width: 140, toCreate: true, value: 'boolean' },
    //
    { disabled: true, name: 'mediaPrerollFile', title: 'preroll', videoUpload: { fileFormat: ['mp4'] }, width: 30 },
    { name: 'mediaPreroll', title: 'mediaPreroll', width: 240, toCreate: false, toEditable: false },
    //
    { name: 'sequence', title: 'sequence', value: 'number', width: 140 },
    { name: 'priority', title: 'priority', value: 'number', width: 140, tooltip: 'Ads mit hoher Prioritär werden als erstes angezeigt. Wird der Wert 100 angegeben, wird diese Werbung als einzige preroll ausgespielt' },
    {
      name: 'instreamType',
      title: 'instreamType',
      width: 140,
      tooltip: 'image: Ein einfaches Bild das durchlaufend im unteren Bereich des Videos angezeigt wird | staticImage:  Genauso wie Instream-Ad (Image) nur nicht durchlaufend. Das ermöglicht es gifs zu verwenden. | lotty: LEGACY, ein altes Werbeformat welches nurnoch vom AdManager erzeugt wird.',
      dropdown: true,
      dropdownValue: [
        { value: undefined, label: 'undefined' },
        { value: 'staticImage', label: 'staticImage' },
        { value: 'image', label: 'image' },
        { value: 'lotty', label: 'lotty' }
      ]
    },
    //
    { disabled: true, name: 'mediaInstream-imgUpload', title: 'img', connectionRowName: 'mediaInstream', imagesUploadV2: { methode: 'nojimp', width: 728, height: 90, noImgUrl: noImg, fileFormat: ['png', 'jpeg', 'gif'] } },
    { name: 'mediaInstream', title: 'mediaInstream', width: 240, toCreate: false, toEditable: false },
    //
    { disabled: true, name: 'socialSharingOverlay-imgUpload', title: 'img', connectionRowName: 'socialSharingOverlay', imagesUploadV2: { methode: 'nojimp', width: 2276, height: 1288, noImgUrl: noImg, fileFormat: ['png', 'jpeg', 'gif'] } },
    { name: 'socialSharingOverlay', title: 'socialSharingOverlay', width: 150, toCreate: false, toEditable: false },
    {
      name: 'socialSharingMode',
      title: 'socialSharingMode',
      width: 140,
      tooltip: 'überschreibt andere Regelungen für die einblendung im social sharing.',
      dropdown: true,
      dropdownValue: [
        { value: undefined, label: 'undefined' },
        { value: 'none', label: 'none' },
        { value: 'forceActive', label: 'forceActive' },
        { value: 'forceDeactive', label: 'forceDeactive' }
      ]
    },
    //
    
    { name: 'activeLotty', title: 'activeLotty', value: 'boolean', width: 140 },
    { name: 'instreamTextA', title: 'instreamTextA', width: 140 },
    { name: 'instreamTextB', title: 'instreamTextB', width: 140 },
    { name: 'instreamButtonText', title: 'instreamButtonText', width: 140 },
    { name: 'instreamButtonColor', title: 'instreamButtonColor', width: 140 },
    { name: 'instreamButtonTextColor', title: 'instreamButtonTextColor', width: 140 },
    { name: 'instreamTextColor', title: 'instreamTextColor', width: 140 },
    { name: 'mediaLottyInstreamSide', title: 'mediaLottyInstreamSide', width: 140 },
    { name: 'mediaLottyInstream', title: 'mediaLottyInstream', width: 140 },
    //
    { name: 'instreamStartOffset', title: 'instreamStartOffset', value: 'number', width: 140, tooltip: 'Zeit in Millisekunden' },
    { name: 'instreamDuration', title: 'instreamDuration', value: 'number', width: 140, tooltip: 'Zeit in Millisekunden' },
    { name: 'instreamRelativeStarttime', title: 'instreamRelativeStarttime', value: 'boolean', width: 140 },
    { name: 'instreamRepeatTime', title: 'instreamRepeatTime', value: 'number', width: 140, tooltip: 'Zeit in Millisekunden' },
    //
    { disabled: true, name: 'mediaCityUri-imgUpload', title: 'img', connectionRowName: 'mediaCityUri', imagesUploadV2: { methode: 'nojimp', width: 300, height: 170, noImgUrl: noImg, fileFormat: ['png', 'jpeg'] } },
    { name: 'mediaCityUri', title: 'mediaCityUri', width: 240, toCreate: false, toEditable: false },
    //
    { disabled: true, name: 'image-imgUpload', title: 'img', connectionRowName: 'image', imagesUploadV2: { methode: 'nojimp', width: 1280, height: 720, noImgUrl: noImg, fileFormat: ['png', 'jpeg'] } },
    { name: 'image', title: 'image', width: 240, toCreate: false, toEditable: false },
    //
    { name: 'digitalEnabler', title: 'digitalEnabler', width: 140, toCreate: false, value: 'boolean' },
    { name: 'vastURL', title: 'External VAST URL', width: 140 },
    { name: 'accessKey', title: 'accessKeyL', width: 140 },
    { name: 'owner', title: 'owner', width: 140 },
    { name: 'contractId', title: 'contractId', width: 140 },

    { name: 'inStream', title: 'inStream', value: 'boolean', width: 140 },
    { name: 'inSummary', title: 'inSummary', value: 'boolean', width: 140 },

    { name: 'active', title: 'active', value: 'boolean', width: 140 },
    { name: 'validFrom', title: 'gültig von *deutsche zeit', value: 'datetime', width: 140, toCreate: true },
    { name: 'validTill', title: 'gültig bis *deutsche zeit', value: 'datetime', width: 140, toCreate: true },
    { name: 'tenantIds', title: 'tenantIds', value: 'tagInput', width: 140 },
    ...storageLayerTableInfo
  ],
  defaultHiddenColumnNames: [{ label: 'Alles', value: [''] }]
}

class Generic extends Component {
  constructor (props) {
    super(props)

    this.state = {
      rows: [],
      tempId: '0',
      check: false,
      loadingCall: false,
      uploadProcess: undefined,
      loadingRows: true,
      openWarning: false
    }

    this.handleEditColum = this.handleEditColum.bind(this)
    this.handleDeleteColum = this.handleDeleteColum.bind(this)
    this.handleAddColum = this.handleAddColum.bind(this)
    this.handleGetCall = this.handleGetCall.bind(this)
    this.handleUploadBar = this.handleUploadBar.bind(this)
  }

  handleId = (id) => {
    if (id != null) {
      this.setState({ tempId: id })
    }
  };

  /**
  * Api-call get GameList
  */
  componentDidMount () {
    this._isMounted = true
    this.handleGetCall()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  handleMounted () {
    return this._isMounted
  }

  handleGetCall (check, nextRound, intervalStop) {
    if (check) {
      this.state.rows.splice(0, this.state.rows.length)
      this.setState({ check: false, loadingRows: true })
    }

    const configArray = [{
      url: discovery.API_ADDITIONAL + '/generic/',
      minEntrys: undefined,
      auth: header,
      handle: this.handleGetGenericList.bind(this),
      number: 4,
      intervalStop: intervalStop || 10
    }]

    if (nextRound) {
      getLazyLoadingNext(configArray, this.handleMounted.bind(this), this.state.rows, this.state.lastToken)
      this.setState({ lastToken: undefined })
    } else {
      getLazyLoading(configArray, this.handleMounted.bind(this))
    }
  }

  handleGetGenericList (res, checkLast, lastToken) {
    // ADD tableId by Rows
    res.map((row, index) => {
      row.RowKey = row.RowKey
      row.tableId = index

      if (row.validTill === '*') {
        row.validTill = '2100-01-01T00:00:00.000Z'
      } else if (row.validTill === undefined) {
        row = { ...row, validTill: '2100-01-01T00:00:00.000Z' }
      }

      if (row.validFrom === '*') {
        row.validFrom = '2000-01-01T00:00:00.000Z'
      } else if (row.validFrom === undefined) {
        row = { ...row, validFrom: '2000-01-01T00:00:00.000Z' }
      }

      return row
    })

    if (checkLast === true) {
      this.setState({
        rows: res,
        loadingRows: false,
        lastToken: lastToken
      })
    } else {
      this.setState({
        rows: res,
        lastToken: lastToken
      })
    }
  }

  handleUploadBar (prozess, finish) {
    if (finish) {
      this.setState({
        uploadProcess: undefined
      })
    } else {
      this.setState({
        uploadProcess: prozess
      })
    }
  }

  async uploadFile (data, fileName) {
    const uploadConfigImg = [
      { name: 'image-imgUpload', linkName: 'image', fileNamePrefix: 'im-' },
      { name: 'mediaCityUri-imgUpload', linkName: 'mediaCityUri', fileNamePrefix: 'mc-' },
      { name: 'mediaInstream-imgUpload', linkName: 'mediaInstream', fileNamePrefix: 'mi-' },
      { name: 'socialSharingOverlay-imgUpload', linkName: 'socialSharingOverlay', fileNamePrefix: 'sa-' }
    ]

    for (let i = 0; i < uploadConfigImg.length; i++) {
      if (data.json[uploadConfigImg[i].name]) {
        const uploadFileName = uploadConfigImg[i].fileNamePrefix + fileName
        const type = 'image/png'
        let file = data.json[uploadConfigImg[i].name]
        // data.json.mediaType = 'image'
        file = file.replace('data:image/png;base64,', '')
        file = file.replace('data:image/jpeg;base64,', '')
        file = file.replace('data:image/gif;base64,', '')
        var imageContent = window.atob(file)
        // create an ArrayBuffer and a view (as unsigned 8-bit)
        var buffer = new ArrayBuffer(imageContent.length)
        var view = new Uint8Array(buffer)
        // fill the view, using the decoded base64
        for (var n = 0; n < imageContent.length; n++) {
          view[n] = imageContent.charCodeAt(n)
        }
        // convert ArrayBuffer to Blob
        var blob = new Blob([buffer], { type: type })
        file = blob
        // reset newThumbnail for post
        data.json[uploadConfigImg[i].name] = undefined

        const dataSignedUrl = await getSignedUrlGeneric({ fileName: uploadFileName, folderName: 'digitalEnabler', contentType: type })
        const signedUrl = dataSignedUrl.data.signedUrl
        if (signedUrl) {
          const check = await uploadGeneric(file, signedUrl, this.handleUploadBar, null, type)
          if (check) {
            const newUrl = 'https://storage.googleapis.com/sw-sc-de-assets/digitalEnabler/' + uploadFileName + '.png'
            data.json[uploadConfigImg[i].linkName] = newUrl
          }
        }
      }
    }

    if (data.json.newVideoFile) {
      const dataSignedUrl = await getSignedUrlGeneric({ fileName: fileName, folderName: 'digitalEnabler', contentType: 'video/mp4' })
      const signedUrl = dataSignedUrl.data.signedUrl
      if (signedUrl) {
        const check = await uploadGeneric(data.json.newVideoFile, signedUrl, this.handleUploadBar, null, 'video/mp4')
        if (check) {
          const newUrl = 'https://storage.googleapis.com/sw-sc-de-assets/digitalEnabler/' + fileName + '.mp4'
          data.json.mediaPreroll = newUrl
        }
      }
    }

    return data
  }

  async handleEditColum (data) {
    if(this.checkSetMediaCityUri(data)) {
      return false
    }

    this.setState({ loadingCall: true })
    let checkPostStatus = false
    if (data.json && data.json.name) {
      data.json.name = data.json.name.replace(noSpecialCharsExp, '')
    }
    data = await this.uploadFile(data, data.row.AdId)

    data = this.checkArray(data, 'enabledClubs')
    data = this.checkArray(data, 'enabledCityIds')
    data = this.checkArray(data, 'matchSponsor')
    data = this.checkArray(data, 'competitionSponsor')
    data = this.checkArray(data, 'regionSponsor')
    data = this.checkArray(data, 'tenantIds')

    if(JSON.stringify(data.json) === '{}') {
      this.setState({ loadingCall: false })
      return true
    }

    await postGeneric(data.json, data.row.AdId).then((res) => {
      if (res.status === 200) {
        checkPostStatus = true
      }
    })
    this.setState({ loadingCall: false })
    return checkPostStatus
  }

  checkArray = (data, value) => {
    if (data.json[value] !== undefined) {
      if(!Array.isArray(data.json[value])) {
        data.json[value] = []
      }
    }
    return data
  }

  async handleDeleteColum (data) {
    this.setState({ loadingCall: true })
    let checkPostStatus = false
    await deleteGeneric(data.row.AdId).then((res) => {
      if (res.status === 200) {
        checkPostStatus = true
      }
    })
    this.setState({ loadingCall: false })
    return checkPostStatus
  }

  async handleAddColum (data) {
    if(this.checkSetMediaCityUri(data)) {
      return false
    }
    
    if (data.json && data.json.name) {
      data.json.name = data.json.name.replace(noSpecialCharsExp, '')
    }
    this.setState({ loadingCall: true })
    let checkPostStatus = false
    const AdId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

    const json = JSON.stringify(data.json) // Object to JSON
    await postGeneric(json, AdId).then((res) => {
      if (res.status === 200) {
        checkPostStatus = true
      }
    })

    if (checkPostStatus) {
      data = await this.uploadFile(data, AdId)
      await postGeneric(data.json, AdId)
    }

    this.setState({ loadingCall: false })
    return checkPostStatus
  }

  checkSetMediaCityUri = (data) => {
    if(data.json.enabledCityIds && data.json.enabledCityIds.length > 0) {
      if ((data.json['mediaCityUri-imgUpload'] && data.json['mediaCityUri-imgUpload'].length > 0) || (data.json.mediaCityUri && data.json.mediaCityUri.length > 0) || (data.row.mediaCityUri && data.row.mediaCityUri.length > 0)) {
        return false
      } else {
        this.setState({
          openWarning: true
        })
        return true
      }
    }
    return false
  }

  render () {
    return (
      <div>
        <HeadText text={this.props.headingText} />
        <Snackbar
          style={{ backgroundColor: 'red' }}
          open={this.state.openWarning}
          autoHideDuration={6000}
          onClose={() => this.setState({openWarning: false})}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          message={'FEHLER: Wenn CityIds eingetragen wurden, muss auch mediaCityUri gesetzt sein, bevor gespeichert werden kann.'}
          action={() => this.setState({openWarning: false})}
        />
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Table
              resetData={this.handleGetCall}
              onSelectId={this.handleId}
              tableConfig={config}
              tableRows={this.state.rows}
              handleEditRow={this.handleEditColum}
              handleDeleteRow={this.handleDeleteColum}
              handleCreateRow={this.handleAddColum}
              loadingCall={this.state.loadingCall}
              loadingData={this.state.loadingRows}
              uploadProcess={this.state.uploadProcess}
              param={this.props.match}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Generic
