import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp, faTimes } from '@fortawesome/free-solid-svg-icons'

class Sorting extends Component {
  constructor (props) {
    super(props)

    this.state = {
      sort: {
        date: undefined,
        prio: undefined,
        az: undefined
      }
    }

    this.handleSortingReset = this.handleSortingReset.bind(this)
    this.handleSorting = this.handleSorting.bind(this)
  }

  handleSorting (name) {
    let checkValue = true
    if (this.state.sort && this.state.sort[name]) {
      checkValue = !this.state.sort[name]
    }

    this.setState({
      sort: {
        [name]: checkValue
      }
    })

    this.props.onSorting({ sort: { [name]: checkValue } })
  }

  handleSortingReset () {
    this.setState({
      sort: {
        date: undefined,
        prio: undefined,
        az: undefined
      }
    })

    this.props.onSorting()
  }

  render () {
    return (
      <div className='socialMedia-sorting'>
        <div className='sorting-row'>
          <div className='row-text'>Datum</div>
          <div onClick={() => { this.handleSorting('date') }} className={this.state.sort && this.state.sort.date === undefined ? 'row-btn row-disable' : 'row-btn row-active'}>
            <FontAwesomeIcon icon={this.state.sort && this.state.sort.date ? faArrowDown : faArrowUp} />
          </div>
        </div>
        <div className='sorting-row'>
          <div className='row-text'>Priorität</div>
          <div onClick={() => { this.handleSorting('prio') }} className={this.state.sort && this.state.sort.prio === undefined ? 'row-btn row-disable' : 'row-btn row-active'}>
            <FontAwesomeIcon icon={this.state.sort && this.state.sort.prio ? faArrowDown : faArrowUp} />
          </div>
        </div>
        <div className='sorting-row'>
          <div className='row-text'>A-Z</div>
          <div onClick={() => { this.handleSorting('az') }} className={this.state.sort && this.state.sort.az === undefined ? 'row-btn row-disable' : 'row-btn row-active'}>
            <FontAwesomeIcon icon={this.state.sort && this.state.sort.az ? faArrowDown : faArrowUp} />
          </div>
        </div>
        <div className='sorting-row'>
          <div onClick={() => { this.handleSortingReset() }} className='row-btn'> <FontAwesomeIcon icon={faTimes} /></div>
        </div>
      </div>
    )
  }
}

export default Sorting
