import React, { Component } from 'react'
import HeadText from '../../modules/static/tableHeading'
import Grid from '@material-ui/core/Grid'
import Table from '../../modules/static/table'
import discovery from '@soccerwatch/discovery'
import { postCup } from '../../api/api-cup'
import { header, getLazyLoading } from '../../api/helper'
import { storageLayerTableInfo } from '../../modules/static/config/storageLayerTableConfig'

const config = {
  tableName: 'cup',
  tableUrl: '/cup/',
  tableDialogTitle: 'Cup',
  tableToolbar: true,
  tableAdd: true,
  columns: [
    { name: 'RowKey', title: 'RowKey', width: 140, toCreate: true, toEditable: false },
    { name: 'active', title: 'Aktiv', value: 'boolean', width: 100, toCreate: true },
    { name: 'shortName', title: 'Kurzbezeichnung', width: 180, toCreate: true },
    { name: 'name', title: 'Name', width: 200, toCreate: true },
    { name: 'thumbnail', title: 'Vorschaubild', width: 200, toCreate: true },
    { name: 'leagueReference', title: 'Liga-Referenz', width: 200, toCreate: true },
    { name: 'featured', title: 'Featured', value: 'boolean', width: 100, toCreate: true },
    { name: 'league', title: 'Liga', value: 'boolean', width: 100, toCreate: true },
    { name: 'levelOfDetail', title: 'levelOfDetail', width: 140, toCreate: true },
    { name: 'priority', title: 'Priorität', width: 140, toCreate: true },
    { name: 'reference', title: 'Referenz', width: 200, toCreate: true },
    { name: 'validFrom', title: 'Start Datum', value: 'datetime', width: 170, toCreate: true },
    { name: 'validTill', title: 'End Datum', value: 'datetime', width: 170, toCreate: true },
    { name: 'Ref_CC_Tenant', title: 'Ref_CC_Tenant', width: 150, editable: false, tooltip: 'Tenant (Kunden) die diesem Eintrag zugeordnet sind' },
    ...storageLayerTableInfo
  ],
  defaultHiddenColumnNames: [{ label: 'Alles', value: [''] }]
}

class Cup extends Component {
  constructor (props) {
    super(props)

    this.state = {
      rows: [],
      tempId: '0',
      check: false,
      loadingCall: false,
      uploadProcess: undefined
    }
  }

  _isMounted = false;

  handleId = (id) => {
    if (id != null) {
      this.setState({ tempId: id })
    }
  };

  /**
   * Api-call get all GenericList
   */
  componentDidMount () {
    this._isMounted = true
    this.handleGetCall()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  handleMounted = () => {
    return this._isMounted
  }

  handleGetCall = (check) => {
    if (check) {
      this.state.rows.splice(0, this.state.rows.length)
      this.setState({ check: false, loadingRows: true })
    }

    const configArray = [
      {
        url: discovery.API_CUP + '/cup/',
        minEntrys: undefined,
        auth: header,
        handle: this.handleGetAdList
      }
    ]

    getLazyLoading(configArray, this.handleMounted)
  }

  handleGetAdList = (res, checkLast) => {
    // Sort and ADD Id by Rows
    res.sort((a, b) => {
      return Number(a.RowKey) - Number(b.RowKey)
    }).map((row, index) => {
      row.tableId = index
      return row
    })

    if (checkLast === true) {
      this.setState({
        rows: res,
        loadingRows: false
      })
    } else {
      this.setState({
        rows: res
      })
    }
  }

   handleEditColum = async (data) => {
     this.setState({ loadingCall: true })
     let checkPostStatus = false

     await postCup(data.json, data.row.RowKey).then((res) => {
       if (res.status === 200) {
         checkPostStatus = true
       }
     })

     this.setState({ loadingCall: false })
     return checkPostStatus
   }

   handleAddColum = async (data) => {
     this.setState({ loadingCall: true })
     let checkPostStatus = false

     if (data.json.RowKey) {
       await postCup(data.json, data.json.RowKey).then((res) => {
         if (res.status === 200) {
           checkPostStatus = true
         }
       })
     }

     this.setState({ loadingCall: false })
     return checkPostStatus
   }

   render () {
     return (
       <div>
         <HeadText text={this.props.headingText} />
         <Grid container spacing={24}>
           <Grid item xs={12}>
             <Table
               resetData={this.handleGetCall}
               onSelectId={this.handleId}
               tableConfig={config}
               tableRows={this.state.rows}
               handleEditRow={this.handleEditColum}
               handleCreateRow={this.handleAddColum}
               loadingCall={this.state.loadingCall}
             />
           </Grid>
         </Grid>
       </div>
     )
   }
}

export default Cup
