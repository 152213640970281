import React, { Component } from 'react'
import Pagination from './pagination'
import ChatListCard from './contentChat'

import { createCard } from './helper/createCard'
// import { getCamStatus } from '../../../api/api-camera'

class Content extends Component {
  constructor (props) {
    super(props)

    this.state = {
      data: this.props.data || [],
      pageOfItems: [],
      pagers: undefined,
      totalItems: 0
    }

    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleTotalItems = this.handleTotalItems.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        data: this.props.data
      })
    }
  }

  handleChangePage (pageOfItems) {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems })
  }

  handleTotalItems (value) {
    this.setState({ totalItems: value })
  }

  render () {
    return (
      <div className='socialMedia-content'>
        <ChatListCard
          globalMsgList={this.props.globalMsgList}
          rows={this.props.rows}
          onActiveContainer={this.props.onActiveContainer}
          filterActive={this.props.filterActive}
          totalItems={this.state.totalItems}
          dataSize={this.props.dataSize}
        />
        <Pagination
          items={this.state.data}
          onChangePage={this.handleChangePage}
          dataSize={this.props.dataSize}
          onSetTotalItems={this.handleTotalItems}
        >
          <div className='content-boxes'>
            {this.state.pageOfItems.map((itm, index) => {
              return createCard(itm, index, this.props.onActiveContainer)
            })}
          </div>
        </Pagination>
      </div>
    )
  }
}

export default Content
