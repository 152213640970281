import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Select from '@material-ui/core/Select'
import Tooltip from '@material-ui/core/Tooltip'

import './static.scss'
import { FormControl, Input } from '@material-ui/core'

export const InputDateTime = (props) => {
  return (
    <Grid item xs={12} sm={6}>
      <div className='textField-label'>
        {props.label}
        {props.tooltip ? (
          <Tooltip disabled title={props.tooltip} placement="bottom-end">
            <div className="tooltip-icon">?</div>
          </Tooltip>
        ) : null}
      </div>
      <TextField
        id={props.id}
        type='datetime-local'
        value={props.value}
        onChange={props.handleChange}
        margin='normal'
        className='textField-table'
        InputLabelProps={{
          shrink: true
        }}
      />
    </Grid>
  )
}

/**
 *  <InputFieldInit label="" value="" handleChange="" />
 *
 * @param {label, value, handleChange} props
 */
export const InputFieldInit = (props) => {
  return (
    <TextField
      disabled={props.disabled}
      className='textField-table'
      id={props.id || props.label}
      label={props.label}
      value={props.value}
      onChange={props.handleChange}
      margin='normal'
      type={props.type}
    />
  )
}

/**
 * <InputDropDown label="" value="" handleChange="" menuField="" />
 *
 * @param {label, value, handleChange, menuField} props
 */
export const InputDropDownInit = (props) => {
  return (
    <Grid style={props.style}>
      <div className='textField-label'>
        {props.label}
        {props.tooltip ? (
          <Tooltip disabled title={props.tooltip} placement="bottom-end">
            <div className="tooltip-icon">?</div>
          </Tooltip>
        ) : null}
      </div>
      <Select
        id='select-currency-native'
        className='textField-table-dropdowns'
        value={props.value}
        onChange={props.handleChange}
        native
      >
        {props.menuField.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </Grid>
  )
}

/**
 * <InputField id="" label="" value="" activeCheck="" handleChange="" />
 *
 * @param {id, label, value, activeCheck, handleChange} props
 */
export const InputField = (props) => {
  return (
    <Grid item xs={12} sm={6}>  
      <div className='textField-label'>
        {props.label}
        {props.tooltip ? (
          <Tooltip disabled title={props.tooltip} placement="bottom-end">
            <div className="tooltip-icon">?</div>
          </Tooltip>
        ) : null}
      </div>
      
      <TextField
        className='textField-table'
        id={String(props.id)}
        // label={props.label}
        multiline={props.multiline}
        value={props.value}
        onChange={props.handleChange}
        margin='none'
        type={props.type || null}
        disabled={!props.activeCheck || false}
        onClick={props.onClick}
        placeholder={props.placeholder}
        error={props.error}
        helperText={props.helperText}
      />
    </Grid>
  )
}

/**
 * <InputDropDown label="" value="" handleChange="" menuField="" />
 *
 * @param {label, value, handleChange, menuField} props
 */
export const InputDropDownNew = (props) => {
  return (
    <Grid className='textField-table-dropdown' item xs={12} sm={6}>
      <div className='textField-label'>
        {props.label}
        {props.tooltip ? (
          <Tooltip disabled title={props.tooltip} placement="bottom-end">
            <div className="tooltip-icon">?</div>
          </Tooltip>
        ) : null}
      </div>

      <Select
        id='select-currency-native'
        className='textField-table-dropdowns'
        value={props.value}
        onChange={props.handleChange}
        native
        margin='none'
      >
        {props.menuField.map((option, index) => (
          <option key={index} value={option.value} disabled={props.setOnlyOldState ? option.setOnlyOldState && option.setOnlyOldState.find(a => a !== props.value) ? false : true : false}>
            {option.label}
          </option>
        ))}
      </Select>
    </Grid>
  )
}

/**
 * <InputDropDown label="" value="" handleChange="" menuField="" />
 *
 * @param {label, value, handleChange, menuField} props
 */
export const InputDropDown = (props) => {
  return (
    <Grid className='textField-table-dropdown' item xs={12} sm={6}>
      <div className='textField-label'>
        {props.label}
        {props.tooltip ? (
          <Tooltip disabled title={props.tooltip} placement="bottom-end">
            <div className="tooltip-icon">?</div>
          </Tooltip>
        ) : null}
      </div>
      <TextField
        id='select-currency-native'
        select
        className='textField-table-dropdowns'
        // label={props.label}
        value={props.value}
        onChange={props.handleChange}
        SelectProps={{
          native: true
        }}
        margin='none'
      >
        {props.menuField.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    </Grid>
  )
}

/**
 * <InputCheck label="" value="" handleChange="" />
 *
 * @param {label, value, handleChange} props
 */
export const InputCheck = (props) => {
  return (
    <Grid className='textField-table-check' item xs={12} sm={6}>
      <div className='textField-label'>
        {props.tooltip ? (
          <Tooltip disabled title={props.tooltip} placement="bottom-end">
            <div className="tooltip-icon">?</div>
          </Tooltip>
        ) : null}
      </div>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.value}
            onChange={props.handleChange(props.label)}
            value={props.label}
            color='primary'
          />
        }
        label={props.label}
      />
    </Grid>
  )
}

/**
 * <InputSwitch check="" value="" handle="" />
 *
 * @param {check, value, handle} props
 */
export const InputSwitch = (props) => {
  return <Switch checked={props.check} onChange={props.handle} value={props.value} color='primary' />
}

export const InputFieldWithDropdown = (props) => {
  return (
    <Grid className='textField-table-dropdown' item xs={12} sm={6}>
      <div className='textField-label'>
        {props.label}
        {props.tooltip ? (
          <Tooltip disabled title={props.tooltip} placement="bottom-end">
            <div className="tooltip-icon">?</div>
          </Tooltip>
        ) : null}
      </div>
      <Input
        className='runnote-grp-body-dd-input'
        id={props.id}
        label={props.label}
        value={props.value}
        onChange={props.handleChange}
      />
      <FormControl className='runnote-grp-body-dd'>
        <Select native onChange={props.handleChangeDropdown} className='runnote-grp-body-dd-dd'>
          <option value='' />

          {props.menuField.map((n, index2) => {
            return (
              <option className='runnote-grp-body-dd-dd-disablecolor' key={index2} value={n.value}>
                {n.label}
              </option>
            )
          })}
        </Select>
      </FormControl>
    </Grid>
  )
}