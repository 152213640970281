import React, { Component } from 'react'
import Select from 'react-select'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { getDetectLastCameraError } from '../../../../api/api-server'

class SearchCameraError extends Component {
  constructor (props) {
    super(props)

    this.state = {
      numberMatches: 5,
      search: 1,
      camLastError: [],
      dateFrom: new Date('2017-01-01').getTime(),
      dateTo: new Date().getTime(),
      clubID: [],
      tempCamList: [],
      selectCamId: '',
      clubs: undefined,
      cameraSystemList: undefined
    }
  }

  async updateClubs (clubs, camList) {
    clubs.sort((a, b) => {
      if (a.searchName < b.searchName) return -1
      if (a.searchName > b.searchName) return 1
      return 0
    })

    let tempHasSystem = []
    let finalHasSystem = []

    clubs.map((n) => {
      if (n.hasSystem === true) {
        tempHasSystem.push({ RowKey: n.RowKey, name: n.name })
      }
      return null
    })

    let temp = camList
    tempHasSystem.map((n) => {
      temp.map((x) => {
        if (n.RowKey === x.ownerClubId && x.ownerClubId !== '0') {
          finalHasSystem.push({ id: x.RowKey, name: n.name })
        }
        return null
      })
      return null
    })

    this.setState({ clubID: finalHasSystem })
    this.handleDropdownMenu()
  }

  componentDidUpdate (prevProps) {
    const check = prevProps.clubs && prevProps.clubs !== this.state.clubs && prevProps.cameraSystemList !== this.state.cameraSystemList
    if (check) {
      this.setState({
        clubs: prevProps.clubs,
        cameraSystemList: prevProps.cameraSystemList
      })
      this.updateClubs(prevProps.clubs, prevProps.cameraSystemList)
    }
  }

  hanldeSearchError () {
    getDetectLastCameraError(this.state.search, this.state.numberMatches, this.state.dateFrom, this.state.dateTo).then(
      (res) => {
        this.setState({
          camLastError: res.data
        })

        this.props.handleSearch(res.data)
      }
    )
  }

  hanldeSearchErrorSelect () {
    getDetectLastCameraError(
      this.state.selectCamId,
      this.state.numberMatches,
      this.state.dateFrom,
      this.state.dateTo
    ).then((res) => {
      this.setState({
        camLastError: res.data
      })

      this.props.handleSearch(res.data)
    })
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value
    })
  };

  handleDate = (name) => (event) => {
    let date = new Date(event.target.value)
    this.setState({
      [name]: date.getTime()
    })
  };

  handleChangeSelect = (selectedOption) => {
    if (selectedOption !== null) {
      this.setState({ selectCamId: selectedOption.value })
    } else {
      this.setState({ selectCamId: selectedOption })
    }
  };

  handleDropdownMenu () {
    let tempCamList = []
    this.state.clubID.map((cam) => tempCamList.push({ value: cam.id, label: cam.id + ': ' + cam.name }))

    tempCamList.sort((num1, num2) => num1.value - num2.value)
    return this.setState({ tempCamList: tempCamList })
  }

  render () {
    let date = new Date()
    return (
      <div style={{ width: '100%' }}>
        {this.state.clubID.length > 0 ? (
          <FormControl className='formControl-testadd'>
            <div className='settings-headingText-select'>ID oder Name</div>
            <Select
              name='form-field-name'
              value={this.state.selectCamId}
              onChange={this.handleChangeSelect}
              options={this.state.tempCamList}
            />
          </FormControl>
        ) : (
          <div>
            <div className='settings-headingText'>ID oder Name</div>
            <TextField
              id='search'
              value={this.state.search}
              onChange={this.handleChange('search')}
              margin='dense'
              fullWidth
            />
          </div>
        )}

        <div className='settings-headingText'>Anzahl der letzten Matches</div>
        <TextField
          id='numberMatches'
          value={this.state.numberMatches}
          onChange={this.handleChange('numberMatches')}
          type='number'
          InputLabelProps={{
            shrink: true
          }}
          margin='dense'
          fullWidth
        />
        <form noValidate>
          <TextField
            id='dateFrom'
            label='Von:'
            type='date'
            defaultValue='2017-01-01'
            onChange={this.handleDate('dateFrom')}
            InputLabelProps={{
              shrink: true
            }}
            style={{ width: '100%' }}
            margin='dense'
          />
          <TextField
            id='dateTo'
            label='Bis:'
            type='date'
            defaultValue={date.toISOString().slice(0, 10)}
            onChange={this.handleDate('dateTo')}
            InputLabelProps={{
              shrink: true
            }}
            style={{ width: '100%' }}
            margin='dense'
          />
        </form>
        <Button
          variant='contained'
          color='primary'
          className='btn'
          style={{ width: '100%' }}
          onClick={
            this.state.clubID.length > 0 ? this.hanldeSearchErrorSelect.bind(this) : this.hanldeSearchError.bind(this)
          }
        >
          Suche
        </Button>
      </div>
    )
  }
}

export default SearchCameraError
