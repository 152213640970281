import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { getAuthorization } from './helper'
rax.attach()

export const addOrEditBlacklist = async (json) => {
  return axios.post(discovery.API_BLACKLIST + '/blacklist', json, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const deleteBlacklist = async (key) => {
  return axios.delete(discovery.API_BLACKLIST + '/blacklist/' + key, { headers: { Authorization: await getAuthorization() } })
}

export const addOrEditDefaultlist = async (json) => {
  return axios.post(discovery.API_BLACKLIST + '/defaults/', json, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const deleteDefaultlist = async (key) => {
  return axios.delete(discovery.API_BLACKLIST + '/defaults/' + key, { headers: { Authorization: await getAuthorization() } })
}
