import React from 'react'
import NoUserImg from '../../../../../../icon/noImgUser.png'
import { AddCircleOutlineSharp } from '@material-ui/icons'
import './UserSelect.css'

const relevantLicences = {
  AdManagerAccess: 'AdManager',
  CameraLicense: 'CameraController',
  TrainerSubscription: 'Trainer',
  ContractOwnerLicense: 'ContractOwner',
  CommentatorLicense: 'Commentator',
  ClubTaggerLicense: 'ClubTagger',
  ClubStreamerLicense: 'ClubStreamerLicense'
}

export default class UserSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getUserRolesString = (licences) => {
    const userRoles = Object.keys(
      licences.reduce((roles, l) => {
        if (relevantLicences[l.type]) {
          const licenseRole = relevantLicences[l.type]
          if (!roles[licenseRole]) {
            roles[licenseRole] = true
          }
        }
        return roles
      }, {})
    )
    userRoles.sort(function (a, b) {
      if (a < b || a === 'ContractOwnerLicense') {
        return -1
      }
      if (a > b) {
        return 1
      }
      return 0
    })
    return userRoles
  }

  renderUserContainer = (userData, handleOpenUser) => {
    const userRoles = this.getUserRolesString(userData.licences)

    return (
      <div key={userData.RowKey} className='userContainer' onClick={() => handleOpenUser(userData)}>
        <div className='userThumb'>
          <img className='thump' referrerPolicy='no-referrer' src={userData.thumbnail ? userData.thumbnail : NoUserImg} />
        </div>
        <div className='userInfo'>
          <div>
            <div className='userName'>{userData.username}</div>
            <div className='userId'>{userData.RowKey}</div>
            <div className='userRoles'>
              {userRoles.map((role, index) => {
                if (userRoles.length === index + 1) {
                  return <span key={role}>{role}</span>
                }
                return role
              })}
            </div>
          </div>
          <div className='mbsc-ic mbsc-ic-fa-angle-right arrow-size' />
        </div>
      </div>
    )
  }

  renderAddUserContainer = () => {
    return (
      <div
        key='NEW_USER_CONTAINER'
        className='userContainer newUser'
        onClick={() => this.onAddUser(true)}
      >
        <div className='userThumb'>
          <AddCircleOutlineSharp />
        </div>
        <div className='userInfo'>
          <div>
            <div className='userName'>User Hinzufügen</div>
          </div>
          <div className='mbsc-ic mbsc-ic-fa-angle-right arrow-size' />
        </div>
      </div>

    )
  }

  onAddUser = () => {
    this.props.handleNewUser(true)
  }

  onUserSelected = (user) => {
    this.props.handleOpenUser(user)
  }

  render() {
    const addUserContainer = this.renderAddUserContainer()
    return (
      <>
        {addUserContainer}
        {this.props.user.map((data) => {
          return this.renderUserContainer(data, this.onUserSelected)
        })}
      </>
    )
  }
}
