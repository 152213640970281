import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { postTrainerToken } from '../../../api/api-subscription'

const isIntegerString = (str) => parseInt(str).toString() === str

class OverlayTrainertool extends Component {
  constructor (props) {
    super(props)
    const today = new Date()
    const expires = new Date()
    expires.setDate(today.getDate() + 30)
    this.state = {
      loading: false,
      err: undefined,
      trainer: {
        recieverMail: '',
        grants: [{
          club: undefined,
          validUntil: expires.getTime()
        }]
      },
      submitButtonIsEnabled: true
    }

    this.handlePostRequest = this.handlePostRequest.bind(this)
    this.handleDelteClub = this.handleDelteClub.bind(this)
    this.handleChangeGrants = this.handleChangeGrants.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const now = new Date().getTime()
    const validUntilValuesAreValidDates = this.state.trainer.grants.reduce(
      (areValid, grant) =>
        areValid && grant.validUntil && !Number.isNaN(grant.validUntil) && grant.validUntil > now,
      true
    )

    const clubIDsAreValid = this.state.trainer.grants.reduce(
      (areValid, grant) => areValid && isIntegerString(grant.club),
      true
    )

    if (validUntilValuesAreValidDates && clubIDsAreValid) {
      this.enableSubmitButton()
    } else {
      this.disableSubmitButton()
    }
  }

  disableSubmitButton = () => {
    if (this.state.submitButtonIsEnabled) {
      // params: isLoading, disableSubmitButton
      this.props.onLoading(false, true)

      this.setState({ submitButtonIsEnabled: false })
    }
  }

  enableSubmitButton = () => {
    if (!this.state.submitButtonIsEnabled) {
      // params: isLoading, disableSubmitButton
      this.props.onLoading(false, false)

      this.setState({ submitButtonIsEnabled: true })
    }
  }

  handleChangeEmail = event => {
    this.setState({
      trainer: {
        ...this.state.trainer,
        recieverMail: event.target.value
      }
    })
  }

  handleChangeGrants = e => event => {
    const trainer = { ...this.state.trainer }
    trainer.grants = [...trainer.grants]
    trainer.grants[e] = { ...trainer.grants[e] }
    trainer.grants[e].club = event.target.value

    this.setState({ trainer })
  }

  handleDateTimePicker = (index) => (event) => {
    const trainer = { ...this.state.trainer }
    trainer.grants = [...trainer.grants]
    trainer.grants[index] = { ...trainer.grants[index] }
    trainer.grants[index].validUntil = new Date(event.target.value).getTime()

    this.setState({ trainer })
  }

  handleMoreClubs () {
    const today = new Date()
    const expires = new Date()
    expires.setDate(today.getDate() + 30)

    const newGrant = {
      club: undefined,
      validUntil: expires.getTime()
    }

    this.setState({
      trainer: {
        ...this.state.trainer,
        grants: [
          ...this.state.trainer.grants,
          newGrant
        ]
      }
    })
  }

  handleDelteClub (index) {
    if (this.state.trainer.grants.length > 1) {
      const grants = [...this.state.trainer.grants]
      grants.splice(index, 1)

      this.setState({
        trainer: {
          ...this.state.trainer,
          grants
        }
      })
    }
  }

  // This method is called by src/components/modules/static/menus/overlay.js:65
  handlePostRequest () {
    this.props.onLoading(true)
    this.setState({
      loading: true
    })
    const trainer = { ...this.state.trainer }
    trainer.type = 'TrainerToken'
    trainer.grants = trainer.grants.map((g) => {
      return {
        type: "TrainerSubscription",
        validUntil: new Date(g.validUntil).toISOString(),
        activeUntil: new Date(g.validUntil).toISOString(),
        paymentType: "Manuel-Admin",
        paymentId: "AdminSet-",
        data: {
          club: g.club,
          subTokenSlots: "3",
          subTrainer: ["", "", ""],
        }
      }
    })
    if (trainer.recieverMail === '') {
      this.props.onLoading(false)
      this.setState({
        loading: false,
        err: true
      })
      return null
    }

    postTrainerToken(trainer).then(res => {
      if (res.status === 200) {
        this.setState({
          loading: false,
          err: false
        })
        this.props.closeOverlay()
      } else {
        this.setState({
          loading: true,
          err: true
        })
      }

      this.props.onLoading(false)
    })
  }

  render () {
    const now = new Date().getTime()
    return (
      <div style={{ position: 'relative' }}>
        <form noValidate autoComplete='off'>
          <TextField
            id='email'
            label='Email'
            value={this.state.trainer.recieverMail}
            onChange={this.handleChangeEmail.bind(this)}
            margin='normal'
            fullWidth
            type='email'
            disabled={this.state.loading}
          />

          {this.state.trainer.grants.map((grant, index) => {
            const dateIsValidDate = !Number.isNaN(grant.validUntil) && grant.validUntil
            const dateIsValid = dateIsValidDate && grant.validUntil > now

            return (
              <div key={index} style={{ display: 'flex' }}>
                <TextField
                  id='club'
                  label='Club ID'
                  error={!isIntegerString(grant.club)}
                  helperText={!isIntegerString(grant.club) ? 'Bitte geben Sie eine valide Club-ID an.' : undefined}
                  value={grant.club}
                  onChange={this.handleChangeGrants(index)}
                  margin='normal'
                  fullWidth
                  type='text'
                  disabled={this.state.loading}
                />
                <div style={{ paddingTop: '16px', marginLeft: '10px' }}>
                  <p style={{ margin: '0', fontSize: '12px', color: dateIsValid ? '#757575' : '#f44336' }}>Gültig bis</p>
                  <input
                    label='Gültig bis'
                    className='table-datepicker'
                    style={{
                      outline: 'none',
                      paddingTop: '5px',
                      fontSize: '16px',
                      borderBottomWidth: dateIsValid ? undefined : '2px',
                      borderColor: dateIsValid ? undefined : '#f44336'
                    }}
                    id='date'
                    type='date'
                    value={dateIsValidDate ? new Date(grant.validUntil).toISOString().slice(0, 10) : ''}
                    onChange={this.handleDateTimePicker(index)}
                    disabled={this.state.loading}
                  />
                  {dateIsValid ? null :
                    <p
                      style={{
                        color: '#f44336',
                        margin: 0,
                        fontSize: '0.75rem',
                        textAlign: 'left',
                        marginTop: '8px',
                        minHeight: '1em',
                        lineHeight: '1em'
                      }}
                    >
                      Bitte geben Sie ein gültiges Datum aus der Zukunft an.
                    </p>
                  }
                </div>
                <div style={{ width: '72px', textAlign: 'center', paddingTop: '40px', color: 'rgb(247, 133, 133)', cursor: 'pointer' }}>
                  <FontAwesomeIcon icon={faMinusCircle} onClick={() => { this.handleDelteClub(index) }} />
                </div>
              </div>
            )
          })}
        </form>

        {this.state.loading ? null : (
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <button className='trainerMail-btn' onClick={this.handleMoreClubs.bind(this)}>
              <FontAwesomeIcon icon={faPlusCircle} />
            </button>
          </div>
        )}

        {this.state.err ? (
          <div className='error-container'>
            Ein Fehler ist aufgetreten! Bitte versuche es erneut.
          </div>
        ) : null}

        {this.state.loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <CircularProgress />
          </div>
        ) : null}
      </div>
    )
  }
}

export default OverlayTrainertool
