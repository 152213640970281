
import React, { Component } from 'react'
import HeadText from '../../modules/static/tableHeading'
import Grid from '@material-ui/core/Grid'
import Table from '../../modules/static/table'
import discovery from '@soccerwatch/discovery'

import { header, getLazyLoading, getLazyLoadingWithoutDataObj } from '../../api/helper'
// import { getAllContract, createContract } from '../../api/api-contract'
import { storageLayerTableInfo } from '../../modules/static/config/storageLayerTableConfig'
import { Snackbar } from '@material-ui/core'
import { setNewCity, setCity } from '../../api/api-city'

const config = {
  tableName: 'cityTable',
  tableUrl: '/city/',
  tableDialogTitle: 'City',
  tableToolbar: true,
  tableAdd: true,
  columns: [
    { name: 'RowKey', title: 'RowKey', operation: 'equal', editable: false, width: 90 },
    { name: 'name', title: 'name', width: 200 },
    { name: 'cityImageSelect', value: 'cityImageSelect', editable: false, toCreate: false, disabled: true },
    // { name: 'cityMerge', title: 'CityMerge', toCreate: false, value: 'cityMerge' },
    { name: 'country', title: 'country', width: 90 },
    { name: 'state', title: 'state', width: 150 },
    { name: 'stateShort', title: 'stateShort', width: 50 },
    { name: 'clubs', title: 'clubs', width: 90, editable: false, toCreate: false },
    { name: 'Timestamp', title: 'Timestamp', width: 90, editable: false, toCreate: false },
    { name: 'imageUrl', value: 'imageUrl', editable: false, toCreate: false, disabled: true },
    { name: 'Ref_CC_Tenant', title: 'Ref_CC_Tenant', width: 150, editable: false, tooltip: 'Tenant (Kunden) die diesem Eintrag zugeordnet sind' },
    ...storageLayerTableInfo
  ],
  defaultHiddenColumnNames: [{ label: 'Alles', value: [''] }]
}

class City extends Component {
  constructor (props) {
    super(props)

    this.state = {
      error: undefined,
      rows: [],
      tempId: 0,
      check: false,
      loadingRows: true,
      loadingCall: false
    }

    this.handleAddColum = this.handleAddColum.bind(this)
  }

  _isMounted = false;

  handleId = (id) => {
    if (id != null) {
      this.setState({ clubs: [], tempId: id })
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.handleGetCall()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  handleMounted = () => {
    return this._isMounted
  }

  handleGetContractList = (res, checkLast) => {
    // Sort and ADD Id by Rows
    res.sort((a, b) => {
      return null
    }).map((row, index) => {
      row.tableId = index
      return row
    })

    if (checkLast === true) {
      this.setState({
        rows: res,
        loadingRows: false
      })
    } else {
      this.setState({
        rows: res
      })
    }
  }

  handleGetCall = (check) => {
    const userId = this.props.match.params.UserId
    const getUrl = discovery.API_CITY + '/cities/'

    if (check) {
      this.state.rows.splice(0, this.state.rows.length)
      this.setState({ check: false, loadingRows: true })
    }

    const configArray = [
      {
        url: getUrl,
        minEntrys: undefined,
        auth: header,
        handle: this.handleGetContractList
      }
    ]

    if (userId) {
      getLazyLoadingWithoutDataObj(configArray, this.handleMounted)
    } else {
      getLazyLoading(configArray, this.handleMounted)
    }
  }

  handleEditColum = async (data) => {
    this.setState({ loadingCall: true })
    let checkPostStatus = false
    // SET 'RowKey' to Json, need this for backend
    data.json.RowKey = data.row.RowKey
    console.log('data', data.json)
    if (data.json.name && data.json.name === '') {
      this.setState({ error: 'Der Name darf nicht Leer sein!' })
      return
    }
    if (data.json.country && !data.json.country === '') {
      this.setState({ error: 'Das Land darf nicht Leer sein!' })
      return
    }
    if (data.json.state && !data.json.state === '') {
      this.setState({ error: 'Das Bundesland (bzw der Bundesstaat) darf nicht Leer sein!' })
      return
    }
    if (data.json.stateShort && !data.json.stateShort === '') {
      this.setState({ error: 'Das Kürzel für das Bundesland (bzw dem Bundesstaat) darf nicht Leer sein!' })
      return
    }
    await setCity(data.json.RowKey, data.json).then((res) => {
      if (res.status === 200) {
        checkPostStatus = true
        this.setState({ loadingCall: false })
      }
    })

    return checkPostStatus
  }

  async handleAddColum (data) {
    let checkPostStatus = false
    if (data.json.name && data.json.name === '') {
      this.setState({ error: 'Der Name darf nicht Leer sein!' })
      return
    }
    if (data.json.country && !data.json.country === '') {
      this.setState({ error: 'Das Land darf nicht Leer sein!' })
      return
    }
    if (data.json.state && !data.json.state === '') {
      this.setState({ error: 'Das Bundesland (bzw der Bundesstaat) darf nicht Leer sein!' })
      return
    }
    if (data.json.stateShort && !data.json.stateShort === '') {
      this.setState({ error: 'Das Kürzel für das Bundesland (bzw dem Bundesstaat) darf nicht Leer sein!' })
      return
    }

    await setNewCity(data.json).then((res) => {
      if (res.status === 200) {
        checkPostStatus = true
      }
    })

    return checkPostStatus
  }

  handleSnackbarClose = () => {
    this.setState({ error: undefined })
  }

  render () {
    return (
      <div>
        <HeadText text={this.props.headingText} />
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Table
              resetData={this.handleGetCall}
              onSelectId={this.handleId}
              tableConfig={config}
              tableRows={this.state.rows}
              param={this.props.match}
              loadingData={this.state.loadingRows}
              handleEditRow={this.handleEditColum}
              handleCreateRow={this.handleAddColum}
              loadingCall={this.state.loadingCall}
            />
          </Grid>
        </Grid>
        <Snackbar
          open={!!this.state.error}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          message={this.state.error}
          action={this.handleSnackbarClose}
        />
      </div>
    )
  }
}

export default City
