import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

class AlertCheckSimple extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false
    }

    this.handleAccept = this.handleAccept.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidUpdate () {
    if (this.props.open !== this.state.open) {
      this.setState({ open: this.props.open })
    }
  }

  handleAccept () {
    this.props.func()
    this.handleClose()
  }

  handleClose () {
    if (this.props.handleClose) {
      this.props.handleClose()
    }
    this.setState({
      open: false
    })
  }

  render () {
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{this.props.titel || 'Titel fehlt!'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {this.props.text || 'Text fehlt!'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Abbrechen
            </Button>
            <Button onClick={this.handleAccept} color='primary' autoFocus>
              {this.props.btnOk || 'Bestätigen'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default AlertCheckSimple
