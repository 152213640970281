import React, { Component } from 'react'
import HeadText from '../../static/tableHeading'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import NormalTest from './normalTest'
import FinalCheckTest from './finalCheckTest'

import { getCameraSystemList } from '../../../api/api-camera'

import 'react-select/dist/react-select.css'
import '../game.css'


class Addtest extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeTab: 0,
      selectCamId: [],
      tempCamList: []
    }
  }

  async componentDidMount () {
    await getCameraSystemList().then((res) => {
      this.setState({ cameras: res })
    })

    this.handleDropdownMenu()
  }

  handleDropdownMenu (name) {
    const tempCamList = []
    const tempSelectCamId = []

    if (name === 'checkAllCamsNotTest') {
      this.state.cameras.map((cam) =>
        cam.systemState === 'ACTIVE'
          ? tempCamList.push({ value: cam.RowKey, label: cam.RowKey + ') ' + cam.address, testSystem: false })
          : null
      )
    } else {
      this.state.cameras.map((cam) => {
        if (cam.lifecircleState === 'TESTSYSTEM' || cam.lifecircleState === 'DEV') {
          return tempCamList.push({ value: cam.RowKey, label: cam.RowKey + ') ' + cam.address, testSystem: true })
        } else {
          return tempCamList.push({ value: cam.RowKey, label: cam.RowKey + ') ' + cam.address, testSystem: false })
        }
      })
    }

    tempCamList.sort((num1, num2) => num1.value - num2.value)
    tempCamList.map((id) => {
      if (id.label.search('undefined') === -1 || id.testSystem) {
        return tempSelectCamId.push(id.value)
      }
      return null
    })

    return this.setState({
      tempCamList: tempCamList,
      selectCamId: tempSelectCamId
    })
  }

  handleChange = (event, value) => {
    this.setState({ activeTab: value })
  }

  handleChangeMultiSelect = (event) => {
    this.setState({ selectCamId: event.target.value })
  }

  render () {
    const { activeTab, tempCamList, selectCamId } = this.state

    return (
      <div>
        <HeadText text={this.props.headingText} />
        <Paper>
          <Tabs
            value={activeTab}
            onChange={this.handleChange}
          >
            <Tab
              disableRipple
              label="OldbutGold"
            />
            <Tab
              disableRipple
              label="Kamera Endprüfung"
            />
          </Tabs>

          <div style={{ borderTop: '2px solid #fff', marginTop: '-2px', paddingTop: '6px' }}>
            {activeTab === 0 ? (
              <NormalTest tempCamList={tempCamList} selectCamId={selectCamId} handleChangeMultiSelect={this.handleChangeMultiSelect} />
            ) : null}

            {activeTab === 1 ? (
              <FinalCheckTest tempCamList={tempCamList} />
            ) : null}
          </div>
        </Paper>
      </div>
    )
  }
}

export default Addtest
