import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Overlay from './btnOverlay'
import Runnote from './runnote/runnote'
import Webrtc from './webrtc/webrtc'
import SpeedTest from './speedTest/speedTest'

import { postCamTest, postCamWebServerStart, postCamWebServerStop } from '../../../api/api-camera'

import '../cameras.css'

class BtnArea extends Component {
  constructor (props) {
    super(props)

    this.state = {
      tempInfo: '',
      btnActive: false
    }
  }

  componentWillReceiveProps (prevProps) {
    if (this.props.rowinfo && (this.props.rowinfo.RowKey !== undefined)) {
      if (this.props.rowinfo !== prevProps.rowinfo || this.state.tempInfo === '') {
        this.setState({
          tempInfo: prevProps.rowinfo,
          btnActive: true
        })
      }
    }
  }

  handleChange = (name) => (event) => {
    switch (name) {
      case 'status': // status activated
        this.props.handleStatus(!this.props.statusState, this.state.tempInfo.tableId)
        break
      case 'test': // Camera-test start
        postCamTest(this.state.tempInfo.RowKey) // APicall
        this.props.notification(event, 'Erfolg', 'Test wurde gestartet ', 'success', 'tc') // notification info
        break
      case 'dashboard': // Telemetry start
        this.props.handleTelemetry(!this.props.telemetryState, this.state.tempInfo.tableId)
        break
      case 'webserverStart': // webserver start
        postCamWebServerStart(this.state.tempInfo.RowKey).then((res) => {
          if (res.status === 200) {
            this.props.notification(event, 'Erfolg', 'WebServer wurde gestartet ', 'success', 'tc')

            let tempId = this.state.tempInfo.RowKey

            if (String(tempId).length === 1) {
              tempId = '000' + this.state.tempInfo.RowKey
            } else if (String(tempId).length === 2) {
              tempId = '00' + this.state.tempInfo.RowKey
            } else if (String(tempId).length === 3) {
              tempId = '0' + this.state.tempInfo.RowKey
            }

            setTimeout(function () {
              window.open('http://control.soccerwatch.tv:4' + tempId, '_blank')
            }, 2800)
          } else {
            this.props.notification(event, 'Fehler', 'WebServer wurde nicht gestartet ', 'error', 'tc')
          }
        })
        break
      case 'webserverStop': // webserver start
        postCamWebServerStop(this.state.tempInfo.RowKey).then((res) => {
          if (res.status === 200) {
            this.props.notification(event, 'Erfolg', 'WebServer wurde gestoppt ', 'success', 'tc')
          } else {
            this.props.notification(event, 'Fehler', 'WebServer wurde nicht gestoppt ', 'error', 'tc')
          }
        })
        break
      default:
        break
    }
  };

  render () {
    const btnActive = this.state.btnActive

    return (
      <Paper className='camera-layout'>
        <div className='layout-head'>AKTUELLE KAMERA: {this.state.tempInfo && this.state.tempInfo.RowKey !== undefined ? this.state.tempInfo.RowKey : null}</div>
        <div className=''>
          {btnActive && this.state.tempInfo ? (
            <div>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12}>
                  <Runnote
                    openRunnotes={this.props.openRunnotes}
                    btnname='Laufzettel'
                    camId={this.state.tempInfo.RowKey}
                    tempInfo={this.state.tempInfo}
                    notification={this.props.notification}
                    resetData={this.props.resetData}
                    secondColor
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Overlay
                    btnname='Cleanup'
                    camId={this.state.tempInfo.RowKey}
                    tempInfo={this.state.tempInfo}
                    notification={this.props.notification}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                   <Webrtc
                    btnname='Cockpit'
                    camId={this.state.tempInfo.RowKey}
                    hardwarePlatform={this.state.tempInfo.hardwarePlatform}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Overlay
                    btnname='Starten'
                    camId={this.state.tempInfo.RowKey}
                    tempInfo={this.state.tempInfo}
                    notification={this.props.notification}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <SpeedTest
                    btnname='SpeedTest'
                    camId={this.state.tempInfo.RowKey}
                  />
                </Grid>
                {/*<Grid item xs={6} sm={6}>
                  <Overlay btnname='Connect RSSH' camId={this.state.tempInfo.RowKey} disabled />
                </Grid>*/}
                <Grid item xs={6} sm={6}>
                  <Overlay
                    btnname='Stoppen'
                    camId={this.state.tempInfo.RowKey}
                    tempInfo={this.state.tempInfo}
                    notification={this.props.notification}
                  />
                </Grid>
                {/*<Grid item xs={6} sm={6}>
                  <Overlay btnname='Disconnect RSSH' camId={this.state.tempInfo.RowKey} disabled />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Button
                    onClick={this.handleChange('test').bind(this)}
                    color='primary'
                    variant='contained'
                    className='camera-btn'
                  >
                    Test starten
                  </Button>
                </Grid>*/}
                <Grid item xs={6} sm={6}>
                  <Overlay
                    btnname='System update'
                    camId={this.state.tempInfo.RowKey}
                    tempInfo={this.state.tempInfo}
                    notification={this.props.notification}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Button
                    onClick={this.handleChange('status').bind(this)}
                    color='primary'
                    variant='contained'
                    className='camera-btn'
                  >
                    Status
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Button
                    onClick={this.handleChange('dashboard').bind(this)}
                    color='primary'
                    variant='contained'
                    className='camera-btn'
                  >
                    Dashboard
                  </Button>
                </Grid>
                {/*<Grid item xs={6} sm={6}>
                  <Button
                    onClick={this.handleChange('webserverStart').bind(this)}
                    color='primary'
                    variant='contained'
                    className='camera-btn'
                  >
                    WebServer Start
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Button
                    onClick={this.handleChange('webserverStop').bind(this)}
                    color='primary'
                    variant='contained'
                    className='camera-btn'
                  >
                    WebServer Stop
                  </Button>
                </Grid>*/}
                <Grid item xs={6} sm={6}>
                  <Overlay
                    btnname='Kamera deinstallieren'
                    camId={this.state.tempInfo.RowKey}
                    tempInfo={this.state.tempInfo}
                    notification={this.props.notification}
                    resetData={this.props.resetData}
                    secondColor
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Overlay
                    btnname='Kamera austauschen'
                    camId={this.state.tempInfo.RowKey}
                    tempInfo={this.state.tempInfo}
                    notification={this.props.notification}
                    resetData={this.props.resetData}
                    secondColor
                  />
                </Grid>
              </Grid>
            </div>
          ) : null}
        </div>
      </Paper>
    )
  }
}

export default BtnArea
