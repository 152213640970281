import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Table from '../../static/table'
import Hidden from '@material-ui/core/Hidden'
import Info from './container/info'

import { getContentClips, postContentClip, createContentClip } from '../../../api/api-clip'
import { uploadThumpnailLogo, uploadVideo, getSignedUrl } from '../../../api/api-mediaupload'

const noImg = 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_noimg_16x9.png'

const config = {
  tableName: 'contentTable',
  tableUrl: '/editing/clip/',
  tableDialogTitle: 'Clip',
  tableToolbar: true,
  tableAdd: true,
  columns: [
    { name: 'id', title: 'id', value: 'string', operation: 'equal', width: 150, toEditable: false, toCreate: false },
    { name: 'thumbnailImg', title: 'img', imagesUpload: { width: 960, height: 540, noImgUrl: noImg, fileFormat: ['png', 'jpeg'] } },
    { name: 'clipStreamFile', title: 'clip', videoUpload: { fileFormat: ['mp4'] }, width: 30 },
    { name: 'referenceDate', title: 'referenceDate', value: 'datetime', width: 175, toCreate: true },
    { name: 'active', title: 'active', value: 'boolean', width: 80, toCreate: true },
    { name: 'adFree', title: 'adFree', value: 'boolean', width: 80, toCreate: true },
    { name: 'name', title: 'name', value: 'string', width: 150, toCreate: true, multiline: true },
    { name: 'description', title: 'description', value: 'string', multiline: true, width: 150, toCreate: true },
    { name: 'thumbnail', title: 'thumbnail', value: 'string', width: 200, toCreate: true },
    { name: 'clipStream', title: 'clipStream', value: 'string', width: 200, toCreate: true },
    { name: 'city', title: 'city', value: 'cityOverlay', width: 100, toCreate: true },
    // { name: 'cityIds', title: 'cityIds', value: 'tagInput', width: 100, toCreate: false, readOnly: true },
    { name: 'clubIds', title: 'clubIds', value: 'array', width: 140, toCreate: true },
    { name: 'matchIds', title: 'matchIds', value: 'array', width: 140, toCreate: true },
    { name: 'priority', title: 'priority', value: 'number', width: 80, toCreate: true },
    { name: 'Timestamp', title: 'Timestamp', value: 'datetime', width: 170, toCreate: false, toEditable: false }
  ],
  defaultHiddenColumnNames: [
    { label: 'Alles', value: [''] }
  ]
}
class EditingContent extends Component {
  constructor (props) {
    super(props)

    this.state = {
      rows: [],
      tempId: 0,
      check: false,
      loadingRows: true,
      loadingCall: false,
      uploadProcess: undefined
    }

    this.onSelectId = this.onSelectId.bind(this)
    this.handleGetCall = this.handleGetCall.bind(this)
    this.handleEditRow = this.handleEditRow.bind(this)
    this.handleCreateRow = this.handleCreateRow.bind(this)
    // this.handleDeleteRow = this.handleDeleteRow.bind(this)
    this.handleUploadBar = this.handleUploadBar.bind(this)
  }

  onSelectId (id) {
    if (id != null) {
      this.setState({ tempId: id })
    }
  };

  componentDidMount () {
    this.handleGetCall()
  }

  async handleGetCall (check) {
    if (check) {
      this.state.rows.splice(0, this.state.rows.length)
      this.setState({ check: false, loadingRows: true })
    }

    getContentClips().then((res) => {
      // Sort and ADD id to Rows, id needs for Table Container
      res.sort((a, b) => {
        return new Date(b.referenceDate) - new Date(a.referenceDate)
      }).map((row, index) => {
        row.tableId = index
        row.city = String(row.city)
        row.clubIds = String(row.clubIds)
        row.matchIds = String(row.matchIds)
        const tempThumpnail = row.thumbnail || 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_noimg.png'
        row.thumbnailImg = (
          <img
            src={tempThumpnail}
            alt='no-thumbnail'
            style={{ width: 54, height: 32, marginTop: 2 }}
            onError={(e) => { e.target.onerror = null; e.target.src = 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_noimg.png' }}
          />)

        return row
      })
      this.setState({
        rows: res,
        loadingRows: false
      })
    })
  }

  async uploadImg (data) {
    let newUrl
    const imgInfo = {
      clipId: data.row.id,
      img: data.json.newThumbnail
    }
    await uploadThumpnailLogo(imgInfo).then((res) => {
      if (res.status === 200) {
        newUrl = res.data.thumbnail
      }
    })
    data.json.newThumbnail = undefined
    data.json.thumbnail = newUrl

    return data
  }

  handleUploadBar (prozess, finish) {
    if (finish) {
      this.setState({
        uploadProcess: undefined
      })
    } else {
      this.setState({
        uploadProcess: prozess
      })
    }
  }

  /**
   * @param {object} data
   * @returns
   *     data.json // new or edited value
   *     data.row  // return: current row
   */
  async handleEditRow (data) {
     // check object is emty / there is no change so nothing is saved
     if(data.json && Object.keys(data.json).length === 0 && data.json.constructor === Object) {
      return true
    }

    this.setState({ loadingCall: true })
    let checkEditStatus = false
    if (data.json.newThumbnail) {
      data = await this.uploadImg(data)
    }

    if (data.json.newVideoFile) {
      const fileName = data.row.id
      const dataSignedUrl = await getSignedUrl(fileName)
      const signedUrl = dataSignedUrl.data.signedUrl
      if (signedUrl) {
        const check = await uploadVideo(data.json.newVideoFile, signedUrl, this.handleUploadBar)
        if (check) {
          const newUrl = 'https://storage.googleapis.com/sw-sc-de-assets/content/cl-' + fileName + '.mp4'
          data.json.clipStream = newUrl
        }
      }
    }
    if (data.json.cityId) {
      data.json.cityIds = [data.json.cityId]
      delete data.json.cityId
    }
    await postContentClip(data.json, data.row.id).then((res) => {
      if (res.status === 200) {
        checkEditStatus = true
      }
    })
    this.setState({ loadingCall: false })
    return checkEditStatus
  }

  /**
   * @param {object} data
   * @returns
   *     data.json // new or edited value
   *     data.row  // return: current row
   */
  async handleCreateRow (data) {
    this.setState({ loadingCall: true })
    let checkPostStatus = false
    let uniqueKey
    if (data.json.cityId) {
      data.json.cityIds = [data.json.cityId]
      delete data.json.cityId
    }
    await createContentClip(data.json).then((res) => {
      if (res.status === 200) {
        uniqueKey = res.data.uniqueKey
        checkPostStatus = true
      } else {
        return null
      }
    })

    if (checkPostStatus) {
      let thumbnailURL
      if (uniqueKey && data.json.newThumbnail) {
        data.row.id = uniqueKey
        thumbnailURL = await this.uploadImg(data)
        if (thumbnailURL) {
          thumbnailURL = { thumbnail: thumbnailURL.json.thumbnail }
          // SET url
          await postContentClip(thumbnailURL, uniqueKey)
        }
      }

      if (uniqueKey && data.json.newVideoFile) {
        const fileName = uniqueKey
        const dataSignedUrl = await getSignedUrl(fileName)
        const signedUrl = dataSignedUrl.data.signedUrl
        if (signedUrl) {
          const check = await uploadVideo(data.json.newVideoFile, signedUrl, this.handleUploadBar, this.handleUploadBarFinish)
          if (check) {
            const clipStreamUrl = { clipStream: 'https://storage.googleapis.com/sw-sc-de-assets/content/cl-' + fileName + '.mp4' }
            // SET url
            await postContentClip(clipStreamUrl, uniqueKey)
          }
        }
      }
    }
    this.setState({ loadingCall: false })
    return checkPostStatus
  }

  render () {
    return (
      <div>
        <Grid container spacing={24}>
          <Hidden lgUp>
            <Grid item xs={12} lg={12}>
              <Info rowinfo={this.state.rows[this.state.tempId]} />
            </Grid>
          </Hidden>
          <Grid item xs={12} lg={8}>
            <Table
              tableConfig={config}
              tableRows={this.state.rows}
              loadingData={this.state.loadingRows}
              onSelectId={this.onSelectId}
              resetData={this.handleGetCall}
              handleEditRow={this.handleEditRow}
              handleCreateRow={this.handleCreateRow}
              loadingCall={this.state.loadingCall}
              uploadProcess={this.state.uploadProcess}
              param={this.props.match}
              // handleDeleteRow={this.handleDeleteRow}
              // addColum={AddColum}  // needs for individually overlay design by Create
              // editColum={EditColum} // needs for individually overlay design by Edit
            />
          </Grid>
          <Hidden mdDown>
            <Grid item lg={4}>
              <Info rowinfo={this.state.rows[this.state.tempId]} />
            </Grid>
          </Hidden>
        </Grid>
      </div>
    )
  }
}

export default EditingContent
