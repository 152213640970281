import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import moment from 'moment'

import { getCamStatus } from '../../../api/api-camera'

import '../cameras.css'

class Status extends Component {
  constructor (props) {
    super(props)

    this.state = {
      info: '',
      status: '',
      apiUrl: '',
      await: false
    }
  }

  componentDidMount () {
    getCamStatus(this.props.rowinfo.RowKey).then((res) => {
      this.setState({
        info: res.data,
        status: res.request.status,
        apiUrl: res.request.responseURL
      })

      this.setState({ await: true })
    })
  }

  statusUpload () {
    const matchIdArray = Object.keys(this.state.info.uploader)
    let renderDiv = null
    let finlDiv = []
    var index = matchIdArray.indexOf('upload_delay')
    if (index > -1) {
      matchIdArray.splice(index, 1)
    }

    // matchIdArray
    for (let i = 0; i < matchIdArray.length; i++) {
      renderDiv = (
        <div>
          <div className='status-body-info'>MatchId: {matchIdArray[i]}</div>
          <div className='status-body-info'>
            Match Duration: {this.state.info.uploader[matchIdArray[i]].match_duration}
          </div>
          <div className='status-body-info'>StartTime: {this.state.info.uploader[matchIdArray[i]].start_time}</div>
          <div className='status-body-info'>
            Upload Duration: {this.state.info.uploader[matchIdArray[i]].uploaded_duration}
          </div>
          <div className='status-body-info'>Upload Delay: {this.state.info.uploader.upload_delay}</div>
          <div className='after' />
        </div>
      )
      finlDiv = (
        <div>
          {finlDiv}
          {renderDiv}
        </div>
      )
    }

    return finlDiv
  }

  pad (num) {
    return ('0' + num).slice(-2)
  }

  hhmmss (secs) {
    var minutes = Math.floor(secs / 60)
    secs = secs % 60
    var hours = Math.floor(minutes / 60)
    minutes = minutes % 60
    return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(secs)}`
  }

  generatedList () {
    const list = []
    let uploadDatetime

    this.state.info.detail.upload.uploader.map((itm, index) => {
      const start = moment.unix(itm.start)

      if (itm.session === 0 && itm.index === 0) {
        uploadDatetime = moment(start).add(itm.uploaded_s, 'seconds')
      }

      let uploadingStatus
      if (itm.uploading) {
        uploadingStatus = 'uploading'
      } else if (itm.uploading && index >= 1) {
        uploadingStatus = 'delayed by previous uploader ' + this.state.info.detail.upload.uploader[index - 1].record_id
      } else {
        uploadingStatus = 'not uploading'
      }

      const calculatedEnd = moment(start).add(itm.duration_s, 'seconds')
      let estimatedDurationTilFinish
      let estimatedFinishDatetime
      let left
      let checkMoment

      if (itm.session === 0) {
        const remain = calculatedEnd.diff(uploadDatetime, 'seconds')
        if (moment(new Date()) < moment(calculatedEnd)) {
          if (this.state.info.detail.upload.factor < 1) {
            left = remain / this.state.info.detail.upload.factor
          } else {
            left = remain
          }
        } else {
          left = remain / this.state.info.detail.upload.factor
        }

        estimatedDurationTilFinish = left
        estimatedFinishDatetime = moment(new Date()).add(left)
        checkMoment = true
      } else {
        estimatedDurationTilFinish = 'NA'
        estimatedFinishDatetime = 'NA'
        checkMoment = false
      }

      const renderDiv = (
        <div key={index}>
          <div
            className='status-body-info'
            style={{ backgroundColor: '#efefef', padding: '4px', border: '1px solid #dcdcdc' }}
          >
            <div>
              {itm.record_id} : {uploadingStatus}
            </div>
            <table>
              <tr>
                <td>Started</td>
                <td />
                <td>{moment.unix(itm.start).format('DD.MM.YYYY HH:mm')}</td>
              </tr>
              <tr>
                <td>Uploaded / Remain</td>
                <td />
                <td>
                  {this.hhmmss(itm.uploaded_s)} / {this.hhmmss(itm.remain_s)}
                </td>
              </tr>
              <tr>
                <td>Record Duration</td>
                <td />
                <td>{this.hhmmss(itm.duration_s)}</td>
              </tr>
              <tr>
                <td>Upload Delay</td>
                <td />
                <td>{this.hhmmss(itm.delay_s)}</td>
              </tr>
              <tr>
                <td>Upload Finish In</td>
                <td />
                {checkMoment ? (
                  <td>
                    {this.hhmmss(estimatedDurationTilFinish)} /{' '}
                    {moment(estimatedFinishDatetime).format('DD.MM.YYYY HH:mm')}
                  </td>
                ) : (
                  <td>
                    {estimatedDurationTilFinish} / {estimatedFinishDatetime}
                  </td>
                )}
              </tr>
            </table>
          </div>
        </div>
      )

      list.push(renderDiv)

      return null
    })

    return list
  }

  renderSenosrInfo = () => {
    const sensor = ['0','1','2','3','4','5']

      
    if(!this.state.info.videoDevices) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }} className='status-body'>
            <div style={{ marginRight: 8, color: '#888', fontSize: '15px' }}>KAMERASENSOREN:</div>
            <div style={{ display: 'flex', gap: '12px' }}>ERROR</div>
        </div>
      )
    }

    const sensorRes = sensor.map(sensorId => {
      if(this.state.info.videoDevices.find((id) => id === 'video' + sensorId)) {
        return true
      } else {
        return false
      }
    })

    return (
      <div style={{ display: 'flex', alignItems: 'center', padding: '0 10px' }} className='status-body'>
        <div style={{ marginRight: 8, color: '#888', fontSize: '15px' }}>KAMERASENSOREN:</div>
        <div style={{ display: 'flex', gap: '12px' }}>
          {sensorRes.map((sensor, index) => {
            return (
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                Sensor {index + 1}: {sensor ? <p style={{ color: '#1be200' }}>aktiv</p> : <p style={{ color: 'red' }}>inaktiv</p>}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  render () {
    console.log(this.state.info)
    return this.state.await === true ? (
      <Paper className='camera-layout'>
        <div className='layout-head'>STATUS</div>
        <div>
          <div className='status-head'>Request URL:</div>
          <div className='status-body'>{this.state.apiUrl}</div>
          <div className='after' />
          <div className='status-head'>Response STATUS:</div>
          <div className='status-body'>{this.state.status}</div>
          <div className='after' />
          <div className='status-head'>INFO:</div>
          <div className='status-body'>
            <div className='status-body-info'>
              {this.state.info.online === true ? (
                <div className='dot-true'>online</div>
              ) : (
                <div className='dot-false'>offline</div>
              )}
            </div>
            <div className='status-body-info'>currentTask: {this.state.info.currentTask}</div>
            <div className='status-body-info'>internet: {this.state.info.networkInterface ? this.state.info.networkInterface : 'error'}</div>
            <div className='status-body-info'>system packages: {this.state.info.detail.version_basis_setup}</div>
            <div className='status-body-info'>version recorder: {this.state.info.detail.version_recorder}</div>
            <div className='status-body-info'>swc update: {this.state.info.detail.version_services}</div>
            {this.state.info.swcs !== undefined ? (
              <div>
                <div className='status-body-info'>fieldID: {this.state.info.swcs.fieldID}</div>
                <div className='status-body-info'>name: {this.state.info.swcs.name}</div>
                <div className='status-body-info'>uid: {this.state.info.swcs.uid}</div>
              </div>
            ) : null}
            <div className='after' />
          </div>
          {this.renderSenosrInfo()}
          {this.state.info.environment ? (
            <div className='status-body' style={{ display: 'flex', alignItems: 'center' }}>
              <div className='status-head' style={{ paddingLeft: 0, fontSize: 15 }}>TEMP/LUFTFEUCHTIGKEIT:</div>
              <div className='status-body-info'>Temperatur: {this.state.info.environment.temperature}°C</div>
              <div className='status-body-info'>Taupunkt: {this.state.info.environment.dewpoint}°C</div>
              <div className='status-body-info'>Absolute Luftfeuchtigkeit: {this.state.info.environment.absoluteHumidity} g/m³</div>
              <div className='status-body-info'>Relative Luftfeuchtigkeit: {this.state.info.environment.relativeHumidity}%</div>
              <div className='after' />
            </div>
          ) : (
            <div className='status-body' style={{ display: 'flex', alignItems: 'center' }}>
              <div className='status-head' style={{ paddingLeft: 0, fontSize: 15 }}>TEMP/LUFTFEUCHTIGKEIT:</div>
              <div className='status-body-info'>ERROR</div>
            </div>
          )}
          
          {this.state.info.swcs !== undefined && this.state.info.uploader ? (
            <div>
              <div className='status-head'>Upload:</div>
              <div className='status-body'>{this.statusUpload()}</div>
            </div>
          ) : null}

          {this.state.info.detail.upload ? (
            <>
              <div className='status-head'>UPLOAD:</div>
              <div className='status-body'>
                <div className='status-body-info'>status: {this.state.info.detail.upload.status}</div>
                <div className='status-body-info'>rate mbits: {this.state.info.detail.upload.rate_mbits}</div>
                <div className='status-body-info' style={{ display: 'flex' }}>
                  factor:
                  <p
                    style={
                      this.state.info.detail.upload.factor <= 1
                        ? { color: '#ff0000', fontWeight: 'bold', margin: '0px 6px' }
                        : this.state.info.detail.upload.factor <= 1.6
                          ? { color: '#e2db0', fontWeight: 'bold', margin: '0px 6px' }
                          : { color: '#1be200', fontWeight: 'bold', margin: '0px 6px' }
                    }
                  >
                    {this.state.info.detail.upload.factor}
                  </p>
                </div>
                <div className='after' />

                {this.generatedList()}

                <div className='after' />
              </div>
            </>
          ) : (
            <>
              <div className='status-head'>UPLOAD:</div>
              <div className='status-body'>
                <div className='status-body-info'>Nicht verfügbar, bitte Kamera updaten</div>
                <div className='after' />
              </div>
            </>
          )}

          {this.state.info.detail &&
          this.state.info.detail.packages &&
          this.state.info.detail.packages['system-packages'] ? (
            <>
              <div style={{ display: 'flex' }}>
                <table className='status-pack-table' align='left'>
                  <tr>
                    <th>Paketname</th>
                    <th>Version</th>
                    <th>Env</th>
                    <th>Installiert am</th>
                    <th>Installation erfolgreich</th>
                  </tr>

                  <div style={{ height: '8px' }} />
                  <tr>
                    <td>system-packages</td>
                    <td>{this.state.info.detail.packages['system-packages'].version}</td>
                    <td>{this.state.info.detail.packages['system-packages'].usage}</td>
                    <td>
                      {moment(this.state.info.detail.packages['system-packages'].installed_since).format(
                        'DD.MM.YYYY HH:mm'
                      )}
                    </td>
                    <td>{String(this.state.info.detail.packages['system-packages'].install_successful)}</td>
                  </tr>

                  {this.state.info.detail.packages['swc-update'] ? (
                    <tr>
                      <td>swc-update</td>
                      <td>{this.state.info.detail.packages['swc-update'].version}</td>
                      <td>{this.state.info.detail.packages['swc-update'].usage}</td>
                      <td>
                        {moment(this.state.info.detail.packages['swc-update'].installed_since).format(
                          'DD.MM.YYYY HH:mm'
                        )}
                      </td>
                      <td>{String(this.state.info.detail.packages['swc-update'].install_successful)}</td>
                    </tr>
                  ) : null}

                  <div style={{ height: '8px' }} />

                  {Object.keys(this.state.info.detail.packages).map((key, index) => {
                    if (key === 'system-packages' || key === 'swc-update') {
                      return null
                    }
                    return (
                      <tr key={index}>
                        <td>{key}</td>
                        <td>{this.state.info.detail.packages[key].version}</td>
                        <td>{this.state.info.detail.packages[key].usage}</td>
                        <td>
                          {moment(this.state.info.detail.packages[key].installed_since).format('DD.MM.YYYY HH:mm')}
                        </td>
                        <td>{String(this.state.info.detail.packages[key].install_successful)}</td>
                      </tr>
                    )
                  })}
                </table>
              </div>
              <div className='after' />
            </>
            ) : null}
        </div>
      </Paper>
    ) : null
  }
}

export default Status
