import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from '@material-ui/core'

// import './TagInput.scss'

class TagInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openOverlay: false,
      value: ''
    }
  }

  handleOpen = () => {
    this.setState({ openOverlay: true })
  }

  handleClose = () => {
    this.setState({ openOverlay: false })
  }
  onChangeValue = (e) => {
    const value = e.currentTarget.value
    this.setState({ value })
  }

  onAddItem = (e) => {
    if (!this.props.items.includes(this.state.value) && this.state.value !== '') {
      const added = this.props.onAddItem(this.state.value)
      if (added) {
        this.setState({ value: '' })
      }
    }
  }

  onRemoveItem = (index) => {
    const item = this.props.items[index]
    this.props.onRemoveItem(item, index)
  }

  render() {
    return (
      <Grid item xs={12} style={{ width: '100%' }}>
        <div className='textField-label'>
          {this.props.addLabel}
          {this.props.tooltip ? (
            <Tooltip disabled title={this.props.tooltip} placement="bottom-end">
              <div className="tooltip-icon">?</div>
            </Tooltip>
          ) : null}
        </div>

        <div style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '20px' }}>
          {this.props.dropdownValue && Array.isArray(this.props.dropdownValue) ? (
            <TextField
              id='select-currency-native'
              select
              className='textField-table-dropdowns'
              label={this.props.placeholde}
              value={this.state.value}
              onChange={this.onChangeValue}
              SelectProps={{
                native: true
              }}
              margin='dense'
              style={{margin: '24px 0 4px 0'}}
              disabled={this.props.disabled}
            >
              {this.props.dropdownValue.filter(item => !this.props.items.includes(item.value)).map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          ) : (
              <TextField
                className='textField-table'
                id='name'
                label={this.props.placeholder}
                value={this.state.value}
                onChange={this.onChangeValue}
                margin='dense'
                disabled={this.props.disabled}
              />
            )
          }

          <Button onClick={this.onAddItem} color='primary' variant='text' style={{ fontSize: 24 }} disabled={this.props.disabled}>
            <FontAwesomeIcon icon={faPlusCircle} />
          </Button>
        </div>
        {/*<div>{this.props.listLabel}</div>*/}
        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '-14px' , maxHeight: 400, overflow: 'auto' }}>
          {this.props.items ? this.props.items.map((itm, index) => {
            return (
              <div key={itm} style={{ flexShrink: '0', padding: '0 10px 10px 0' }}>
                <div style={{ padding:' 6px 10px', background: '#FF35173b', borderRadius: '20px' }}>

                {String(itm)} 
              
                <Button onClick={() => { this.onRemoveItem(index) }} color='primary' variant='text' style={{ fontSize: 16, padding: 0, margin: 0, minWidth: 0, marginTop: '-2px', marginLeft: '6px' }}>
                  <FontAwesomeIcon icon={faMinusCircle} />
                </Button>
                </div>
              </div>
            )
          }) : null}
        </div>
      </Grid>

    )
  }
}

export default TagInput
