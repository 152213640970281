import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import EditColumLocal from './editColum'
import Button from '@material-ui/core/Button'

import CircularProgress from '@material-ui/core/CircularProgress'

class TableDialog extends Component {
  state = {
    open: false,
    loading: this.props.loadingCall,
    loadingAdd: false
  };

  componentDidUpdate () {
    if (this.props.loadingCall !== this.state.loading) {
      this.setState({ loading: this.props.loadingCall })
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  };

  handleClose = (e, reload = false) => {
    this.setState({ open: false })
    if (reload) {
      this.props.reloadData()
    }
  };

  async handleDeleteRow () {
    await this.props.handleDeleteRow({ row: this.props.tableRow })
    this.props.changeRow(undefined, undefined, this.props.tableRow )
    this.handleClose()
  }

  handleLoading (load) {
    this.setState({ loading: load, loadingAdd: load })
  }

  render () {
    const EditColum = this.props.editColum || EditColumLocal
    const AddColum = this.props.addColum || EditColumLocal
    let tempText = '...'
    let tempTextEnd = ' Editieren'
    let HeaderText
    if (this.props.switch) {
      tempTextEnd = ' Hinzufügen'
    }
    tempText = this.props.tableConfig.tableDialogTitle ? this.props.tableConfig.tableDialogTitle : '...'
    HeaderText = tempText + tempTextEnd
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        {this.state.loading || this.state.loadingAdd ? (
          <>
            <div className='dialogLoading-bg' />
            <div className='dialogLoading-wait'><CircularProgress size={100} thickness={3} /></div>
          </>
        ) : null}

        <div>
          <DialogTitle id='alert-dialog-title'>{HeaderText}</DialogTitle>
          {this.props.handleDeleteRow && !this.props.switch ? (
            <Button
              onClick={this.handleDeleteRow.bind(this)}
              className='btn'
              variant='contained'
              style={{ backgroundColor: '#fd3d3d', float: 'right', marginTop: '-50px', marginRight: '20px' }}
            >
              löschen
            </Button>) : null}
        </div>
        <DialogContent>
          {this.props.switch ? (
            <AddColum
              tableConfig={this.props.tableConfig}
              handleClose={this.handleClose}
              tableRow={this.props.tableRow}
              handleRow={this.props.handleCreateRow}
              changeRow={this.props.changeRow}
              handleLoading={this.handleLoading.bind(this)}
              uploadProcess={this.props.uploadProcess}
              addRow
            />
          ) : (
            <EditColum
              tableConfig={this.props.tableConfig}
              handleClose={this.handleClose}
              tableRow={this.props.tableRow}
              handleRow={this.props.handleEditRow}
              changeRow={this.props.changeRow}
              handleLoading={this.handleLoading.bind(this)}
              uploadProcess={this.props.uploadProcess}
            />
          )}
        </DialogContent>
      </Dialog>
    )
  }
}

export default TableDialog
