import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { getAuthorization } from './helper'

rax.attach()

export const postAispotters = async (bodyObj) => {
  const body = JSON.stringify(bodyObj)
  return axios.post(discovery.API_TAG + '/AiSpotterProxy/', body, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}
