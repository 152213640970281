import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

class FormDialog extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: true
    }

    this.handleClose = this.handleClose.bind(this)
  }

  handleClose () {
    this.setState({
      open: false
    })
  }

  render () {
    const spanStyle = { color: '#4a4a4a', textDecoration: 'underline', padding: '0 6px' }

    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{'Club wurde Importiert.'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <span style={spanStyle}>{this.props.data.RowKey}</span>
              Verein
              <span style={spanStyle}>{ this.props.data.name }</span>
              in Datenbank
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary' autoFocus>
            Weiter
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default FormDialog
