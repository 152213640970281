import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { getAuthorization } from './helper'

rax.attach()

/*export const getAllGeneric = async () => {
  return axios.get(discovery.API_ADDITIONAL + '/generic', { headers: { Authorization: await getAuthorization() } })
}*/

export const postGeneric = async (body, key) => {
  return axios.post(discovery.API_ADDITIONAL + '/genericById/' + key, body, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const deleteGeneric = async (key) => {
  return axios.delete(discovery.API_ADDITIONAL + '/genericById/' + key, { headers: { Authorization: await getAuthorization() } })
}

/*export const getSponsor = async () => {
  let SponsorList = []
  await axios.get(discovery.API_ADDITIONAL + '/sponsors/', { headers: { Authorization: await getAuthorization() } }).then((res) => {
    SponsorList = res
    SponsorList.data = res.data.data
  })
  return SponsorList
}*/

export const getSponsorFor = async (uid) => {
  let Sponsor = []
  await axios.get(discovery.API_ADDITIONAL + '/sponsor/' + uid, { headers: { Authorization: await getAuthorization() } }).then((res) => {
    Sponsor = res
    Sponsor.data = res.data.data
  })
  return Sponsor
}

export const postSponsor = async (json) => {
  const insert = json
  const updateAddon = insert.RowKey ? `/${insert.RowKey}` : ''
  return axios.post(discovery.API_ADDITIONAL + '/sponsor/' + insert.PartitionKey + updateAddon, json, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const getAllPaywallPackages = async (id) => {
  return axios.get(`${discovery.API_ADDITIONAL}/paywall/manage/video/${id}`, { headers: { Authorization: await getAuthorization() } })
}

export const postPaywallPackage = async (body) => {
  return axios.post(discovery.API_ADDITIONAL + '/paywall/package?callAs=admin', body, { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}
