import React, { Component } from 'react'
import HeadText from '../static/tableHeading'
import Grid from '@material-ui/core/Grid'
import Table from '../static/table'
import discovery from '@soccerwatch/discovery'
import { header, getLazyLoading } from '../../api/helper'

import './league.css'
import { createLeague, updateLeague } from '../../api/api-competition'
import { Snackbar, Alert } from '@material-ui/core'
import { storageLayerTableInfo } from '../../modules/static/config/storageLayerTableConfig'
import { priorityTenScale } from '../static/dropdownValues'

const config = {
  tableName: 'leagueTable',
  tableUrl: '/league/',
  tableDialogTitle: 'League',
  tableToolbar: true,
  tableAdd: true,
  columns: [
    { name: 'RowKey', title: 'RowKey', operation: 'equal', editable: false, toCreate: false, width: 90 },
    { name: 'name', title: 'Name', toCreate: true, toEditable: false, width: 140 },
    { name: 'active', title: 'Active', value: 'boolean', toCreate: true, width: 90 },
    { name: 'eventType', title: 'Event Type', value: 'string', toCreate: true, width: 90 },
    { name: 'color', title: 'color', value: 'string', toCreate: false, width: 90 },
    { name: 'image', title: 'Image', value: 'string', toCreate: false, width: 140 },
    { name: 'leagueName', title: 'League Name', value: 'string', toCreate: true, width: 90 },
    { name: 'leagueScore', title: 'League Score', value: 'number', toCreate: true, width: 90 },
    { name: 'SLNeedsVerification', title: 'SLNeedsVerification', value: 'boolean', width: 140, toCreate: false },
    { name: 'SLCreatedBy', title: 'SLCreatedBy', width: 140, toCreate: false },
    { name: 'SLLastUpdatedBy', title: 'SLLastUpdatedBy', width: 140, toCreate: false, editable: false },
    ...storageLayerTableInfo
  ],
  defaultHiddenColumnNames: [{ label: 'Alles', value: [''] }]
}

class League extends Component {
  constructor (props) {
    super(props)

    this.state = {
      clubs: [],
      rows: [],
      tempId: 0,
      check: false,
      loadingRows: true,
      loadingCall: false,
      error: undefined
    }

    this.handleGetLeagueList = this.handleGetLeagueList.bind(this)
    this.handleEditColum = this.handleEditColum.bind(this)
    this.handleAddColum = this.handleAddColum.bind(this)
    this.handleMounted = this.handleMounted.bind(this)
    this.handleGetCall = this.handleGetCall.bind(this)
    this.handleId = this.handleId.bind(this)
  }

  _isMounted = false;

  handleId (id) {
    if (id != null) {
      this.setState({ clubs: [], tempId: id })
    }
  };

  /**
   * Api-call get ClubList
   */
  componentDidMount () {
    this._isMounted = true
    this.handleGetCall()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  handleMounted () {
    return this._isMounted
  }

  handleGetLeagueList (res, checkLast) {
    // Sort and ADD Id by Rows
    res.sort((a, b) => {
      return null
      // return Number(a.RowKey) - Number(b.RowKey)
    }).map((row, index) => {
      index === 0 && console.log(row)
      // row.link = (
      //   <a
      //     target='_blank'
      //     rel='noopener noreferrer'
      //     style={{ color: 'blue' }}
      //     href={`https://www.fussball.de/verein/id/${row.dId}#!/`}
      //   >
      //     Fussball.de
      //   </a>
      // )
      row.tableId = index

      return row
    })

    if (checkLast === true) {
      this.setState({
        rows: res,
        loadingRows: false
      })
    } else {
      this.setState({
        rows: res
      })
    }
  }

  handleGetCall (check) {
    if (check) {
      this.state.clubs.splice(0, this.state.clubs.length)
      this.state.rows.splice(0, this.state.rows.length)
      this.setState({ check: false, loadingRows: true })
    }

    const configArray = [
      {
        url: discovery.API_COMPETITION + '/leagues/',
        minEntrys: undefined,
        auth: header,
        handle: this.handleGetLeagueList
      }
    ]

    getLazyLoading(configArray, this.handleMounted)
  }

  async handleEditColum (data) {
    this.setState({ loadingCall: true })
    let checkPostStatus = false
    // SET 'RowKey' to Json, need this for backend
    data.json.RowKey = data.row.RowKey
    data.json.name = data.row.name
    await updateLeague(data.json).then((res) => {
      if (res.status === 200) {
        checkPostStatus = true
        this.setState({ loadingCall: false })
      }
    })

    return checkPostStatus
  }

  requiredFields = ['name', 'eventType', 'leagueName', 'leagueScore']

  async handleAddColum (data) {
    let checkPostStatus = false
    const missingFields = this.requiredFields.filter((key) => !data.json[key] && data.json[key] !== 0)
    if (missingFields.length) {
      this.setState({ error: `Benötigte Daten unvollständig. Bitte die Felder: ${missingFields.join(', ')} ausfüllen` })
      return
    } else {
      await createLeague(data.json).then((res) => {
        if (res.status === 200) {
          checkPostStatus = true
        }
      })
    }
    return checkPostStatus
  }

  handleSnackbarClose = () => {
    this.setState({ error: undefined })
  }

  render () {
    return (
      <div>
        <HeadText text={this.props.headingText} />

        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Table
              resetData={this.handleGetCall}
              onSelectId={this.handleId}
              tableConfig={config}
              tableRows={this.state.rows}
              param={this.props.match}
              loadingData={this.state.loadingRows}
              handleEditRow={this.handleEditColum}
              handleCreateRow={this.handleAddColum}
              loadingCall={this.state.loadingCall}
              // addColum={AddColum}
            />
          </Grid>
        </Grid>
        <Snackbar
          open={this.state.error}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          message={this.state.error}
          action={this.handleSnackbarClose}
        />
      </div>
    )
  }
}

export default League
