import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

class SearchBox extends Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  handleClick (id) {
    window.open(window.location.origin + '/games/overview/rowkey=' + id, '_blank')
  }

  render () {
    return (
      <div>
        <Paper>
          <div className='layout-head'>{this.props.headText}</div>
          <div className='searchBox-leg'>
            <FontAwesomeIcon className='searchBox-btn-activ' icon={faCheck} /> Aktiv
            <FontAwesomeIcon style={{ paddingLeft: '10px' }} className='searchBox-btn-inactiv' icon={faTimes} /> Inaktiv
          </div>
          <div className='searchBox-leg-clubname'>{this.props.data.clubName}</div>
          <div className='errorBox'>
            {this.props.data.matches.map((n, index) => {
              var options = { year: 'numeric', month: 'numeric', day: 'numeric' }
              var date = new Date(n.gameDay).toLocaleDateString('de-DE', options)
              return (
                <div key={index} style={{ display: 'flex' }} onClick={this.handleClick.bind(this, n.RowKey)}>
                  <div className='searchBox-numberSearch'>
                    {n.active ? (
                      <FontAwesomeIcon className='searchBox-btn-activ' icon={faCheck} />
                    ) : (
                      <FontAwesomeIcon className='searchBox-btn-inactiv' icon={faTimes} />
                    )}
                  </div>
                  <div className={n.errorMessage !== undefined ? 'errorBox-list-fail errorBox-list' : 'errorBox-list'}>
                    <div>
                      <div style={{ fontSize: '12px' }}>
                        ID-
                        {n.RowKey}
                      </div>
                      <div style={{ fontSize: '12px' }}>{date}</div>
                      <div style={{ fontSize: '12px' }}>{n.clubB}</div>
                    </div>
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <div style={{ fontSize: '12px', position: 'relative', float: 'right' }}>{n.errorMessage}</div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </Paper>
      </div>
    )
  }
}

export default SearchBox
