import { Component } from 'react'

import '../static.scss'

class TableIdHadle extends Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  componentDidMount () {
    this.props.handleIdFiltering(this.props.handleId)
  }

  componentDidUpdate (prevProps) {
    if (this.props.handleId !== prevProps.handleId) {
      this.props.handleIdFiltering(this.props.handleId)
    }
  }

  render () {
    return null
  }
}

export default TableIdHadle
