export const describe = {
  grp: [
    {
      name: 'Installation',
      value: 'install',
      assignment: 'swcs',
      content: [
        {
          name: 'Produktion',
          value: 'montage',
          text: 'Produktion',
          content: [
            {
              name: 'Anmerkung vom vorherigen Laufzettel',
              content: [{ type: 'tooltipAnnotation' }]
            },
            {
              name: 'Allgemeine Informationen',
              content: [
                { type: 'textfield', heading_text: 'ID', id: 'General_SystemId', disabled: true },
                { type: 'textfield', id: 'Hardware_Version', heading_text: 'Hardware-Code', mandatory: true },
                { type: 'date', id: 'General_ProdDate', heading_text: 'Produktionsdatum', mandatory: true },
                {
                  type: 'checkbox',
                  id: 'General_OldIdCheck',
                  heading_text: 'Vorgänger ID',
                  after_text: 'nicht vorhanden / Neues System'
                },
                { type: 'textfield', id: 'General_OldId' },
                {
                  type: 'tooltip',
                  heading_text:
                    'Ein erneut geflashetes Jetson Modul erhält immer eine neue ID. Um Hardwarefehler nachverfolgen zu können, muss die vorige ID hier festgehalten werden.'
                }
              ]
            },
            /*{
              name: 'Hardware',
              content: [
                
                /* { type: 'textfield', id: 'Hardware_CamVersion', heading_text: 'Kamerakopf-Version', mandatory: true },
                {
                  type: 'dropdown',
                  id: 'Hardware_Casing',
                  heading_text: 'Gehäuse-Typ',
                  value: ['SHP-3701H (Samsung Kamera Dome)'],
                  mandatory: true
                },
                { type: 'textfield', id: 'Hardware_Heatsink', heading_text: 'Kühlkörper-Version', mandatory: true },
                { type: 'text', heading_text: 'Leitungsführung mit Gel ausgegossen und verschlossen?' },
                { type: 'checkboxGroup', id: 'Hardware_wiringGel', value: ['Ja', 'Nein'], mandatory: true } *//*
              ]
            },*/
            {
              name: 'Cellular-Modul',
              content: [
                {
                  type: 'dropdown',
                  id: 'Cellular_Type',
                  heading_text: 'Typ',
                  value: ['USB-LTE Stick', 'Huawei E3372 (USB LTE Stick)', 'TOBY-L201 (N. America)', 'TOBY-L210 (EMEA/APAC)', 'TOBY-L220 (Japan)', 'TOBY-L280 (S. America/APAC)', 'Quectel EG25-G (Global)', 'Quectel EC25-E (EMEA/South Korea/Thailand/India)', 'Quectel EC25-EUX (EMEA/Thailand)'],
                  mandatory: true
                },
                { type: 'textfield', id: 'Cellular_Id', heading_text: 'Modul Identifier (IMEI)', mandatory: true },
                { type: 'textfield', id: 'Cellular_SimId', heading_text: 'SIM Seriennummer', mandatory: true }
              ]
            },
            {
              name: 'Computer-Modul',
              content: [
                { type: 'text', heading_text: 'Type' },
                { type: 'checkboxGroup', id: 'Computer_Type', value: ['TX1', 'TX2'], mandatory: true },
                {
                  type: 'textfield',
                  id: 'Computer_SerialNo',
                  heading_text: 'Computermodul-Serien-Nummer (S/N)',
                  mandatory: true
                },
                { type: 'text', heading_text: 'Mac-Adressen' },
                { type: 'textfield', id: 'Computer_Mac0', heading_text: 'MAC 0', mandatory: true },
                { type: 'textfield', id: 'Computer_Mac1', heading_text: 'MAC 1', mandatory: true },
                { type: 'textfield', id: 'Computer_Mac2', heading_text: 'MAC 2', mandatory: true }
              ]
            },
            {
              name: 'Carrierboard',
              content: [
                {
                  type: 'dropdown',
                  id: 'Carrierboard_Platin',
                  heading_text: '',
                  value: ['Althalimain (e-con)', 'Auvidea J106 & Auvidea M100']
                },
                { type: 'textfield', id: 'Carrierboard_FabricationCode', heading_text: 'Fabrication Code', mandatory: true },
                { type: 'textfield', id: 'Carrierboard_SN', heading_text: 'Serien-Nummer (S/N)', mandatory: true },
                { type: 'textfield', id: 'Carrierboard_Rev', heading_text: 'Revisions-Nummer (rev)', mandatory: true }
              ]
            },
            /* {
              name: 'Trägerplatine Auvidea J106',
              content: [
                { type: 'checkbox', id: 'AuvideaJ106_NotInstall', after_text: 'nicht verbaut' },
                { type: 'textfield', id: 'AuvideaJ106_SerialNo', heading_text: 'Serien-Nummer (S/N)' },
                {
                  type: 'textfield',
                  id: 'AuvideaJ106_RevisionNo',
                  heading_text: 'Revisions-Nummer (rev)'
                }
              ]
            },
            {
              name: 'Trägerplatine Auvidea M100',
              content: [
                { type: 'checkbox', id: 'AuvideaM100_NotInstall', after_text: 'nicht verbaut' },
                {
                  type: 'dropdown',
                  id: 'AuvideaM100_RevisionNo',
                  heading_text: 'Revisions-Nummer (rev)',
                  value: ['38217-3', '38217-2', '3824-3']
                },
                {
                  type: 'dropdown',
                  id: 'AuvideaM100_Type',
                  heading_text: 'Typ / Sonstiges',
                  value: ['M.2 Typ B Variante', 'Typ / Sonstiges', 'M110']
                }
              ]
            },
            {
              name: 'Trägerplatine Althalimain (e-con)',
              content: [
                { type: 'checkbox', id: 'Althalimain_NotInstall', after_text: 'nicht verbaut' },
                {
                  type: 'textfield',
                  id: 'Althalimain_SerialNo',
                  heading_text: 'Serien-Nummer (S/N)'
                },
                {
                  type: 'textfield',
                  id: 'Althalimain_RevisionNo',
                  heading_text: 'Revisions-Nummer (rev)'
                }
              ]
            }, */
            {
              name: 'Speicher-Modul',
              content: [
                {
                  type: 'checkboxGroup',
                  id: 'AuvideaJ106_Storage',
                  value: ['Micro SD Karte', 'M.2 Modul'],
                  mandatory: true
                },
                {
                  type: 'dropdownOnly',
                  id: 'AuvideaJ106_SDCardCapacity',
                  heading_text: 'Speicher Kapazität',
                  value: ['32', '64', '128', '256'],
                  withoutspace: true,
                  mandatory: true
                }
              ]
            },
            /* {
              name: 'Powerline Adapter (PLC) Kamerasystem',
              content: [
                { type: 'checkbox', id: 'PLC_NotInstall', after_text: 'nicht verbaut' },
                { type: 'dropdown', id: 'PLC_Model', heading_text: 'Geräte Model', value: ['ALL168250'] },
                { type: 'checkbox', id: 'PLC_MarkedAndCoupled', after_text: 'Adapter gekoppelt und markiert' },
                { type: 'textfield', id: 'PLC_MAC', heading_text: 'MAC' }
              ]
            }, */
            {
              name: 'Sound-Modul',
              content: [
                {
                  type: 'dropdown',
                  id: 'Soundmodul_Typ',
                  heading_text: 'Typ',
                  value: ['USB Sound Karte', 'I2S Mikro'],
                  mandatory: true
                },
                {
                  type: 'dropdown',
                  id: 'Soundmodul_SoundCard',
                  heading_text: 'Bezeichnung',
                  value: ['Creative SoundBlaster', 'Soundcard', 'Dynamode USB 2.0', 'I2S Stereo Mikrofon', 'test'],
                  mandatory: true
                },
                {
                  type: 'dropdown',
                  id: 'Soundmodul_Mikrofon',
                  heading_text: 'Mikrofon',
                  value: ['Mikrofonkapsel EMY-63M', 'Creative Soundblaster', 'Mikrofon', 'Knowles SPH0645LM4H-B', 'test'],
                  mandatory: true
                }
              ]
            },
            {
              name: 'Qualitätssicherung',
              content: [
                { type: 'text', heading_text: 'QS korrekt durchgeführt?' },
                { type: 'checkbox', id: 'Quality_Check', after_text: 'Ja,', mandatory: true },
                { type: 'textfield', id: 'Quality_Version', heading_text: 'gemäß QS-Version', mandatory: true },
                { type: 'textfield', id: 'Quality_TestRecordingEnd', heading_text: 'Testaufnahme Endprüfung' },
                { type: 'datetime', id: 'Quality_TestRecordingEndDate', heading_text: 'Datum Endprüfung', mandatory: true },
                { type: 'textfield', id: 'Quality_Text', heading_text: 'Notizen' }
              ]
            },
            {
              name: 'Inbetriebnahme',
              content: [
                { type: 'multiline', id: 'Commissioning_Text', label: 'Festgestellte Mängel' },
                {
                  type: 'checkboxGroup',
                  id: 'Commissioning_Check',
                  value: ['FREIGEGEBEN', 'NICHT FREIGEGEBEN'],
                  mandatory: true
                },
                {
                  type: 'tooltip',
                  heading_text:
                    'FREIGEGEBEN: Bild, Ton und Empfang funktionieren fehlerfrei. Alle Mängel wurden beseitigt. Das System ist freigegeben zur Installation.'
                },
                {
                  type: 'tooltip',
                  heading_text:
                    'NICHT FREIGEGEBEN: Das System hat einen Defekt oder benötigt Nacharbeit.'
                }
              ]
            },
            {
              name: 'QR Code',
              content: [
                { type: 'qrCodeGenerate', id: 'Qr_Code' }
              ]
            },
            /* {
              name: 'Anmerkungen',
              content: [{ type: 'multiline', id: 'Annotation_Text', label: 'Notizen' }]
            }, */
            {
              name: 'Anmerkung',
              content: [{ type: 'multiline', id: 'Annotation_NextNote_Text', label: 'Notizen' }]
            },
            {
              name: 'Abnahme',
              content: [
                { type: 'checkbox', id: 'Decrease_Check', after_text: 'Geprüft' },
                { type: 'datetime', id: 'Decrease_Date', heading_text: 'Datum', disabled: true },
                { type: 'textfield', id: 'Decrease_Name', heading_text: 'Username:', disabled: true }
              ]
            }
          ]
        },



        ////////////////////////////////////////////////////////////////////////////////////////////////
        {
          name: 'Nacharbeit',
          value: 'rework',
          text: 'Nacharbeit',
          content: [
            {
              name: 'Anmerkung vom vorherigen Laufzettel',
              content: [{ type: 'tooltipAnnotation' }]
            },
            {
              name: 'Allgemeine Informationen',
              content: [
                { type: 'textfield', id: 'General_SystemId', heading_text: 'ID', mandatory: true, disabled: true },
                { type: 'textfield', id: 'Hardware_Version', heading_text: 'Hardware-Code (neu)', mandatory: true },
                { type: 'date', id: 'General_ReworkDate', heading_text: 'Datum', mandatory: true },
              ]
            },
            {
              name: 'Durchgeführte Änderungen',
              content: [{ type: 'multiline', id: 'Rework_Changes', label: '', mandatory: true }]
            },
            {
              name: 'Qualitätssicherung',
              content: [
                { type: 'text', heading_text: 'QS korrekt durchgeführt?' },
                { type: 'checkbox', id: 'Quality_Check', after_text: 'Ja,', mandatory: true },
                { type: 'textfield', id: 'Quality_Version', heading_text: 'gemäß QS-Version', mandatory: true },
                { type: 'textfield', id: 'Quality_TestRecordingEnd', heading_text: 'Testaufnahme Endprüfung' },
                { type: 'datetime', id: 'Quality_TestRecordingEndDate', heading_text: 'Datum Endprüfung', mandatory: true },
                { type: 'textfield', id: 'Quality_Text', heading_text: 'Notizen' }
              ]
            },
            {
              name: 'Inbetriebnahme',
              content: [
                { type: 'multiline', id: 'Commissioning_Text', label: 'Festgestellte Mängel' },
                {
                  type: 'checkboxGroup',
                  id: 'Commissioning_Check',
                  value: ['FREIGEGEBEN', 'NICHT FREIGEGEBEN'],
                  mandatory: true
                },
                {
                  type: 'tooltip',
                  heading_text: 'FREIGEGEBEN: Bild, Ton und Empfang funktionieren fehlerfrei. Alle Mängel wurden beseitigt. Das System ist freigegeben zur Installation.'
                },
                {
                  type: 'tooltip',
                  heading_text: 'NICHT FREIGEGEBEN: Das System hat einen Defekt oder benötigt Nacharbeit.'
                }
              ]
            },
            {
              name: 'QR Code',
              content: [
                { type: 'qrCodeGenerate', id: 'Qr_Code' }
              ]
            },
            {
              name: 'Anmerkung',
              content: [{ type: 'multiline', id: 'Annotation_NextNote_Text', label: 'Notizen' }]
            },
            {
              name: 'Abnahme',
              content: [
                { type: 'checkbox', id: 'Decrease_Check', after_text: 'Geprüft' },
                { type: 'datetime', id: 'Decrease_Date', heading_text: 'Datum', disabled: true },
                { type: 'textfield', id: 'Decrease_Name', heading_text: 'Username:', disabled: true }
              ]
            }
          ]
        },
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////








        {
          name: 'Installation',
          value: 'install',
          text: 'Installation',
          content: [
            {
              name: 'Anmerkung vom vorherigen Laufzettel',
              content: [{ type: 'tooltipAnnotation' }]
            },
            {
              name: 'Allgemein Informationen',
              content: [
                { type: 'text', heading_text: 'Art der Installation' },
                {
                  type: 'checkboxGroup',
                  id: 'General_TypeOfInstall',
                  value: ['Neuinstallation', 'Kamera Wechsel'],
                  mandatory: true
                },
                {
                  type: 'tooltip',
                  heading_text:
                    "Bei einem Kamera Wechsel muss für die deinstallierte Kamera ein DEINSTALLATIONS Laufzettel ausgefüllt werden. Die ID der deinstallierten Kamera wird hier bei 'Vorgänger ID' eingetragen."
                },
                { type: 'textfield', id: 'General_NewId', heading_text: 'ID' },
                { type: 'textfield', id: 'General_OldId', heading_text: 'Vorgänger ID (wenn vorhanden)' },
                {
                  type: 'checkbox',
                  id: 'General_Uninstall',
                  after_text: 'Vorgänger wurde bereits im Vorfeld ersatzlos deinstalliert'
                },
                { type: 'textfield', id: 'General_CamVersion', heading_text: 'Hardware-Code', mandatory: true },
                { type: 'date', id: 'General_InstallDate', heading_text: 'Installationsdatum', mandatory: true },
                { type: 'multiline', id: 'General_Plumber', heading_text: 'Installateur(e)', mandatory: true },
                { type: 'text', heading_text: 'Adresse der Installation' },
                { type: 'textfield', id: 'General_Club', heading_text: 'Verein', mandatory: true },
                { type: 'textfield', id: 'General_Street', heading_text: 'Straße', mandatory: true },
                { type: 'textfield', id: 'General_Plz', heading_text: 'PLZ', mandatory: true },
                { type: 'textfield', id: 'General_Place', heading_text: 'Ort', mandatory: true },
                { type: 'textfield', id: 'General_Country', heading_text: 'Land', mandatory: true },
                {
                  type: 'textfield',
                  id: 'General_PlayfieldNumber',
                  heading_text: 'Platzbezeichnung (Nummer oder Name)'
                },
                {
                  type: 'dropdown',
                  id: 'General_LocationType',
                  heading_text: 'Platzart',
                  value: ['Kunstrasen', 'Asche', 'Naturrasen'],
                  mandatory: true
                }
              ]
            },
            {
              name: 'Kameraposition',
              content: [
                {
                  type: 'dropdown',
                  id: 'CameraPos_Place',
                  heading_text: 'Installationsort',
                  value: [
                    'Flutlichtmast, auf Höhe der Mittellinie',
                    'Tribünendach',
                    'Flutlichtmast, versetzt zur Mittellinie.',
                    'Mobiler Mast'
                  ],
                  mandatory: true
                },
                {
                  type: 'textfield-endicon',
                  id: 'CameraPos_Height',
                  iconTextEnd: 'Meter',
                  heading_text: 'Installationshöhe',
                  mandatory: true
                },
                {
                  type: 'textfield-endicon',
                  id: 'CameraPos_Distance',
                  iconTextEnd: 'Meter',
                  heading_text: 'Distanz zwischen dem Kamera Lot und der Seitenlinie',
                  mandatory: true
                },
                {
                  type: 'textfield-endicon',
                  id: 'CameraPos_Offset',
                  iconTextEnd: 'Meter',
                  heading_text: 'Seitlicher Versatz der Kamera von der Mittellinie',
                  mandatory: true
                }
              ]
            },
            {
              name: 'Kommunikation',
              content: [
                { type: 'text', heading_text: 'Welche Verbindungsart steht dem Kamerasystem zur Verfügung' },
                {
                  type: 'checkbox',
                  id: 'Communication_Omni',
                  after_text: 'Mobilfunk, Omni-Antenne',
                  dependencies: true
                },
                {
                  type: 'checkbox',
                  id: 'Communication_Antenna',
                  after_text: 'Mobilfunk, Richtantenne',
                  dependencies: true
                },
                { type: 'textfield', id: 'Communication_Alignment', heading_text: 'Ausrichtung (Richtantenne)' },
                // { type: 'checkbox', id: 'Communication_Powerline', after_text: 'Powerline', dependencies: true },
                // { type: 'checkbox', id: 'Communication_Wlan', after_text: 'WLAN', dependencies: true },
                { type: 'checkbox', id: 'Communication_Lan', after_text: 'LAN', dependencies: true },
                { type: 'text', heading_text: 'Antenne:' },
                {
                  type: 'checkbox',
                  id: 'Communication_Antenna_1',
                  after_text:
                    'Omni-Antenne, Gehäuseantenne, WAO-GUL0D-3C000-NF000 Fa. Welotec (mit Gehäuse verschraubt)'
                },
                {
                  type: 'checkbox',
                  id: 'Communication_Antenna_2',
                  after_text: 'Omni-Antenne, Dual Loop Fa. FTS Hennig'
                },
                {
                  type: 'checkbox',
                  id: 'Communication_Antenna_5',
                  after_text: 'Omni-Antenne, AISW Eigenbau'
                },
                {
                  type: 'checkbox',
                  id: 'Communication_Antenna_3',
                  after_text: 'Richtantenne 650-2700MHz, 18701.01 Fa. Wimo (weiße Panel-Antenne)'
                },
                {
                  type: 'checkbox',
                  id: 'Communication_Antenna_4',
                  after_text: 'Richtantenne 800MHz, 18705.12DUAL Fa. Wimo (große graue Panel-Antenne)'
                },
                {
                  type: 'checkbox',
                  id: 'Communication_Antenna_6',
                  after_text: 'Richtantenne 790-2700MHz, LAT-56 UNICOM, Fa. Wittenberg (2 Stück)'
                },
                {
                  type: 'checkbox',
                  id: 'Communication_Antenna_7',
                  after_text: 'Omni-Antenne, Proscan Omni LTE N(f)'
                },
                {
                  type: 'checkbox',
                  id: 'Communication_Antenna_8',
                  after_text: 'Omni-Antenne, Proscan Omni LTE SMA(m)'
                },
                { type: 'textfield', id: 'Communication_Antenna_Text', heading_text: 'Sonstiges (Antenne)' },
                { type: 'text', heading_text: 'Uploadspeed' },
                { type: 'textfield-endicon', id: 'Communication_LteSpeed', iconTextEnd: 'Mbit/s' }
              ]
            },
            {
              name: 'Basisbox',
              content: [
                { type: 'textfield', id: 'Supply_BasiboxVersion', heading_text: 'Version', mandatory: true },
                { type: 'checkbox', id: 'Supply_Relai', after_text: 'Zeit-Relais 2min' },
                { type: 'checkbox', id: 'Supply_Savebox', after_text: 'Sicherungsautomat 6-16A' },
                { type: 'checkbox', id: 'Supply_Protect', after_text: 'Überspannungsschutz' },
                { type: 'checkbox', id: 'Supply_Filter', after_text: 'Netzfilter' },
                { type: 'checkbox', id: 'Supply_Trafo', after_text: 'Trafo' },
                { type: 'checkbox', id: 'Supply_LTE', after_text: 'LTE Router RUT240' },
                { type: 'textfield', id: 'Supply_LTE_Text', heading_text: 'Identifier Label (falls nicht vorhanden MAC und Seriennummer eintragen)' },
                { type: 'text', heading_text: 'Installiert:' },
                { type: 'checkbox', id: 'Supply_CameraAvailble', after_text: 'Schon vorhanden (Kamera Wechsel)' },

                { type: 'checkbox', id: 'Supply_ApronInstalled', after_text: 'Im Vorfeld Installiert' },
                { type: 'checkbox', id: 'Supply_NewInstall', after_text: 'Neu-Installation' },
                /* {
                  type: 'checkboxGroup',
                  id: 'Supply_Install',
                  value: ['Im Vorfeld durch den Verein installiert.', 'Durch Soccerwatch installiert.'],
                  mandatory: true
                }, */
                { type: 'textfield', id: 'Supply_Changes', heading_text: 'Durchgeführte Änderungen / Notizen' },
                { type: 'textfield', id: 'Supply_Place', heading_text: 'Ort', mandatory: true }
              ]
            },
            {
              name: '230V Zuleitung',
              content: [
                {
                  type: 'tooltip',
                  heading_text:
                    'Bezeichnet die Zuleitung zwischen 230V Quelle (i.d.R. Verteilerkasten im Vereinsheim) und dem Übergabepunkt der Kamera, der Basisbox.'
                },
                { type: 'text', heading_text: 'Details:' },
                { type: 'checkboxGroup', id: 'Supply_CableRouting', value: ['3-polig', '2-polig'], mandatory: true },
                { type: 'checkboxGroup', id: 'Supply_Management', value: ['unterirdisch', 'oberirdisch', 'beides'] },
                { type: 'text', heading_text: 'PE ist:' },
                {
                  type: 'checkboxGroup',
                  id: 'Supply_PE',
                  value: ['nicht am Mast geerdet', 'am Mast geerdet'],
                  mandatory: true
                },
                {
                  type: 'checkbox',
                  id: 'Supply_AlreadyAvilable',
                  after_text: 'Zuleitung schon vorhanden (Kamera Wechsel).'
                },
                {
                  type: 'checkbox',
                  id: 'Supply_Mast',
                  after_text: 'Zuleitung wurde vom Verein installiert.',
                  dependencies1: true
                },
                // { type: 'text', heading_text: 'Leitungsführung:' },
                {
                  type: 'checkbox',
                  id: 'Supply_AboveMast',
                  after_text:
                    'Oberirdische Zuleitung zum Mast, die durch soccerwatch.tv installiert wurde. Gespeist aus einer entfernten Stromquelle (z.B. Vereinsheim). I.d.R. inklusive Überspannungsableiter am Hauseintritt.',
                  dependencies1: true
                },
                {
                  type: 'checkbox',
                  id: 'Supply_NotInstall',
                  after_text:
                    'Die Zuleitung wurde nicht angeschlossen bzw. war nicht vorhanden. Unterhalb der Kamera wurde ein 3-poliger 230V Anschlusspunkt hinterlassen. Der Betreiber kümmert sich um den Anschluss der Stromversorgung.',
                  dependencies1: true
                },
                { type: 'multiline', id: 'Supply_Text', label: 'Durchgeführte Änderungen / Notizen' }
              ]
            },
            /* {
              name: 'Überspannungsschutz',
              content: [
                { type: 'text', heading_text: 'Überspannungsableiter installiert?' },
                {
                  type: 'checkbox',
                  id: 'Supply_SurgeNot',
                  after_text: 'nicht notwendig, da der Strom von einem 230V-Übergabepunkt am Mast bezogen wird.',
                  dependencies2: true
                },
                {
                  type: 'checkbox',
                  id: 'Supply_SurgeAvailable',
                  after_text: 'schon vorhanden (Kamera Wechsel)',
                  dependencies2: true
                },
                { type: 'checkbox', id: 'Supply_SurgeYes', after_text: 'Ja', dependencies2: true },
                { type: 'textfield', id: 'Supply_SurgePlace', heading_text: "Ort 'falls vorhanden'" },
                { type: 'text', heading_text: 'Wie wurde der Überspannungsableiter geerdet?' },
                { type: 'checkbox', id: 'Supply_Spike', after_text: 'Erdspieß' },
                { type: 'textfield', id: 'Supply_SpikeInfo' }
              ]
            }, */
            {
              name: 'Checkliste',
              content: [
                { type: 'text', heading_text: 'Inbetriebnahme' },
                {
                  type: 'checkbox',
                  id: 'Checklist_CalibrationReachable',
                  after_text: 'System verbindet sich mit dem Server & ist erreichbar & Videostream ist möglich.',
                  mandatory: true
                },
                {
                  type: 'checkbox',
                  id: 'Checklist_CalibrationField',
                  after_text: 'Spielfeldecken sind gleichmäßig abgebildet.',
                  mandatory: true
                },
                {
                  type: 'checkbox',
                  id: 'Checklist_CalibrationHorizon',
                  after_text: 'Kamera ist parallel zum Horizont / Spielfeldrand ausgerichtet.',
                  mandatory: true
                },
                /* {
                  type: 'checkbox',
                  id: 'Checklist_CalibrationFocus',
                  after_text: 'Objektive sind scharfgestellt und verklebt.',
                  mandatory: true
                }, */
                {
                  type: 'dropdown',
                  id: 'Checklist_Operator',
                  heading_text: 'Remote Operator:',
                  value: ['Jean Liba', 'Vitali Knatz', 'Timo Lüning', 'Jonathan Overhoff'],
                  mandatory: true
                },
                { type: 'text', heading_text: 'Kamera' },
                { type: 'checkbox', id: 'Checklist_CamDrip', after_text: 'Abtropfkante' },
                { type: 'checkbox', id: 'Checklist_CamDripAndGlareShield', after_text: 'Tropf- und Blendschutz' },
                // { type: 'checkbox', id: 'Checklist_CamDryback', after_text: 'Dryback', mandatory: true },
                {
                  type: 'checkbox',
                  id: 'Checklist_CamClean',
                  after_text: 'Kuppel Innen und Außen sauber gemacht',
                  mandatory: true
                },
                {
                  type: 'checkbox',
                  id: 'Checklist_CamSafety',
                  after_text: 'Stahlseil-Sicherung (Safety) installiert',
                  mandatory: true
                },
                { type: 'text', heading_text: 'Fotos gemacht?' },
                {
                  type: 'checkbox',
                  id: 'Checklist_PhotoInstall',
                  after_text: 'Kamerasystem (von allen Seiten, inkl Antenne)',
                  mandatory: true
                },
                {
                  type: 'checkbox',
                  id: 'Checklist_PhotoElectric',
                  after_text: 'Basisbox (von allen Seiten und von Innen)',
                  mandatory: true
                },
                {
                  type: 'checkbox',
                  id: 'Checklist_PhotoMastAbove',
                  after_text: 'Totale (z.B. der gesamte Flutlichtmast mit Kamerasystem)',
                  mandatory: true
                },
                /* {
                  type: 'checkbox',
                  id: 'Checklist_PhotoMast',
                  after_text: 'Mast unten innen (Komponenten & Kabelzuführungen)',
                  mandatory: true
                }, */
                { type: 'text', heading_text: 'Fotos sortiert & in Cloud gespeichert?' },
                {
                  type: 'checkboxGroup',
                  id: 'Checklist_PhotoBelow',
                  value: ['Ja', 'Nein, wird nachgereicht'],
                  mandatory: true
                },
                {
                  type: 'textfield',
                  id: 'Checklist_PhotoShield',
                  heading_text: 'Wie viele Hinweisschilder wurden installiert?',
                  mandatory: true
                }
                /* {
                  type: 'textfield',
                  id: 'Checklist_PhotoBanner',
                  heading_text: 'Wie viele Banner wurden installiert?',
                  mandatory: true
                } */
              ]
            },
            /* {
              name: 'Anmerkungen',
              content: [{ type: 'multiline', id: 'Annotation_Text', label: 'Notizen' }]
            }, */
            {
              name: 'Anmerkung',
              content: [{ type: 'multiline', id: 'Annotation_NextNote_Text', label: 'Notizen' }]
            },
            {
              name: 'Abnahme',
              content: [
                { type: 'checkbox', id: 'Decrease_Check', after_text: 'Geprüft' },
                { type: 'datetime', id: 'Decrease_Date', heading_text: 'Datum', disabled: true },
                { type: 'textfield', id: 'Decrease_Name', heading_text: 'Username:', disabled: true }
              ]
            }
          ]
        },
        {
          name: 'Übergabe',
          value: 'delivery',
          text: 'Übergabe',
          content: [
            {
              name: 'Anmerkung vom vorherigen Laufzettel',
              content: [{ type: 'tooltipAnnotation' }]
            },
            {
              name: 'Übergabe',
              content: [
                { type: 'textfield', heading_text: 'ID', id: 'General_SystemId', mandatory: true },
                { type: 'date', id: 'SystemManagement_Date', heading_text: 'Datum', mandatory: true },
                {
                  type: 'dropdown',
                  id: 'SystemManagement_Art',
                  heading_text: 'Art:',
                  value: ['Versand', 'Abholung vor Ort'],
                  mandatory: true
                },
                { type: 'textfield', id: 'SystemManagement_Receiver', heading_text: 'Empfänger', mandatory: true },
                { type: 'textfield', id: 'SystemManagement_Location', heading_text: 'Voraussichtlicher Einsatzort', mandatory: true },
                { type: 'checkbox', id: 'SystemManagement_Certificate', after_text: 'Übergabeprotokoll ausgefüllt?', mandatory: true }
              ]
            },
            {
              name: 'System-Verwaltung',
              content: [
                { type: 'checkbox', id: 'SystemManagement_OwnerCheck', after_text: 'owner eingetragen (Systeminhaber)', mandatory: true },
                { type: 'tableCheckOwner', id: 'Table_CheckOwner' }
              ]
            },
            {
              name: 'Anmerkung',
              content: [
                { type: 'multiline', id: 'Annotation_NextNote_Text', label: 'Notizen' }
              ]
            },
            {
              name: 'Abnahme',
              content: [
                { type: 'checkbox', id: 'Decrease_Check', after_text: 'Geprüft' },
                { type: 'datetime', id: 'Decrease_Date', heading_text: 'Datum', disabled: true },
                { type: 'textfield', id: 'Decrease_Name', heading_text: 'Username:', disabled: true }
              ]
            }
          ]
        },
        {
          name: 'Inbetriebnahme',
          value: 'installAdmin',
          text: 'Inbetriebnahme',
          content: [
            {
              name: 'Anmerkung vom vorherigen Laufzettel',
              content: [{ type: 'tooltipAnnotation' }]
            },
            {
              name: 'System-Verwaltung',
              content: [
                { type: 'date', id: 'System_StartupDate', heading_text: 'Datum der Inbetriebnahme', mandatory: true },
                { type: 'textfield', id: 'System_EditorName', heading_text: 'Installateur vor Ort:', mandatory: true },
                {
                  type: 'checkbox',
                  id: 'System_PlaceID',
                  after_text: 'Adresse eingetragen',
                  mandatory: true
                },
                { type: 'checkbox', id: 'System_ClubId', after_text: 'Vereins-ID', mandatory: true },
                // { type: 'tooltip', heading_text: 'Bei Lizenz Kameras "0" eintragen' },
                { type: 'checkbox', id: 'System_HasSystem', after_text: "Club 'has system' gesetzt", mandatory: true },
                {
                  type: 'checkbox',
                  id: 'System_ClubInfo',
                  after_text: 'Stitching auf TBD gesetzt',
                  mandatory: true
                },
                // { type: 'checkbox', id: 'System_Systemstatus', after_text: 'Systemstatus gesetzt', mandatory: true },
                {
                  type: 'checkbox',
                  id: 'System_Coordinates',
                  after_text: 'Geo-Koordinaten eingetragen',
                  mandatory: true
                },
                {
                  type: 'checkbox',
                  id: 'System_CameraOwner',
                  after_text: 'Systeminhaber (owner) eingetragen?',
                  mandatory: true
                },
                // { type: 'tooltip', heading_text: '“AISW” “swisscom” “ClipMyHorse”' },
                {
                  type: 'checkbox',
                  id: 'System_DefaultWatermark',
                  after_text: 'defaultWatermark eintragen?',
                  mandatory: true
                },
                // { type: 'tooltip', heading_text: 'Das dem Systeminhaber zugehörige Wasserzeichen eintragen: Soccerwatch / AISW: “aisportswatch” ClipMyHorse: “cmh” Swisscom: “asport-vw”' },
                {
                  type: 'checkbox',
                  id: 'System_DefaultEventType',
                  after_text: 'defaultEventType eintragen?',
                  mandatory: true
                },
                { type: 'tooltip', heading_text: 'Typische Sportart auswählen' }
              ]
            },
            {
              name: 'Übersichtstabelle',
              content: [
                { type: 'tableCheck', id: 'Table_Check' }
              ]
            },
            {
              name: 'System-Kalibrierung',
              content: [
                // { type: 'text', heading_text: 'Kalibrieraufnahme angelegt?' },
                // { type: 'checkboxGroup', id: 'SystemCalibration_Recording', value: ['Ja', 'Nein'], mandatory: true },
                // { type: 'textfield', id: 'SystemCalibration_RecordingId', heading_text: 'Ticket-ID' },
                // { type: 'text', heading_text: ' Kalibrierungs-Ticket angelegt?' },
                // { type: 'checkbox', id: 'SystemCalibration_Trello', after_text: 'Kalibrierungs-Ticket angelegt?', mandatory: true },

                { type: 'checkbox', id: 'SystemCalibration_Fieldcovers', after_text: 'Spielfeldecken sind gleichmäßig abgebildet', mandatory: true },
                { type: 'checkbox', id: 'SystemCalibration_Orientation', after_text: 'Kamera ist parallel zum Horizont / Spielfeldrand ausgerichtet', mandatory: true },
                // { type: 'checkbox', id: 'SystemCalibration_Focused', after_text: 'Objektive sind scharfgestellt', mandatory: true },
                {
                  type: 'dropdown',
                  id: 'SystemCalibration_RecPreset',
                  heading_text: 'Recorder Preset eingestellt auf:',
                  value: ['default', 'ice', 'sand'],
                  mandatory: true
                },
                { type: 'tooltip', heading_text: 'Zur Einstellung muss auf dem System der Befehl ‘swpreset [default,sand,ice]’ ausgeführt werden.' },
                { type: 'textfield', id: 'SystemCalibration_SetTestVideoId', heading_text: '5 Min Testaufnahme angelegt, ID:', mandatory: true }
              ]
            },
            /* {
              name: 'Anmerkungen',
              content: [{ type: 'multiline', id: 'Annotation_Text' }]
            }, */
            {
              name: 'Anmerkung',
              content: [{ type: 'multiline', id: 'Annotation_NextNote_Text', label: 'Notizen' }]
            },
            {
              name: 'Abnahme',
              content: [
                { type: 'checkbox', id: 'Decrease_Check', after_text: 'Geprüft' },
                { type: 'datetime', id: 'Decrease_Date', heading_text: 'Datum', disabled: true },
                { type: 'textfield', id: 'Decrease_Name', heading_text: 'Username:', disabled: true }
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'Reparatur vor Ort',
      value: 'repair',
      assignment: 'swcs',
      content: [
        {
          name: 'Reparatur',
          value: 'repair',
          text: 'Reparatur',
          content: [
            {
              name: 'Anmerkung vom vorherigen Laufzettel',
              content: [{ type: 'tooltipAnnotation' }]
            },
            {
              name: 'Allgemein Informationen',
              content: [
                { type: 'textfield', id: 'General_SystemId', heading_text: 'ID', disabled: true },
                { type: 'textfield', id: 'General_CamVersion', heading_text: 'Hardware-Code' },
                {
                  type: 'tooltip',
                  heading_text:
                    'Falls sich der Hardware-Code durch die Reparatur ändert, den neuen eintragen.'
                },
                { type: 'date', id: 'General_Date', heading_text: 'Datum', mandatory: true },
                { type: 'multiline', id: 'General_Plumber', heading_text: 'Installateur(e)' },
                { type: 'text', heading_text: 'Adresse' },
                { type: 'textfield', id: 'General_Club', heading_text: 'Verein' },
                { type: 'textfield', id: 'General_Street', heading_text: 'Straße' },
                { type: 'textfield', id: 'General_Plz', heading_text: 'PLZ' },
                { type: 'textfield', id: 'General_Place', heading_text: 'Ort' },
                {
                  type: 'textfield',
                  id: 'General_PlayfieldNumber',
                  heading_text: 'Platzbezeichnung (Nummer oder Name)'
                }
              ]
            },
            {
              name: 'Informationen zum Update',
              content: [
                { type: 'text', heading_text: 'Grund der Änderung' },
                { type: 'multiline', id: 'Information_Text' },
                { type: 'text', heading_text: 'Beschreibung der Änderung' },
                { type: 'multiline', id: 'Information_TextChange' }
              ]
            },
            {
              name: 'Anmerkung',
              content: [{ type: 'multiline', id: 'Annotation_NextNote_Text', label: 'Notizen' }]
            },
            {
              name: 'Abnahme',
              content: [
                { type: 'checkbox', id: 'Decrease_Check', after_text: 'Alles korrekt ausgefüllt?' },
                { type: 'datetime', id: 'Decrease_Date', heading_text: 'Datum', disabled: true },
                { type: 'textfield', id: 'Decrease_Name', heading_text: 'Username:', disabled: true }
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'Fehleranalyse',
      value: 'troubleshooting',
      assignment: 'swcs',
      content: [
        {
          name: 'Fehleranalyse',
          value: 'troubleshooting',
          text: 'Fehleranalyse',
          content: [
            {
              name: 'Anmerkung vom vorherigen Laufzettel',
              content: [{ type: 'tooltipAnnotation' }]
            },
            {
              name: 'Allgemein Informationen',
              content: [
                { type: 'textfield', id: 'General_SystemId', heading_text: 'ID', disabled: true },
                { type: 'textfield', id: 'General_Cellular', heading_text: 'Cellular-Modul-ID', mandatory: true },
                { type: 'textfield', id: 'General_HardwareVersion', heading_text: 'Harware-Version', mandatory: true },
                {
                  type: 'multiline',
                  id: 'General_Text',
                  heading_text: "Besonderheiten 'z.B. falsche Soundkarte, Dryback vergessen etc'"
                }
              ]
            },
            {
              name: 'Fehlerbeschreibung',
              content: [
                { type: 'text', heading_text: 'Fehlerbild' },
                {
                  type: 'checkbox',
                  id: 'Error_Reachable',
                  after_text: 'System nicht erreichbar, seit:',
                  dependencies: true
                },
                { type: 'date', id: 'Error_Date' },
                { type: 'multiline', id: 'Error_SystemText', dependencies: true },
                {
                  type: 'text',
                  heading_text: "Fehlerhistorie 'Welche Fehler sind bei dem System schon mal aufgetreten?'"
                },
                { type: 'multiline', id: 'Error_Text' }
              ]
            },
            {
              name: 'Fehleranalyse I - nach Checkliste',
              content: [
                { type: 'text', heading_text: 'Netzteil aktiv?' },
                { type: 'checkboxGroup', id: 'ErrorAnalysis_PowerActiv', value: ['Ja', 'Nein'], mandatory: true },
                { type: 'text', heading_text: 'USB Devices aktiv?' },
                {
                  type: 'checkboxGroup',
                  id: 'ErrorAnalysis_usbActiv',
                  value: ['Ja', 'Nein', 'nicht vorhanden'],
                  mandatory: true
                },
                { type: 'text', heading_text: 'Platinen aktiv?' },
                { type: 'checkboxGroup', id: 'ErrorAnalysis_BoardActiv', value: ['Ja', 'Nein'], mandatory: true },
                { type: 'text', heading_text: 'SOM ansprechbar?' },
                { type: 'text', heading_text: 'Mobilfunk:' },
                {
                  type: 'checkboxGroup',
                  id: 'ErrorAnalysis_Mobile',
                  value: ['Ja', 'Nein', 'nicht vorhanden'],
                  mandatory: true
                },
                { type: 'text', heading_text: 'Ethernet:' },
                { type: 'checkboxGroup', id: 'ErrorAnalysis_Ethernet', value: ['Ja', 'Nein'] },
                { type: 'text', heading_text: 'Serielle Schnittstelle:' },
                { type: 'checkboxGroup', id: 'ErrorAnalysis_Serial', value: ['Ja', 'Nein'] }
              ]
            },
            {
              name: 'Fehleranalyse II - Individuelle Untersuchung',
              content: [
                { type: 'text', heading_text: 'Methoden & Ergebnisse (Was wurde gemacht? Was kam heraus?)' },
                { type: 'multiline', id: 'ErrorAnalysis_Text', mandatory: true }
              ]
            },
            {
              name: 'Auswertung',
              content: [
                { type: 'text', heading_text: 'Analyseergebnis:' },
                { type: 'multiline', id: 'Evaluation_Reason', dependencies1: true }
              ]
            },
            {
              name: 'Weiteres Vorgehen',
              content: [
                { type: 'text', heading_text: 'Freigabe' },
                {
                  type: 'checkboxGroup',
                  id: 'Proceed_Approved',
                  value: ['FREIGEGEBEN', 'NICHT FREIGEGEBEN'],
                  mandatory: true
                },
                {
                  type: 'tooltip',
                  heading_text:
                    'FREIGEGEBEN: Das System ist freigegeben zur weiteren Verarbeitung. In der Produktion wird entschieden, was mit dem System geschieht. Besonderheiten, die in der Produktion beachtet werden müssen oder spezielle Anweisungen werden unter ‘Anmerkungen für den nächsten Laufzettel’ festgehalten.'
                },
                // { type: "tooltip", heading_text: "FREIGEGEBEN MIT EINSCHRÄNKUNG: Das System ist freigegeben MIT einschränkung zur weiteren Verarbeitung. In der Produktion wird entschieden, was mit dem System geschieht. Folgendes muss beachtet werden ''"},
                {
                  type: 'tooltip',
                  heading_text: 'NICHT FREIGEGEBEN: Das System ist nicht freigegeben zur weiteren Verarbeitung.'
                }
                // { type: "textfield", id: "Proceed_ApprovedInfoText", heading_text: "'FREIGEGEBEN MIT EINSCHRÄNKUNG' Was beachtet werden muss:" },
                // { type: "textfield", id: "Proceed_NotApprovedReason", heading_text: "'NICHT FREIGEGEBEN' Grund:" },
                // { type: "multiline", id: "Proceed_Remakes" , heading_text: "Anmerkungen / Anweisungen"},
              ]
            },
            {
              name: 'Anmerkung',
              content: [{ type: 'multiline', id: 'Annotation_NextNote_Text', label: 'Notizen' }]
            },
            {
              name: 'Abnahme',
              content: [
                { type: 'checkbox', id: 'Decrease_Check', after_text: 'Geprüft' },
                { type: 'datetime', id: 'Decrease_Date', heading_text: 'Datum', disabled: true },
                { type: 'textfield', id: 'Decrease_Name', heading_text: 'Username:', disabled: true }
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'Deinstallation',
      value: 'deinstall',
      assignment: 'swcs',
      content: [
        {
          name: 'Deinstallation',
          value: 'deinstall',
          text: 'Deinstallation',
          content: [
            {
              name: 'Anmerkung vom vorherigen Laufzettel',
              content: [{ type: 'tooltipAnnotation' }]
            },
            {
              name: 'Allgemein Informationen',
              content: [
                { type: 'textfield', id: 'General_SystemId', heading_text: 'ID', disabled: true },
                { type: 'date', id: 'General_InstallDate', heading_text: 'Deinstallationsdatum', mandatory: true },
                { type: 'multiline', id: 'General_Plumber', heading_text: 'Installateur(e)', mandatory: true },
                { type: 'text', heading_text: 'Adresse' },
                { type: 'textfield', id: 'General_Club', heading_text: 'Verein', mandatory: true },
                { type: 'textfield', id: 'General_Street', heading_text: 'Straße', mandatory: true },
                { type: 'textfield', id: 'General_Plz', heading_text: 'PLZ', mandatory: true },
                { type: 'textfield', id: 'General_Place', heading_text: 'Ort', mandatory: true },
                {
                  type: 'textfield',
                  id: 'General_PlayfieldNumber',
                  heading_text: 'Platzbezeichnung (Nummer oder Name)'
                },
                {
                  type: 'dropdown',
                  id: 'General_LocationType',
                  heading_text: 'Platzart',
                  value: ['Kunstrasen', 'Asche', 'Naturrasen'],
                  mandatory: true
                }
              ]
            },
            {
              name: 'Informationen zur Deinstallation',
              content: [
                { type: 'text', heading_text: 'Grund der Deinstallation?' },
                {
                  type: 'checkbox',
                  id: 'InfoDeinstall_VersionUpgrade',
                  after_text: 'Versions-Upgrade',
                  dependencies: true
                },
                {
                  type: 'checkbox',
                  id: 'InfoDeinstall_RadioUpdate',
                  after_text: 'Richtfunk-Upgrade',
                  dependencies: true
                },
                {
                  type: 'checkbox',
                  id: 'InfoDeinstall_Defect',
                  after_text: 'System hat einen Defekt',
                  dependencies: true
                },
                { type: 'textfield', id: 'InfoDeinstall_ErrorText', heading_text: 'Fehlerbeschreibung' },
                {
                  type: 'checkbox',
                  id: 'InfoDeinstall_Deinstall',
                  after_text: 'System wird ersatzlos deinstalliert',
                  dependencies: true
                },
                { type: 'textfield', id: 'InfoDeinstall_Reason', heading_text: 'Grund' },
                { type: 'text', heading_text: 'Wurde ein neues System installiert?' },
                { type: 'checkboxGroup', id: 'InfoDeinstall_NewSystem', value: ['Ja', 'Nein'], mandatory: true },
                { type: 'textfield', id: 'InfoDeinstall_Id', heading_text: 'ID (bei ja)' },
                { type: 'textfield', id: 'InfoDeinstall_Version', heading_text: 'Version (bei ja)' }
              ]
            },
            {
              name: 'QR Code & Kameraberechtigung Entfernen',
              content: [
                { type: 'qrRemove' }
              ]
            },
            {
              name: 'Anmerkung',
              content: [{ type: 'multiline', id: 'Annotation_NextNote_Text', label: 'Notizen' }]
            },
            {
              name: 'Abnahme',
              content: [
                { type: 'checkbox', id: 'Decrease_Check', after_text: 'Geprüft' },
                { type: 'datetime', id: 'Decrease_Date', heading_text: 'Datum', disabled: true },
                { type: 'textfield', id: 'Decrease_Name', heading_text: 'Username:', disabled: true }
              ]
            }
          ]
        },
        {
          name: 'Rücknahme',
          value: 'takeback',
          text: 'Rücknahme',
          content: [
            {
              name: 'Anmerkung vom vorherigen Laufzettel',
              content: [{ type: 'tooltipAnnotation' }]
            },
            {
              name: 'Rücknahme',
              content: [
                { type: 'textfield', heading_text: 'ID', id: 'General_SystemId', mandatory: true },
                { type: 'date', id: 'Takeback_Date', heading_text: 'Datum', mandatory: true },
                {
                  type: 'dropdown',
                  id: 'Takeback_Art',
                  heading_text: 'Art:',
                  value: ['Paketlieferung', 'Rückgabe vor Ort'],
                  mandatory: true
                },
                { type: 'textfield', id: 'Takeback_Shipper', heading_text: 'Versender / Überbringer', mandatory: true },
                { type: 'textfield', id: 'Takeback_PlaceOfOrigin', heading_text: 'Herkunftsort', mandatory: true }
              ]
            },
            {
              name: 'Informationen zur Deinstallation',
              content: [
                {
                  type: 'checkbox',
                  id: 'Takeback_Defect',
                  after_text: 'System hat einen Defekt'
                },
                { type: 'textfield', id: 'Takeback_ReturnReason', heading_text: 'Rückgabegrund / Fehlerbeschreibung', mandatory: true }
              ]
            },
            {
              name: 'System-Verwaltung',
              content: [
                {
                  type: 'checkbox',
                  id: 'Takeback_OwnerSignedOut',
                  after_text: 'owner ausgetragen (Systeminhaber)',
                  mandatory: true
                },
                { type: 'tableCheckOwner', id: 'Table_CheckOwner' }
              ]
            },
            {
              name: 'Anmerkung',
              content: [
                { type: 'multiline', id: 'Annotation_NextNote_Text', label: 'Notizen' }
              ]
            },
            {
              name: 'Abnahme',
              content: [
                { type: 'checkbox', id: 'Decrease_Check', after_text: 'Geprüft' },
                { type: 'datetime', id: 'Decrease_Date', heading_text: 'Datum', disabled: true },
                { type: 'textfield', id: 'Decrease_Name', heading_text: 'Username:', disabled: true }
              ]
            }
          ]
        },
        {
          name: 'Außerbetriebnahme',
          value: 'deinstallAdmin',
          text: 'Außerbetriebnahme',
          content: [
            {
              name: 'Anmerkung vom vorherigen Laufzettel',
              content: [{ type: 'tooltipAnnotation' }]
            },
            {
              name: 'Beschreibung',
              content: [
                {
                  type: 'text',
                  heading_text:
                    'Für jedes System, das zu soccerwatch zurück kommt, muss entschieden werden, ob es weiterverarbeitet werden kann, oder ob eine Fehleranalyse durchgeführt werden muss. Informationen dazu finden sich in Reparatur Tickets oder im Deinstallations Laufzettel.'
                }
              ]
            },
            {
              name: 'Grund der Deinstallation',
              content: [
                { type: 'text', heading_text: 'Bitte einen aussagekräftigen Deinstallationsgrund angeben. Falls wegen einem Defekt oder Fehler deinstalliert wurde, diesen bitte aussagekräftig beschreiben.' },
                { type: 'multiline', id: 'Proceed_InfoText', mandatory: true }
              ]
            },
            {
              name: 'System-Verwaltung',
              content: [
                { type: 'text', heading_text: 'Das System muss aus der Datenbank ausgetragen werden. Diese Tools helfen dabei:' },
                {
                  type: 'tooltip',
                  heading_text:
                    'KAMERA DEINSTALLIEREN: trägt das System aus. Dabei wird der hinterlegte Verein gelöscht und Statusspalten und Geokoordinaten zurückgesetzt.'
                },
                {
                  type: 'tooltip',
                  heading_text:
                    'KAMERA AUSTAUSCHEN: überträgt die aktuell hinterlegten Daten zu einer anderen Kamera und trägt das System anschließend aus.'
                },
                {
                  type: 'checkbox',
                  id: 'SystemManagement_StatusReset',
                  after_text: 'System ausgetragen',
                  mandatory: true
                }
              ]
            },
            {
              name: 'Weiteres Vorgehen',
              content: [
                { type: 'text', heading_text: 'Freigabe zur Weiterverarbeitung' },

                {
                  type: 'checkboxGroup',
                  id: 'Proceed_Approved',
                  value: ['FREIGEGEBEN', 'NICHT FREIGEGEBEN'],
                  mandatory: true
                },
                {
                  type: 'tooltip',
                  heading_text:
                    'Das System ist FREIGEGEBEN zur weiteren Verarbeitung. Eine Fehleranalyse ist nicht notwendig. Die Montage-Station entscheidet was mit dem System passiert.'
                },
                {
                  type: 'tooltip',
                  heading_text:
                    'Das System ist NICHT FREIGEGEBEN zur weiteren Verarbeitung. Es muss eine Fehleranalyse durchgeführt werden. Dort wird entschieden, was mit dem System passiert.'
                }
                // { type: 'text', heading_text: 'Fehlerbeschreibung / Anmerkungen / Anweisungen' },
                // { type: 'multiline', id: 'Proceed_InfoText' }
              ]
            },
            {
              name: 'Anmerkung',
              content: [{ type: 'multiline', id: 'Annotation_NextNote_Text', label: 'Notizen' }]
            },
            {
              name: 'Abnahme',
              content: [
                { type: 'checkbox', id: 'Decrease_Check', after_text: 'Geprüft' },
                { type: 'datetime', id: 'Decrease_Date', heading_text: 'Installationsdatum', disabled: true },
                { type: 'textfield', id: 'Decrease_Name', heading_text: 'Username:', disabled: true }
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'Lager',
      value: 'stock',
      assignment: 'swcs',
      content: [
        {
          name: 'Lager',
          value: 'stock',
          text: 'Lager',
          content: [
            {
              name: 'Anmerkung vom vorherigen Laufzettel',
              content: [{ type: 'tooltipAnnotation' }]
            },
            {
              name: 'Einlagerung',
              content: [
                { type: 'textfield', id: 'General_SystemId', heading_text: 'ID', mandatory: true, disabled: true },
                { type: 'textfield', id: 'Hardware_Version', heading_text: 'Hardware-Code', mandatory: true, disabled: true },
                { type: 'date', id: 'Stock_Date', heading_text: 'Datum', mandatory: true },
                // { type: 'textfield', id: 'Stock_Place', heading_text: 'Lagerort', mandatory: true },
                {
                  type: 'dropdown',
                  id: 'Stock_Place',
                  heading_text: 'Lagerort',
                  value: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'Sperrlager'],
                  mandatory: true
                },
                { type: 'textfield', id: 'Stock_Reserved', heading_text: 'Reserviert für' },
              ]
            },
            {
              name: 'Anmerkung',
              content: [{ type: 'multiline', id: 'Annotation_NextNote_Text', label: 'Notizen' }]
            },
            {
              name: 'Abnahme',
              content: [
                { type: 'checkbox', id: 'Decrease_Check', after_text: 'Geprüft' },
                { type: 'datetime', id: 'Decrease_Date', heading_text: 'Datum', disabled: true },
                { type: 'textfield', id: 'Decrease_Name', heading_text: 'Username:', disabled: true }
              ]
            }
          ]
        }
      ]
    }
  ]
}
