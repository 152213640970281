import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import moment from 'moment'

import { getSearchCity } from '../../../../../api/api-fanpage'
import { setNewCity } from '../../../../../api/api-city'

import { InputField } from '../../../inputFields'

import { LinearProgress } from '@material-ui/core'

import './cityOverlay.scss'

class CityOverlay extends Component {
  constructor (props) {
    super(props)

    this.state = {
      openOverlay: false,
      city: undefined,
      allFoundCitys: undefined,
      search: false,
      createCity: false,
      createCityObj: {
        name: undefined,
        state: undefined,
        stateShort: undefined,
        country: undefined,
        countryShort: undefined
      },
      errorCreateState: false
    }

    this.timer = null
  }

  clearState = () => {
    this.setState({
      openOverlay: false,
      city: undefined,
      allFoundCitys: undefined,
      search: false,
      createCity: false,
      createCityObj: {
        name: undefined,
        state: undefined,
        stateShort: undefined,
        country: undefined,
        countryShort: undefined
      },
      errorCreateState: false
    })
  }

  handleOpen = () => {
    this.setState({ openOverlay: true })
  }

  handleClose = () => {
    this.clearState()
    this.setState({ openOverlay: false })
  }

  handleChangeCity = (event) => {
    this.setState({
      city: event.target.value,
      search: true
    })
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.searchCity()
    }, 1000)
  }

  searchCity = () => {
    const { city } = this.state
    getSearchCity(city).then(res => {
      if (res.status === 200) {
        this.setState({
          allFoundCitys: res.data.hits.hits,
          search: false
        })
      }
    })
  }

  onChangeCityOverlay = (name, id) => {
    const obj = {
      city: name,
      cityId: id
    }

    this.clearState()
    this.props.onChangeCityOverlay(obj)
    this.handleClose()
  }

  handleSetCreateCity = (name, event) => {
    this.setState({
      createCityObj: {
        ...this.state.createCityObj,
        [name]: event.target.value
      }
    })
  }

  handlePostSetCity = () => {
    const { name, state, stateShort, country, countryShort } = this.state.createCityObj
    if (name === undefined || state === undefined || stateShort === undefined || country === undefined) {
      this.setState({
        errorCreateState: true
      })
      return
    }

    setNewCity(this.state.createCityObj).then(res => {
      if (res.status === 200) {
        this.onChangeCityOverlay(res.data.name, res.data.RowKey)
      }
    })
  }

  render () {
    const { index, row, value } = this.props
    return (
      <div className='cityOverlay'>
        <Dialog fullWidth open={this.state.openOverlay} onClose={this.handleClose} aria-labelledby='alert-dialog-title'>
          <DialogTitle id='alert-dialog-title'>
            {this.props.row.title}
          </DialogTitle>
          <DialogContent>
            <div className='cityOverlay-content'>
              <InputField
                key={index}
                id={row.name}
                label={row.title}
                value={this.state.city || value}
                activeCheck={row.editable !== false}
                // handleChange={row.value === 'number' ? this.handleChangeNumber : this.tempHandleChange}
                // type={row.value === 'number' ? 'number' : null}
                tooltip={row.tooltip}
                handleChange={!this.state.createCity ? this.handleChangeCity : undefined}
              />

              {this.state.search && <LinearProgress variant='query' style={{ marginTop: 2 }} />}

              {this.state.createCity ? (
                <div className='content-createCity'>

                  <div className='createCity-input'>
                    <InputField
                      label='Stadtname'
                      placeholder='Essen'
                      value={this.state.createCityObj.name}
                      activeCheck
                      handleChange={(event) => this.handleSetCreateCity('name', event)}
                      error={this.state.errorCreateState && this.state.createCityObj.name === undefined}
                      helperText={this.state.errorCreateState && this.state.createCityObj.name === undefined ? 'Bitte geben Sie einen Stadtnamen ein' : undefined}
                    />
                  </div>
                  <div className='createCity-input'>
                    <InputField
                      label='Bundesland'
                      placeholder='Nordrhein-Westfalen'
                      value={this.state.createCityObj.state}
                      activeCheck
                      handleChange={(event) => this.handleSetCreateCity('state', event)}
                      error={this.state.errorCreateState && this.state.createCityObj.state === undefined}
                      helperText={this.state.errorCreateState && this.state.createCityObj.state === undefined ? 'Bitte geben Sie ein Bundesland ein' : undefined}
                    />
                  </div>
                  <div className='createCity-input'>
                    <InputField
                      label='Bundesland Kurzschreibweise'
                      placeholder='NRW'
                      value={this.state.createCityObj.stateShort}
                      activeCheck
                      handleChange={(event) => this.handleSetCreateCity('stateShort', event)}
                      error={this.state.errorCreateState && this.state.createCityObj.stateShort === undefined}
                      helperText={this.state.errorCreateState && this.state.createCityObj.stateShort === undefined ? 'Bitte geben Sie eine Bundesland Kurzschreibweise ein' : undefined}
                    />
                  </div>
                  <div className='createCity-input'>
                    <InputField
                      label='Land'
                      placeholder='Deutschland'
                      value={this.state.createCityObj.country}
                      activeCheck
                      handleChange={(event) => this.handleSetCreateCity('country', event)}
                      error={this.state.errorCreateState && this.state.createCityObj.country === undefined}
                      helperText={this.state.errorCreateState && this.state.createCityObj.country === undefined ? 'Bitte geben Sie ein Land ein' : undefined}
                    />
                  </div>
                  <Button
                    style={{ width: '100%', marginTop: '20px' }}
                    className='btn'
                    color='primary'
                    variant='raised'
                    onClick={() => this.handlePostSetCity()}
                  >
                    Stadt Hinzufügen
                  </Button>

                </div>
              ) : (
                <div>
                  {this.state.allFoundCitys === undefined && (
                    <div style={{ height: '300px' }} />
                  )}
                  {this.state.allFoundCitys && (
                    <div className='content-searchBox'>
                      {this.state.allFoundCitys.length === 0 ? (
                        <div className='searchBox-notFoundCity'>
                        Keine Städte gefunden
                        </div>
                      ) : (
                        <div className='searchBox-title'>
                        Gefundene Städte
                        </div>
                      )}

                      {this.state.allFoundCitys.map((city, index) => {
                        return (
                          <div key={index} className='searchBox-city' onClick={() => this.onChangeCityOverlay(city._source.name, city._source.RowKey)}>
                            <span>{city._source.name}</span>
                          </div>
                        )
                      })}

                      <div key={index} className='searchBox-city searchBox-createCity' onClick={() => this.setState({ createCity: true })}>
                        <span>Stadt Hinzufügen</span>
                      </div>
                    </div>

                  )}
                </div>
              )}

            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Schließen
            </Button>
          </DialogActions>
        </Dialog>
        <InputField
          key={index}
          id={row.name}
          label={row.title}
          value={value}
          activeCheck={row.editable !== false}
          // handleChange={row.value === 'number' ? this.handleChangeNumber : this.tempHandleChange}
          type={row.value === 'number' ? 'number' : null}
          tooltip={row.tooltip}
          onClick={() => this.handleOpen()}
        />
      </div>
    )
  }
}

export default CityOverlay
