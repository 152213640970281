import React, { Component } from 'react'
import Jimp from 'jimp'

export default class ImageUpload extends Component {
  constructor(props) {
    super(props)
    this._handleImageChange = this._handleImageChange.bind(this)
    this.applyFilter = this.applyFilter.bind(this)
    this.handleImageReset = this.handleImageReset.bind(this)

    this.state = {
      rawImage: undefined,
      error: undefined
    }
  }

  _handleImageChange(e) {
    this.props.handleLoading(true)
    e.preventDefault()

    // eslint-disable-next-line
    let reader = new FileReader()
    const file = e.target.files[0]

    if (this.props.fileFormat) {
      const format = this.props.fileFormat
      const fileType = file && file.type ? file.type : undefined
      let checkFile = false

      for (let i = 0; i < format.length; i++) {
        if ('image/' + format[i] === fileType) {
          checkFile = true
          break
        }
      }

      if (checkFile) {
        this.setState({ error: undefined })
      } else {
        let text = 'Es wird nur '
        for (let i = 0; i < format.length; i++) {
          text = text + '.' + format[i] + ' '
        }
        text = text + 'akzeptiert'
        this.setState({
          error: text
        })
        this.props.handleLoading(false)
        return null
      }
    }

    reader.onloadend = async () => {
      try {
        if (this.props.methode === 'nojimp') {
          const image = reader.result
          await this.setState({ rawImage: image })
          this.applyFilter(image, this.props.methode)
          this.props.handleLoading(false)
        } else {
          const imageData = reader.result.substring(reader.result.indexOf('base64,') + 'base64,'.length)
          const image = await Jimp.read(Buffer.from(imageData, 'base64'))
          await this.setState({ rawImage: image })
          this.applyFilter(image, this.props.methode)
          this.props.handleLoading(false)
        }
      } catch (err) {
        console.log(err)
      }
    }

    reader.readAsDataURL(file)
  }
  handleImageDelete = () => {
    this.props.handleImageUploadDelete(this.props.name, this.props.connectionRowName)
    this.setState({
      rawImage: undefined
    })
  }

  handleImageReset = () => {
    this.props.imagePreviewUrlReset(this.props.name)
    this.setState({
      rawImage: undefined
    })
  }

  async applyFilter(image, methode) {
    if (methode === 'nojimp') {
      const rawData = image
      this.props.imageSelectCallback(rawData, this.props.name)
    } else {
      const ratio = image.bitmap.width / image.bitmap.height
      const desiredRatio = this.props.expectedWidth / this.props.expectedHeight

      if (methode === 'auto') {
        methode = (desiredRatio / ratio > 1.0) ? 'cover' : 'contain'
      }
      if (methode === 'cover') {
        image = await image.cover(this.props.expectedWidth || 400, this.props.expectedHeight || 400)
      } else {
        image = await image.contain(this.props.expectedWidth || 400, this.props.expectedHeight || 400, Jimp.HORIZONTAL_ALIGN_CENTER | Jimp.VERTICAL_ALIGN_MIDDLE)
      }
      const rawData = await image.getBase64Async(Jimp.MIME_PNG)

      this.props.imageSelectCallback(rawData, this.props.name)
    }
  }

  render() {
    const noImg = this.props.noImgUrl || 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_noimg.png'

    return (
      <div className=''>
        <div className='textField-label' style={{ paddingBottom: '6px' }}>{this.props.connectionRowName}</div>
        <div className='uploadImg-prevImg'>
          {this.state.rawImage ? null : <div className='uploadImg-prevImg-size'>{this.props.expectedWidth}x{this.props.expectedHeight}</div>}
          <div className={this.state.rawImage ? 'uploadImg-prevImg-tri uploadImg-prevImg-tri-red' : 'uploadImg-prevImg-tri'} />
          <img
            src={this.props.imagePreviewUrl ? this.props.imagePreviewUrl : noImg}
            alt={this.props.imagePreviewUrl ? 'no-logo' : 'logo'}
            style={this.props.backgroundColor ? { width: '100%', backgroundColor: this.props.backgroundColor } : { width: '100%' }}
            onError={this.props.imagePreviewUrl ? (e) => { e.target.onerror = null; e.target.src = noImg } : null}
          />

          <div className={this.state.rawImage ? 'uploadImg-prevImg-tri-right uploadImg-prevImg-tri-right-red' : 'uploadImg-prevImg-tri-right'} />
        </div>
        <div className={this.state.rawImage ? 'uploadImg-imgUploadWrapper uploadImg-fileInput-warning' : 'uploadImg-imgUploadWrapper'}>
          {this.state.rawImage ? 'Zurücksetzten' : 'Bild Updaten'}
          {this.state.rawImage ? (
            <input className='uploadImg-fileInput' onClick={this.handleImageReset} />
          ) : (
            <input
              className='uploadImg-fileInput'
              type='file'
              onChange={(e) => this._handleImageChange(e)}
            />
          )}

        </div>
        {!this.state.rawImage && this.props.imagePreviewUrl &&
        <div className={'uploadImg-imgUploadWrapper red'} onClick={this.handleImageDelete}>
          Löschen
        </div>
        }
        {this.state.error ? (
          <div className='uploadImg-fileInput-error'>
            {this.state.error}
          </div>
        ) : null}
      </div>
    )
  }
}
