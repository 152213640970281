import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import './dashboard.css'

class TableMatch extends Component {
  constructor (props) {
    super(props)

    this.state = {
      selected: [0]
    }
  }

  handleClick = (event, id) => {
    this.setState({ selected: [id] })
    this.props.handleMatchId(id, this.props.cameraMatches[this.state.selected].expectedStartTime)
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  render () {
    var options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }

    return (
      <Paper>
        <div style={{ overflow: 'auto' }}>
          <Table style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow
                style={{
                  height: '45px'
                }}
              >
                <TableCell>Start Zeit</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Aktiv</TableCell>
              </TableRow>
            </TableHead>
          </Table>
          {this.props.cameraMatches.length > 0 ? (
            <div style={{ overflow: 'auto', height: '260px' }}>
              <Table style={{ tableLayout: 'fixed' }}>
                <TableBody>
                  {this.props.cameraMatches.map((row, index) => (
                    <TableRow
                      hover
                      key={index}
                      selected={this.isSelected(index)}
                      onClick={(event) => this.handleClick(event, index)}
                      className={row.clubAId !== '0' ? 'tablerow-match' : ''}
                    >
                      <TableCell>{new Date(row.startTime).toLocaleDateString('de-DE', options)}</TableCell>
                      <TableCell component='th' scope='row' style={{ paddingLeft: '30px' }}>
                        {row.RowKey}
                      </TableCell>
                      <TableCell style={{ paddingLeft: '35px' }}>{row.state}</TableCell>
                      <TableCell style={{ paddingLeft: '35px' }}>{String(row.active)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : (
            <div style={{ marginTop: '18px', textAlign: 'center', height: '40px' }}>
            Keine Daten vorhanden.
            </div>
          )}
        </div>
      </Paper>
    )
  }
}

export default TableMatch
