import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
// import OverlayCheck from '../../../modules/static/overlayCheck';
import ChipInput from 'material-ui-chip-input'
import Grid from '@material-ui/core/Grid'
import DatePicker from 'react-datepicker'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { postHighlight } from '../../../api/api-clip'

import '../clips.css'

class Info extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      tags: [],
      rerender: false
    }
    this.getRowNamesByIndex = this.getRowNamesByIndex.bind(this)
    this.handleAddTag = this.handleAddTag.bind(this)
    this.handleDeleteTag = this.handleDeleteTag.bind(this)
    this.handleAddTagsToClips = this.handleAddTagsToClips.bind(this)
    this.handleRemoveTagsFromClip = this.handleRemoveTagsFromClip.bind(this)
    this.handleDownload = this.handleDownload.bind(this)
  }

  /**
   *  Dialog OverlayCheck
   */
  handleDialogOpen = (value) => {
    this.setState({ open: value })
  };

  async handleActive (value) {
    if (!this.props.multiSelect) {
      return
    }
    const data = this.props.multiSelect.map((index) => {
      const itm = this.props.rows[index]
      return {
        RowKey: itm.RowKey,
        active: value
      }
    })

    for (const clip of data) {
      try {
        await postHighlight(clip)
      } catch (err) {
        console.error(err)
        window.alert('Es ist ein Fehler aufgetreten')
      }
    }
  }

  handleRerendering () {
    this.handleDialogOpen(true) // Dialog OverlayCheck
  }

  handleAddTag (tag) {
    const tags = this.state.tags
    tags.push(tag)
    this.setState({ tags })
  }

  handleDeleteTag (tag, index) {
    const tags = this.state.tags
    tags.splice(index, 1)
    this.setState({ tags })
  }

  async handleAddTagsToClips () {
    if (!this.props.multiSelect) {
      return
    }
    const tags = this.state.tags
    const data = this.props.multiSelect.map((index) => {
      const itm = this.props.rows[index]
      let metaTags = tags
      if (itm.metaTags && itm.metaTags.length) {
        const itmTags = itm.metaTags.split(', ')
        metaTags = metaTags.filter((tag) => {
          return !itmTags.includes(tag)
        })
        metaTags = itmTags.concat(metaTags)
      }
      return {
        RowKey: itm.RowKey,
        metaTags
      }
    })
    for (const clip of data) {
      try {
        await postHighlight(clip)
      } catch (err) {
        console.error(err)
        window.alert('Es ist ein Fehler aufgetreten')
      }
    }
  }

  async handleRemoveTagsFromClip () {
    if (!this.props.multiSelect) {
      return
    }
    const tags = this.state.tags
    const data = this.props.multiSelect
      .map((index) => {
        const itm = this.props.rows[index]
        if (!itm.metaTags || !itm.metaTags.length) {
          return false
        }
        const itmTags = itm.metaTags.split(', ')
        const metaTags = itmTags.filter((tag) => {
          return !tags.includes(tag)
        })
        return {
          RowKey: itm.RowKey,
          metaTags
        }
      })
      .filter((clip) => {
        return Boolean(clip)
      })
    for (const clip of data) {
      try {
        await postHighlight(clip)
      } catch (err) {
        console.error(err)
        window.alert('Es ist ein Fehler aufgetreten')
      }
    }
  }

  async apiCallPrio (value) {
    if (!this.props.multiSelect) {
      return
    }
    const data = this.props.multiSelect.map((index) => {
      const itm = this.props.rows[index]
      return {
        RowKey: itm.RowKey,
        priority: parseInt(value, 10)
      }
    })

    for (const clip of data) {
      try {
        await postHighlight(clip)
      } catch (err) {
        console.error(err)
        window.alert('Es ist ein Fehler aufgetreten')
      }
    }
  }

  handleChange = (name) => (event) => {
    switch (name) {
      case 'false': // game setActvie
        this.handleActive(true)
        break
      case 'true': // game setDeactivated
        this.handleActive(false)
        break
      default:
        break
    }
  };

  getRowNamesByIndex () {
    if (!this.props.multiSelect) {
      return
    }
    const rows = this.props.rows
    const names = this.props.multiSelect.map((index) => {
      return rows[index].name
    })
    return names.join(', ')
  }

  async handleDownload () {
    const rerenderUrl = `https://convert.soccerwatch.tv/${this.props.rowinfo.RowKey}/summary/true`
    const url = 'https://convert.soccerwatch.tv/' + this.props.rowinfo.RowKey

    if (this.state.rerender) {
      await fetch(rerenderUrl).then(res => {
        console.log(res)
      }).catch(error => {
        console.log(error)
      })

      this.setState({
        rerender: false
      })
    }

    window.open(url, '_blank')
  }

  handleRerenderDownload () {
    this.setState({
      rerender: !this.state.rerender
    })
  }

  render () {
    let tempInfo = ''
    if (this.props.rowinfo !== undefined) {
      tempInfo = this.props.rowinfo
    }

    let tempError
    if (tempInfo.bundle !== undefined) {
      tempError = tempInfo.bundle.error
    }
    const names = this.getRowNamesByIndex()
    return (
      <Paper className='game-layout'>
        <div className='layout-head'>INFO</div>
        {this.props.rowinfo !== undefined ? (
          <div>
            <div className='layout-body'>
              <div className='body-infoline'>Aktuelles Spiel: {tempInfo.RowKey}</div>            

              {tempError !== undefined ? (
                <div className='body-infoline infoline-error'>Error "{tempError}" aufgetreten</div>
              ) : null}

              <div className='body-infoline'>Name: {tempInfo.name}</div>
              <div className='body-infoline'>EventType: {tempInfo.eventType}</div>
              <div className='body-infoline'>metaTags: {tempInfo.metaTags}</div>
              <div className='body-infoline'>Timestamp: {tempInfo.Timestamp}</div>
              {tempInfo.clipStream ? (
                <div className='body-infoline'>
                  Video Url:{' '}
                  <a href={tempInfo.clipStream} target='blank'>
                    {tempInfo.clipStream}
                  </a>
                </div>
              ) : null}

              <div className='body-infoline'>TAG davor: {this.props.prevTag ? this.props.prevTag.name : null}</div>

              {this.props.multiSelect ? (
                <div className='body-infoline'>SelectedEntries: {this.props.multiSelect.length}</div>
              ) : null}
              {names}
            </div>
            <div>
              <Grid
                container
                spacing={0}
                style={{
                  textAlign: 'center',
                  paddingBottom: '4px',
                  marginBottom: '4px',
                  borderBottom: 'solid 1px #123'
                }}
              >
                <Grid item xs={12} sm={12}>
                  Priority:
                </Grid>
                <Grid item xs={4} sm={2}>
                  <Button
                    color='primary'
                    variant='contained'
                    style={{ width: '100%' }}
                    className='btn'
                    onClick={this.apiCallPrio.bind(this, 0)}
                  >
                    {' '}
                    0{' '}
                  </Button>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <Button
                    color='primary'
                    variant='contained'
                    style={{ width: '100%' }}
                    className='btn'
                    onClick={this.apiCallPrio.bind(this, 1)}
                  >
                    {' '}
                    1{' '}
                  </Button>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <Button
                    color='primary'
                    variant='contained'
                    style={{ width: '100%' }}
                    className='btn'
                    onClick={this.apiCallPrio.bind(this, 2)}
                  >
                    {' '}
                    2{' '}
                  </Button>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <Button
                    color='primary'
                    variant='contained'
                    style={{ width: '100%' }}
                    className='btn'
                    onClick={this.apiCallPrio.bind(this, 3)}
                  >
                    {' '}
                    3{' '}
                  </Button>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <Button
                    color='primary'
                    variant='contained'
                    style={{ width: '100%' }}
                    className='btn'
                    onClick={this.apiCallPrio.bind(this, 4)}
                  >
                    {' '}
                    4{' '}
                  </Button>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <Button
                    color='primary'
                    variant='contained'
                    style={{ width: '100%' }}
                    className='btn'
                    onClick={this.apiCallPrio.bind(this, 4)}
                  >
                    {' '}
                    5{' '}
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div>
              <Button color='primary' variant='contained' onClick={this.handleChange('false')} className='game-btn'>
                Ausgewählte Aktivieren
              </Button>
              <Button color='primary' variant='contained' onClick={this.handleChange('true')} className='game-btn'>
                Ausgewählte Deaktiveren
              </Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '8px 0' }}>
              <label className='body-infoline'>Tags: </label>
              <ChipInput style={{ width: '100%', marginRight: '6px' }} value={this.state.tags} onAdd={this.handleAddTag} onDelete={this.handleDeleteTag} />
            </div>
            <div>
              <Button color='primary' variant='contained' onClick={this.handleAddTagsToClips} className='game-btn'>
                Tags Hinzufügen
              </Button>
              <Button color='primary' variant='contained' onClick={this.handleRemoveTagsFromClip} className='game-btn'>
                Tags Entfernen
              </Button>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.rerender}
                    onChange={this.handleRerenderDownload.bind(this)}
                    color='primary'
                  />
                }
                label='Rerender Download'
              />
              <Button color='primary' variant='contained' onClick={this.handleDownload} className='game-btn-full'>
                Download
              </Button>
            </div>
            <div className='fromTo'>
              <DatePicker dateFormat='dd.MM.yyyy' selected={this.props.clipFromSince} onChange={this.props.handleSetFromSince.bind(this)} />
              bis
              <DatePicker dateFormat='dd.MM.yyyy' selected={this.props.clipToSince} onChange={this.props.handleSetToSince.bind(this)} />
              <Button onClick={this.props.handleGetCall.bind(this)} className='btnClip' variant='contained' color='primary' size='small'>reload</Button>
            </div>
          </div>
        ) : null}
      </Paper>
    )
  }
}

export default Info
