import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TagInput from '../tagInput/TagInput'
// import Grid from '@material-ui/core/Grid'
// import TextField from '@material-ui/core/TextField'
// import Select from '@material-ui/core/Select'
// import moment from 'moment'

import './cityMerge.css'
import { mergeCity } from '../../../../../api/api-city'

import AiswSpinner from '../../../../../common/AiswSpinner'

class CityMerge extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openOverlay: false,
      citiesToMerge: [],
      loading: false
    }
  }

  handleOpen = () => {
    this.setState({ openOverlay: true })
  }

  handleClose = () => {
    this.setState({ openOverlay: false })
  }

  onAddItem = (value) => {
    if (value !== '' && !isNaN(value) && !this.state.citiesToMerge.includes(value) && value !== this.props.editRow.RowKey) {
      const citiesToMerge = JSON.parse(JSON.stringify(this.state.citiesToMerge))
      citiesToMerge.push(value)
      this.setState({ citiesToMerge })
      return true
    }
    return false
  }

  onRemoveItem = (index) => {
    const citiesToMerge = JSON.parse(JSON.stringify(this.state.citiesToMerge))
    citiesToMerge.splice(index, 1)
    this.setState({ citiesToMerge })
  }

  onMergeClick = async () => {
    const doMerge = window.confirm('Cities [' + this.state.citiesToMerge.join(', ') + '] werden dem aktuell ausgewählten Team ' + this.props.editRow.RowKey + ' hinzugefügt und dann gelöscht. Bist du sicher?')
    if (doMerge) {
      this.setState({ loading: true })
      try {
        await mergeCity(this.state.citiesToMerge, this.props.editRow.RowKey)
      } catch (err) {
        console.error('Cities ', + this.state.citiesToMerge, + 'konnte der Hauptstadt ' + this.props.editRow.RowKey + ' nicht hinzugefügt werden: ' + err)
        // TODO: Implement Toast to notify user
      }
      this.setState({ loading: false, openOverlay: false })
      this.props.onDone(undefined, true)
    }
  }

  render() {
    return (
      <div>
        <Dialog fullWidth open={this.state.openOverlay} onClose={this.handleClose} aria-labelledby='alert-dialog-title'>
          <DialogTitle id='alert-dialog-title'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {this.props.editRow.RowKey}:{this.props.editRow.name}
            </div>
          </DialogTitle>
          <DialogContent>
            <TagInput
              addLabel="Cities Hinzufügen"
              listLabel="Cities"
              placeholder="CityId"
              items={this.state.citiesToMerge}
              onAddItem={this.onAddItem}
              onRemoveItem={this.onRemoveItem}
            />
            {this.state.loading && <AiswSpinner />}
          </DialogContent>
          <DialogActions>
            <Button disabled={this.state.loading} onClick={this.handleClose} color='primary'>
              Abbrechen
            </Button>
            <Button disabled={this.state.loading || this.state.citiesToMerge.length <= 0} onClick={this.onMergeClick} color='primary'>
              Zusammenfügen
            </Button>
          </DialogActions>
        </Dialog>
        <Button
          onClick={() => this.handleOpen()}
          color='primary'
          variant='contained'
          className='camera-btn'
          style={{ alignSelf: 'center' }}
        >
          {this.props.row.title} Öffnen
        </Button>
      </div>
    )
  }
}

export default CityMerge
