import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

class Settings extends Component {
  constructor (props) {
    super(props)

    this.state = {
      ttsMatchCounter: 5,
      activeMatchCounter: 5
    }
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value
    })
  };

  handleCounter = () => {
    if (this.props.ttsMatchCounter !== this.state.ttsMatchCounter) {
      this.props.handleCounter('ttsMatchCounter', this.state.ttsMatchCounter)
    }

    if (this.props.activeMatchCounter !== this.state.activeMatchCounter) {
      this.props.handleCounter('activeMatchCounter', this.state.activeMatchCounter)
    }
  };

  render () {
    return (
      <div style={{ width: '100%' }}>
        <div className='settings-headingText'>Anzahl der Matches beim TTS Check</div>
        <div>
          <TextField
            id='ttsMatchCounter'
            value={this.state.ttsMatchCounter}
            onChange={this.handleChange('ttsMatchCounter')}
            type='number'
            InputLabelProps={{
              shrink: true
            }}
            margin='dense'
            fullWidth
          />
        </div>
        <div className='settings-headingText'>Anzahl der Matches beim Active Check</div>
        <div>
          <TextField
            id='activeMatchCounter'
            value={this.state.activeMatchCounter}
            onChange={this.handleChange('activeMatchCounter')}
            type='number'
            InputLabelProps={{
              shrink: true
            }}
            margin='dense'
            fullWidth
          />
        </div>
        <Button
          variant='contained'
          color='primary'
          className='btn'
          style={{ width: '100%' }}
          onClick={this.handleCounter.bind(this)}
        >
          Ändern
        </Button>
      </div>
    )
  }
}

export default Settings
