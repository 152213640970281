import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import './static.scss'
import { TextField } from '@material-ui/core'

class OverlayCheck extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false,
      useLabelId: this.props.labelId,
      useLatestCalibration: this.props.useLatestCalibration ? this.props.useLatestCalibration : false
    }
  }

  componentDidUpdate () {
    if (this.props.open !== this.state.open) {
      this.setState({ open: this.props.open })
    }

    if (this.props.labelId !== this.state.useLabelId) {
      this.setState({ useLabelId: this.props.labelId })
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  };

  handleClose = () => {
    this.setState({ open: false, useLabelId: 0 })
    this.props.click(false)
  };

  handleCloseOk () {
    this.setState({ open: false })
    this.props.click(false)
    this.props.check(this.state.useLabelId, this.state.useLatestCalibration)
  }

  handleChange = (name) => (event) => {
    if (event.target.value.length === 0) {
      this.setState({ [name]: 0 })
    } else {
      const intValue = parseInt(event.target.value, 10)
      if (isNaN(intValue)) {
        return
      }
      this.setState({ [name]: intValue })
    }
  };

  onChangeUseLatestCalibration = (e) => {
    const useLatestCalibration = e.currentTarget.value
    this.setState({ useLatestCalibration })
  }

  render () {
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>{this.props.text}</DialogContentText>
            <div>
              <div className='overlaycheck-heading'>Weitere Einstellungen:</div>
              Mit Labels (0 : AI , 1: existing main , 2: existing second ... )
              <TextField value={this.state.useLabelId} onChange={this.handleChange('useLabelId')} color='primary' />
            </div>
            <br />
            <div>
              <label>useLatestCalibration: </label>
              <select label='useLatestCalibration' value={this.state.useLatestCalibration} onChange={this.onChangeUseLatestCalibration}>
                <option value={true}>True</option>
                <option value={false}>False</option>
              </select>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} className='btn' color='primary' variant='contained'>
              Abbrechen
            </Button>
            <Button
              onClick={this.handleCloseOk.bind(this)}
              className='btn'
              color='primary'
              variant='contained'
              autoFocus
            >
              Bestätigen
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default OverlayCheck
