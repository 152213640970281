import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'

import '../game.css'

class Plot extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <Paper className='game-layout'>
        <div className='layout-head'>UPLOAD</div>
        {this.props.matchId ? (
          <iframe src={'https://europe-west3-aisw-ww-ai.cloudfunctions.net/plot-upload?match_id=' + this.props.matchId} style={{ width: '100%', height: '293px' }} frameBorder='0' />
        ) : null}

      </Paper>
    )
  }
}

export default Plot
