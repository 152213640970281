import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Menu, MenuItem } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import moment from 'moment'
import DatePicker from 'react-datepicker'

import './objectOverlay.scss'

const today = new Date()
const endOfPreset = new Date(today)
endOfPreset.setFullYear(endOfPreset.getFullYear() + 3)

const presets = {
  "Standard 520M": {
    minutes: 520,
    transactionId: 'Standard 520M',
    type: 'MONTH_SINGLEUSE', // 'YEAR'
    validFrom: today.toISOString(),
    validTo: endOfPreset.toISOString()
  },
  "Pro LTE 1300M": {
    minutes: 1300,
    transactionId: 'Pro LTE 1300M',
    type: 'MONTH_SINGLEUSE', // 'YEAR'
    validFrom: today.toISOString(),
    validTo: endOfPreset.toISOString()
  },
  "Pro LAN 1300M": {
    minutes: 1300,
    transactionId: 'Pro LAN 1300M',
    type: 'MONTH_SINGLEUSE', // 'YEAR'
    validFrom: today.toISOString(),
    validTo: endOfPreset.toISOString()
  },
  "Flexibel": {
    minutes: 0,
    transactionId: '',
    type: 'MONTH_SINGLEUSE', // 'YEAR'
    validFrom: today.toISOString(),
    validTo: endOfPreset.toISOString()
  }
}

class ObjectOverlay extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openOverlay: false,
      menuAnchor: undefined
    }
  }

  handleOpenMenu = evt => {
    this.setState({ menuAnchor: evt.currentTarget })
  }

  handleCloseMenu = evt => {
    this.setState({ menuAnchor: undefined })
  }

  handleOpen = () => {
    this.setState({ openOverlay: true })
  }

  handleClose = () => {
    this.setState({ openOverlay: false })
  }

  setObjectOverlay = (index, key, value) => {
    let obj = [...this.props.value]
    obj[index][key] = value
    this.props.onChangeObjectOverlay(this.props.row.name, obj)
  }

  setObjectOverlayDate = (index, key, value) => {
    let obj = [...this.props.value]
    obj[index][key] = moment(value).toISOString()
    this.props.onChangeObjectOverlay(this.props.row.name, obj)
  }

  removeObjectOverlay = (index) => {
    let obj = [...this.props.value]
    obj.splice(index, 1)
    this.props.onChangeObjectOverlay(this.props.row.name, obj)
  }

  addObjectOverlay = (type) => {
    let obj = [...this.props.value]
    obj.unshift({
      minutes: 0,
      transactionId: '',
      type: type, // 'YEAR'
      validFrom: new Date().toISOString(),
      validTo: new Date().toISOString()
    })
    this.props.onChangeObjectOverlay(this.props.row.name, obj)
  }

  addPreset = (presetName) => () => {
    const preset = presets[presetName]
    if (!preset) {
      console.error('Could not add Preset, namne not found in preset-list')
      return
    }
    let obj = [...this.props.value]
    obj.unshift(preset)
    this.props.onChangeObjectOverlay(this.props.row.name, obj)
    this.handleCloseMenu()
  }

  renderContainer = () => {
    return this.props.value.map((value, index) => {
      return (
        <div key={index} style={{ padding: '12px 0', position: 'relative' }}>
          <div style={{ position: 'absolute', right: 0, top: 2, zIndex: 1000 }}>
            <Button onClick={() => this.removeObjectOverlay(index)} color='primary'>
              Abo Entfernen
            </Button>
          </div>
          <Grid container spacing={24} style={{ background: '#e3e3e3', paddingTop: 30 }}>
            <Grid item xs={12} sm={12}>
              <TextField
                className='textField-table'
                label={'transactionId'}
                value={value.transactionId}
                onChange={(event) => this.setObjectOverlay(index, 'transactionId', event.target.value)}
                margin='none'
                type='text'
              />

            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                className='textField-table'
                label={'minutes'}
                value={value.minutes}
                onChange={(event) => this.setObjectOverlay(index, 'minutes', Number(event.target.value))}
                margin='none'
                type='number'
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <label className='smol'>type</label>
              <Select
                className='textField-table-dropdowns'
                value={value.type}
                title={'type'}
                onChange={(event) => this.setObjectOverlay(index, 'type', event.target.value)}
                native
                margin='none'
              >
                <option value='YEAR'>
                  YEAR
                </option>
                <option value='MONTH'>
                  MONTH
                </option>
                <option value='MONTH_SINGLEUSE'>
                  MONTH_SINGLEUSE
                </option>
                <option value='ONCE'>
                  ONCE
                </option>
              </Select>
            </Grid>
            <Grid item xs={6} sm={6}>
              <div style={{ color: 'rgba(0, 0, 0, 0.54)', padding: 0, fontSize: '12px', margin: '4px 0px 4px' }}>
                <span>validFrom</span>
              </div>
              <DatePicker
                className='table-datepicker overlay-datepicker'
                selected={value.validFrom === '*' ? new Date('2020').toISOString() : new Date(value.validFrom).toISOString()}
                onChange={(event) => this.setObjectOverlayDate(index, 'validFrom', event)}
                dateFormat='dd.MM.yyyy'
                popperPlacement="right"
                popperModifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [0, 0],
                    },
                  }
                ]}
                showYearDropdown
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <div style={{ color: 'rgba(0, 0, 0, 0.54)', padding: 0, fontSize: '12px', margin: '4px 0px 4px' }}>
                <span>validTo</span>
              </div>
              <DatePicker
                className='table-datepicker overlay-datepicker'
                selected={value.validTo === '*' ? new Date('2100').toISOString() : new Date(value.validTo).toISOString()}
                onChange={(event) => this.setObjectOverlayDate(index, 'validTo', event)}
                dateFormat='dd.MM.yyyy'
                popperPlacement="left"
                popperModifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [0, 0],
                    },
                  }
                ]}
                showYearDropdown
              />
            </Grid>
          </Grid>
          <div style={{ height: '20px' }} />
        </div>
      )
    })
  }

  render() {
    return (
      <div>
        <Dialog fullWidth open={this.state.openOverlay} onClose={this.handleClose} aria-labelledby='alert-dialog-title'>
          <DialogTitle id='alert-dialog-title'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {this.props.row.title}
              {/* <Button onClick={() => this.addObjectOverlay('YEAR')} color='primary'>
                Neues Abo Hinzufügen
              </Button> */}
              <Button color='primary' aria-owns={this.state.menuAnchor ? 'contingentMenu' : undefined}
                aria-aria-haspopup="true" onClick={this.handleOpenMenu}
              >
                Neues Abo Hinzufügen
              </Button>
              <Menu
                id="contingentMenu"
                anchorEl={this.state.menuAnchor}
                open={Boolean(this.state.menuAnchor)}
                onClose={this.handleCloseMenu}
              >
                {Object.keys(presets).map((key) => {
                  return <MenuItem key={key} onClick={this.addPreset(key)}>{key}</MenuItem>
                })}
              </Menu>
            </div>
          </DialogTitle>
          <DialogContent>
            {this.renderContainer()}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Schließen
            </Button>
          </DialogActions>
        </Dialog>
        <Button
          onClick={() => this.handleOpen()}
          color='primary'
          variant='contained'
          className='camera-btn'
          style={{ alignSelf: 'center' }}
        >
          {this.props.row.title} Öffnen
        </Button>
      </div>
    )
  }
}

export default ObjectOverlay
