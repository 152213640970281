import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'

class GuardContainer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      data: undefined
    }
  }

  render () {
    let temparray = {}
    this.props.data.map(itm => {
      if (temparray && temparray[itm.requestingService]) {
        temparray[itm.requestingService].push(itm)
      } else {
        temparray = { ...temparray, [itm.requestingService]: [itm] }
      }
      return null
    })

    const list = Object.entries(temparray).map(([key, value], index) => {
      return (
        <div key={index}>
          {value.map((itm, i) => {
            return (
              <div key={i}>
                <div style={{
                  padding: '4px',
                  backgroundColor: 'rgb(224, 224, 224)',
                  color: 'rgb(17, 17, 17)',
                  textAlign: 'center'
                }}
                >
                  {itm.serviceName} &gt; {itm.requestingService}
                </div>
                <div>
                  {itm.result ? itm.result.map((res, i) => {
                    const info = (
                      res.detail.map((detail, i) => {
                        return (
                          <div key={i} style={{ borderBottom: '1px solid #fff' }}>
                            Result: {String(detail.result)} <br />
                            Paths: {detail.position}  <br />
                            Command: {detail.command}
                          </div>
                        )
                      })
                    )
                    return (
                      <div key={i} style={{ padding: '4px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          {res.path}
                          <Tooltip title={info} classes={{ tooltip: null }} placement='right'>
                            <div style={{ color: '#a5a5a5', paddingRight: '8px' }}>info</div>
                          </Tooltip>
                        </div>
                        <div style={res.result ? { height: '2px', backgroundColor: 'rgb(122, 217, 38)' } : { height: '2px', background: 'rgb(253, 114, 114)' }} />
                      </div>
                    )
                  })
                    : (
                      <div key={i} style={{ padding: '4px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                         Noch keine Ergebnisse vorhanden.
                        </div>
                        <div style={{ height: '2px', background: 'rgb(253, 114, 114)' }} />
                      </div>)}
                </div>
              </div>
            )
          })}
        </div>
      )
    })

    return (
      <Paper>
        <div className='layout-head'>Guardlayer</div>
        {list}
      </Paper>
    )
  }
}

export default GuardContainer
