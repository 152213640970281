import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import * as t from '@material-ui/core/Tabs'
import AiswSpinner from '../../../../../common/AiswSpinner'
import { getClubsByCityId } from '../../../../../api/api-club'
import { getGEOImages, setGEOImage } from '../../../../../api/api-city'
import { Grid, Input, InputLabel, Select, Tab, Tabs } from '@material-ui/core'

import './cityImageSelect.css'

class CityImageSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      uploading: false,
      coordinates: [],
      currentCoordinate: -1,
      selectedImage: undefined,
      images: [],
      showBigImage: false,
      openOverlay: false,
      currentTab: 0,
      // Manuel Set
      customUrl: '',
      customLicense: '',
      customLicenseAuthor: '',
      customLicenseTitle: '',
      customLicenseSrc: ''
    }
  }

  onChangeTab = (e, currentTab) => {
    this.setState({ currentTab })
  }

  onImageClick = (e) => {
    if (this.state.loading) {
      return
    }
    const index = e.currentTarget.id.split('-')[1]
    this.setState({ selectedImage: index })
  }

  setImage = async () => {
    if (!this.state.selectedImage) {
      return
    }
    this.setState({ loading: true })
    const image = this.state.images[this.state.selectedImage]
    const body = {
      url: image.imageinfo[0].url,
      ccTitle: image.title.replace('File:', '')
    }
    await setGEOImage(this.props.cityId, body)
    // console.log('Would set Image', body)
    this.setState({ loading: false })
  }

  onChangeTextInput = (prop) => (e) => {
    this.setState({ [prop]: e.currentTarget.value })
  }

  setCustomImage = async () => {
    const { customUrl, customLicense, customLicenseAuthor, customLicenseTitle, customLicenseSrc } = this.state

    if (!customUrl || !customLicense || !customLicenseAuthor || !customLicenseTitle) {
      return
    }
    this.setState({ uploading: true })
    let creditLine = `<div>${customLicenseTitle} by ${customLicenseAuthor} - License: ${customLicense}`
    if (customLicenseSrc) {
      creditLine = `<a href=${customLicenseSrc}>${creditLine}</a>`
    }
    creditLine = `<div class="licenseClaim">${creditLine}</div>`
    const body = {
      licenseData: {
        source: customLicenseSrc,
        author: customLicenseAuthor,
        title: customLicenseTitle,
        type: 'Manual Upload Sw-AdminTool'
      },
      url: customUrl,
      ccTitle: customLicenseTitle,
      creditLine
    }
    await setGEOImage(this.props.cityId, body)
    this.setState({ uploading: false })
  }

  getCityCoordinates = async () => {
    this.setState({ loading: true })
    const clubs = await getClubsByCityId(this.props.cityId)
    const coordinates = clubs.filter((c) => c.latitude && c.longitude).map((c) => { return { lat: c.latitude, lon: c.longitude, name: c.name } })
    const currentCoordinate = coordinates && coordinates.length > 0 ? 0 : -1
    this.setState({ coordinates, loading: false, currentCoordinate }, this.getImagesForCoordinate)
    return
  }

  getImagesForCoordinate = async (index) => {
    if (!this.state.coordinates.length) {
      return
    }
    if (index < 0 || index > this.state.coordinates.length) {
      console.error('Index not Found in Coordinates')
      return []
    }
    this.setState({ loading: true })
    const coordinates = index ? this.state.coordinates[index] : this.state.coordinates[this.state.currentCoordinate]
    const images = await getGEOImages(coordinates.lat, coordinates.lon)
    const selectedImage = images && images.data.length > 0 ? 0 : undefined
    this.setState({ images: images.data, selectedImage, loading: false })
  }

  onCoordChange = (e) => {
    const currentCoordinate = e.currentTarget.value
    this.setState({ currentCoordinate }, this.getImagesForCoordinate)
  }

  nextImage = () => {
    if (this.state.loading) {
      return
    }
    let index = this.state.selectedImage
    if (!index && index !== 0) {
      return
    }
    index++
    if (index >= this.state.images.length) {
      index = 0
    }
    this.setState({ selectedImage: index })
  }

  prevImage = () => {
    if (this.state.loading) {
      return
    }
    let index = this.state.selectedImage
    if (!index && index !== 0) {
      return
    }
    index--
    if (index < 0) {
      index = this.state.images.length - 1
    }
    this.setState({ selectedImage: index })
  }

  handleOpen = () => {
    this.setState({ openOverlay: true })
    this.getCityCoordinates()
  }

  handleClose = () => {
    this.setState({ openOverlay: false })
  }

  onSelect = async () => {
    this.setState({ loading: true })

    if (this.state.currentTab === 0) {
      await this.setCustomImage()
    } else if (this.state.currentTab === 1) {
      await this.setImage()
    }

    this.setState({ loading: false, openOverlay: false })
    this.props.onDone && this.props.onDone(undefined, true)
  }

  getFullSizeSwUrl(swUrl) {
    const parts = swUrl.split('.')
    parts[parts.length - 2] = `${parts[parts.length - 2]}-4x`
    return parts.join('.')
  }

  evaluateValidCondition = () => {
    if (this.state.currentTab === 0) {
      return this.state.customLicense && this.state.customLicense !== ''
        && this.state.customLicenseAuthor && this.state.customLicenseAuthor !== ''
        && this.state.customLicenseSrc && this.state.customLicenseSrc !== ''
        && this.state.customLicenseTitle && this.state.customLicenseTitle !== ''
        && this.state.customUrl && this.state.customUrl !== ''
    }
    if (this.state.currentTab === 1) {
      return this.state.selectedImage && this.state.selectedImage > 0
    }
  }


  render() {
    return (
      <div>
        <Dialog fullWidth open={this.state.openOverlay} onClose={this.handleClose} aria-labelledby='alert-dialog-title'>
          <DialogTitle id='alert-dialog-title'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* {this.props.editRow.RowKey}: */}
              {this.props.editRow.name}
            </div>
          </DialogTitle>
          <DialogContent>
            <Tabs value={this.state.currentTab} onChange={this.onChangeTab}>
              <Tab label="Manuel" value={0} />
              <Tab label="Common Browser" value={1} />
            </Tabs>
            {this.state.currentTab === 0 && <>
              <Grid container spacing={24}>
                <Grid item>
                  <InputLabel htmlFor='customUrl'>Url:</InputLabel>
                  <Input disabled={this.state.loading} name="customUrl" id="customUrl" value={this.state.customUrl} onChange={this.onChangeTextInput('customUrl')}></Input>
                </Grid>
                <Grid item>
                  <InputLabel htmlFor='customLicenseSrc'>Src:</InputLabel>
                  <Input disabled={this.state.loading} name="customLicenseSrc" id="customLicenseSrc" value={this.state.customLicenseSrc} onChange={this.onChangeTextInput('customLicenseSrc')}></Input>
                </Grid>
                <Grid item>
                  <InputLabel htmlFor='customLicenseTitle'>Title:</InputLabel>
                  <Input disabled={this.state.loading} name="customLicenseTitle" id="customLicenseTitle" value={this.state.customLicenseTitle} onChange={this.onChangeTextInput('customLicenseTitle')}></Input>
                </Grid>
                <Grid item>
                  <InputLabel htmlFor='customLicense'>License:</InputLabel>
                  <Input disabled={this.state.loading} name="customLicense" id="customLicense" value={this.state.customLicense} onChange={this.onChangeTextInput('customLicense')}></Input>
                </Grid>
                <Grid item>
                  <InputLabel htmlFor='customLicenseAuthor'>Author:</InputLabel>
                  <Input disabled={this.state.loading} name="customLicenseAuthor" id="customLicenseAuthor" value={this.state.customLicenseAuthor} onChange={this.onChangeTextInput('customLicenseAuthor')}></Input>
                </Grid>
                <Grid item xs={12}>
                  {this.state.customUrl && this.state.customUrl !== '' ?
                    <img src={this.state.customUrl} />
                    :
                    <i className='fa fa-5x fa-image' />
                  }
                </Grid>
              </Grid>
            </>}

            {this.state.currentTab === 1 && <>
              {this.state.loading && <AiswSpinner />}
              {!this.state.loading &&
                <>
                  <select onChange={this.onCoordChange} value={this.state.currentCoordinate + ''}>
                    {this.state.coordinates.map((coord, index) => {
                      return <option key={coord.name + coord.lat + coord.lon} value={index + ''}>{`${coord.name}: ${coord.lat}-${coord.lon}`}</option>
                    })}
                  </select>
                  <span>Showing:</span>
                  <Button color='primary' disabled={!this.state.images.length} onClick={() => { this.setState({ showBigImage: !this.state.showBigImage }) }}>{this.state.showBigImage ? 'Preview' : 'Galery'}</Button>
                  {this.state.showBigImage &&
                    <>
                      <Button color='primary' disabled={this.state.loading} onClick={this.prevImage}>Prev</Button>
                      <Button color='primary' disabled={this.state.loading} onClick={this.nextImage}>Next</Button>
                      {/* <Button color='primary' onClick={this.setImage}>Try Set</Button> */}
                    </>
                  }
                </>
              }
              {!this.state.loading && !this.state.images.length &&
                <h4> No Images Found</h4>
              }
              {!!this.state.images.length && <>
                {!this.state.showBigImage &&
                  <Grid container spacing={24}>
                    {this.state.images && this.state.images.map((img, index) => {
                      return <Grid key={img.pageid} id={`image-${index}`} onClick={this.onImageClick} item xs={6} sm={3}>
                        <img className={`imgContainer ${index + '' === this.state.selectedImage + '' ? 'active' : ''}`} src={img.imageinfo[0].responsiveUrls["1.5"]}></img>
                      </Grid>
                    })
                    }
                  </Grid>
                }
                {this.state.showBigImage &&
                  <Grid container spacing={24}>
                    <Grid item>
                      <img className='bigImage' src={this.state.images[this.state.selectedImage].imageinfo[0].responsiveUrls["1.5"]} />
                    </Grid>
                  </Grid>
                }
              </>}
            </>}
          </DialogContent>
          <DialogActions>
            <Button disabled={this.state.loading} onClick={this.handleClose} color='primary'>
              Abbrechen
            </Button>
            <Button disabled={this.state.loading || !this.evaluateValidCondition()} onClick={this.onSelect} color='primary'>
              Auswählen
            </Button>
          </DialogActions>
        </Dialog>
        <div>
          {this.props.editRow.imageUrl && this.props.editRow.imageUrl !== '' ?
            <img src={`${this.getFullSizeSwUrl(this.props.editRow.imageUrl)}?time=${Date.now()}`} />
            :
            <i className='fa fa-5x fa-image' />
          }
          <Button
            onClick={() => this.handleOpen()}
            color='primary'
            variant='contained'
            className='camera-btn'
            style={{ alignSelf: 'center' }}
          >
            Stadtbild Auswählen
          </Button>
        </div>
      </div >
    )
  }
}

export default CityImageSelect
