import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import { Cookies } from 'react-cookie'
import { getCameraComment, postCameraComment, deleteCameraComment } from '../../../api/api-camera'

class Comments extends Component {
  constructor (props) {
    super(props)

    this.state = {
      RowKey: 0,
      comments: [],
      multiline: ''
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.rowinfo && (this.props.rowinfo.RowKey !== undefined)) {
      if (this.props.rowinfo.RowKey !== this.state.RowKey) {
        getCameraComment(this.props.rowinfo.RowKey).then((res) => {
          this.setState({
            RowKey: this.props.rowinfo.RowKey,
            comments: res
          })
        })
      }
    }
  }

  handleApicall () {
    getCameraComment(this.props.rowinfo.RowKey).then((res) => {
      this.setState({
        comments: res
      })
    })
  }

  handleDelete (id) {
    deleteCameraComment(parseInt(id, 10), String(this.props.rowinfo.RowKey)).then((res) => {
      this.setState({ comments: [] })
      this.handleApicall()
    })
  }

  handleAddComment () {
    const cookies = new Cookies()
    cookies.get('soccerwatchuser')

    const add = {
      cameraId: String(this.props.rowinfo.RowKey),
      comment: this.state.multiline,
      author: cookies.cookies.soccerwatchusername
    }

    postCameraComment(add).then((res) => {
      this.setState({ comments: [] })
      this.handleApicall()
    })
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value
    })
  };

  render () {
    return (
      <Paper className='camera-layout'>
        <div className='layout-head'>KOMMENTARE</div>
        <div className='comment-container'>
          <TextField
            id='multiline-flexible'
            placeholder='Kommentar schreiben...'
            multiline
            rowsMax='4'
            value={this.state.multiline}
            onChange={this.handleChange('multiline')}
            margin='normal'
            fullWidth
            className='comment-textinput'
          />
          {this.state.multiline !== '' ? (
            <Button
              onClick={this.handleAddComment.bind(this)}
              variant='contained'
              color='primary'
              className='comment-textinput-btn'
            >
              Kommentar hinzufügen
            </Button>
          ) : null}
        </div>
        <div className='layout-body-n bottom-cl01'>
          {this.state.comments
            .sort(function (a, b) {
              return +new Date(b.Timestamp) - +new Date(a.Timestamp)
            })
            .map((n, index) => {
              return (
                <div key={index} className='comment'>
                  <div className='comment-author'>
                    {n.author}
                    <Button onClick={this.handleDelete.bind(this, n.RowKey)}>
                      {' '}
                      <i className='fa fa-fw fa-times-circle' style={{ fontSize: '1.4em' }} />{' '}
                    </Button>
                  </div>
                  <div className='comment-time'>{n.Timestamp}</div>
                  <div className='comment-text'>{n.comment}</div>
                </div>
              )
            })}
        </div>
      </Paper>
    )
  }
}

export default Comments
