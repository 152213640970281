import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import NativeSelect from '@material-ui/core/NativeSelect'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import QRCode from './container/qrcode'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { postDocket, getDocket, deleteDocket } from '../../../../api/api-metric'
import { getCameraSystem } from '../../../../api/api-camera'
import { getClubInfo } from '../../../../api/api-club'
import { describe } from './data'
import { Cookies } from 'react-cookie'
import { circularProgressCenter } from '../../../static/loadingProgress'

class NoteRender extends Component {
  constructor (props) {
    super(props)

    const temp = []
    let p = {}
    let checkData = {}

    describe.grp.map((n) => {
      return n.content.map((x) => {
        return x.content.map((y) => {
          return y.content.map((z) => {
            return temp.push(z.id)
          })
        })
      })
    })
    temp.map((n) => {
      return (p = { ...p, [n]: '' })
    })

    if (this.props.Data === undefined) {
      checkData = {
        CameraPos_Height: '7,50',
        AuvideaJ106_SDCardCapacity: '64',
        Carrierboard_Platin: 'Althalimain (e-con)'
        // General_InstallDate: this.dateValid('date'),
        // System_StartupDate: this.dateValid('date')
      }
    }

    this.state = {
      cameraClubDetails: undefined,
      editRunnotes: false,
      checkEditor: false,
      checkMandatory: false,
      checkOne: false,
      checkTwo: false,
      checkThree: false,
      PartitionKey: 'swcs-' + this.props.camId,
      RowKey: '',
      Data: {
        ...p,
        ...checkData,
        General_SystemId: String(this.props.camId)
      }
    }

    this.handleReloadCameraClubDetails = this.handleReloadCameraClubDetails.bind(this)
    this.handleReloadCameraOwner = this.handleReloadCameraOwner.bind(this)
  }

  componentDidUpdate () {
    const checkMandatory = []
    const checkDependencies = []
    const checkDependencies1 = []
    const checkDependencies2 = []
    let mandatory = false

    describe.grp.map((s) => {
      return s.content.map((x) => {
        if (this.props.renderValue === x.value) {
          return x.content.map((b) => {
            return b.content.map((c) => {
              if (c.mandatory === true) {
                if (
                  this.state.Data[c.id] !== undefined &&
                  (this.state.Data[c.id].length > 0 || this.state.Data[c.id] === true)
                ) {
                  return checkMandatory.push(true)
                } else {
                  return checkMandatory.push(false)
                }
              }

              if (c.dependencies === 'oneGrp') {
                if (
                  this.state.Data[c.id] !== undefined &&
                  (this.state.Data[c.id].length > 0 || this.state.Data[c.id] === true)
                ) {
                  checkDependencies.push(true)
                } else {
                  checkDependencies.push(false)
                }
              }

              if (c.dependencies === 'twoGrp') {
                if (
                  this.state.Data[c.id] !== undefined &&
                  (this.state.Data[c.id].length > 0 || this.state.Data[c.id] === true)
                ) {
                  checkDependencies1.push(true)
                } else {
                  checkDependencies1.push(false)
                }
              }

              if (c.dependencies === 'threeGrp') {
                if (
                  this.state.Data[c.id] !== undefined &&
                  (this.state.Data[c.id].length > 0 || this.state.Data[c.id] === true)
                ) {
                  checkDependencies2.push(true)
                } else {
                  checkDependencies2.push(false)
                }
              }

              return null
            })
          })
        }
        return null
      })
    })

    var found = checkMandatory.find(function (element) {
      return element === false
    })

    var found2 = checkDependencies.find(function (element) {
      return element === true
    })

    var found3 = checkDependencies1.find(function (element) {
      return element === true
    })

    var found4 = checkDependencies2.find(function (element) {
      return element === true
    })

    if (
      (found === undefined &&
        checkDependencies.length === 0 &&
        checkDependencies1.length === 0 &&
        checkDependencies2.length === 0) ||
      (found === undefined && found2 === true && checkDependencies1.length === 0 && checkDependencies2.length === 0) ||
      (found === undefined && found2 === true && found3 === true && checkDependencies2.length === 0) ||
      (found === undefined && found2 === true && found3 === true && found4 === true)
    ) {
      mandatory = true
    } else {
      mandatory = false
    }

    if (this.state.checkOne !== found2) {
      this.setState({ checkOne: found2 })
    }

    if (this.state.checkTwo !== found3) {
      this.setState({ checkTwo: found3 })
    }

    if (this.state.checkThree !== found4) {
      this.setState({ checkThree: found4 })
    }

    if (this.state.checkMandatory !== mandatory) {
      this.setState({ checkMandatory: mandatory })
    }
  }

  async componentDidMount () {
    const { dataCall, renderValue } = this.props

    if (renderValue === 'montage' && dataCall && dataCall.length > 1) {
      for (let i = 0; i < dataCall.length; i++) {
        const check = dataCall[i].RowKey.match(renderValue)

        if (check) {
          const data = dataCall[i].data

          data.Decrease_Name = ''
          data.Decrease_Date = ''
          data.Decrease_Check = ''
          data.dateCreate = ''

          this.setState({
            Data: data
          })
          break
        }
      }
    } else if (renderValue === 'install' && dataCall && dataCall.length >= 1) {
      for (let i = 0; i < dataCall.length; i++) {
        const check = dataCall[i].RowKey.match('montage')

        if (check) {
          const data = dataCall[i].data.General_SystemId
          const newData = this.state.Data
          newData.General_NewId = data

          this.setState({
            Data: newData
          })
          break
        }
      } 
    } else if ((renderValue === 'stock' || renderValue === 'rework') && dataCall && dataCall.length >= 1) {
      for (let i = 0; i < dataCall.length; i++) {
        const check = dataCall[i].RowKey.match('montage') || dataCall[i].RowKey.match('rework') 

        if (check) {
          const data = dataCall[i].data.Hardware_Version
          const newData = this.state.Data
          newData.Hardware_Version = data

          this.setState({
            Data: newData
          })
          break
        }
      }
    }

    // for TableCheck
    this.handleReloadCameraClubDetails()
    this.handleReloadCameraOwner()

    const cookies = new Cookies()
    const runCookie = cookies.get('soccerwatchRun')

    if (runCookie === '5684252478924') {
      this.setState({
        checkEditor: true
      })
    }

    if (this.props.Data !== undefined) {
      this.setState({
        Data: this.props.Data.data
      })
    }
  }

  handleChange = (name) => (event) => {
    this.setState({
      Data: {
        ...this.state.Data,
        [name]: event.target.value
      }
    })
  };

  handleChangeCheck = (name) => (event) => {
    const cookies = new Cookies()
    cookies.get('soccerwatchusername')

    if (name === 'Decrease_Check' && event.target.checked === true) {
      this.setState({
        Data: {
          ...this.state.Data,
          [name]: event.target.checked,
          Decrease_Name: cookies.cookies.soccerwatchusername,
          Decrease_Date: this.dateValid('dateTime')
        }
      })
    } else {
      this.setState({
        Data: {
          ...this.state.Data,
          [name]: event.target.checked
        }
      })
    }
  };

  handleChangeGroup = (name, id) => (event) => {
    this.setState({
      Data: {
        ...this.state.Data,
        [name]: { ...this.state.Data[name], [id]: event.target.value }
      }
    })
  };

  handleEditRunnotes () {
    this.setState({
      editRunnotes: true
    })
  }

  handleDeleteRunnotes () {
    deleteDocket(this.props.Data.PartitionKey, this.props.Data.RowKey).then((res) => {
      if (res.status === 200) {
        this.props.handleClear()
      }
    })
  }

  dateValid (value) {
    const tempDate = new Date()
    const date =
      tempDate.getFullYear() +
      '-' +
      ('0' + (tempDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + tempDate.getDate()).slice(-2)
    const time =
      ('0' + tempDate.getHours()).slice(-2) +
      ':' +
      ('0' + tempDate.getMinutes()).slice(-2) +
      ':' +
      ('0' + tempDate.getSeconds()).slice(-2)

    if (value === 'date') {
      return date
    } else if (value === 'dateTime') {
      return date + 'T' + time
    }
  }

   handleApiCall = async () => {
     let counter = 0
     let json
     let tempData

     await this.handleReloadCameraClubDetails()
     await this.handleReloadCameraOwner()

     // Counter for RowKey
     if (this.props.dataCall !== undefined) {
       this.props.dataCall.map((n) => {
         const splitKey = n.RowKey.split('-')

         if (this.props.renderValue === 'montage') {
           if (splitKey[0] === 'montage') {
             counter++
           }
         } else {
           if (splitKey[0] === 'montage' && splitKey[1] >= 1) {
             counter++
           }
         }

         return null
       })

       Object.keys(this.state.Data).map((n) => {
         if (this.state.Data[n] !== '') {
           return (tempData = { ...tempData, [n]: this.state.Data[n] })
         }
         return null
       })

       json = {
         RowKey: this.props.renderValue + '-' + counter,
         data: { ...tempData, dateCreate: new Date() }
       }
     } else {
       Object.keys(this.state.Data).map((n) => {
         if (this.state.Data[n] !== '') {
           return (tempData = { ...tempData, [n]: this.state.Data[n] })
         }
         return null
       })

       json = {
         RowKey: this.props.Data.RowKey,
         data: tempData
       }
     }

     postDocket(json, this.state.PartitionKey).then((res) => {
       if (res.status === 200) {
         this.props.handleClear()
       }
     })
   };

   async handleReloadCameraClubDetails () {
     const checkData = this.props.Data && this.props.Data.data && this.props.Data.data.Decrease_Check ? this.props.Data.data.Decrease_Check : undefined

     if (this.props.renderValue === 'installAdmin' && !checkData) {
       await getCameraSystem(this.props.camId).then(res => {
         if (res.status === 200) {
           const clubId = res.data.ownerClubId
           if (clubId) {
             getClubInfo(clubId).then(club => {
               let cameraClubDetails
               if (club.status === 200) {
                 cameraClubDetails = {
                   clubId: res.data.ownerClubId,
                   clubName: club.data.name,
                   placeId: res.data.address,
                   clubHasSystem: club.data ? 'Ja' : 'Nein',
                   stichting: res.data.stitching,
                   latitude: res.data.latitude,
                   longitude: res.data.longitude,
                   systemOwner: res.data.systemOwner,
                   defaultWatermark: res.data.defaultWatermark,
                   defaultEventType: res.data.defaultEventType
                 }
               } else {
                 cameraClubDetails = {
                   clubId: res.data.ownerClubId,
                   clubName: 'Nicht Bekannt...',
                   placeId: res.data.address,
                   clubHasSystem: 'Nein',
                   stichting: res.data.stitching,
                   latitude: res.data.latitude,
                   longitude: res.data.longitude,
                   systemOwner: res.data.systemOwner,
                   defaultWatermark: res.data.defaultWatermark,
                   defaultEventType: res.data.defaultEventType
                 }
               }

               this.setState({
                 Data: {
                   ...this.state.Data,
                   Table_Check: cameraClubDetails
                 }
               })
             })
           }
         }
       })
     }

     return null
   }

   async handleReloadCameraOwner () {
     const checkData = this.props.Data && this.props.Data.data && this.props.Data.data.Decrease_Check ? this.props.Data.data.Decrease_Check : undefined

     if ((this.props.renderValue === 'delivery' || this.props.renderValue === 'takeback') && !checkData) {
       await getCameraSystem(this.props.camId).then(res => {
         if (res.status === 200) {
           this.setState({
             Data: {
               ...this.state.Data,
               Table_CheckOwner: { systemOwner: res.data.systemOwner }
             }
           })
         }
       })
     }

     return null
   }

   incrementNameId (RowKey) {
     let nameNumber = RowKey
     nameNumber = nameNumber.split('-')

     describe.grp.map((n) => {
       return n.content.map((x) => {
         if (nameNumber[0] === x.value) {
           nameNumber[0] = x.name
         }
         return null
       })
     })

     const incrementIndex = Number(nameNumber[1]) + 1
     return nameNumber[0] + '-' + incrementIndex
   }

   render () {
     const currentDate = this.dateValid('date')
     const currentDateTime = this.dateValid('dateTime')
     const noteList = Array.from(this.props.notes)
     let finalInfoText
     let finalInfoTextFrom
     let indexCounter = 0

     if (this.props.Data !== undefined) {
       noteList.reverse().map((n, index) => {
         if (this.props.Data.RowKey === n.RowKey) {
           return (indexCounter = index)
         }
         return null
       })

       if (noteList[indexCounter - 1] !== undefined) {
         finalInfoText = noteList[indexCounter - 1].data.Annotation_NextNote_Text
         finalInfoTextFrom = this.incrementNameId(noteList[indexCounter - 1].RowKey)
       }
     } else {
       if (noteList.length > 0) {
         finalInfoText = noteList[indexCounter].data.Annotation_NextNote_Text
         finalInfoTextFrom = this.incrementNameId(noteList[indexCounter].RowKey)
       }
     }

     return (
       <div>
         {describe.grp.map((s) => {
           return s.content.map((b) => {
             if (this.props.renderValue === b.value) {
               return b.content.map((n, index) => {
                 return (
                   <div
                     className={
                       this.props.Data !== undefined
                         ? this.state.editRunnotes === false && this.props.Data.data.Decrease_Check && n.name !== 'QR Code'
                           ? 'runnote-grp runnote-grp-disable'
                           : 'runnote-grp'
                         : 'runnote-grp'
                     }
                     key={index}
                   >
                     <div className='runnote-grp-head'>{n.name}</div>
                     <div className='runnote-grp-body'>
                       {n.content.map((n, index) => {
                         if (n.type === 'textfield') {
                           return (
                             <div
                               key={index}
                               style={
                                 n.mandatory === 'true'
                                   ? { padding: '6px', borderRight: '10px solid rgb(29, 162, 51)' }
                                   : {}
                               }
                             >
                               <div className='runnote-grp-body-cb-head'>{n.heading_text}</div>

                               {n.mandatory === true ? (
                                 this.state.Data[n.id] !== '' && this.state.Data[n.id] !== undefined ? (
                                   <div className='runnote-grp-marker runnote-marker-true' />
                                 ) : (
                                   <div className='runnote-grp-marker runnote-marker-false' />
                                 )
                               ) : null}

                               {n.disabled ? (
                                 <Input
                                   style={{ width: '100%' }}
                                   id={n.id}
                                   label={n.heading_text}
                                   value={this.state.Data[n.id]}
                                   disabled
                                   onChange={this.handleChange(n.id)}
                                 />
                               ) : (
                                 <Input
                                   style={{ width: '100%' }}
                                   id={n.id}
                                   label={n.heading_text}
                                   value={this.state.Data[n.id]}
                                   onChange={this.handleChange(n.id)}
                                 />
                               )}
                             </div>
                           )
                         } else if (n.type === 'textfield-endicon') {
                           return (
                             <div key={index}>
                               <div className='runnote-grp-body-cb-head'>{n.heading_text}</div>
                               {n.mandatory === true ? (
                                 this.state.Data[n.id] !== '' && this.state.Data[n.id] !== undefined ? (
                                   <div className='runnote-grp-marker runnote-marker-true' />
                                 ) : (
                                   <div className='runnote-grp-marker runnote-marker-false' />
                                 )
                               ) : null}
                               <Input
                                 style={{ width: '100%' }}
                                 id={n.id}
                                 label={n.heading_text}
                                 value={this.state.Data[n.id]}
                                 onChange={this.handleChange(n.id)}
                                 endAdornment={<InputAdornment position='end'>{n.iconTextEnd}</InputAdornment>}
                               />
                             </div>
                           )
                         } else if (n.type === 'textfieldGroup') {
                           const temp = n.value

                           if (temp.length > 0) {
                             return temp.map((ne, indexB) => {
                               return (
                                 <div key={indexB}>
                                   <div>
                                     <div className='runnote-grp-body-cb-head'>{n.heading_text}</div>
                                     {n.mandatory === true ? (
                                       this.state.Data[n.id] !== '' && this.state.Data[n.id] !== undefined ? (
                                         <div className='runnote-grp-marker runnote-marker-true' />
                                       ) : (
                                         <div className='runnote-grp-marker runnote-marker-false' />
                                       )
                                     ) : null}
                                     <Input
                                       style={{ width: '100%' }}
                                       id={ne.id}
                                       label={n.heading_text}
                                       value={this.state.Data[n.id][indexB]}
                                       onChange={this.handleChangeGroup(n.id, indexB)}
                                     />
                                     <Button onClick={this.handleDeleteGroup.bind(this, n.id, indexB)}> X </Button>
                                   </div>
                                 </div>
                               )
                             })
                           }
                         } else if (n.type === 'checkbox') {
                           return (
                             <div key={index}>
                               {n.id === 'Decrease_Check' ? (
                                 this.state.checkMandatory === true ? (
                                   <div>
                                     <div className='runnote-grp-body-cb-head'>{n.heading_text}</div>

                                     {/*eslint-disable */ 
                                    n.mandatory === true ? (
                                      this.state.Data[n.id] !== '' &&
                                      this.state.Data[n.id] !== false &&
                                      this.state.Data[n.id] !== undefined ? (
                                          <div className='runnote-grp-marker runnote-marker-true' />
                                        ) : (
                                          <div className='runnote-grp-marker runnote-marker-false' />
                                        )
                                    ) : null}

                                    <Checkbox
                                      className='runnote-grp-body-cb-cb'
                                      checked={this.state.Data[n.id]}
                                      onChange={this.handleChangeCheck(n.id)}
                                      value={n.id}
                                      color='primary'
                                    />
                                    {n.after_text}
                                  </div>
                                ) : (
                                  <div>
                                    <div className='runnote-grp-body-cb-head'>{n.heading_text}</div>
                                    {n.mandatory === true ? (
                                      this.state.Data[n.id] !== '' &&
                                      this.state.Data[n.id] !== false &&
                                      this.state.Data[n.id] !== undefined ? (
                                          <div className='runnote-grp-marker runnote-marker-true' /> 
                                        ) : (
                                          <div className='runnote-grp-marker runnote-marker-false' />
                                        )
                                    ) : null}
                                    <Checkbox
                                      className='runnote-grp-body-cb-cb'
                                      checked={this.state.Data[n.id]}
                                      onChange={this.handleChangeCheck(n.id)}
                                      value={n.id}
                                      color='primary'
                                      disabled
                                    />
                                    {n.after_text} "unvollständig"
                                  </div>
                                )
                              ) : (
                                <div>
                                  <div className='runnote-grp-body-cb-head'>{n.heading_text}</div>

                                  {n.mandatory === true ? (
                                    this.state.Data[n.id] !== '' &&
                                    this.state.Data[n.id] !== false &&
                                    this.state.Data[n.id] !== undefined ? (
                                      <div className='runnote-grp-marker runnote-marker-true' />
                                      ) : (
                                        <div className='runnote-grp-marker runnote-marker-false' />
                                      )
                                  ) : null}

                                  {n.dependencies === 'oneGrp' ? (
                                    this.state.checkOne === true ? (
                                      <div className='runnote-grp-marker runnote-marker-true' />
                                    ) : (
                                      <div className='runnote-grp-marker runnote-marker-false' />
                                    )
                                  ) : null}

                                  {n.dependencies === 'twoGrp' ? (
                                    this.state.checkTwo === true ? (
                                      <div className='runnote-grp-marker runnote-marker-true' />
                                    ) : (
                                      <div className='runnote-grp-marker runnote-marker-false' />
                                    )
                                  ) : null}

                                  {n.dependencies === 'threeGrp' ? (
                                    this.state.checkThree === true ? (
                                      <div className='runnote-grp-marker runnote-marker-true' />
                                    ) : (
                                      <div className='runnote-grp-marker runnote-marker-false' />
                                    )
                                  ) : null /* eslint-enable */}
                                  <Checkbox
                                    className='runnote-grp-body-cb-cb'
                                    checked={this.state.Data[n.id]}
                                    onChange={this.handleChangeCheck(n.id)}
                                    value={n.id}
                                    color='primary'
                                  />
                                  {n.after_text}
                                </div>
                               )}
                             </div>
                           )
                         } else if (n.type === 'checkboxGroup') {
                           return (
                             <div key={index}>
                               {n.mandatory === true ? (
                                 this.state.Data[n.id] !== '' && this.state.Data[n.id] !== undefined ? (
                                   <div className='runnote-grp-marker runnote-grp-marker-grp runnote-marker-true' />
                                 ) : (
                                   <div className='runnote-grp-marker runnote-grp-marker-grp runnote-marker-false' />
                                 )
                               ) : null}

                               <FormControl component='fieldset' className='runnote-checkboxGroup-padding' required>
                                 <div className='runnote-grp-body-cb-head'>{n.heading_text}</div>
                                 <RadioGroup
                                   value={this.state.Data[n.id]}
                                   onChange={this.handleChange(n.id)}
                                   className='runnote-checkboxGroup'
                                 >
                                   {n.value.map((n, index2) => {
                                     return <FormControlLabel key={index2} value={n} control={<Radio />} label={n} />
                                   })}
                                 </RadioGroup>
                               </FormControl>
                             </div>
                           )
                         } else if (n.type === 'dropdown') {
                           return (
                             <div key={index} className='runnote-grp-dd'>
                               <div className='runnote-grp-body-cb-head'>{n.heading_text}</div>
                               {n.mandatory === true ? (
                                 this.state.Data[n.id] !== '' && this.state.Data[n.id] !== undefined ? (
                                   <div className='runnote-grp-marker runnote-marker-true' />
                                 ) : (
                                   <div className='runnote-grp-marker runnote-marker-false' />
                                 )
                               ) : null}
                               <Input
                                 className='runnote-grp-body-dd-input'
                                 id={n.id}
                                 label={n.heading_text}
                                 value={this.state.Data[n.id]}
                                 onChange={this.handleChange(n.id)}
                               />
                               <FormControl className='runnote-grp-body-dd'>
                                 <Select native onChange={this.handleChange([n.id])} className='runnote-grp-body-dd-dd'>
                                   <option value='' />

                                   {n.value.map((n, index2) => {
                                     return (
                                       <option className='runnote-grp-body-dd-dd-disablecolor' key={index2} value={n}>
                                         {n}
                                       </option>
                                     )
                                   })}
                                 </Select>
                               </FormControl>
                             </div>
                           )
                         } else if (n.type === 'dropdownOnly') {
                           return (
                             <div key={index}>
                               {n.mandatory === true ? (
                                 this.state.Data[n.id] !== '' && this.state.Data[n.id] !== undefined ? (
                                   <div className='runnote-grp-marker runnote-grp-marker-drop runnote-marker-true' />
                                 ) : (
                                   <div className='runnote-grp-marker runnote-grp-marker-drop runnote-marker-false' />
                                 )
                               ) : null}

                               <FormControl>
                                 <div className='runnote-grp-body-cb-head'>{n.heading_text}</div>
                                 <NativeSelect value={this.state.Data[n.id]} onChange={this.handleChange(n.id)}>
                                   {n.withoutspace === true ? null : <option value='' />}

                                   {n.value.map((n, indexx) => {
                                     return (
                                       <option key={indexx} value={n}>
                                         {n} GB
                                       </option>
                                     )
                                   })}
                                 </NativeSelect>
                               </FormControl>
                             </div>
                           )
                         } else if (n.type === 'multiline') {
                           return (
                             <div key={index}>
                               <div className='runnote-grp-body-cb-head'>{n.heading_text}</div>
                               {n.mandatory === true ? (
                                 this.state.Data[n.id] !== '' && this.state.Data[n.id] !== undefined ? (
                                   <div className='runnote-grp-marker runnote-marker-true' />
                                 ) : (
                                   <div className='runnote-grp-marker runnote-marker-false' />
                                 )
                               ) : null}
                               <TextField
                                 id={n.id}
                                 label={n.label}
                                 multiline
                                 className='anti-margin-top'
                                 style={{ width: '100%' }}
                                 rowsMax='10'
                                 value={this.state.Data[n.id]}
                                 onChange={this.handleChange(n.id)}
                                 margin='normal'
                               />
                             </div>
                           )
                         } else if (n.type === 'text') {
                           return (
                             <div className='runnote-grp-module-text' key={index}>
                               {n.heading_text}
                             </div>
                           )
                         } else if (n.type === 'tableCheck') {
                           // Check runnote releas
                           if (this.state.Data && this.state.Data.Decrease_Date) {
                             const exDate = new Date('2019-10-13T12:00:30')
                             const createDate = new Date(this.state.Data.Decrease_Date)
                             if (exDate > createDate) {
                               return (
                                 <div style={{ textAlign: 'center', padding: '10px', backgroundColor: '#ffd2d2', marginTop: '10px' }} key={index}>
                                   <div>Zum Zeitpunkt der Abnahme dieses Laufzettels gab es diese Funktion noch nicht.</div>
                                   <div style={{ paddingTop: '10px' }}>Daher gibt es keine Daten hierzu.</div>
                                 </div>
                               )
                             }
                           }

                           return (
                             <div style={{ marginTop: '10px' }} key={index}>
                               {this.state.Data[n.id] ? (
                                 <table style={{ width: '100%' }}>
                                   <tbody style={{ backgroundColor: '#fff' }}>
                                     <tr>
                                       <td className='runnote-grp-module-table-td'>Club ID</td>
                                       <td className='runnote-grp-module-table-td'>
                                         {this.state.Data[n.id].clubId}
                                       </td>
                                     </tr>
                                     <tr>
                                       <td className='runnote-grp-module-table-td'>Club Name</td>
                                       <td className='runnote-grp-module-table-td'>
                                         {this.state.Data[n.id].clubName}
                                       </td>
                                     </tr>
                                     <tr>
                                       <td className='runnote-grp-module-table-td'>Addresse</td>
                                       <td className='runnote-grp-module-table-td'>
                                         {this.state.Data[n.id].placeId}
                                       </td>
                                     </tr>
                                     <tr>
                                       <td className='runnote-grp-module-table-td'>Club has System</td>
                                       <td className='runnote-grp-module-table-td'>
                                         {this.state.Data[n.id].clubHasSystem}
                                       </td>
                                     </tr>
                                     <tr>
                                       <td className='runnote-grp-module-table-td'>Stichting</td>
                                       <td className='runnote-grp-module-table-td'>
                                         {this.state.Data[n.id].stichting}
                                       </td>
                                     </tr>
                                     <tr>
                                       <td className='runnote-grp-module-table-td'>Latitude, Longitude</td>
                                       <td className='runnote-grp-module-table-td'>
                                         <a style={{ color: '#FF3517' }} href={'https://maps.google.com/?q=' + this.state.Data[n.id].latitude + ',' + this.state.Data[n.id].longitude} target='blank'>
                                           {this.state.Data[n.id].latitude} / {this.state.Data[n.id].longitude}
                                         </a>
                                       </td>
                                     </tr>

                                     <tr>
                                       <td className='runnote-grp-module-table-td'>Systeminhaber</td>
                                       <td className='runnote-grp-module-table-td'>
                                         {this.state.Data[n.id].systemOwner}
                                       </td>
                                     </tr>

                                     <tr>
                                       <td className='runnote-grp-module-table-td'>defaultWatermark</td>
                                       <td className='runnote-grp-module-table-td'>
                                         {this.state.Data[n.id].defaultWatermark}
                                       </td>
                                     </tr>

                                     <tr>
                                       <td className='runnote-grp-module-table-td'>defaultEventType</td>
                                       <td className='runnote-grp-module-table-td'>
                                         {this.state.Data[n.id].defaultEventType}
                                       </td>
                                     </tr>
                                   </tbody>
                                 </table>
                               ) : null}
                               <Button onClick={() => {
                                 this.handleReloadCameraClubDetails()
                                 this.handleReloadCameraOwner()
                               }}
                               >Information neu laden
                               </Button>
                             </div>
                           )
                         } else if (n.type === 'tableCheckOwner') {
                           // Check runnote releas
                           if (this.state.Data && this.state.Data.Decrease_Date) {
                             const exDate = new Date('2020-08-24T12:00:30')
                             const createDate = new Date(this.state.Data.Decrease_Date)
                             if (exDate > createDate) {
                               return (
                                 <div style={{ textAlign: 'center', padding: '10px', backgroundColor: '#ffd2d2', marginTop: '10px' }} key={index}>
                                   <div>Zum Zeitpunkt der Abnahme dieses Laufzettels gab es diese Funktion noch nicht.</div>
                                   <div style={{ paddingTop: '10px' }}>Daher gibt es keine Daten hierzu.</div>
                                 </div>
                               )
                             }
                           }

                           return (
                             <div style={{ marginTop: '10px' }} key={index}>
                               {this.state.Data[n.id] ? (
                                 <table style={{ width: '100%' }}>
                                   <tbody style={{ backgroundColor: '#fff' }}>
                                     <tr>
                                       <td className='runnote-grp-module-table-td'>Systeminhaber</td>
                                       <td className='runnote-grp-module-table-td'>
                                         {this.state.Data[n.id].systemOwner}
                                       </td>
                                     </tr>
                                   </tbody>
                                 </table>
                               ) : null}
                               <Button onClick={this.handleReloadCameraOwner}>Information neu laden</Button>
                             </div>
                           )
                         } else if (n.type === 'date') {
                           return n.disabled ? (
                             <div key={index}>
                               <div className='runnote-grp-body-cb-head'>{n.heading_text}</div>
                               {n.mandatory === true ? (
                                 this.state.Data[n.id] !== '' && this.state.Data[n.id] !== undefined ? (
                                   <div className='runnote-grp-marker runnote-marker-true' />
                                 ) : (
                                   <div className='runnote-grp-marker runnote-marker-false' />
                                 )
                               ) : null}
                               <TextField
                                 id={n.id}
                                 label={n.label}
                                 type='date'
                                 disabled
                                 className='anti-margin-top'
                                 style={{ width: '100%' }}
                                 value={this.state.Data[n.id]}
                                 onChange={this.handleChange(n.id)}
                                 margin='normal'
                               />
                             </div>
                           ) : (
                             <div key={index}>
                               <div className='runnote-grp-body-cb-head'>{n.heading_text}</div>
                               {n.mandatory === true ? (
                                 this.state.Data[n.id] !== '' && this.state.Data[n.id] !== undefined ? (
                                   <div className='runnote-grp-marker runnote-marker-true' />
                                 ) : (
                                   <div className='runnote-grp-marker runnote-marker-false' />
                                 )
                               ) : null}
                               {this.state.Data[n.id] === '' ? (
                                 <TextField
                                   id={n.id}
                                   label={n.label}
                                   type='date'
                                   className='anti-margin-top'
                                   style={{ width: '100%' }}
                                   // value={currentDate}
                                   onChange={this.handleChange(n.id)}
                                   margin='normal'
                                 />
                               ) : (
                                 <TextField
                                   id={n.id}
                                   label={n.label}
                                   type='date'
                                   className='anti-margin-top'
                                   style={{ width: '100%' }}
                                   value={this.state.Data[n.id]}
                                   onChange={this.handleChange(n.id)}
                                   margin='normal'
                                 />
                               )}
                             </div>
                           )
                         } else if (n.type === 'datetime') {
                           return (
                             <div key={index}>
                               <div className='runnote-grp-body-cb-head'>{n.heading_text}</div>
                               {n.mandatory === true ? (
                                 this.state.Data[n.id] !== '' && this.state.Data[n.id] !== undefined ? (
                                   <div className='runnote-grp-marker runnote-marker-true' />
                                 ) : (
                                   <div className='runnote-grp-marker runnote-marker-false' />
                                 )
                               ) : null}
                               {n.disabled ? (
                                 this.state.Data[n.id] === '' && this.state.Data[n.id] !== undefined ? (
                                   <div>
                                     <TextField
                                       id={n.id}
                                       disabled
                                       label={n.label}
                                       type='datetime-local'
                                       className='anti-margin-top'
                                       style={{ width: '100%' }}
                                       // value={currentDateTime}
                                       onChange={this.handleChange(n.id)}
                                       margin='normal'
                                     />
                                   </div>
                                 ) : (
                                   <div>
                                     <TextField
                                       id={n.id}
                                       disabled
                                       label={n.label}
                                       type='datetime-local'
                                       className='anti-margin-top'
                                       style={{ width: '100%' }}
                                       value={this.state.Data[n.id]}
                                       onChange={this.handleChange(n.id)}
                                       margin='normal'
                                     />
                                   </div>
                                 )
                               ) : this.state.Data[n.id] === '' && this.state.Data[n.id] !== undefined ? (
                                 <div>
                                   <TextField
                                     id={n.id}
                                     label={n.label}
                                     type='datetime-local'
                                     className='anti-margin-top'
                                     style={{ width: '100%' }}
                                     // value={currentDateTime}
                                     onChange={this.handleChange(n.id)}
                                     margin='normal'
                                   />
                                 </div>
                               ) : (
                                 <div>
                                   <TextField
                                     id={n.id}
                                     label={n.label}
                                     type='datetime-local'
                                     className='anti-margin-top'
                                     style={{ width: '100%' }}
                                     value={this.state.Data[n.id]}
                                     onChange={this.handleChange(n.id)}
                                     margin='normal'
                                   />
                                 </div>
                               )}
                             </div>
                           )
                         } else if (n.type === 'qrCodeGenerate') {
                           return (
                             <div key={index}>
                               <QRCode
                                 cameraID={this.props.camId}
                                 value={this.state.Data[n.id]}
                                 onChange={this.handleChange(n.id)}
                                 disabled={this.props.Data && this.state.editRunnotes === false && this.props.Data.data.Decrease_Check}
                               />
                             </div>
                           )
                         } else if (n.type === 'qrRemove') {
                           return (
                             <div key={index}>
                               <QRCode
                                 cameraID={this.props.camId}
                                 removeQrCode
                               />
                             </div>
                           )
                         } else if (n.type === 'tooltip') {
                           return (
                             <div key={index}>
                               <div className='runnote-grp-body-cb-head runnote-tooltip'>{n.heading_text}</div>
                             </div>
                           )
                         } else if (n.type === 'tooltipAnnotation' && finalInfoText !== undefined) {
                           return (
                             <div key={index}>
                               <div>vorherigen Laufzettel: {finalInfoTextFrom}</div>
                               <div className='runnote-grp-body-cb-head runnote-tooltip'>{finalInfoText}</div>
                             </div>
                           )
                         }

                         return null
                       })}
                     </div>
                   </div>
                 )
               })
             }
             return null
           })
         })}

         {this.props.Data !== undefined ? (
           this.props.Data.data.Decrease_Check === true ? (
             <Grid style={{ padding: '6px' }} container spacing={8}>
               {this.props.renderValue === 'montage' ? (
                 <Grid item xs={12} sm={6}>
                   {this.state.checkEditor ? (
                     <Button
                       fullWidth
                       variant='contained'
                       color='primary'
                       className='btn'
                       onClick={this.handleEditRunnotes.bind(this)}
                     >
                       {' '}
                      Bearbeiten{' '}
                     </Button>
                   ) : (
                     <Button
                       disabled
                       fullWidth
                       variant='contained'
                       color='primary'
                       className='btn'
                       onClick={this.handleApiCall.bind(this)}
                     >
                       {' '}
                      Bearbeiten{' '}
                     </Button>
                   )}
                 </Grid>
               ) : (
                 <Grid item xs={12} sm={6} />
               )}
               <Grid item xs={12} sm={6}>
                 {this.state.editRunnotes ? (
                   <Button
                     fullWidth
                     variant='contained'
                     color='primary'
                     className='btn'
                     onClick={this.handleApiCall.bind(this)}
                   >
                     {' '}
                    Editierte Speichern{' '}
                   </Button>
                 ) : (
                   <Button
                     disabled
                     fullWidth
                     variant='contained'
                     color='primary'
                     className='btn'
                     onClick={this.handleApiCall.bind(this)}
                   >
                     {' '}
                    Speichern{' '}
                   </Button>
                 )}
               </Grid>
             </Grid>
           ) : (
             <Grid style={{ padding: '6px' }} container spacing={8}>
               <Grid item xs={12} sm={6}>
                 <Button
                   fullWidth
                   variant='contained'
                   color='primary'
                   className='btn'
                   onClick={this.handleDeleteRunnotes.bind(this)}
                 >
                  löschen
                 </Button>
               </Grid>
               <Grid item xs={12} sm={6}>
                 {this.state.Data.Decrease_Check === true ? (
                   <Button
                     fullWidth
                     variant='contained'
                     style={{ backgroundColor: '#D20117' }}
                     className='btn'
                     onClick={this.handleApiCall.bind(this)}
                   >
                     {' '}
                    PROZESS ABSCHLIESSEN{' '}
                   </Button>
                 ) : (
                   <Button
                     fullWidth
                     variant='contained'
                     color='primary'
                     className='btn'
                     onClick={this.handleApiCall.bind(this)}
                   >
                     {' '}
                    Speichern{' '}
                   </Button>
                 )}
               </Grid>
             </Grid>
           )
         ) : (
           <Grid style={{ padding: '6px' }} container spacing={0}>
             {this.state.Data.Decrease_Check === true ? (
               <Button
                 fullWidth
                 variant='contained'
                 style={{ backgroundColor: '#D20117' }}
                 className='btn'
                 onClick={this.handleApiCall.bind(this)}
               >
                 {' '}
                PROZESS ABSCHLIESSEN{' '}
               </Button>
             ) : (
               <Button
                 fullWidth
                 variant='contained'
                 color='primary'
                 className='btn'
                 onClick={this.handleApiCall.bind(this)}
               >
                 {' '}
                Speichern{' '}
               </Button>
             )}
           </Grid>
         )}
       </div>
     )
   }
}

class RunnoteList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      Data: [],
      open: false,
      notes: '',
      noteIndex: '',
      checkNotes: [],
      loading: false
    }
  }

  componentDidMount () {
    this.setState({ loading: true })
    getDocket('swcs-' + this.props.camId, '1337').then((res) => {
      if (res.status === 200) {
        const temp = res.data

        temp.sort(function (a, b) {
          return +new Date(b.dateCreate) - +new Date(a.dateCreate)
        })

        this.setState({
          Data: temp,
          loading: false
        })
      } else {
        this.handleClose()
        this.setState({ loading: false })
      }
    })
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  };

  handleClose = () => {
    this.setState({ open: false })
  };

  handleNoteDialog (value) {
    this.setState({
      notes: value
    })

    this.handleClose()
  }

  handleDeleteGroup = (name, id) => {
    const recipes = Object.assign({}, this.state.Data[name])
    delete recipes[id]

    this.setState({
      Data: {
        ...this.state.Data,
        [name]: { ...this.state.recipes }
      }
    })
  };

  dateValid (Timestamp) {
    const tempDate = new Date(Timestamp)
    const date =
      ('0' + tempDate.getDate()).slice(-2) +
      '.' +
      ('0' + (tempDate.getMonth() + 1)).slice(-2) +
      '.' +
      tempDate.getFullYear()
    const time =
      ('0' + tempDate.getHours()).slice(-2) +
      ':' +
      ('0' + tempDate.getMinutes()).slice(-2) +
      ':' +
      ('0' + tempDate.getSeconds()).slice(-2)
    return date + ' / ' + time
  }

  handleOpenNote = (RowKey, index) => {
    const splitKey = RowKey.split('-')

    this.setState({ noteIndex: index, notes: splitKey[0] })
  };

  handleClear = () => {
    this.setState({ notes: '', noteIndex: '' })
    this.componentDidMount()
  };

  changeText = (value, only) => {
    let temp
    const split = value.split('-')

    describe.grp.map((n) => {
      return n.content.map((x) => {
        if (x.value === split[0]) {
          if (only !== true) {
            return (temp = (
              <div>
                <div className='runnote-banner-icon' style={{ float: 'left' }}>
                  {String(Number(split[1]) + 1)}
                </div>
                <div style={{ float: 'left' }}>{x.text}</div>
              </div>
            ))
          } else {
            return (temp = x.text)
          }
        }
        return null
      })
    })
    return temp
  };

  componentDidUpdate () {
    let tempDataSearch = Array.from(this.state.Data)

    let noteRight = [false, false, false, false, false, false, false, false, false, false, false]

    if (tempDataSearch.length > 0) {
      const splitRowKey = tempDataSearch[0].RowKey.split('-')

      // filter last dockets
      if (splitRowKey[1] > 0) {
        tempDataSearch = tempDataSearch.filter(itm => {
          const split = itm.RowKey.split('-')[1]
          return split === splitRowKey[1]
        })
      }

      if (splitRowKey[0] === 'montage') {
        if (tempDataSearch[0].data.Decrease_Check) {
          if (tempDataSearch[0].data.Commissioning_Check === 'FREIGEGEBEN') {
            noteRight = [false, true, true, true, true, false, false, false, false, false, true]
          } else {
            noteRight = [false, true, false, false, false, false, true, false, false, false, true]
          }
        }
      }

      if (splitRowKey[0] === 'installAdmin' || splitRowKey[0] === 'install' || splitRowKey[0] === 'delivery') {
        noteRight = [false, true, true, true, true, false, false, false, false, false, true]

        let checkDock = false
        let checkXor = false
        tempDataSearch.map(docket => {
          const name = docket.RowKey
          if (name.search('install-') !== -1) {
            noteRight[2] = false
            //noteRight[3] = false
            if (docket.data.Decrease_Check) checkXor = true
          }

          

          if (name.search('installAdmin-') !== -1) {
            noteRight[4] = false
            console.log('?')
            if (docket.data.Decrease_Check) checkXor = true
          }

          if (name.search('delivery-') !== -1) {
            // noteRight[2] = false
            noteRight[3] = false
            console.log('???', noteRight)
            if (docket.data.Decrease_Check) checkXor = true
          }

          return null
        })

        if (checkXor) {
          //noteRight = [false, true, false, true, false, true, false, true, true, true, true]
          noteRight[0] = false
          noteRight[1] = true
          noteRight[5] = true
          noteRight[6] = false
          noteRight[7] = true
          noteRight[8] = true
          noteRight[9] = true
          noteRight[10] = true
        } else {
          noteRight = [false, false, false, false, false, false, false, false, false, false, false]
        }
      }

      if (splitRowKey[0] === 'stock') {
        if (tempDataSearch[0].data.Decrease_Check) {
          noteRight = [false, true, true, true, true, false, false, false, false, false, false]
        } else {
          noteRight = [false, false, false, false, false, false, false, false, false, false, false]
        }


        const reworkCheck = tempDataSearch[1].RowKey.split('-')[0]
        let tempRework = tempDataSearch
        if (reworkCheck) {
          tempRework = tempDataSearch.filter(itm => {
            const split = itm.RowKey.split('-')[0]
            return split === reworkCheck
          })
        }
        // temp check rework
        if(reworkCheck === 'rework' || reworkCheck === 'montage') {
          if(tempRework[0].data.Commissioning_Check === 'NICHT FREIGEGEBEN') {
            noteRight = [false, reworkCheck === 'montage',  false, false, false, false, true, false, false, false, false]
          }
        }
      }

      if (splitRowKey[0] === 'rework') {
        if (tempDataSearch[0].data.Decrease_Check) {
          if (tempDataSearch[0].data.Commissioning_Check === 'FREIGEGEBEN') {
            noteRight = [false, false, true, true, true, false, false, false, false, false, true]
          } else {
            noteRight = [false, false,  false, false, false, false, true, false, false, false, true]
          }
        } else {
          noteRight = [false, false, false, false, false, false, false, false, false, false, false]
        }
      }

      if (splitRowKey[0] === 'repair' || splitRowKey[0] === 'deinstall' || splitRowKey[0] === 'takeback') {
        let checkXor = false
        noteRight = [false, true, false, false, false, true, false, true, true, false, true]

        tempDataSearch.map(docket => {
          const name = docket.RowKey
          if (name.search('deinstall-') !== -1) {
            noteRight[7] = false
            if (docket.data.Decrease_Check) checkXor = true
          }

          if (name.search('takeback-') !== -1) {
            noteRight[8] = false
            if (docket.data.Decrease_Check) checkXor = true
          }
          return null
        })

        if (checkXor) {
          noteRight = [false, true, false, false, false, false, false, true, false, true, true]
        }
      }

      if (splitRowKey[0] === 'deinstallAdmin') {
        if (tempDataSearch[0].data.Decrease_Check) {
          if (tempDataSearch[0].data.Proceed_Approved === 'FREIGEGEBEN') {
            noteRight = [true, true, false, false, false, false, false, false, false, false, true]
          } else {
            noteRight = [false, true, false, false, false, false, true, false, false, false, true]
          }
        }
      }

      if (splitRowKey[0] === 'troubleshooting') {
        if (tempDataSearch[0].data.Decrease_Check) {
          if (tempDataSearch[0].data.Proceed_Approved === 'FREIGEGEBEN') {
            noteRight = [true, true, false, false, false, false, false, false, false, false, true]
          } else {
            noteRight = [false, true, false, false, false, false, false, false, false, false, true]
          }
        }
      }
    } else {
      noteRight = [true, true, false, false, false, false, false, false, false, false, true]
    }

    if (JSON.stringify(this.state.checkNotes) !== JSON.stringify(noteRight)) {
      this.setState({ checkNotes: noteRight })
    }
  }

  render () {
    let indexCounter = 0
    this.state.Data.sort((a, b) => {
      return new Date(b.data.dateCreate) - new Date(a.data.dateCreate)
    })

    return (
      <div>
        <div className='runnote-list'>
          {this.state.notes === '' ? (
            <div>
              <div className='runnote-list-headtext'>Laufzettel Kamera: {this.props.camId}</div>
              <Fab className='runnote-list-btn' color='primary' onClick={this.handleClickOpen}>
                +
              </Fab>
            </div>
          ) : (
            <div>
              <div className='runnote-list-headtextinfo'>Kamera: {this.props.camId}</div>
              <div className='runnote-list-headinfo'>{this.changeText(this.state.notes, true)}</div>
              <Button
                variant='contained'
                className='runnote-list-btn'
                color='primary'
                onClick={this.handleClear.bind(this)}
              >
                zurück
              </Button>
            </div>
          )}

          <Dialog disableBackdropClick disableEscapeKeyDown open={this.state.open} onClose={this.handleClose}>
            <DialogTitle>Laufzettel auswahl</DialogTitle>
            <DialogContent>
              <form>
                {describe.grp.map((n, index) => {
                  return (
                    <div className='runnote-list-dialog' key={index}>
                      <div className='runnote-list-dialog-category'>{n.name}</div>
                      <div>
                        {n.content.map((n, indexX) => {
                          if (this.state.checkNotes[indexCounter] === true) {
                            indexCounter++
                            return (
                              <div
                                onClick={this.handleNoteDialog.bind(this, n.value)}
                                key={indexX}
                                className='runnote-list-dialog-category-grp'
                              >
                                {n.name}
                              </div>
                            )
                          } else {
                            indexCounter++
                            return (
                              <div key={indexX} className='runnote-list-dialog-category-grp-false'>
                                {n.name}
                              </div>
                            )
                          }
                        })}
                      </div>
                    </div>
                  )
                })}
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color='primary'>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        {this.state.notes === '' ? (
          <div>
            {this.state.Data.map((n, index) => {
              const tempRowKey = n.RowKey.split('-')
              let css = 'runnote-grp-line-grey'

              if (tempRowKey[0] === 'repair' || tempRowKey[0] === 'troubleshooting') {
                css = 'runnote-grp-line-yellow'
              }

              if (tempRowKey[0] === 'deinstall' || tempRowKey[0] === 'deinstallAdmin' || tempRowKey[0] === 'takeback') {
                css = 'runnote-grp-line-red'
              }

              if (n.data.Decrease_Check !== true) {
                return (
                  <div key={index}>
                    <div style={{ padding: '16px 20px 10px' }}>Aktueller Vorgang</div>
                    <div
                      onClick={this.handleOpenNote.bind(this, n.RowKey, index)}
                      className={'runnote-grp-line ' + css}
                    >
                      <div className='runnote-grp-line-index'>{this.changeText(n.RowKey)}</div>
                      <div className='runnote-grp-line-time'>{this.dateValid(n.data.dateCreate)}</div>
                    </div>
                  </div>
                )
              }

              return null
            })}

            {this.state.loading ? (
              circularProgressCenter(60)
            ) : (
              <>
                <div style={{ padding: '10px 20px 10px' }}>Chronik</div>
                {this.state.Data.map((n, index) => {
                  const tempRowKey = n.RowKey.split('-')
                  let css = 'runnote-grp-line-grey'

                  if (tempRowKey[0] === 'repair' || tempRowKey[0] === 'troubleshooting') {
                    css = 'runnote-grp-line-yellow'
                  }

                  if (tempRowKey[0] === 'deinstall' || tempRowKey[0] === 'deinstallAdmin' || tempRowKey[0] === 'takeback') {
                    css = 'runnote-grp-line-red'
                  }

                  if (n.data.Decrease_Check === true) {
                    return (
                      <div
                        key={index}
                        onClick={this.handleOpenNote.bind(this, n.RowKey, index)}
                        className={'runnote-grp-line ' + css}
                      >
                        <div className='runnote-grp-line-index'>
                          {this.changeText(n.RowKey)} <FontAwesomeIcon className='runnote-grp-check-icon' icon={faCheck} />
                        </div>
                        <div className='runnote-grp-line-time'>{this.dateValid(n.data.dateCreate)}</div>
                      </div>
                    )
                  }

                  return null
                })}
              </>
            )}

          </div>
        ) : this.state.noteIndex === '' ? (
          <div>
            <NoteRender
              renderValue={this.state.notes}
              camId={this.props.camId}
              dataCall={this.state.Data}
              handleClear={this.handleClear.bind(this)}
              notes={this.state.Data}
            />
          </div>
        ) : (
          <div>
            <NoteRender
              renderValue={this.state.notes}
              camId={this.props.camId}
              Data={this.state.Data[this.state.noteIndex]}
              handleClear={this.handleClear.bind(this)}
              notes={this.state.Data}
            />
          </div>
        )}
      </div>
    )
  }
}

class Runnote extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: this.props.openRunnotes
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  };

  handleClose = () => {
    this.setState({ open: false })
  };

  render () {
    return (
      <div className={!this.props.secondColor ? 'camera-btn-heigh' : 'camera-btn-heigh-o'}>
        {this.props.disabled === true ? (
          <Button
            onClick={this.handleClickOpen}
            color='primary'
            variant='contained'
            disabled
            className='camera-btn camera-btn-disable'
          >
            {this.props.btnname}
          </Button>
        ) : (
          <Button onClick={this.handleClickOpen} color='primary' variant='contained' className='camera-btn'>
            {this.props.btnname}
          </Button>
        )}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          className='runnote-overlay'
          aria-labelledby='alert-dialog-title'
          fullWidth
          maxWidth='sm'
        >
          <RunnoteList camId={this.props.camId} />
        </Dialog>
      </div>
    )
  }
}

export default Runnote
