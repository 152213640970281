import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'

import { ResponsivePie } from '@nivo/pie'

import '../dashboard.css'

class CameraChart extends Component {
  constructor (props) {
    super(props)

    this.state = {
      state: [],
      number: 0
    }
  }

  handleUrl (param) {
    let url

    if (param.label === 'idle' || param.label === 'capturing') {
      url = '/cameras/currentTask=' + param.label + '&lifecircleState=' + this.props.headText
    } else {
      url = '/cameras/systemState=' + param.label + '&lifecircleState=' + this.props.headText
    }

    if (url !== undefined) {
      window.open(url)
    }
  }

  componentDidMount () {
    const data = []

    if (this.props.counter.initial > 0) {
      data.push({
        id: 'initial',
        label: 'initial',
        value: this.props.counter.initial || 0,
        color: 'hsl(210, 7.5%, 21%)'
      })
    }

    if (this.props.counter.active_idle > 0) {
      data.push({
        id: 'idle',
        label: 'idle',
        value: this.props.counter.active_idle || 0,
        color: 'hsl(92, 70%, 50%)'
      })
    }

    if (this.props.counter.active_capturing > 0) {
      data.push({
        id: 'capturing',
        label: 'capturing',
        value: this.props.counter.active_capturing || 0,
        color: 'hsl(92, 70%, 50%)'
      })
    }

    if (this.props.counter.no_power > 0) {
      data.push({
        id: 'no power',
        label: 'no_power',
        value: this.props.counter.no_power || 0,
        color: 'hsl(41, 70%, 50%)'
      })
    }

    if (this.props.counter.connection_lost > 0) {
      data.push({
        id: 'connection lost',
        label: 'connection_lost',
        value: this.props.counter.connection_lost || 0,
        color: 'hsl(0, 97%, 72%)'
      })
    }

    if (this.props.counter.defect > 0) {
      data.push({
        id: 'defect',
        label: 'defect',
        value: this.props.counter.defect || 0,
        color: 'hsl(0, 97%, 50%)'
      })
    }

    if (this.props.counter.not_existing > 0) {
      data.push({
        id: 'not existing',
        label: 'not_existing',
        value: this.props.counter.not_existing || 0,
        color: 'hsl(0, 100%, 36.7%)'
      })
    }

    this.setState({
      state: data
    })
  }

  render () {
    const number = this.props.counter.number
    return (
      <div>
        {this.state.state.length <= 0 ? null : (
          <Paper>
            <div className='layout-head'>{this.props.headText}</div>
            <div className='cameraChart'>
              <ResponsivePie
                data={this.state.state}
                margin={{
                  top: 40,
                  right: 90,
                  bottom: 40,
                  left: 90
                }}
                startAngle={45}
                endAngle={315}
                innerRadius={0.7}
                padAngle={2}
                onClick={this.handleUrl.bind(this)}
                // colors={{ scheme: 'nivo' }}
                colors={d => d.data.color}
                // colorBy='id'
                borderWidth={1}
                borderColor='inherit:darker(0.2)'
                radialLabel={function (e) {
                  const round = (100 / number) * e.value
                  const percent = round.toFixed(1)
                  return e.id + ' (' + e.value + ' | ' + percent + '%)'
                }}
                radialLabelsSkipAngle={2}
                radialLabelsTextXOffset={6}
                radialLabelsTextColor='#333333'
                radialLabelsLinkOffset={0}
                radialLabelsLinkDiagonalLength={6}
                radialLabelsLinkHorizontalLength={24}
                radialLabelsLinkStrokeWidth={1}
                radialLabelsLinkColor={{ from: 'color', modifiers: [] }}
                slicesLabelsSkipAngle={10}
                slicesLabelsTextColor='#333333'
                animate
                motionStiffness={90}
                motionDamping={15}
                enableSlicesLabels={false}
                theme={{
                  tooltip: {
                    container: {
                      fontSize: '13px'
                    }
                  },
                  labels: {
                    textColor: '#555'
                  }
                }}
              />
            </div>
            <div className='outer-div-number' style={number > 99 ? { paddingRight: '34px' } : { paddingRight: '20px' }}>
              <div className='inner-div-number'>
                {number}
              </div>
            </div>
          </Paper>
        )}
      </div>
    )
  }
}

export default CameraChart
