import React, { Component } from 'react'
import HeadText from '../../modules/static/tableHeading'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import VideoLibrary from '@material-ui/icons/VideoLibrary'
import PictureInPicture from '@material-ui/icons/PictureInPicture'
import Clips from './clips/index'
import Header from './header/index'

import './contentEditing.css'

class EditingContent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: null
    }
  }

  componentDidMount () {
    this.setState({
      value: this.props.menu
    })
  }

  handleChange = (event, value) => {
    this.setState({ value })
  };

  render () {
    const { value } = this.state

    return (
      <div>
        <HeadText text={this.props.headingText} />
        <AppBar position='static' color='default' style={{ marginBottom: '20px', backgroundColor: '#fff' }}>
          <Tabs
            value={value}
            onChange={this.handleChange}
            variant='scrollable'
            scrollButtons='on'
            indicatorColor='primary'
            textColor='primary'
          >
            <Tab label='Clips' icon={<VideoLibrary />} />
            <Tab label='Header' icon={<PictureInPicture />} />
          </Tabs>
        </AppBar>
        {value === 0 && <Clips {...this.props} />}
        {value === 1 && <Header {...this.props} />}
      </div>
    )
  }
}

export default EditingContent
