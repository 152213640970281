import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { postCamStart, postCamStop, postCameraSystem, postCamDeinstall, postCamMove, postCamUpdate, getCamSystemCleanup } from '../../../api/api-camera'

import { InputFieldInit, InputDropDownInit } from '../../static/inputFields'

class AlertDialog extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false,
      protocol: 'string',
      destination: 'hls:///mnt/sdcard/records',
      destinationPort: 0,
      bitrate: 10000,
      mapping: 'Grid100',
      duration: 10,
      id: 42,
      ip: '13.80.17.212',
      ports: '2345,2345,22',
      user: 'fancyuser',
      overlay: false,
      matches: [],
      cameraid: '',
      name: '',
      releasType: 'productive',
      devLock: 0,
      force: false,
      errorMsg: undefined,
      systemCleanup: undefined
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.camId !== this.props.camId) {
      this.setState({
        systemCleanup: undefined
      })
    }
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
      name: '',
      releasType: 'productive',
      devLock: 0,
      force: false,
      errorMsg: undefined
    })
  };

  handleClose = () => {
    this.setState({ open: false })
  };

  handleChange (event) {
    const x = event.target.value
    this.setState({ [event.target.id]: x })
  }

  handleChangeBoolean (event) {
    this.setState({ [event]: !this.state[event] })
  }

  handleChangeNumber (event) {
    const x = event.target.value
    this.setState({ [event.target.id]: Number(x) })
  }

  handleChangeDropdown (event) {
    this.setState({
      releasType: event.target.value
    })
  }

  handleOverlay (name) {
    const tempHandleChange = this.handleChange.bind(this)
    const handleChangeNumber = this.handleChangeNumber.bind(this)
    const handleChangeBoolean = this.handleChangeBoolean.bind(this)

    switch (name) {
      case 'Starten':
        return (
          <div>
            <InputFieldInit label='protocol' value={this.state.protocol} handleChange={tempHandleChange} />
            <InputFieldInit label='destination' value={this.state.destination} handleChange={tempHandleChange} />
            <InputFieldInit
              label='destinationPort'
              value={this.state.destinationPort}
              handleChange={tempHandleChange}
            />
            <InputFieldInit label='bitrate' value={this.state.bitrate} handleChange={tempHandleChange} />
            <InputFieldInit label='mapping' value={this.state.mapping} handleChange={tempHandleChange} />
            <InputFieldInit label='duration' value={this.state.duration} handleChange={tempHandleChange} />
            <InputFieldInit label='id' value={this.state.id} handleChange={tempHandleChange} />
          </div>
        )
      case 'Stoppen':
        return (
          <div>
            <InputFieldInit label='id' value={this.state.id} handleChange={tempHandleChange} />
          </div>
        )
      case 'Cleanup':
        return (
          this.state.systemCleanup ? (
            <div>
              <div>detail: {this.state.systemCleanup.detail}</div>
              <div style={{paddingTop: '4px'}}>success: {String(this.state.systemCleanup.success)}</div>
            </div>
          ) : (
            <div>
              Alle Videodaten im Buffer werden gelöscht. Alle laufenden Aufnahmen beendet.
            </div>
          )
        )
      case 'System update':
        return (
          <div>
            <InputDropDownInit
              id='releasType'
              label='Release type'
              menuField={[{ label: 'productive', value: 'productive' }, { label: 'develop', value: 'develop' }, { label: 'patch', value: 'patch' }]}
              value={this.state.releasType}
              handleChange={this.handleChangeDropdown.bind(this)}
              style={{ marginTop: '16px', marginBottom: '8px' }}
            />
            <InputFieldInit id='name' label='Specified' value={this.state.name} handleChange={tempHandleChange} />
            <InputFieldInit type='number' disabled={this.state.releasType !== 'develop'} id='devLock' label='develop lock duration' value={this.state.devLock} handleChange={handleChangeNumber} />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.force}
                  onChange={() => handleChangeBoolean('force')}
                  value='force'
                  color='primary'
                />
              }
              label='force'
            />
            {this.state.errorMsg ? (
              <div className='sys-update-err'>
                Fehler: {this.state.errorMsg}
              </div>
            ) : null}
          </div>
        )
      case 'Connect RSSH':
      case 'Disconnect RSSH':
        return (
          <div>
            <InputFieldInit label='ip' value={this.state.ip} handleChange={tempHandleChange} />
            <InputFieldInit label='ports' value={this.state.ports} handleChange={tempHandleChange} />
            <InputFieldInit label='user' value={this.state.user} handleChange={tempHandleChange} />
          </div>
        )
      case 'Kamera austauschen':
        return (
          <div>
            Aktuelle Position (Club ID): {this.props.tempInfo.ownerClubId}
            <p>Wohin soll die Kamera umgezogen werden?</p>
            <InputFieldInit label='cameraid' value={this.state.cameraid} handleChange={tempHandleChange} />
          </div>
        )
      case 'Kamera deinstallieren':
        return <div>Soll die Kamera wirklich deinstalliert werden?</div>
      default:
        break
    }
  }

  handleFinal = async (event) => {
    if (this.props.btnname === 'Starten') {
      const start = {
        protocol: this.state.protocol,
        destination: this.state.destination,
        destinationPort: parseInt(this.state.destinationPort, 10),
        bitrate: parseInt(this.state.bitrate, 10),
        mapping: this.state.mapping,
        duration: parseInt(this.state.duration, 10),
        id: String(this.state.id)
      }

      const json = JSON.stringify(start) // Object to JSON

      postCamStart(json, this.props.tempInfo.RowKey).then((res) => {
        const string = "{'success':true,'swcsReached':true}"
        const split = string.split(',')
        const splitlength = split.length
        let tempCounter = 0

        for (let i = 0; i < splitlength; i++) {
          const temp = split[i].search('true')

          if (temp > 0) {
            tempCounter++
          }
        }

        if (tempCounter >= 2) {
          this.props.notification(event, 'Erfolg', res.request.response, 'success', 'tc')
        } else {
          this.props.notification(event, 'Fehler', res.request.response, 'error', 'tc')
        }
      })

      this.handleClose() // Close Edit-Dialog
    } else if (this.props.btnname === 'Stoppen') {
      const stop = {
        // id: String(this.state.id)
      }

      const json = JSON.stringify(stop) // Object to JSON

      postCamStop(json, this.props.tempInfo.RowKey) // APicall

      this.props.notification(event, 'Erfolg', 'Kamera wurde gestoppt ', 'success', 'tc')

      this.handleClose() // Close Edit-Dialog
    } else if (this.props.btnname === 'Connect RSSH') {
      this.handleClose() // Close Edit-Dialog
    } else if (this.props.btnname === 'Disconnect RSSH') {
      this.handleClose() // Close Edit-Dialog
    } else if (this.props.btnname === 'Cleanup') {
      getCamSystemCleanup(this.props.tempInfo.RowKey).then(res => {
        if(res.status === 200) {
          this.setState({
            systemCleanup: res.data
          })
        } else {
          this.props.notification(event, 'Fehler', res.status, 'error', 'tc')
        }
      }).catch(error => {
        console.log(error)
        this.props.notification(event, 'Fehler', error.message, 'error', 'tc')
      })
      // this.handleClose() // Close Edit-Dialog
    } else if (this.props.btnname === 'Kamera deinstallieren') {
      const cameraId = this.props.tempInfo.uid
      const cameraInfo = this.props.tempInfo
      await postCamDeinstall(cameraInfo, cameraId)
      this.handleClose() // Close Edit-Dialog
      this.props.resetData(true)
    } else if (this.props.btnname === 'Kamera austauschen') {
      if (this.state.cameraid !== '') {
        const cameraIdOld = this.props.tempInfo.uid
        const cameraIdNew = this.state.cameraid
        const oldCamData = this.props.tempInfo
        await postCamMove(oldCamData, cameraIdOld, cameraIdNew)
        this.handleClose() // Close Edit-Dialog
        this.props.resetData(true)
      }
    } else if (this.props.btnname === 'System update') {
      const checkPackageName = this.state.name.trim() === '' ? undefined : this.state.name.trim()
      const checkLockDuration = this.state.releasType !== 'develop' ? undefined : this.state.devLock
      const camId = this.props.tempInfo.RowKey

      const body = {
        packageName: checkPackageName,
        installType: this.state.releasType,
        lockDuration: checkLockDuration,
        force: this.state.force
      }

      postCamUpdate(body, camId).then(res => {
        if (res.data.success) {
          this.handleClose()
        } else {
          this.handleErrorMassage(res.data.error)
        }
      })
    }
  }

  handleErrorMassage (msg) {
    this.setState({
      errorMsg: msg
    })
  }

  render () {
    return (
      <div className={!this.props.secondColor ? 'camera-btn-heigh' : 'camera-btn-heigh-o'}>
        {this.props.disabled === true ? (
          <Button
            onClick={this.handleClickOpen}
            color='secondary'
            variant='contained'
            disabled
            className='camera-btn camera-btn-disable'
            style={{ backgroundColor: '#fff !important' }}
          >
            {this.props.btnname}
          </Button>
        ) : (
          <Button onClick={this.handleClickOpen} color='primary' variant='contained' className='camera-btn'>
            {this.props.btnname}
          </Button>
        )}
        <Dialog fullWidth open={this.state.open} onClose={this.handleClose} aria-labelledby='alert-dialog-title'>
          <DialogTitle id='alert-dialog-title'>{'Kamera: ' + this.props.camId}</DialogTitle>
          <DialogContent>{this.handleOverlay(this.props.btnname)}</DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Abrechen
            </Button>
            <Button onClick={this.handleFinal.bind(this)} color='primary' autoFocus>
              {this.props.btnname}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default AlertDialog
