import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

class RunnotesInProgress extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false,
      data: []
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    let check = false
    if (nextProps.data !== this.state.data) {
      this.setState({
        data: nextProps.data
      })
      check = true
    }

    if (nextState.open !== this.state.open) {
      check = true
    }

    if (nextProps.open !== this.state.open) {
      check = true
      this.setState({
        open: nextProps.open
      })
    }

    return check
  }

  handleOpen () {
    this.setState({
      open: !this.state.open
    })

    if (!this.state.open) {
      this.props.handleOpen(this.props.id)
    }
  }

  handleClick (id) {
    window.open(window.location.origin + '/games/overview/RowKey=' + id, '_blank')
  }

  render () {
    const length = this.state.data ? this.state.data.length : 0
    return (
      <div>
        <div className='dash-notes-heading' onClick={this.handleOpen.bind(this)}>
          <div className='dash-notes-headingtext'>
            {length}x {this.props.headText}
          </div>
          <div className='dash-notes-heading-btnX'>
            {this.state.open ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
          </div>
        </div>
        {this.state.open ? (
          <div className='dash-note-container'>
            {this.state.data && this.state.data.map((n, index) => {
              var options = { year: 'numeric', month: 'numeric', day: 'numeric' }
              var date = new Date(parseInt(n.expectedStartTime, 10)).toLocaleDateString('de-DE', options)
              var dateTime = new Date(parseInt(n.expectedStartTime, 10)).toLocaleTimeString('de-DE', {
                hours: 'numeric',
                minutes: 'numeric',
                millisecond: 'numeric'
              })
              return (
                <div key={index} className='dash-note-container-body' onClick={this.handleClick.bind(this, n.RowKey)}>
                  <div>
                    <div style={{ fontSize: '12px' }}>
                      ID-
                      {n.RowKey}
                    </div>
                    <div style={{ fontSize: '12px' }}>
                      swcs-
                      {n.swcsID}
                    </div>
                    <div style={{ fontSize: '12px' }}>
                      {date} {dateTime}
                      Uhr
                    </div>
                    <div style={{ fontSize: '12px' }}>
                      {n.clubAName} gegen {n.clubBName}
                    </div>
                  </div>
                  <div style={{ whiteSpace: 'nowrap' }}>
                    <div style={{ fontSize: '12px', position: 'relative', float: 'right' }}>{n.errorMessage}</div>
                  </div>
                </div>
              )
            })}
          </div>
        ) : null}
      </div>
    )
  }
}

export default RunnotesInProgress
