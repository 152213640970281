import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import DialogActions from '@material-ui/core/DialogActions'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ColorPicker from '../../static/table/container/colorPicker/colorPicker'
import moment from 'moment'

import { postMeta } from '../../../api/api-video'
import { getCameraSystemList } from '../../../api/api-camera'
import { InputFieldWithDropdown } from '../../static/inputFields'
import { getClubSearch } from '../../../api/api-search'
import { getCameraCornerPointsAll } from '../../../api/api-v2-admin'

import '../game.css'

class AddColum extends Component {
  constructor (props) {
    super(props)

    this.state = {
      clubs: [],
      clubATeam: '',
      clubBTeam: '',
      clubAId: '',
      clubBId: '',
      clubAName: '',
      clubBName: '',
      field: '',
      cameras: [],
      startTime: moment().add(5, 'minutes').format('YYYY-MM-DDTHH:mm'),// '2019-01-01T10:30',
      did: '',
      cup: '',
      cupGroup: '',
      uselabels: '0',
      league: undefined,
      durationMin: 150,
      swcsID: undefined,
      connection: undefined,
      connectionLabel: 'Connection',
      videoType: false,
      cornerPointSet: '',
      availableCornerPoints: [],
      clubsSearch: [],
      clubSearchHome: '',
      clubSearcGuest: ''
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.swcsID !== this.state.swcsID) {
      this.setState({ availableCornerPoints: [], cornerPointSet: '' })
      this.getAvailableCornerPointSets()
    }
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value
    })
  };

  handleDualChange = (event) => {
    const cameraField = event.target.value.split(':::')
    const swcsID = cameraField[0]
    const field = cameraField[1]
    this.setState({
      swcsID,
      field
    })
  };

  handleDualChangeClub = (first, second) => (event) => {
    const club = event.target.value.split(':::')
    this.setState({
      [first]: club[0],
      [second]: club[1]
    })
  };

  componentDidMount () {
    /*getClubs().then((res) => {
      const clubs = res.sort((a, b) => {
        if (a.searchName < b.searchName) return -1
        if (a.searchName > b.searchName) return 1
        return 0
      })
      this.setState({ clubs: clubs })
    })*/

    this.searchClub('staige')

    getCameraSystemList().then((res) => {
      this.setState({ cameras: res })
    })
  }

  searchClub(value) {
    getClubSearch(value).then((res) => {
      const clubs = []
      res.data.hits.hits.map((club) => {
        console.log(club._source.RowKey, club._source.name)
        clubs.push(  {
          value: club._source.RowKey,
          label: club._source.name
        })
      })
      this.setState({
        clubsSearch: clubs 
      })
    })
  }

  getAvailableCornerPointSets = async () => {
    try {
      const res = await getCameraCornerPointsAll(this.state.swcsID)
      const availableCornerPoints = res.data.map((set) => set.fieldName)
      this.setState({ availableCornerPoints, cornerPointSet: '' })
    } catch (err) {
      // TODO: Maybe Handle this somehow, if not 404
      console.error(err)
    }
  }


  clubDid (value) {
    let didClub

    if (value !== null) {
      this.state.clubs.map((club, index) => (club.name === value ? (didClub = club.dId) : null))
    }

    return didClub
  }

  handleAddColum () {
    let connection
    let check = true

    if (this.state.connection !== undefined && this.state.connection !== '') {
      const checkValue = this.state.connection.search('rtmp://')
      const checkValue2 = this.state.connection.search('srt://')
      if (checkValue === -1 && checkValue2 === -1) {
        check = false

        this.setState({
          connectionLabel: 'Das Connection Feld muss mit "rtmp://" oder srt:// beginnen'
        })
      } else {
        this.setState({
          connectionLabel: 'Connection'
        })
      }

      connection = this.state.connection
    } else {
      connection = undefined
    }

    const addMatch = {
      clubAId: this.state.clubAId,
      clubAName: this.state.clubAName,
      clubANameSearchable: this.state.clubANameSearchable,
      clubATeam: this.state.clubATeam,
      clubATeamId: this.state.clubATeamId,
      clubAShirtColor: this.state.clubAShirtColor,
      clubBId: this.state.clubBId,
      clubBName: this.state.clubBName,
      clubBNameSearchable: this.state.clubBNameSearchable,
      clubBTeam: this.state.clubBTeam,
      clubBTeamId: this.state.clubBTeamId,
      clubBShirtColor: this.state.clubBShirtColor,
      durationMin: parseInt(this.state.durationMin, 10),
      expectedStartTime: new Date(this.state.startTime).getTime(), // parseInt(this.state.startTime, 10),
      // startTime: this.state.startTime,
      state: 'created',
      swcsID: this.state.swcsID,
      thumbnail: this.state.thumbnail,
      views: parseInt(this.state.views, 10),
      field: this.state.field,
      league: this.state.league,
      priority: this.state.priority,
      cup: this.state.cup,
      cupGroup: this.state.cupGroup,
      uselabels: this.state.uselabels,
      connection: connection,
      videoProcessing: this.state.videoType ? 'panorama' : undefined
    }
    if (this.state.cornerPointSet && this.state.cornerPointSet !== '') {
      addMatch.fieldCorners = this.state.cornerPointSet
    }

    if (
      check &&
      this.state.clubAId !== '' &&
      this.state.clubBId !== '' &&
      this.state.clubAName !== '' &&
      this.state.clubBName !== '' &&
      this.state.clubATeam !== '' &&
      this.state.clubBTeam !== '' &&
      this.state.league !== '' &&
      this.state.field !== '' &&
      new Date(this.state.startTime).getTime() > new Date().getTime()
    ) {
      postMeta(addMatch) // APicall
      this.props.handleClose() // Close Edit-Dialog
    }
  }

  handleChangeSwitch = (name) => (event) => {
    this.setState({
      [name]: event.target.checked
    })
  }

  onChangeColorPicker = (name, colors) => {
    this.setState({
      [name]: colors
    })
  }

  render () {
    return (
      <Grid container spacing={24}>
        <Grid item xs={12} sm={6} className='dialogtableedit-team'>
          <div className='dialogtableedit-team-head'>HEIMMANNSCHAFT</div>
          <TextField
            className='textField-table'
            id='clubAName'
            label='Teamname'
            onChange={this.handleChange('clubAName')}
            margin='normal'
            error={this.state.clubAName !== '' && this.state.clubAName !== undefined ? false : true}
          />
          <TextField
            className='textField-table'
            id='clubAId'
            value={this.state.clubAId}
            label='Id (wenn vorhanden)'
            onChange={this.handleChange('clubAId')}
            margin='normal'
            error={this.state.clubAId !== '' && this.state.clubAId !== undefined ? false : true}
          />

          <div className='clubX'>
            <InputFieldWithDropdown
              label={'Club'}
              menuField={this.state.clubsSearch}
              value={this.state.clubSearchHome}
              handleChange={(event) => {        
                this.setState({
                  clubSearchHome: event.target.value
                })
                this.searchClub(event.target.value)
              }}
              handleChangeDropdown={(event) => {
                const label = this.state.clubsSearch.find(a => a.value === event.target.value).label
                this.setState({
                  'clubATeam': label,
                  'clubSearchHome': label,
                  'clubAId': event.target.value
                })
              }}
            />
          </div>
      

          {/*<FormControl>
            <InputLabel htmlFor='cluba'>Klub</InputLabel>
            <Select
              native
              value={`${this.state.clubATeam}:::${this.state.clubAId}`}
              onChange={this.handleDualChangeClub('clubATeam', 'clubAId')}
              inputProps={{
                id: 'cluba'
              }}
              error={this.state.clubATeam !== '' && this.state.clubATeam !== undefined ? false : true}
            >
              <option value='' />
              {this.state.clubs.map((club, index) => (
                <option value={`${club.name}:::${club.RowKey}`} key={index}>
                  {club.name}
                </option>
              ))}
            </Select>
          </FormControl>*/}

          <div style={{ marginTop: '24px' }}>
            <ColorPicker
              row={{ name: 'clubAShirtColor', title: 'Trikot Farben' }}
              max={3}
              value={[]}
              handleChange={this.onChangeColorPicker}
            />
          </div>

        </Grid>
        <Grid item xs={12} sm={6} className='dialogtableedit-team'>
          <div className='dialogtableedit-team-head'>GASTMANNSCHAFT</div>
          <TextField
            className='textField-table'
            id='clubBName'
            label='Teamname'
            onChange={this.handleChange('clubBName')}
            margin='normal'
            error={this.state.clubBName !== '' && this.state.clubBName !== undefined ? false : true}
          />
          <TextField
            className='textField-table'
            id='clubBId'
            value={this.state.clubBId}
            label='Id (wenn vorhanden)'
            onChange={this.handleChange('clubBId')}
            margin='normal'
            error={this.state.clubBId !== '' && this.state.clubBId !== undefined ? false : true}
          />



          <div className='clubX'>
            <InputFieldWithDropdown
              label={'Club'}
              menuField={this.state.clubsSearch}
              value={this.state.clubSearchGuest}
              handleChange={(event) => {        
                this.setState({
                  clubSearchGuest: event.target.value
                })
                this.searchClub(event.target.value)
              }}
              handleChangeDropdown={(event) => {
                const label = this.state.clubsSearch.find(a => a.value === event.target.value).label
                this.setState({
                  'clubBTeam': label,
                  'clubSearchGuest': label,
                  'clubBId': event.target.value
                })
              }}
            />
          </div>
      


          {/*<FormControl>
            <InputLabel htmlFor='clubb'>Klub</InputLabel>
            <Select
              native
              value={`${this.state.clubBTeam}:::${this.state.clubBId}`}
              onChange={this.handleDualChangeClub('clubBTeam', 'clubBId')}
              inputProps={{
                id: 'clubb'
              }}
              error={this.state.clubBTeam !== '' && this.state.clubBTeam !== undefined ? false : true}
            >
              <option value='' />
              {this.state.clubs.map((club, index) => (
                <option value={`${club.name}:::${club.RowKey}`} key={index}>
                  {club.name}
                </option>
              ))}
            </Select>
          </FormControl>*/}
          <div style={{ marginTop: '24px' }}>
            <ColorPicker
              row={{ name: 'clubBShirtColor', title: 'Trikot Farben' }}
              max={3}
              value={[]}
              handleChange={this.onChangeColorPicker}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='startTime'
            label='Startzeit'
            type='datetime-local'
            value={this.state.startTime}
            InputLabelProps={{
              shrink: true
            }}
            onChange={this.handleChange('startTime')}
            error={new Date(this.state.startTime).getTime() < new Date().getTime()}
            helperText={new Date(this.state.startTime).getTime() < new Date().getTime() ? 'Der Aufnahmestart darf nicht in der Vergangenheit liegen' : false}
            fullWidth
          />
          <TextField
            className='textField-table'
            id='durationMin'
            label='Duration'
            value={this.state.durationMin}
            onChange={this.handleChange('durationMin')}
            margin='normal'
          />
          <TextField
            className='textField-table'
            id='league'
            label='Liga'
            onChange={this.handleChange('league')}
            margin='normal'
            error={this.state.league !== '' && this.state.league !== undefined ? false : true}
          />
          <FormControl>
            <InputLabel htmlFor='field'>Feld</InputLabel>
            <Select
              native
              value={this.state.address}
              onChange={this.handleDualChange}
              inputProps={{
                id: 'field'
              }}
              error={this.state.field !== '' && this.state.field !== undefined ? false : true}
            >
              <option value='' />
              {this.state.cameras.map((cam, index) => (
                <option value={`${cam.RowKey}:::${cam.address}`} key={index}>
                  {cam.RowKey}) {cam.address}
                </option>
              ))}
            </Select>
          </FormControl>
          { this.state.swcsID &&
            <>
            <InputLabel htmlFor='CornerPoints'>CornerPoints</InputLabel>
              {
                this.state.availableCornerPoints.length ?
                <Select 
                  native 
                  value={this.state.cornerPointSet}
                  onChange={this.handleChange('cornerPointSet')}
                  inputProps={{ id: 'CornerPoints' }}>
                    <option value=''>Standard</option>
                    { this.state.availableCornerPoints.map((set) =>
                      set !== 'default' ?
                      <option key={set} value={set}>{set}</option>
                      : null
                    )}
                </Select>
                :
                <span>Loading...</span>
              }
            </>
          }
          <TextField
            className='textField-table'
            id='did'
            label='Did (wenn vorhanden)'
            onChange={this.handleChange('did')}
            margin='normal'
          />
          <TextField
            className='textField-table'
            id='cup'
            label='cup'
            onChange={this.handleChange('cup')}
            margin='normal'
          />
          <TextField
            className='textField-table'
            id='cupGroup'
            label='cupGroup'
            onChange={this.handleChange('cupGroup')}
            margin='normal'
          />
          <TextField
            className='textField-table'
            id='uselabels'
            label='Use Labels'
            value={this.state.uselabels}
            onChange={this.handleChange('uselabels')}
            margin='normal'
          />
          <TextField
            className={this.state.connectionLabel === 'Connection' ? ('textField-table') : ('textField-table-err')}
            id='connection'
            label={this.state.connectionLabel}
            value={this.state.connection}
            onChange={this.handleChange('connection')}
            margin='normal'
          />
          <div style={{ color: 'rgba(0, 0, 0, 0.54)', marginTop: 16, marginBottom: 8 }}>
            Als VR Stream Rendern:
            <FormControlLabel
              className='headpadding-ceck'
              control={
                <Checkbox
                  checked={this.state.videoType}
                  onChange={this.handleChangeSwitch('videoType')}
                  value='videoType'
                  color='primary'
                  margin='normal'
                />
              }
            />
          </div>
        </Grid>
        <Grid className='dialog-btn'>
          <DialogActions>
            <Button onClick={this.props.handleClose} className='btn' color='primary' variant='contained'>
              Abbrechen
            </Button>
            <Button
              onClick={this.handleAddColum.bind(this)}
              className='btn'
              color='primary'
              variant='contained'
              autoFocus
              disabled={
                this.state.clubAId === ''  || this.state.clubAId === undefined ||
                this.state.clubBId === ''  || this.state.clubBId === undefined ||
                this.state.clubAName === ''  || this.state.clubAName === undefined ||
                this.state.clubBName === ''  || this.state.clubBName === undefined ||
                this.state.clubATeam === ''  || this.state.clubATeam === undefined ||
                this.state.clubBTeam === ''  || this.state.clubBTeam === undefined ||
                this.state.league === ''  || this.state.league === undefined ||
                this.state.field === ''  || this.state.field === undefined || 
                new Date(this.state.startTime).getTime() < new Date().getTime()
              }
            >
              Hinzufügen
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    )
  }
}

export default AddColum
