import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Select from 'react-select'
import Checkbox from '@material-ui/core/Checkbox'
import Select2 from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'

import { postTestgame } from '../../../api/api-import'

import 'react-select/dist/react-select.css'
import '../game.css'
import AiswSpinner from '../../../common/AiswSpinner'

class NormalTest extends Component {
  constructor(props) {
    super(props)

    const date = new Date()
    date.setMinutes(date.getMinutes() + 5)

    this.state = {
      startTimeInPast: false,
      checkAllCams: false,
      checkAllCamsNotTest: false,
      useForCalibration: false,
      mbit: undefined,
      open: false,
      selectedOption: { value: '' },
      cameras: [],
      duration: 10,
      videoType: false,
      startTime:
        date.getFullYear() +
        '-' +
        (date.getMonth() < 9 ? '0' : '') +
        (date.getMonth() + 1) +
        '-' +
        (date.getDate() < 10 ? '0' : '') +
        date.getDate() +
        'T' +
        (date.getHours() < 10 ? '0' : '') +
        date.getHours() +
        ':' +
        (date.getMinutes() < 10 ? '0' : '') +
        date.getMinutes()
    }
  }

  handleClickOpen = (createtTestMatches) => {
    this.setState({
      open: true,
      listOfCreateTests: createtTestMatches
    })
  }

  handleChangeSelect = (selected) => {
    const selectedOption = selected || { value: '' }
    this.setState({ selectedOption })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value
    })
  }

  handleChangeSwitch = (name) => (event) => {
    if (event.target.checked === true) {
      this.setState({
        selectedOption: { value: '' }, // reset selectedOption
        [name]: event.target.checked
      })
    } else {
      this.setState({
        [name]: event.target.checked
      })
    }

    this.handleDropdownMenu(name)
  }

  handleChangeSwitchCali = (name) => (event) => {
    this.setState({
      [name]: event.target.checked
    })
  }

  handleAddTest() {
    if (new Date(this.state.startTime).getTime() < new Date().getTime()) {
      this.setState({
        startTimeInPast: true
      })
      return null
    } else {
      this.setState({
        startTimeInPast: false
      })
    }
    if (this.state.selectedOption.value === '') {
      return null
    }
    const date = this.state.startTime.split('T')
    let addTestMatch

    if (this.state.checkAllCams || this.state.checkAllCamsNotTest) {
      const tempArray = []

      for (let i = 0; i < this.props.selectCamId.length; i++) {
        tempArray.push({
          date: date[0],
          startTime: date[1],
          swcsID: this.props.selectCamId[i],
          duration: parseInt(this.state.duration, 10),
          useForCalibration: this.state.useForCalibration,
          cameraBitrate: isNaN(Number(this.state.mbit)) ? undefined : Number(this.state.mbit),
          videoType: this.state.videoType ? 'panorama' : undefined,
          connection: this.state.connection,
          gridStream: this.state.gridStream,
          contentType: this.state.contentType || 'test'
        })
      }
      addTestMatch = tempArray
    } else {
      console.log(this.state.mbi)
      addTestMatch = [
        {
          date: date[0],
          startTime: date[1],
          swcsID: this.state.selectedOption.value,
          duration: parseInt(this.state.duration, 10),
          useForCalibration: this.state.useForCalibration,
          cameraBitrate: isNaN(Number(this.state.mbit)) ? undefined : Number(this.state.mbit),
          videoType: this.state.videoType ? 'panorama' : undefined,
          connection: this.state.connection,
          gridStream: this.state.gridStream,
          contentType: this.state.contentType || 'test'
        }
      ]
    }

    const json = JSON.stringify(addTestMatch) // Object to JSON

    if (
      addTestMatch[0].swcsID !== undefined ||
      this.state.checkAllCams === true ||
      this.state.checkAllCamsNotTest === true
    ) {
      postTestgame(json).then(res => {
        if (res.status === 200) {
          this.handleClickOpen(res.data)
        }
      }) // APicall
    }
  }

  render() {
    const { selectedOption } = this.state
    const value = selectedOption && selectedOption.value

    return (
      <div>
        <form className='game-addtest' noValidate>
          <TextField
            className='addtest-textfield'
            id='datetime-local'
            label='Startzeit:'
            type='datetime-local'
            defaultValue={this.state.startTime}
            onChange={this.handleChange('startTime')}
            InputLabelProps={{
              shrink: true
            }}
          />
          <div className='addtest-headpadding'>
            Alle Kameras:
            <FormControlLabel
              className='headpadding-ceck'
              control={
                <Checkbox
                  checked={this.state.checkAllCams}
                  onChange={this.handleChangeSwitch('checkAllCams')}
                  value='checkAllCams'
                  color='primary'
                />
              }
            />
            Nur Aktive Kameras:
            <FormControlLabel
              className='headpadding-ceck'
              control={
                <Checkbox
                  checked={this.state.checkAllCamsNotTest}
                  onChange={this.handleChangeSwitch('checkAllCamsNotTest')}
                  value='checkAllCamsNotTest'
                  color='primary'
                />
              }
            />
          </div>
          {this.state.checkAllCams || this.state.checkAllCamsNotTest ? (
            <FormControl className='formControl-testadd'>
              <InputLabel htmlFor='select-multiple-chip'>Kameras:</InputLabel>
              <Select2
                multiple
                value={this.props.selectCamId}
                onChange={this.props.handleChangeMultiSelect}
                input={<Input id='select-multiple-chip' />}
                renderValue={(selected) => (
                  <div className='chips-testadd'>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </div>
                )}
              >
                {this.props.tempCamList.map((name) => (
                  <MenuItem key={name.value} value={name.value}>
                    {name.label}
                  </MenuItem>
                ))}
              </Select2>
            </FormControl>
          ) : (
            <div>
              <div className='addtest-head'>Kamera:</div>
              {this.props.tempCamList.length ?
                <Select
                  name='form-field-name'
                  value={value}
                  onChange={this.handleChangeSelect}
                  options={this.props.tempCamList}
                />
                : <AiswSpinner />}
            </div>
          )}

          <TextField
            className='addtest-textfield'
            id='number'
            label='Dauer: (in Minuten)'
            value={this.state.duration}
            onChange={this.handleChange('duration')}
            type='number'
            InputLabelProps={{
              shrink: true
            }}
            margin='normal'
          />
          <TextField
            className='addtest-textfield'
            id='number'
            label='kBit/s:'
            value={this.state.mbit}
            onChange={this.handleChange('mbit')}
            type='number'
            InputLabelProps={{
              shrink: true
            }}
            margin='normal'
          />
          <TextField
            className='addtest-textfield'
            id='string'
            label='gridStream:'
            value={this.state.gridStream}
            onChange={this.handleChange('gridStream')}
            type='gridStream'
            InputLabelProps={{
              shrink: true
            }}
            margin='normal'
          />
          <TextField
            className='addtest-textfield'
            id='string'
            label='connection'
            value={this.state.connection}
            onChange={this.handleChange('connection')}
            type='string'
            InputLabelProps={{
              shrink: true
            }}
            margin='normal'
          />

          <div style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
            Use for Calibration:
            <FormControlLabel
              className='headpadding-ceck'
              control={
                <Checkbox
                  checked={this.state.useForCalibration}
                  onChange={this.handleChangeSwitchCali('useForCalibration')}
                  value='useForCalibration'
                  color='primary'
                />
              }
            />
          </div>
          <div style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
            Als VR Stream Rendern:
            <FormControlLabel
              className='headpadding-ceck'
              control={
                <Checkbox
                  checked={this.state.videoType}
                  onChange={this.handleChangeSwitchCali('videoType')}
                  value='videoType'
                  color='primary'
                />
              }
            />
          </div>

          {this.state.startTimeInPast ? (<div style={{ padding: '12px', background: '#ffbaba', marginTop: '12px', marginBottom: '-26px' }}>Die Startzeit liegt in der vergangenheit, bitte anpassen</div>) : null}

          {this.state.selectedOption.value !== '' ||
            this.state.checkAllCams === true ||
            this.state.checkAllCamsNotTest === true
            ? <Button onClick={this.handleAddTest.bind(this)} variant='contained' color='primary' className='addtest-btn btn'>Anlegen</Button>
            : <Button onClick={this.handleAddTest.bind(this)} disabled variant='contained' color='primary' className='addtest-btn btn'> Anlegen</Button>}
          <div>
            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle id='alert-dialog-title'>
                {'Es wurde erfolgreich ein Test auf Kamera ' + this.state.selectedOption.value + ' Angelegt'}
              </DialogTitle>
              <DialogTitle id='alert-dialog-title'>
                {'Match IDs: ' + JSON.stringify(this.state.listOfCreateTests)}
              </DialogTitle>
              <DialogActions>
                <Button onClick={this.handleClose} className='btn' color='primary' variant='contained' autoFocus>
                  Bestätigen
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </form>
      </div>
    )
  }
}

export default NormalTest
