import React, { Component } from 'react'

import './static.scss'

class TableHeading extends Component {
  render () {
    if (this.props.link !== true) {
      return (
        <div className='headingText'>
          <a href='/'>Startseite</a> / {this.props.text}
        </div>
      )
    } else {
      return <div className='headingText'>Startseite / {this.props.text}</div>
    }
  }
}
export default TableHeading
