import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons' // eslint-disable-line
import { faAngleUp } from '@fortawesome/free-solid-svg-icons' // eslint-disable-line

class RunnotesInProgress extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false,
      data: []
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    let check = false
    if (nextProps.data !== this.state.data) {
      this.setState({
        data: nextProps.data
      })
      check = true
    }

    if (nextState.open !== this.state.open) {
      check = true
    }

    if (nextProps.open !== this.state.open) {
      check = true
      this.setState({
        open: nextProps.open
      })
    }

    return check
  }

  handleOpen () {
    this.setState({
      open: !this.state.open
    })

    if (!this.state.open) {
      this.props.handleOpen(this.props.id)
    }
  }

  handleClick (id) {
    window.open(window.location.origin + '/cameras/uid=' + id + '&openNotes', '_blank')
  }

  render () {
    const tempMontage = []
    const tempInstall = []
    const tempInstallAdmin = []
    const temopRepair = []
    const tempTroubleshooting = []
    const tempDeinstall = []
    const tempDeinstallAdmin = []
    const tempMontageOrTrouble = []

    const tempDivList = this.state.data.map((n, index) => {
      let clubName
      const tempId = n.PartitionKey.slice(5)
      const cameraClub = this.props.cameraClubInfoList
      for (let i = 0; i < cameraClub.length; i++) {
        if (tempId === cameraClub[i].id) {
          clubName = cameraClub[i].name
        }
      }

      const temp = n.RowKey.slice(0, n.RowKey.search('-'))
      const tempNumber = n.PartitionKey.slice(n.PartitionKey.search('-') + 1, n.PartitionKey.lenght)
      return (
        <div
          key={index + '-' + temp}
          className='dash-note-container-body'
          onClick={this.handleClick.bind(this, tempNumber)}
        >
          <div style={{ minWidth: '94px' }}>{n.PartitionKey}</div>
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{clubName}</div>
        </div>
      )
    })

    tempDivList.map((n) => {
      const temp = n.key.slice(n.key.search('-') + 1, n.key.length)
      if (temp === 'montage') {
        tempMontage.push(n)
      } else if (temp === 'install') {
        tempInstall.push(n)
      } else if (temp === 'installAdmin') {
        tempInstallAdmin.push(n)
      } else if (temp === 'repair') {
        temopRepair.push(n)
      } else if (temp === 'troubleshooting') {
        tempTroubleshooting.push(n)
      } else if (temp === 'deinstall') {
        tempDeinstall.push(n)
      } else if (temp === 'deinstallAdmin') {
        tempDeinstallAdmin.push(n)
      } else if (temp === 'montageOrTrouble') {
        tempMontageOrTrouble.push(n)
      }

      return null
    })

    return (
      <div>
        <div className='dash-notes-heading' onClick={this.handleOpen.bind(this)}>
          <div className='dash-notes-headingtext'>
            {this.state.data.length} {this.props.headText}
          </div>
          <div className='dash-notes-heading-btnX'>
            {this.state.open ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
          </div>
        </div>
        {this.state.open ? (
          <div style={{ height: '100%' }}>
            {tempMontage.length > 0 ? (
              <div>
                <div className='dash-note-container-head'>Montage</div>
                {tempMontage.map((n) => {
                  return n
                })}
              </div>
            ) : null}

            {tempInstall.length > 0 ? (
              <div>
                <div className='dash-note-container-head'>Installation</div>
                {tempInstall.map((n) => {
                  return n
                })}
              </div>
            ) : null}

            {tempInstallAdmin.length > 0 ? (
              <div>
                <div className='dash-note-container-head'>Install Administration</div>
                {tempInstallAdmin.map((n) => {
                  return n
                })}
              </div>
            ) : null}

            {temopRepair.length > 0 ? (
              <div>
                <div className='dash-note-container-head'>Reparatur</div>
                {temopRepair.map((n) => {
                  return n
                })}
              </div>
            ) : null}

            {tempTroubleshooting.length > 0 ? (
              <div>
                <div className='dash-note-container-head'>Fehleranalyse</div>
                {tempTroubleshooting.map((n) => {
                  return n
                })}
              </div>
            ) : null}

            {tempDeinstall.length > 0 ? (
              <div>
                <div className='dash-note-container-head'>Deinstallation</div>
                {tempDeinstall.map((n) => {
                  return n
                })}
              </div>
            ) : null}

            {tempDeinstallAdmin.length > 0 ? (
              <div>
                <div className='dash-note-container-head'>Deinstall Administration</div>
                {tempDeinstallAdmin.map((n) => {
                  return n
                })}
              </div>
            ) : null}

            {tempMontageOrTrouble.length > 0 ? (
              <div>
                <div className='dash-note-container-head'>Montage oder Fehleranalyse</div>
                {tempMontageOrTrouble.map((n) => {
                  return n
                })}
              </div>
            ) : null}

          </div>
        ) : null}
      </div>
    )
  }
}

export default RunnotesInProgress
