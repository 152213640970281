import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import CardActions from '@material-ui/core/CardActions'
import MenuItem from '@material-ui/core/MenuItem'
import ReactHtmlParser from 'react-html-parser'
import { getGEOImages, setCity } from '../../../api/api-city'
import { getRegions } from '../../../api/api-region'
import { Button, TextField, LinearProgress, Snackbar, Tabs, Tab, AppBar , Select, Input, InputLabel, Switch } from '@material-ui/core'

function precise (x) {
  return Number.parseFloat(x).toPrecision(4)
}

class CityImageDetails extends Component {
  constructor (props) {
    super(props)

    // Check if lat and long must be parsed
    let longs = this.props.city.longitudes
    if (typeof longs === 'string' || longs instanceof String) {
      longs = JSON.parse(longs)
    }
    let lats = this.props.city.latitudes
    if (typeof lats === 'string' || lats instanceof String) {
      lats = JSON.parse(lats)
    }

    const uniqueHelper = []
    const GeoLocations = lats
      .map((lat, i) => {
        const long = longs[i]
        return { lat, long }
      })
      // Remove invalid coordinates
      .filter(({ lat, long }) => typeof lat === 'number' && typeof long === 'number' && !Number.isNaN(lat) && !Number.isNaN(long))
      .filter((loc) => {
        if (uniqueHelper.includes(`${loc.lat}:${loc.long}`)) {
          return false
        }
        uniqueHelper.push(`${loc.lat}:${loc.long}`)
        return true
      })
    this.state = {
      GeoImages: [],
      GeoLocations,
      selectedGeoLocation: 0,
      uploadingGeoImage: -1,
      tabOpen: 0,
      customUrl: '',
      customLicense: '',
      customLicenseAuthor: '',
      customLicenseTitle: '',
      customLicenseSrc: '',
      previewUrl: '',
      regions: ['world'],
      changeCity: {
        active: undefined,
        stateShort: undefined,
        state: undefined,
        region: undefined
      }
    }
    this.doBind.bind(this)()

    getRegions().then((regions) => {
      this.setState({
        ...this.state,
        ...{ regions: regions.map(e => { return e.RowKey }) }
      })
    })
  }

  doBind () {
    this.getCityImageCards = this.getCityImageCards.bind(this)
    this.setGeoLocation = this.setGeoLocation.bind(this)
    this._getGEOImages = this._getGEOImages.bind(this)
    this._setGEOImageManual = this._setGEOImageManual.bind(this)
    this.handleTabs = this.handleTabs.bind(this)
    this.changeCustomLicenseAuthor = this.changeCustomLicenseAuthor.bind(this)
    this.changeCustomLicenseTitle = this.changeCustomLicenseTitle.bind(this)
    this.changeCustomLicenseSrc = this.changeCustomLicenseSrc.bind(this)
    this.changeCustomLicense = this.changeCustomLicense.bind(this)
    this.changeCustomUrl = this.changeCustomUrl.bind(this)
    this.preview = this.preview.bind(this)
    this.setCity = this.setCity.bind(this)
    this.setStateCity = this.setStateCity.bind(this)
    this.setStateActive = this.setStateActive.bind(this)
    this.setRegionCity = this.setRegionCity.bind(this)
    this.setStateShortCity = this.setStateShortCity.bind(this)
  }

  componentDidMount () {
    this._getGEOImages()
  }

  handleTabs (e, tabOpen) {
    this.setState({ tabOpen })
  }

  setGeoLocation (e) {
    const selectedGeoLocation = e.target.value
    this.setState({ selectedGeoLocation }, this._getGEOImages)
  }

  changeCustomUrl (e) {
    const customUrl = e.target.value
    this.setState({ customUrl })
  }

  changeCustomLicense (e) {
    const customLicense = e.target.value
    this.setState({ customLicense })
  }

  changeCustomLicenseAuthor (e) {
    const customLicenseAuthor = e.target.value
    this.setState({ customLicenseAuthor })
  }

  changeCustomLicenseTitle (e) {
    const customLicenseTitle = e.target.value
    this.setState({ customLicenseTitle })
  }

  changeCustomLicenseSrc (e) {
    const customLicenseSrc = e.target.value
    this.setState({ customLicenseSrc })
  }

  preview () {
    const previewUrl = this.state.customUrl
    this.setState({ previewUrl })
  }

  setStateAsync (state) {
    return new Promise((resolve, reject) => {
      this.setState(state, () => {
        resolve()
      })
    })
  }

  async _getGEOImages () {
    const { lat, long } = this.state.GeoLocations[this.state.selectedGeoLocation]
    const res = await getGEOImages(lat, long)
    const GeoImages = !res || !res.data || res.data.error ? [] : res.data
    this.setState({ GeoImages })
  }

  async _setGEOImage (index) {
    if (this.props.uploading) {
      return
    }
    this.setState({ uploadingGeoImage: index })
    const imageData = this.state.GeoImages[index]
    const body = {
      url: imageData.imageinfo[0].url,
      ccTitle: imageData.title.replace('File:', '')
    }
    this.props.setGeoImage(body)
  }

  async _setGEOImageManual () {
    const { customUrl, customLicense, customLicenseAuthor, customLicenseTitle, customLicenseSrc } = this.state

    if (!customUrl || !customLicense || !customLicenseAuthor || !customLicenseTitle) {
      return
    }
    let creditLine = `<div>${customLicenseTitle} by ${customLicenseAuthor} - License: ${customLicense}`
    if (customLicenseSrc) {
      creditLine = `<a href=${customLicenseSrc}>${creditLine}</a>`
    }
    creditLine = `<div class="licenseClaim">${creditLine}</div>`
    const body = {
      licenseData: {
        source: customLicenseSrc,
        author: customLicenseAuthor,
        title: customLicenseTitle,
        type: 'Manual Upload Sw-AdminTool'
      },
      url: customUrl,
      ccTitle: customLicenseTitle,
      creditLine
    }
    this.props.setGeoImage(body)
  }

  getCityImageCards (index) {
    // if (!Array.isArray(this.state.GeoImages)) {
    //   return <h1>Some kind of Error: {JSON.stringify(this.state.GeoImages)}</h1>
    // }
    return this.state.GeoImages.map((data, index) => {
      return (
        <Grid key={data.pageid} item xs={12} sm={2}>
          <Card>
            <CardHeader
              title={data.title.replace('File:', '')}
              titleTypographyProps={{ variant: 'subtitle1' }}
              action={
                <IconButton
                  onClick={() => {
                    this.props.setBigImage(data.imageinfo[0].url)
                  }}
                >
                  <i className='fa fa-arrows-alt' />
                </IconButton>
              }
            />
            {this.props.uploading && this.state.uploadingGeoImage === index ? <LinearProgress variant='query' /> : null}
            {/* <div onClick={() => {
              this._setGEOImage(index)
            }}> */}
            {/* <CardMedia
              component='img'
              alt='Contemplative Reptile'
              className={classes.media}
              height='140'
              image='/static/images/cards/contemplative-reptile.jpg'
              title='Contemplative Reptile'
            /> */}
            {data.imageinfo && data.imageinfo[0] ? (
              <CardMedia
                image={data.imageinfo[0].thumburl}
                component='img'
                title={data.title}
                height='150'
                onClick={() => {
                  this.props.setBigImage(data.imageinfo[0].url)
                }}
              />
            ) : (
              <CardContent>
                <i className='fa fa-5x fa-image' />
              </CardContent>
            )}
            {data.imageinfo && data.imageinfo[0] ? (
              <CardActions>
                <Button
                  size='small'
                  variant='contained'
                  className='btn'
                  color='primary'
                  disabled={this.props.uploading}
                  onClick={() => {
                    this._setGEOImage(index)
                  }}
                >
                  Auswählen
                </Button>
              </CardActions>
            ) : null}
          </Card>
        </Grid>
      )
    })
  }

  getSizeSwUrl (swUrl, suffix = '-4x') {
    const hash = this.props.imageHash || ''
    const parts = `${swUrl}?${hash}`.split('.')
    parts[parts.length - 2] = `${parts[parts.length - 2]}${suffix}`
    return parts.join('.')
  }

  setCity () {
    const cityName = this.props.city.name
    const body = {
      stateShort: this.state.changeCity.stateShort,
      state: this.state.changeCity.state,
      region: this.state.changeCity.region,
      active: this.state.changeCity.active
    }

    setCity(cityName.toLowerCase(), body)
  }

  setStateCity (event) {
    const x = event.target.value

    this.setState({
      changeCity: {
        ...this.state.changeCity,
        state: x
      }
    })
  }

  setStateActive (event) {
    const x = event.target.checked

    this.setState({
      changeCity: {
        ...this.state.changeCity,
        active: x
      }
    })
  }

  setRegionCity (event) {
    const x = event.target.value
    this.setState({
      changeCity: {
        ...this.state.changeCity,
        region: x
      }
    })
  }

  setStateShortCity (event) {
    const x = event.target.value

    this.setState({
      changeCity: {
        ...this.state.changeCity,
        stateShort: x
      }
    })
  }

  render () {
    const cityImgCards = this.state.GeoImages ? this.getCityImageCards() : <h1>Spinner</h1>
    const city = this.props.city
    const GeoOptions = this.state.GeoLocations.map((local, i) => {
      return (
        <MenuItem key={`${local.lat}:${local.long}`} value={i}>
          #{i} - {precise(local.lat)}:{precise(local.long)}
        </MenuItem>
      )
    })
    return (
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={`${city.name} Bearbeiten`}
              style={{ fontSize: '12px' }}
              action={
                <IconButton onClick={this.props.onCloseBtn}>
                  <i className='fa fa-close' />
                </IconButton>
              }
            />
            {this.props.uploading ? <LinearProgress variant='query' /> : null}
            <CardContent style={this.props.isImageClone ? this.props.altColor : { backgroundColor: '#EEEEEE' }}>
              <Grid container spacing={24}>
                <Grid item xs={4} s={4}>
                  <Card>
                    <CardHeader
                      title='Aktives Bild:'
                      action={
                        <IconButton
                          onClick={() => {
                            this.props.setBigImage(this.getSizeSwUrl(city.imageUrl))
                          }}
                        >
                          <i className='fa fa-arrows-alt' />
                        </IconButton>
                      }
                    />
                    {city.imageUrl && city.imageUrl !== '' ? (
                      <CardMedia title='city.imageUrl' image='city.imageUrl'>
                        <img
                          alt='city'
                          src={this.getSizeSwUrl(city.imageUrl, '-2x')}
                          style={{ maxHeight: '200px', maxWidth: '300px' }}
                          onClick={() => {
                            this.props.setBigImage(this.getSizeSwUrl(city.imageUrl))
                          }}
                        />
                      </CardMedia>
                    ) : (
                      <CardContent>
                        <i className='fa fa-4x fa-image' />
                      </CardContent>
                    )}
                  </Card>
                </Grid>
                <Grid item xs={8} s={8}>
                  <form>
                    <TextField
                      margin='dense'
                      id='name'
                      label='Name'
                      value={city.name}
                      variant='outlined'
                      InputProps={{ readOnly: true }}
                    />

                    <TextField
                      margin='dense'
                      id='country'
                      label='Land'
                      value={city.country}
                      variant='outlined'
                      InputProps={{ readOnly: true }}
                    />

                    <TextField
                      margin='dense'
                      id='stateShort'
                      label='Bundesland Abk.'
                      value={this.state.changeCity.stateShort ? this.state.changeCity.stateShort : city.stateShort}
                      variant='outlined'
                      onChange={this.setStateShortCity}
                    />

                    <TextField
                      margin='dense'
                      id='state'
                      label='Bundesland'
                      value={this.state.changeCity.state ? this.state.changeCity.state : city.state}
                      variant='outlined'
                      onChange={this.setStateCity}
                    />
                    <Switch
                      margin='dense'
                      id='active'
                      label='Active'
                      checked={this.state.changeCity.active !== undefined ? this.state.changeCity.active : city.active}
                      variant='outlined'
                      onChange={this.setStateActive}
                    />

                    <InputLabel htmlFor='age-helper'>Region</InputLabel>
                    <Select
                      value={this.state.changeCity.region || city.region || ''}
                      onChange={this.setRegionCity}
                      input={<Input name='region' id='region-helper' />}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      {this.state.regions.map((region) => {
                        return (<MenuItem key={region} value={region}>{region}</MenuItem>)
                    })}

                    </Select>

                    <TextField
                      margin='dense'
                      id='clubs'
                      label='Clubs:'
                      value={city.clubs}
                      variant='outlined'
                      InputProps={{ readOnly: true }}
                    />
                    <TextField
                      margin='dense'
                      id='matches'
                      label='Spiele'
                      value={city.matches}
                      variant='outlined'
                      InputProps={{ readOnly: true }}
                    />
                    <TextField
                      margin='dense'
                      id='Quelle'
                      fullWidth
                      label='Clubs:'
                      value={city.originalUrl}
                      variant='outlined'
                      InputProps={{ readOnly: true }}
                    />
                    <TextField
                      margin='dense'
                      select
                      id='Geo'
                      label='GEO'
                      value={this.state.selectedGeoLocation}
                      onChange={this.setGeoLocation}
                      variant='outlined'
                    >
                      {GeoOptions}
                    </TextField>
                    <div className='licenseClaimContainer'>License: {ReactHtmlParser(city.creditLineHtml)}</div>
                    {this.state.changeCity.stateShort || this.state.changeCity.state || this.state.changeCity.region || this.state.changeCity.active !== undefined ? (
                      <Button
                        size='normal'
                        variant='contained'
                        className='btn'
                        color='primary'
                        onClick={this.setCity}
                        fullWidth
                        style={{ margin: '10px 0' }}
                      >
                        Speichern
                      </Button>
                    ) : null}

                  </form>
                </Grid>
              </Grid>
              <AppBar position='static' variant='fullWidth'>
                <Tabs value={this.state.tabOpen} onChange={this.handleTabs}>
                  <Tab className='btn' label='Wikimedia Commons Browser' />
                  <Tab className='btn' label='Mauneller Upload' />
                </Tabs>
              </AppBar>
              {this.state.tabOpen === 0 && (
                <Grid container spacing={24}>
                  {cityImgCards}
                </Grid>
              )}
              {this.state.tabOpen === 1 && (
                <Grid container spacing={24}>
                  {this.props.uploading ? <LinearProgress variant='query' /> : null}
                  <Grid item xs={3}>
                    <Card>
                      <CardHeader
                        title='Vorschau'
                        action={
                          <IconButton onClick={this.preview}>
                            <i className='fa fa-repeat' />
                          </IconButton>
                        }
                      />
                      <CardMedia
                        image={this.state.previewUrl}
                        title={this.state.previewUrl}
                        style={{
                          height: 0,
                          paddingTop: '56.25%'
                        }}
                        onClick={() => {
                          this.props.setBigImage(this.state.previewUrl)
                        }}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      required
                      title='URL'
                      fullWidth
                      id='url'
                      onChange={this.changeCustomUrl}
                      label='URL'
                      variant='outlined'
                      value={this.state.customUrl}
                    />
                    <TextField
                      required
                      title='License'
                      fullWidth
                      id='license'
                      onChange={this.changeCustomLicense}
                      label='License'
                      variant='outlined'
                      value={this.state.customLicense}
                    />
                    <TextField
                      required
                      title='Author'
                      fullWidth
                      id='author'
                      onChange={this.changeCustomLicenseAuthor}
                      label='Author'
                      variant='outlined'
                      value={this.state.customLicenseAuthor}
                    />
                    <TextField
                      required
                      title='Titel'
                      fullWidth
                      id='title'
                      onChange={this.changeCustomLicenseTitle}
                      label='Titel'
                      variant='outlined'
                      value={this.state.customLicenseTitle}
                    />
                    <TextField
                      title='SourceLink'
                      fullWidth
                      id='src'
                      onChange={this.changeCustomLicenseSrc}
                      label='SourceLink'
                      variant='outlined'
                      value={this.state.customLicenseSrc}
                    />
                    <Button
                      size='small'
                      variant='contained'
                      className='btn'
                      color='primary'
                      disabled={this.props.uploading}
                      onClick={this._setGEOImageManual}
                    >
                      Hochladen
                    </Button>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={Boolean(this.props.error)}
            onClose={this.props.closeError}
            message={<span id='message-id'>{this.props.error}</span>}
            action={[
              <IconButton key='close' aria-label='Close' color='inherit' onClick={this.props.closeError}>
                <span className='fa fa-close' />
              </IconButton>
            ]}
          />
        </Grid>
      </Grid>
    )
  }
}

export default CityImageDetails
