import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import AlertCheck from '../../static/overlayAlertCheckSimple'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import { getPaywallTransactions, getPaywallTransactionsRefund } from '../../../api/api-payment'
import { getAllPaywallPackages, postPaywallPackage } from '../../../api/api-additional'
import { Importer, ImporterField } from 'react-csv-importer'

// theme CSS for React CSV Importer
import 'react-csv-importer/dist/index.css'

class RefundDialog extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false,
      loading: false,
      loadingPackages: false,
      data: [],
      packages: [],
      alertCheckOpen: false,
      checkText: '',
      checkFunction: () => {},
      tap: 0,
      activeCard: undefined,
      openImporter: false
    }
  }

  componentDidUpdate () {
    if (this.props.open !== this.state.open) {
      this.setState({
        open: this.props.open,
        loading: true,
        loadingPackages: true,
        alertCheckOpen: false
      })

      if (this.props.open) {
        getAllPaywallPackages(this.props.matchId).then(res => {
          res.data = res.data.map(row => {
            if (row.hidden === undefined) {
              row.hidden = false
            }
            return row
          })

          if (res.status === 200) {
            this.setState({
              packages: res.data,
              loadingPackages: false
            })
          }
        })

        getPaywallTransactions(this.props.matchId).then((res) => {
          if (res.status === 200) {
            this.setState({
              data: res.data.data,
              loading: false
            })
          }
        })
      }

      if (!this.props.open) {
        setTimeout(() => {
          this.setState({
            loading: false,
            loadingPackages: false,
            data: [],
            packages: [],
            changePackageObj: undefined,
            activeCard: undefined,
            activeCardObj: undefined,
            alertCheckOpen: false,
            checkText: '',
            checkFunction: () => {},
            tap: 0
          })
        }, 200)
      }
    }
  }

  handleRefund = async (row, index) => {
    const data = this.state.data

    await getPaywallTransactionsRefund(row.RowKey).then(res => {
      if (res.status === 200) {
        if (res.data.status === 'refunded') {
          data[index] = { ...data[index], refunded: true }
        } else if (res.data.status === 'error') {
          // err
          data[index] = { ...data[index], refundedError: true }
        }

        this.setState({
          data: data,
          alertCheckOpen: false
        })
      }
    })

    return null
  }

  handleMassRefund = async () => {
    const { data } = this.state
    this.setState({
      loading: true
    })

    for (let i = 0; i < data.length; i++) {
      if (!data[i].refunded) {
        const element = data[i]
        await this.handleRefund(element, i)
      }
    }

    this.setState({
      loading: false,
      alertCheckOpen: false
    })
  }

  checkSuccess (row, index, type) {
    let checkText, checkFunction
    if (type === 'massRefund') {
      checkText = 'Möchtest du wirklich alle Kunden das Geld zurücküberweisen?'
      checkFunction = () => { this.handleMassRefund() }
    } else {
      checkText = 'Möchtest du wirklich ' + row.userId + ' das Geld zurücküberweisen?'
      checkFunction = () => { this.handleRefund(row, index) }
    }

    this.setState({
      alertCheckOpen: true,
      checkText: checkText,
      checkFunction: checkFunction
    })
  }

  checkSuccessClose () {
    this.setState({
      alertCheckOpen: false,
      checkText: '',
      checkFunction: () => {}
    })
  }

  handleChange = (event, value) => {
    this.setState({ tap: value })
  };

  handleChangeActiveCard = (value) => {
    if (value === this.state.activeCard) {
      this.setState({ activeCard: undefined, activeCardObj: undefined, changePackageObj: undefined, importerFinish: false })
    } else {
      const activeCard = this.state.packages.filter(item => item.RowKey === value)[0]
      this.setState({ activeCard: value, activeCardObj: activeCard, changePackageObj: undefined, importerFinish: false })
    }
  }

  handleEditPackage = () => {
    let editPackage = {}

    Object.keys(this.state.activeCardObj).map(key => {
      if (this.state.changePackageObj[key] !== undefined) {
        editPackage = { ...editPackage, [key]: this.state.changePackageObj[key] }
      } else {
        editPackage = { ...editPackage, [key]: this.state.activeCardObj[key] }
      }
    })

    postPaywallPackage(JSON.stringify(editPackage)).then(res => {
      if (res.status === 200) {
        let isNew = true
        const changePackage = this.state.packages.map((item, index) => {
          if (res.data.id === item.id) {
            res.data.RowKey = item.priority
            if (editPackage.list !== undefined) {
              res.data.list = editPackage.list
            }
            isNew = false
            return res.data
          }
          return item
        })

        if (isNew) {
          changePackage.push(res.data)
        }

        this.setState({
          packages: changePackage,
          activeCard: undefined,
          activeCardObj: undefined,
          changePackageObj: undefined,
          newPackageType: undefined
        })
      }
    })
  }

  checkChangePackage = () => {
    if (this.state.changePackageObj === undefined) {
      return false
    }

    let check = false
    Object.keys(this.state.activeCardObj).map(key => {
      if (this.state.changePackageObj && this.state.changePackageObj[key] !== undefined) {
        if (this.state.changePackageObj[key] !== undefined && this.state.changePackageObj[key] !== this.state.activeCardObj[key]) {
          check = true
        }
      }
    })
    return check
  }

  handleRemoveNewPackage = () => {
    this.setState({ activeCard: undefined, activeCardObj: undefined, changePackageObj: undefined, newPackageType: undefined })
  }

  handleRemovelist = () => {
    this.setState({
      changePackageObj: { ...this.state.changePackageObj, list: [] }
    })
  }

  handleTextInput = (event, valueName, number) => {
    this.setState({
      changePackageObj: { ...this.state.changePackageObj, [valueName]: event.target.value }
    })
  }

  handleCheckbox = (event, valueName) => {
    this.setState({
      changePackageObj: { ...this.state.changePackageObj, [valueName]: event.target.checked }
    })
  }

  handleNumberInput = (name) => (event) => {
    this.setState({
      changePackageObj: { ...this.state.changePackageObj, [name]: Number(event.target.value) }
    })
  };

  handleTicket = (event, ticket, key, index) => {
    let cardList
    if (this.state.changePackageObj && this.state.changePackageObj.list) {
      cardList = JSON.parse(JSON.stringify(this.state.changePackageObj.list))
    } else {
      cardList = JSON.parse(JSON.stringify(this.state.activeCardObj.list))
    }

    cardList[index][key] = event

    this.setState({
      changePackageObj: {
        ...this.state.changePackageObj,
        list: cardList
      }
    })
  }

  removeListItem = (item) => {
    let card
    if (this.state.changePackageObj && this.state.changePackageObj.list) {
      card = this.state.changePackageObj.list
    } else {
      card = this.state.activeCardObj.list
    }

    const removeItemList = card.filter(listItem => JSON.stringify(listItem) !== JSON.stringify(item))

    this.setState({
      changePackageObj: {
        ...this.state.changePackageObj,
        list: removeItemList
      }
    })
  }

  addListItem = (type) => {
    let cardList
    if (this.state.changePackageObj && this.state.changePackageObj.list) {
      cardList = JSON.parse(JSON.stringify(this.state.changePackageObj.list))
    } else {
      cardList = JSON.parse(JSON.stringify(this.state.activeCardObj.list))
    }

    cardList.push(this.generateNFreePasses(type))

    this.setState({
      changePackageObj: {
        ...this.state.changePackageObj,
        list: cardList
      }
    })
  }

  generateNFreePasses (type) {
    const rndKey = this.generateId(8)
    if (type === 'ticket') {
      return { 'Trans-Nr': rndKey, Nachname: rndKey }
    } else {
      return { 'Trans-Nr': rndKey }
    }
  }

  generateId (length) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  renderActiveCard () {
    const activeCard = this.state.activeCardObj ? JSON.parse(JSON.stringify(this.state.activeCardObj)) : this.state.activeCardObj
    const changePackageObj = this.state.changePackageObj

    const checkNewPackage = activeCard && activeCard.RowKey
    const RowKeyTitle = checkNewPackage ? 'Paket RowKey: ' + activeCard.RowKey : 'Neues Packet'

    const listKeys = ['Nr']
    if (activeCard && activeCard.list && activeCard.list.length > 0) {
      Object.keys(activeCard.list[0]).map((key) => {
        listKeys.push(key)
      })
    } else {
      if (activeCard && activeCard.type === 'free') {
        listKeys.push('Trans-Nr')
      } else if (activeCard && activeCard.type === 'ticket') {
        listKeys.push('Trans-Nr')
        listKeys.push('Nachname')
      }
    }
    listKeys.push('Entfernen')

    if (changePackageObj && changePackageObj.list) {
      activeCard.list = changePackageObj.list
    }

    if (activeCard) {
      return (
        <>
          <DialogTitle id='alert-dialog-title' style={{ padding: '0 10px 16px', margin: '0px -10px' }}>{RowKeyTitle}</DialogTitle>
          <div>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={6}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={Boolean(changePackageObj && changePackageObj.hidden !== undefined ? changePackageObj.hidden : this.state.activeCardObj.hidden)}
                        onChange={(value) => this.handleCheckbox(value, 'hidden')}
                        value='checkedA'
                      />
                    }
                    label='Unsichbar'
                  />
                </FormGroup>
                <TextField
                  label='Title'
                  value={changePackageObj && changePackageObj.title !== undefined ? changePackageObj.title : this.state.activeCardObj.title}
                  onChange={(event) => this.handleTextInput(event, 'title')}
                  className='textField-table'
                  style={{ marginBottom: '12px' }}
                />
                <TextField
                  label='Description'
                  value={changePackageObj && changePackageObj.description !== undefined ? changePackageObj.description : this.state.activeCardObj.description}
                  onChange={(event) => this.handleTextInput(event, 'description')}
                  className='textField-table'
                  style={{ marginBottom: '12px' }}
                />

                {this.state.newPackageType === 'seasonTicket' || this.state.newPackageType === 'freeTicket' || activeCard.type === 'free' || activeCard.type === 'ticket' ? null : (
                  <TextField
                    label='Price'
                    type='number'
                    value={changePackageObj && changePackageObj.price !== undefined ? changePackageObj.price : this.state.activeCardObj.price}
                    onChange={this.handleNumberInput('price')}
                    className='textField-table'
                    style={{ marginBottom: '12px' }}
                  />
                )}

                <TextField
                  label='Priority'
                  type='number'
                  value={changePackageObj && changePackageObj.priority !== undefined ? changePackageObj.priority : this.state.activeCardObj.priority}
                  onChange={this.handleNumberInput('priority')}
                  className='textField-table'
                  style={{ marginBottom: '12px' }}
                />

              </Grid>
              <Grid item xs={12} sm={6}>
                {!activeCard.list ? <div style={{ textAlign: 'center' }}>Keine Info vorhanden</div> : (
                  <>
                    <div style={{ textAlign: 'center' }}><Button color='primary' onClick={() => this.addListItem(activeCard.type)}>1x Ticket Hinzufügen</Button></div>
                    {this.state.importerFinish || activeCard.type !== 'ticket' ? null : (
                      <Importer
                        chunkSize={10000} // optional, internal parsing chunk size in bytes
                        assumeNoHeaders={false} // optional, keeps 'data has headers' checkbox off by default
                        restartable={false} // optional, lets user choose to upload another file when import is complete
                        onStart={({ file, fields }) => {
                          // optional, invoked when user has mapped columns and started import
                          this.setState({
                            openImporter: true
                          })
                        }}
                        processChunk={async (rows) => {
                          // required, receives a list of parsed objects based on defined fields and user column mapping;
                          // may be called several times if file is large
                          // (if this callback returns a promise, the widget will wait for it before parsing more data)
                          rows = rows.filter(row => {
                            let check = false
                            Object.keys(row).map((key) => {
                              if (row[key] === '') {
                                check = true
                              } else {
                                check = false
                              }
                            })

                            if (check) {
                              return false
                            }
                            return true
                          })

                          let cardList
                          if (this.state.changePackageObj && this.state.changePackageObj.list) {
                            cardList = JSON.parse(JSON.stringify(this.state.changePackageObj.list))
                          } else {
                            cardList = JSON.parse(JSON.stringify(this.state.activeCardObj.list))
                          }

                          this.setState({
                            changePackageObj: {
                              ...this.state.changePackageObj,
                              list: cardList.concat(rows)
                            }
                          })

                          // mock timeout to simulate processing
                          await new Promise((resolve) => setTimeout(resolve, 500))
                        }}
                        onComplete={({ file, fields }) => {
                          // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                        }}
                        onClose={() => {
                          // optional, invoked when import is done and user clicked 'Finish'
                          // (if this is not specified, the widget lets the user upload another file)
                          this.setState({
                            openImporter: false,
                            importerFinish: true
                          })
                        }}
                      >
                        <ImporterField name='Trans-Nr' label='Trans-Nr' />
                        <ImporterField name='Nachname' label='Nachname' />
                      </Importer>
                    )}

                    {this.state.openImporter ? null : (
                      <div style={{ height: '320px', display: 'grid' }}>
                        <div style={{ overflow: 'auto' }}>
                          <Table style={{ tableLayout: 'fixed' }}>
                            <TableHead>
                              <TableRow
                                style={{
                                  height: '45px'
                                }}
                              >
                                {listKeys.map((key, index) => {
                                  return <TableCell key={index} style={{ whiteSpace: 'nowrap' }}>{key}</TableCell>
                                })}
                              </TableRow>
                            </TableHead>
                          </Table>
                        </div>

                        <div style={{ overflow: 'auto', height: '100%' }}>
                          <Table style={{ tableLayout: 'fixed' }}>
                            <TableBody>
                              {activeCard.list.map((ticket, index) => {
                                const row = listKeys.map((key, index) => {
                                  if (key === 'Nr') {
                                    return <TableCell key={index}>{index + 1}</TableCell>
                                  } else if (key === 'Entfernen') {
                                    return <TableCell key={index}><Button onClick={() => this.removeListItem(ticket)} color='primary' style={{ height: '22px' }}>X</Button></TableCell>
                                  }
                                  return (
                                    <TableCell key={index}>
                                      {/* ticket[key] */}
                                      <TextField
                                        style={{ width: '86px' }}
                                        value={ticket[key]}
                                        onChange={(event) => this.handleTicket(event.target.value, ticket, key, index)}
                                        className='textField-table'
                                        fullWidth
                                      />
                                    </TableCell>
                                  )
                                })
                                return <TableRow key={index} style={{ height: '26px' }}>{row}</TableRow>
                              })}
                            </TableBody>
                          </Table>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Grid>
            </Grid>

            <div style={{ textAlign: 'right' }}><Button color='primary' onClick={this.handleRemovelist}>Alle Tickets Entfernen</Button></div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingTop: '20px' }}>
              <Button onClick={() => this.handleRemoveNewPackage()} color='primary'>
                  Abbrechen
              </Button>

              <Button disabled={!checkNewPackage ? false : !this.checkChangePackage()} onClick={() => this.handleEditPackage()} color='primary'>
                {!checkNewPackage ? 'Erstellen' : 'Speichern'}
              </Button>
            </div>
          </div>
        </>
      )
    }
  }

  renderPackageContainer = (title, description, price, priority, id, type, index) => {
    id = id === undefined ? '' : id
    price = price === undefined ? 'n.v.' : price
    priority = priority === undefined ? 'n.v.' : priority
    return (
      <div key={index}>
        <div className='packages-container' onClick={this.state.activeCard === 'new' ? undefined : () => this.handleChangeActiveCard(id)} style={id === this.state.activeCard ? { background: '#ef971230', border: '1px solid #e5e5e5' } : {}}>

          <div style={{ float: 'right', fontWeight: 'bold', fontSize: '16px', color: '#FF3517' }}>
            EDIT
          </div>

          <div className='pack-box'>
            <div className='pack-title'>Title</div>
            <div className='pack-text'>{title || 'n.v.'}</div>
          </div>
          <div className='pack-box'>
            <div className='pack-title'>Description</div>
            <div className='pack-text'>{description || 'n.v.'}</div>
          </div>
          {type === 'free' || type === 'ticket' ? null : (
            <div className='pack-box'>
              <div className='pack-title'>Price</div>
              <div className='pack-text'>{price}€</div>
            </div>
          )}

          <div className='pack-box'>
            <div className='pack-title'>Priority</div>
            <div className='pack-text'>{priority}</div>
          </div>

          {/* <div className='package-menu'>
            <div className='package-menu-icon'>Edit</div>
            <div className='package-menu-icon'>Delete</div>
          </div> */}
        </div>
      </div>
    )
  }

  renderPackages = () => {
    if (this.state.packages.length <= 0) {
      return <div>Ein Default Paket mit einem Preis von 5€ ist Aktiv</div>
    }

    return this.state.packages.map((row, index) => {
      return this.renderPackageContainer(row.title, row.description, row.price, row.priority, row.RowKey, row.type, index)
    })
  }

  createNewPackage = (packageType) => {
    let newPackage, newPackageType
    switch (packageType) {
      case 'soloTicket':
        newPackageType = 'soloTicket'
        newPackage = {
          title: 'Einzel-Zugang',
          active: true,
          type: undefined,
          description: 'Schalte dich für dieses Spiel frei.',
          priority: 0,
          price: 5,
          includes: [`video-${this.props.matchId}`]
        }
        break
      case 'freeTicket':
        newPackageType = 'freeTicket'
        newPackage = {
          title: 'Freikarte',
          active: true,
          type: 'free',
          description: 'Schalte dich für dieses Spiel frei.',
          priority: 0,
          includes: [`video-${this.props.matchId}`],
          // price: 5
          list: []
        }
        break
      case 'seasonTicket':
        newPackageType = 'seasonTicket'
        newPackage = {
          title: 'Dauerkarte',
          active: true,
          type: 'ticket',
          description: 'Schalte dich für dieses Spiel frei.',
          priority: 0,
          includes: [`video-${this.props.matchId}`],
          // price: 5
          list: []
        }
        break
      default:
        break
    }

    this.setState({ activeCard: 'new', activeCardObj: newPackage, changePackageObj: newPackage, newPackageType: newPackageType, importerFinish: false })
  }

  checkPackageAvailable = (packageType) => {
    const isAvailable = this.state.packages.find(row => row.type === packageType)
    return isAvailable
  }

  render () {
    const { open, loading, loadingPackages, alertCheckOpen, checkText, checkFunction } = this.state

    return (
      <div>
        <AlertCheck
          titel='Geld zurücküberweisen'
          text={checkText}
          open={alertCheckOpen}
          func={checkFunction}
          handleClose={() => this.checkSuccessClose()}
        />
        <Dialog
          open={open}
          onClose={this.props.onClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          disableBackdropClick={loading}
          fullWidth
          maxWidth='md'
          style={{ height: '100vh' }}
        >
          <Paper square>
            <Tabs
              value={this.state.tap}
              indicatorColor='primary'
              textColor='primary'
              onChange={this.handleChange}
            >
              <Tab label='Pakete' />
              <Tab label='Zahlungen' />
            </Tabs>
          </Paper>

          {this.state.tap === 1 ? (
            <>
              {loading && <CircularProgress size={24} className='buttonProgress' style={{ position: 'absolute', top: '16px', right: '16px' }} />}
              <DialogTitle id='alert-dialog-title'>{this.props.title || 'Titel fehlt!'}</DialogTitle>
              <Button disabled={loading} onClick={() => this.checkSuccess(undefined, undefined, 'massRefund')} color='primary'>
                Alle Zahlungen zurücküberweisen
              </Button>
              <DialogContent>
                <div style={{ overflow: 'auto' }}>
                  <Table style={{ tableLayout: 'fixed' }}>
                    <TableHead>
                      <TableRow
                        style={{
                          height: '45px'
                        }}
                      >
                        <TableCell style={{ width: '150px' }}>userId</TableCell>
                        <TableCell>status</TableCell>
                        <TableCell>refunded</TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                  {this.state.data.length > 0 ? (
                    <div style={{ overflow: 'auto', height: '100%' }}>
                      <Table style={{ tableLayout: 'fixed' }}>
                        <TableBody>
                          {this.state.data.map((row, index) => (
                            <TableRow
                              key={index}
                            >
                              <TableCell style={{ width: '144px' }}>{row.userId}</TableCell>
                              <TableCell component='th' scope='row' style={{ paddingLeft: '30px' }}>
                                {row.status}
                              </TableCell>
                              <TableCell style={{ paddingLeft: '35px' }}>
                                {row.refunded ? <div className='no-refund'>refunded</div> : <button disabled={loading} onClick={() => { this.checkSuccess(row, index) }} className='btn-refund'>{row.refundedError ? 'error' : 'refund'}</button>}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  ) : (
                    <div style={{ marginTop: '18px', textAlign: 'center', height: '40px' }}>
                      Keine Daten vorhanden.
                    </div>
                  )}
                </div>
              </DialogContent>
            </>
          ) : null}

          {this.state.tap === 0 ? (
            <div>
              {loadingPackages && <CircularProgress size={24} className='buttonProgress' style={{ position: 'absolute', top: '16px', right: '16px' }} />}

              <DialogContent>

                {this.renderActiveCard()}

                <DialogTitle id='alert-dialog-title' style={{ padding: '0 10px 16px', margin: '0px -10px' }}>Paywall Pakete</DialogTitle>
                {loadingPackages ? undefined : (
                  <div className='buttwrapper-packages'>
                    <div style={{ marginRight: '10px' }}>NEU</div>
                    <Button onClick={() => this.createNewPackage('soloTicket')} color='primary' disabled={this.state.activeCard || this.checkPackageAvailable(undefined)}>
                      Einzelpreis
                    </Button>
                    <Button onClick={() => this.createNewPackage('freeTicket')} color='primary' disabled={this.state.activeCard || this.checkPackageAvailable('free')}>
                      Freikarten
                    </Button>
                    <Button onClick={() => this.createNewPackage('seasonTicket')} color='primary' disabled={this.state.activeCard || this.checkPackageAvailable('ticket')}>
                      Dauerkarten
                    </Button>
                  </div>
                )}

                <div className='package-wrapper'>
                  {this.renderPackages()}
                </div>

                {this.state.loadingPackages ? undefined : (
                  <div style={{ paddingTop: '20px' }}>
                    <a style={{ color: 'rgb(239 153 24)' }} target='_blank' rel='noopener noreferrer' href={'https://storage.cloud.google.com/sw-sc-de-prod-intern/paywall-card/' + this.props.matchId + '.json'}>
                      Liste aller Dauerkarten als JSON
                    </a>
                    <a style={{ color: 'rgb(239 153 24)', marginLeft: '40px' }} target='_blank' rel='noopener noreferrer' href={'https://storage.cloud.google.com/sw-sc-de-prod-intern/paywall-free/' + this.props.matchId + '.json'}>
                      Liste aller Freikarten als JSON
                    </a>
                  </div>
                )}

              </DialogContent>
            </div>
          ) : null}

          <DialogActions>
            <Button disabled={loading} onClick={this.props.onClose} color='primary'>
              Abbrechen
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default RefundDialog
