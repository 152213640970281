import React, { Component } from 'react'
import discovery from '@soccerwatch/discovery'

import { Cookies } from 'react-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import firebase from 'firebase/compat/app'
// import 'firebase/compat/auth'
import { auth } from '../common/firebase'

import './navbar.css'

export default class extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 0,
      discovery: 'prod'
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.handleDiscovery = this.handleDiscovery.bind(this)
  }

  componentDidMount() {
    // check environment
    if (discovery.API_CAMERA && discovery.API_USER) {
      let environment = 'fehler'
      const searchCamera = discovery.API_CAMERA.search('prod')
      const searchUser = discovery.API_USER.search('prod')
      if (searchCamera === -1 && searchUser === -1) {
        environment = 'dev'
      } else {
        environment = 'prod'
      }

      this.setState({
        discovery: environment
      })
    }

    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth })
  }

  // check time of before
  CrawlWebForImageOfCat(time, match) {

  }

  handleLogout = async () => {
    const cookies = new Cookies()
    cookies.remove('soccerwatchusername', { path: '/' })
    cookies.remove('soccerwatchuser', { path: '/' })
    cookies.remove('role', { path: '/' })
    cookies.remove('soccerwatchRun', { path: '/' })
    cookies.remove('DuHzzCByRa49A8fpTjqpz2gDW9Y9YTsP', { path: '/' })
    auth.signOut()
    window.location.reload()
  }

  handleDiscovery() {
    const env = this.state.discovery === 'prod' ? 'dev' : this.state.discovery === 'dev' ? 'prodaisw' : 'prod'
    this.setState({
      discovery: env
    })
    this.props.onDiscovery(env)
  }

  render() {
    const cookies = new Cookies()
    cookies.get('soccerwatchusername')

    return (
      <div className='navbar'>
        <div className='navbar-logo'>
          <a href='/'>
            <img alt='Logo' src='https://storage.googleapis.com/aisw-assets/logo/staige/staigeOrange.svg' height='50px' />
          </a>
        </div>
        <div style={{ marginTop: '16px' }}>
          <div className='environment-version'>Version: {process.env.REACT_APP_VERSION}</div>
          <div className='environment-text'>Admintool: <p onClick={this.handleDiscovery} className='environment-text-p'>{this.state.discovery}</p></div>
          {this.state.width > 660 && (
            <div className='navbar-loginName'>
              {cookies.cookies.soccerwatchusername}{' '}
              <div className='logout-btn' onClick={this.handleLogout.bind(this)}>
                <FontAwesomeIcon icon='sign-out-alt' />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}
