import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import InfoBox from './dashboard/infobox'
// import Graph from './dashboard/graph'
import TableMatches from './dashboard/tableMatch'
import NoImage from '../../../../style/img/noimg.png'
import Loading from '../../static/loading'
import TeleStatus from './dashboard/cameraStatus'
import ReactPlayer from 'react-player'

import OldTelemetry from './dashboard/systemtest'

import { getClubInfo } from '../../../api/api-club'
import { getMetaById } from '../../../api/api-video'

class Telemetry extends Component {
  constructor (props) {
    super(props)

    this.state = {
      clubInfo: [],
      cameraMatches: [],
      matchId: 0,
      matchStartTime: 0,
      loadingSite: false,
      activMatch: null,
      showTestContainer: false
    }

    this.handleMatchId = this.handleMatchId.bind(this)
    this.handleShowContainer = this.handleShowContainer.bind(this)
    this.reloadSite = this.reloadSite.bind(this)
  }

  reloadSite () {
    this.componentDidMount()
  }

  componentDidMount () {
    if (this.props.rowinfo.ownerClubId > 0) {
      this.handleApiClubInfo(this.props.rowinfo.ownerClubId)
    } else {
      this.handleApiNoClubInfo(this.props.rowinfo.ownerClubId)
    }
  }

  componentWillUpdate (nextProps) {
    if (nextProps.rowinfo !== undefined) {
      if (this.props.rowinfo.ownerClubId !== String(nextProps.rowinfo.ownerClubId)) {
        if (this.state.clubInfo.RowKey !== undefined) {
          if (nextProps.rowinfo.ownerClubId > 0) {
            this.handleApiClubInfo(nextProps.rowinfo.ownerClubId)
          } else {
            this.handleApiNoClubInfo(nextProps.rowinfo.ownerClubId)
          }
        }
      }
    }
  }

  handleApiClubInfo (id) {
    getClubInfo(id).then((res) => {
      const resTemp = res.data
      let doneTemp = []

      getMetaById(this.props.rowinfo.RowKey, 'done').then((res2) => {
        doneTemp = res2.data.data

        getMetaById(this.props.rowinfo.RowKey, 'created').then((res3) => {
          let lastMatch
          const finalMatchList = doneTemp.concat(res3.data.data).sort(function (a, b) {
            return +new Date(b.startTime) - +new Date(a.startTime)
          })

          for (let i = 0; i < finalMatchList.length - 1; i++) {
            if (finalMatchList[i].state === 'done') {
              lastMatch = finalMatchList[i]
              break
            }
          }

          this.setState({
            clubInfo: resTemp,
            cameraMatches: finalMatchList,
            loadingSite: true,
            lastMatch: lastMatch
          })
        })
      })
    })
  }

  handleApiNoClubInfo () {
    getMetaById(this.props.rowinfo.RowKey, 'done').then((res) => {
      const doneTemp = res.data.data

      getMetaById(this.props.rowinfo.RowKey, 'Mission running').then((resMission) => {
        const missionTemp = doneTemp.concat(resMission.data.data)

        getMetaById(this.props.rowinfo.RowKey, 'created').then((res) => {
          const finalMatchList = missionTemp.concat(res.data.data).sort(function (a, b) {
            return +new Date(b.startTime) - +new Date(a.startTime)
          })

          this.setState({
            clubInfo: { RowKey: '-1', name: 'Kein Club', thumbnail: NoImage },
            cameraMatches: finalMatchList,
            loadingSite: true
          })
        })
      })
    })
  }

  handleMatchId (id, startTime) {
    this.setState({
      matchId: id,
      matchStartTime: startTime
    })
  }

  handleMatch (match) {
    this.setState({
      activMatch: match
    })
  }

  handleShowContainer (name, value) {
    this.setState({
      [name]: value
    })
  }

  render () {
    let vid = ''

    if (this.state.cameraMatches[this.state.matchId] !== undefined) {
      if (this.state.cameraMatches[this.state.matchId].userStream !== undefined) {
        vid = this.state.cameraMatches[this.state.matchId].userStream
      }
    }

    return this.state.loadingSite ? (
      <Grid container spacing={8}>
        <Grid item xs={12} sm={5} md={3} xl={2}>
          <InfoBox
            rowinfo={this.props.rowinfo}
            clubinfo={this.state.clubInfo}
            handleMatch={this.state.activMatch}
            handleContainer={this.handleShowContainer}
            reload={this.reloadSite}
          />
        </Grid>
        <Grid item xs={12} sm={7} md={6} xl={8}>

          <TableMatches cameraMatches={this.state.cameraMatches} handleMatchId={this.handleMatchId} />

          <Paper style={{ marginTop: '10px' }}>
            {this.state.showTestContainer ? (<OldTelemetry rowinfo={this.props.rowinfo} />) : (null)}
          </Paper>

          <Paper style={{ marginTop: '10px' }}>
            <TeleStatus
              rowinfo={this.props.rowinfo}
            />
          </Paper>

          {/*
            <div style={{ marginTop: '10px' }}>
              {this.state.cameraMatches[this.state.matchId] !== undefined ? (
                <Graph
                  id={this.state.cameraMatches[this.state.matchId].RowKey}
                  startTime={this.state.matchStartTime}
                  row={this.state.cameraMatches[this.state.matchId]}
                  handleMatch={this.handleMatch.bind(this)}
                />
              ) : null}
            </div>
          */}

        </Grid>
        <Grid item xs={12} sm={12} md={3} xl={2}>
          <Paper className='game-layout'>
            <div className='layout-head'>VIDEO</div>
            {this.state.cameraMatches[this.state.matchId] !== undefined ? (
              <ReactPlayer
                controls width='100%'
                height='auto'
                url={vid}
                playing
              />
            ) : null}
          </Paper>
        </Grid>
      </Grid>
    ) : (
      <Loading />
    )
  }
}

export default Telemetry
