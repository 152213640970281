import axios from 'axios'
import * as rax from 'retry-axios'
import discovery from '@soccerwatch/discovery'
import { getAuthorization } from './helper'

rax.attach()

const localServer = process.env.REACT_APP_LOCAL_SERVER || ''

export const getLogin = async (value) => {
  return axios.get(localServer + '/login', { headers: { Authorization: value } })
}

export const getLoginAndMigrate = async (user, pw, Authorization) => {
  // return axios.get(localServer + '/login', { headers: { Authorization: value } });
  const body = JSON.stringify({ mail: user, pw, origin: 'Soccerwatch' })
  const res = await axios.post(`${discovery.API_USER}/login`, body, { headers: { Authorization, 'Content-Type': 'application/json' } })
  let data = {}
  if (res.data) {
    data = {
      Mail: res.data.Mail,
      Origin: 'Soccerwatch',
      PartitionKey: res.data.PartitionKey,
      RowKey: res.data.RowKey,
      Roles: res.data.role || res.data.Role
    }
  }
  return { status: res.status, data }
}

/* export const getGamesList = async () => {
  return axios.get(localServer + '/schedule/all', {
    headers: { Authorization: await getAuthorization() }
  })
} */

/* export const postSchedule = async (json, value) => {
  return axios.post(localServer + '/schedule/addMatch', json, {
    headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' }
  })
} */

export const getDetectCameraOffline = async () => {
  return axios.get(localServer + '/detect/errorDetect', {
    headers: { Authorization: await getAuthorization() }
  })
}

export const getDetectCameraAwaitingGrid = async () => {
  return axios.get(localServer + '/detect/gridError', {
    headers: { Authorization: await getAuthorization() }
  })
}

export const getDetectCameraActiveCheck = async (id) => {
  return axios.get(localServer + '/detect/cameraActiveCheck/' + id, {
    headers: { Authorization: await getAuthorization() }
  })
}

export const getDetectCameraTtsCheck = async (id) => {
  return axios.get(localServer + '/detect/cameraTtsCheck/' + id, {
    headers: { Authorization: await getAuthorization() }
  })
}

export const getDetectLastCameraError = async (id, number, from, to) => {
  return axios.get(localServer + '/detect/cameraLastError/' + number + '/' + from + '/' + to + '/' + id, {
    headers: { Authorization: await getAuthorization() }
  })
}

export const getDetectPrioMatches = async () => {
  return axios.get(localServer + '/detect/cameraPrio', {
    headers: { Authorization: await getAuthorization() }
  })
}

export const getDetectNoPlantMatch = async () => {
  return axios.get(localServer + '/detect/cameraMatchesNoPlanned', {
    headers: { Authorization: await getAuthorization() }
  })
}
