import React, { Component } from 'react'
import HeadText from '../../modules/static/tableHeading'
import Paper from '@material-ui/core/Paper'
import Content from './modules/content'
import SearchFilterBar from './modules/searchFilterBar'
import discovery from '@soccerwatch/discovery'
import LinearProgress from '@material-ui/core/LinearProgress'
import ContentContainer from './modules/contentContainers'
import * as _ from 'lodash'
import io from 'socket.io-client'
import { getLazyLoading, getLazyLoadingNext, header } from '../../api/helper'
import { getCameraSystemList } from '../../api/api-camera'

import './socialMedia.scss'

class SocialMedia extends Component {
  constructor (props) {
    super(props)

    this.state = {
      rows: [],
      singleRow: undefined,
      filterRows: [],
      filterConfig: undefined,
      sortConfig: undefined,
      lastToken: undefined,
      loading: false,
      activeContainer: undefined,
      socket: undefined,
      clubsInfo: undefined,
      globalChatMsg: [],
      filterActive: false,
      cameras: []
    }
    this.handleGlobalChatMassage = this.handleGlobalChatMassage.bind(this)
    this.handleInactiveContainer = this.handleInactiveContainer.bind(this)
    this.handleActiveContainer = this.handleActiveContainer.bind(this)
    this.handleSearchAndFilter = this.handleSearchAndFilter.bind(this)
    this.handleGetMatchList = this.handleGetMatchList.bind(this)
    this.handleGetMatches = this.handleGetMatches.bind(this)
    this.handleMounted = this.handleMounted.bind(this)
    this.handleSorting = this.handleSorting.bind(this)
  }

  _isMounted = false

  handleMounted () {
    return this._isMounted
  }

  async componentDidMount () {
    const socket = io('https://chat.soccerwatch.tv/')

    socket.emit('join', 'global-admin')
    socket.on('global-admin', (msg) => {
      this.handleGlobalChatMassage(msg)
    })

    await getCameraSystemList().then(res => {
      const resObject = {}
      res.map(res => {
        resObject[res.RowKey] = res
        return null
      })

      this.setState({
        cameras: resObject
      })
    })

    this.setState({
      socket: socket
    })

    this._isMounted = true
    this.handleGetMatches()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  handleGlobalChatMassage (msg) {
    const { globalChatMsg } = this.state
    const newGlobalChatMsg = []
    Object.assign(newGlobalChatMsg, globalChatMsg)

    // delete one msg
    if (msg.deleted) {
      msg = newGlobalChatMsg.filter(msgList => {
        return msgList.id !== msg.id
      })
    }

    // add one msg
    if (msg.active === true) {
      const tempObj = msg
      tempObj.newMsg = true
      msg = newGlobalChatMsg
      msg.unshift(tempObj)
    }

    this.setState({ globalChatMsg: msg })
  }

  handleGetMatches (nextRound) {
    this.setState({ loading: true })
    const configArray = [{
      url: discovery.API_VIDEO + '/metas/',
      minEntrys: undefined,
      auth: header,
      handle: this.handleGetMatchList,
      number: 4,
      intervalStop: 6
    }]

    if (nextRound) {
      getLazyLoadingNext(configArray, this.handleMounted, this.state.rows, this.state.lastToken)
      this.setState({ lastToken: undefined })
    } else {
      getLazyLoading(configArray, this.handleMounted)
    }
  }

  setColor (match) {
    const startMatch = new Date(match.startTime)
    const dateNow = new Date()
    const cameraOnline = match.cameraObj && match.cameraObj.reachable
    const matchError = match.error !== undefined && match.error !== null && match.error !== ''
    const matchCanceledReason = match.canceledReason !== undefined && match.canceledReason !== null && match.canceledReason !== ''

    // red
    if (match.cameraObj) {
      if (matchError || matchCanceledReason) {
        return { color: '#cc7474', colorName: 'red' }
      }
      if (!cameraOnline && dateNow < startMatch) {
        return { color: '#cc7474', colorName: 'red' }
      }

      // yellow
      if (dateNow < startMatch && cameraOnline && !matchError && !matchCanceledReason && !match.active) {
        return { color: '#e3d06f', colorName: 'yellow' }
      }

      if (dateNow > startMatch && !matchError && !matchCanceledReason && !match.active) {
        return { color: '#e3d06f', colorName: 'yellow' }
      }

      // green
      if (dateNow < startMatch && cameraOnline && match.active && !matchError && !matchCanceledReason) {
        return { color: '#74cc75', colorName: 'green' }
      }

      if (dateNow > startMatch && match.active && !matchError && !matchCanceledReason) {
        return { color: '#74cc75', colorName: 'green' }
      }
    }

    return { color: '#7b7b7b70', colorName: 'grey' }
  }

  handleGetMatchList (res, checkLast, lastToken) {
    res = res.filter(row => {
      return row.clubAId !== '0' && row.league !== 'test'
    }).map((row, index) => {
      row.index = index
      row.cameraObj = this.state.cameras[row.swcsID]
      return row
    }).map(row => {
      const colorObj = this.setColor(row)
      row.cardColor = colorObj.color
      row.cardColorName = colorObj.colorName
      return row
    })

    res = _.sortBy(res, ['startTime'])

    if (checkLast === true) {
      this.setState({
        rows: res,
        lastToken: lastToken,
        loading: false
      })
    } else {
      this.setState({
        rows: res,
        lastToken: lastToken
      })
    }

    if (this.state.filterConfig) {
      const { searchInput, filter } = this.state.filterConfig
      this.handleSearchAndFilter(searchInput, filter)
    } else {
      res = res.filter(row => {
        const dateWeeks = new Date()
        const dateDays = new Date()
        dateWeeks.setDate(dateWeeks.getDate() + 14)
        dateDays.setDate(dateDays.getDate() - 1)
        return new Date(row.startTime) < dateWeeks && new Date(row.startTime) > dateDays
      })
      this.setState({
        filterRows: res
      })
    }
  }

  handleSorting (check) {
    let { filterRows } = this.state

    // reset sort
    if (check === undefined) {
      // Reset Sorting
      filterRows = _.sortBy(filterRows, 'index')
      return this.setState({ filterRows: filterRows })
    }

    // sort date
    if (check.sort.date !== undefined) {
      if (check.sort.date) {
        filterRows = _.sortBy(filterRows, ['startTime'])
      } else {
        filterRows = _.sortBy(filterRows, ['startTime']).reverse()
      }
    }

    // sort prio
    if (check.sort.prio !== undefined) {
      const splitMatchesNothing = []
      let splitMatchesPrio = []

      filterRows.map(match => {
        if (!isNaN(match.priority)) {
          return splitMatchesPrio.push(match)
        } else {
          return splitMatchesNothing.push(match)
        }
      })

      if (check.sort.prio) {
        splitMatchesPrio = _.sortBy(splitMatchesPrio, ['priority', 'RowKey'])
      } else {
        splitMatchesPrio = _.sortBy(splitMatchesPrio, ['priority', 'RowKey']).reverse()
      }

      filterRows = splitMatchesPrio.concat(splitMatchesNothing)
    }

    // sort a-z
    if (check.sort.az !== undefined) {
      if (check.sort.az) {
        filterRows = _.sortBy(filterRows, ['clubAName'])
      } else {
        filterRows = _.sortBy(filterRows, ['clubAName']).reverse()
      }
    }

    this.setState({ filterRows: filterRows })
  }

  handleSearchAndFilter (searchInput, filter) {
    let filterActive = false
    if (filter) {
      filterActive = (
        filter.paywall ||
        filter.videoType.length > 0 ||
        filter.priority.length > 0 ||
        filter.date.length > 0 ||
        filter.color.length > 0 ||
        searchInput.length > 0
      )
    }

    this.setState({
      filterConfig: { searchInput, filter },
      filterActive: filterActive
    })

    let rows = _.cloneDeep(this.state.rows)

    rows.map((row, index) => {
      row.i = index
      return null
    })

    searchInput = searchInput === undefined ? undefined : searchInput.trim()
    if (searchInput !== undefined && searchInput.length > 0) {
      if (isNaN(searchInput.trim())) {
        // search by clubname
        rows = rows.filter((row) => {
          const clubA = row.clubATeam.toLowerCase()
          const clubB = row.clubBTeam.toLowerCase()
          const checkClubA = clubA.search(searchInput.toLowerCase())
          const checkClubB = clubB.search(searchInput.toLowerCase())

          return (
            checkClubA >= 0 ||
            checkClubB >= 0
          )
        })
      } else {
        // filter clubId || matchId || cameraId
        rows = rows.filter((row) => {
          return (
            String(row.RowKey).search(searchInput) !== -1 ||
            // Number(row.RowKey) === Number(searchInput) ||
            String(row.clubAId).search(searchInput) !== -1 ||
            // Number(row.clubAId) === Number(searchInput) ||
            Number(row.swcsID) === Number(searchInput) ||
            Number(row.clubBId) === Number(searchInput)
          )
        })
      }
    }

    if (filter) {
      // filter paywall
      if (filter.paywall) {
        rows = rows.filter((row) => {
          return row.paywall
        })
      }

      // filter prio
      if (filter.priority.length > 0) {
        let newArray = []
        filter.priority.map(prio => {
          const tempArr = rows.filter((row) => {
            return row.priority === String(prio)
          })

          newArray = newArray.concat(tempArr)
          return prio
        })
        rows = newArray
      }

      // filter videoType
      if (filter.videoType.length > 0) {
        let newArray = []
        filter.videoType.map(videoType => {
          const tempArr = rows.filter((row) => {
            return row.videoType === videoType
          })

          newArray = newArray.concat(tempArr)
          return videoType
        })
        rows = newArray
      }

      // filter date
      if (filter.date.length > 0) {
        const nowDate = new Date()
        let newArray = []
        filter.date.map(prio => {
          let tempArr

          if (prio === 'coming') {
            tempArr = rows.filter((row) => {
              return new Date(row.startTime) > nowDate
            })
          } else if (prio === 'current') {
            const vDateNew = new Date()
            const bDateNew = new Date()
            const vDate = vDateNew.setHours(vDateNew.getHours() - 3)
            const bDate = bDateNew.setHours(bDateNew.getHours() + 1)
            tempArr = rows.filter((row) => {
              return new Date(row.startTime) > vDate && new Date(row.startTime) < bDate
            })
          } else if (prio === 'past') {
            tempArr = rows.filter((row) => {
              return new Date(row.startTime) < nowDate
            })
          }

          newArray = newArray.concat(tempArr)
          return prio
        })
        rows = newArray
      }
    }

    // filter color
    if (filter.color.length > 0) {
      let newArray = []
      filter.color.map(color => {
        const tempArr = rows.filter((row) => {
          return row.cardColorName === color
        })
        newArray = newArray.concat(tempArr)
        return color
      })
      rows = newArray
    }

    rows = _.sortBy(rows, 'i')

    this.setState({
      filterRows: rows
    })
  }

  handleActiveContainer (value) {
    let row
    this.state.rows.map(rowItm => {
      if (rowItm.index === value) {
        row = rowItm
      }
      return null
    })

    this.setState({
      activeContainer: value,
      singleRow: row
    })
  }

  handleInactiveContainer () {
    this.setState({ activeContainer: undefined, singleRow: undefined })
  }

  render () {
    return (
      <div>
        {this.state.activeContainer === undefined ? (
          <HeadText text={this.props.headingText} />
        ) : null}

        <Paper className='socialMedia' style={this.state.activeContainer !== undefined ? { display: 'none' } : null}>
          <SearchFilterBar onSearchAndFilter={this.handleSearchAndFilter} onSorting={this.handleSorting} />
          <div className='linearProgress'>
            {this.state.loading ? (
              <LinearProgress />
            ) : null}
          </div>
          <Content
            data={this.state.filterRows}
            rows={this.state.rows}
            onActiveContainer={this.handleActiveContainer}
            dataSize={this.state.rows.length}
            globalMsgList={this.state.globalChatMsg}
            filterActive={this.state.filterActive}
          />
        </Paper>
        {this.state.activeContainer !== undefined ? (
          <>
            <div className='headingText'>
              <a href='/'>Startseite</a> / <span style={{ cursor: 'pointer', color: '#FF3517' }} onClick={this.handleInactiveContainer}>{this.props.headingText}</span> / Match {this.state.singleRow.RowKey}
            </div>
            <Paper className='socialMedia'>
              <ContentContainer
                data={this.state.singleRow}
                onInactiveContainer={this.handleInactiveContainer}
                socket={this.state.socket}
              />
            </Paper>
          </>
        ) : null}
      </div>
    )
  }
}

export default SocialMedia
