import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import * as rax from 'retry-axios'

import { apiList, getAuthorization } from './helper'
rax.attach()

export const getRegions = async function getRegions () {
  return apiList(`${discovery.API_REGION}/regions/`, null, { headers: { Authorization: await getAuthorization() } })
}

export const setRegion = async function setRegion (regionName, body) {
  return axios.post(`${discovery.API_REGION}/region/${regionName}`, JSON.stringify(body), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}

export const getRegionLanguage = async function getRegionLanguage (regionName) {
  return axios.get(`${discovery.API_REGION}/regionLanguageFiles/${regionName}?callAs=${'admin'}`, { headers: { Authorization: await getAuthorization() } })
}

export const postRegionLanguage = async function postRegionLanguage (regionName, shortLang, body) {
  return axios.post(`${discovery.API_REGION}/regionLanguageFile/${regionName}/${shortLang}`, JSON.stringify(body), { headers: { Authorization: await getAuthorization(), 'Content-Type': 'application/json' } })
}
